import React, { Component } from 'react'
import { getStade, getMetaux, getTypeProduit, getTypeCommande } from '../../actions'
import { connect } from 'react-redux'
import FilterSelect from '../../components/filterSelect'
import FilterInput from '../../components/filterInput'
import TreeClient from '../../components/treeClient'
import { Autocomplete } from '@material-ui/lab'
import { TextField } from '@material-ui/core'
import { meekroDB } from '../../config'
import FaconTable from './FaconTable'
import Axios from 'axios'


class AnalyseFacon extends Component {
    today = new Date()
    temp = this.today.getFullYear() + '-' + ("0" + (this.today.getMonth() + 1)).slice(-2) + '-' + ("0" + this.today.getDate()).slice(-2)
    dataDevis = [{ id: 2, description: 'TOUT' }, { id: 1, description: 'DEVIS' }, { id: 0, description: 'NORMAL' }]
    dataImperatif = [{ id: 2, description: 'TOUT' }, { id: 1, description: 'IMPERATIF' }, { id: 0, description: 'NORMAL' }]
    dataTypeCommande = [{ id: 2, description: 'TOUT' }, { id: 1, description: 'TOURNEE' }, { id: 0, description: 'NORMAL' }]

    currentYear = new Date().getFullYear();

    state = {
        stade: '',
        metal: '',
        type: '',
        ref: '',
        client: '',
        tournee: null,
        debut: `${this.currentYear}-01-01`,
        fin: `${this.currentYear}-12-31`,
        devis: null,
        imperatif: null,
        typecommande: null,
        data: [],

    }

    componentDidMount() {
        this.props.getStade()
        this.props.getMetaux()
        this.props.getTypeProduit()
        this.props.getTypeCommande()
    }
    getFilter = () => {
        const data = {
            stade: this.state.stade,
            metal: this.state.metal,
            type: this.state.type,
            ref: this.state.ref,
            facture: this.state.facture,
            client: this.state.client,
            tournee: this.state.tournee ? this.state.tournee.id : 2,
            debut: this.state.debut,
            fin: this.state.fin,
            imperatif: this.state.imperatif ? this.state.imperatif.id : 2,
            type_commande: this.state.typecommande ? this.state.typecommande : 0,

        }
        return data
    }
    onChange = (data, labelState) => {
        const label = labelState.toLowerCase()
        this.setState({ [label]: data })  //, () => this.refreshLists()
    }
    handleChangeClient = (data) => {
        this.setState({ client: data })  //, () => this.refreshLists()
    }

    refreshLists = () => {
        try {
            Axios.put(`${meekroDB}/analyse/analyse_ca.php?action=facon`, this.getFilter())
                .then(response => {
                    this.setState({ data: response.data });
                })
                .catch(error => {
                    console.log(error);
                });
        } catch (error) {
            console.error("Une erreur s'est produite lors de la mise à jour des listes :", error);
        }
    }



    render() {
        const { data } = this.state;
        return (
            <div>
                <div className='row ' >
                    <div className="col-auto" style={{ minWidth: 275, maxWidth: 275 }} >
                        <TreeClient handleChange={this.handleChangeClient} />
                    </div>

                    <div className="col-auto" >
                        <div className='form-group' style={{ width: "165px" }}>
                            <TextField type='date' value={this.state.debut} onChange={e => e.target.value && this.onChange(e.target.value, 'debut')} label='Debut' variant="outlined" margin="dense" />
                            <TextField type='date' value={this.state.fin} onChange={e => e.target.value && this.onChange(e.target.value, 'fin')} label='Fin' variant="outlined" margin="dense" />
                            <Autocomplete
                                options={this.dataTypeCommande}
                                getOptionLabel={(option) => option.description}
                                onChange={(e, values) => this.setState({ tournee: values })} //, () => this.refreshLists()
                                autoHighlight
                                autoComplete
                                clearOnEscape
                                style={{ width: "165px" }}
                                renderInput={(params) => <TextField  {...params} label="Type Commande" variant="outlined" margin="dense" size='small' />}
                            />
                            <Autocomplete
                                options={this.dataImperatif}
                                getOptionLabel={(option) => option.description}
                                onChange={(e, values) => this.setState({ imperatif: values })} //, () => this.refreshLists()
                                autoHighlight
                                autoComplete
                                clearOnEscape
                                style={{ width: "165px" }}
                                renderInput={(params) => <TextField  {...params} label="Impératif" variant="outlined" margin="dense" size='small' />}
                            />

                        </div>

                    </div>
                    <div className="col-sm" style={{ padding: '0 2px' }}>
                        <FilterSelect label='Type Commande' labelId='id' labelDescription="label" onChange={(e) => { this.onChange(e, 'typeCommande') }} options={this.props.listeTypeCommande} />
                    </div>
                    <div className="col-sm" >
                        <FilterSelect label='Stade' labelId='id_stade' onChange={this.onChange} options={this.props.listeStade} />
                    </div>
                    <div className="col-sm" >
                        <FilterSelect label='Metal' labelId='id_type' onChange={this.onChange} options={this.props.listeMetaux} />
                    </div>
                    <div className="col-sm" >
                        <FilterSelect label='Type' labelId='id_typ_prod' onChange={this.onChange} options={this.props.listeTypeProduit} />
                    </div>
                    <div className="col-auto" >
                        <FilterInput label='Ref' type='text' onChange={this.onChange} />
                    </div>
                    <div className="col-sm" >
                        <button style={{ minHeight: 200 }} className='btn btn-primary btn-lg' onClick={() => this.refreshLists()}>Afficher</button>
                    </div>


                </div>
                {data.length > 0 && <FaconTable data={data} />}
            </div >
        )
    }
}

const mapStateToProps = (state) => {
    return {
        listeStade: state.generalData.listeStade,
        listeTypeProduit: state.generalData.listeTypeProduit,
        listeMetaux: state.generalData.listeMetaux,
        listeTypeCommande: state.generalData.typeCommande,


    }
}
const mapDispatchToProps = {
    getStade,
    getMetaux,
    getTypeProduit,
    getTypeCommande,
}
export default connect(mapStateToProps, mapDispatchToProps)(AnalyseFacon)