import React, { Component } from 'react'
import { getListeProduit, setProduit } from '../actions'
import { connect } from 'react-redux'
import SearchProd from '../components/searchProd'
import FicheProduit from '../components/ficheProduit'


class produit extends Component {
    state = {
        matchesProduct: [],
    }
    componentDidMount() {
        this.props.getListeProduit()
    }
    handleSelect = (produit) => {
        //const matchesProduct = Object.values(this.props.listeProduit).filter(r => r.ref.toString().indexOf(produit.ref) > -1)

        this.props.setProduit(produit.id_cv, produit.id_centre)

    }

    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-8">
                        <FicheProduit />
                    </div>
                    <div className="col-4">
                        <SearchProd handleSelect={this.handleSelect} />
                    </div>

                </div>
            </div >

        )
    }
}

const mapStateToProps = (state) => {
    return {
        listeProduit: state.produit.listeProduit,
    }
}
const mapDispatchToProps = {
    getListeProduit,
    setProduit,
}
export default connect(mapStateToProps, mapDispatchToProps)(produit)