import React, { useState, useEffect, useRef } from 'react';
import { Button, LinearProgress, Typography, Box, Paper, List, ListItem, ListItemText } from '@material-ui/core';
import axios from 'axios';

const UpdateWordpress = () => {
    const [progress, setProgress] = useState(0);
    const [status, setStatus] = useState('');
    const [consoleMessages, setConsoleMessages] = useState([]);
    const consoleEndRef = useRef(null);

    const handleUpdate = async () => {
        setConsoleMessages([]);
        setStatus('Récupération des images...');
        setProgress(10);
        try {
            // Étape 1 : Récupérer les produits via l'API
            const { data } = await axios.get('https://extensia-france.com/api/wordpress/get_product.php');
            const products = data;
            setProgress(30);

            // Étape 2 : Vérifier et uploader les images si besoin
            const uploadedImages = new Set();
            const imageUploadPromises = [];
            for (let product of products) {
                for (let variation of product.produits) {
                    if (!uploadedImages.has(variation.image)) {
                        const formData = new FormData();
                        formData.append('image', variation.image);
                        const uploadPromise = axios.post('https://boutique.extensia-joaillerie.fr/api/wp_upload.php', formData)
                            .then(() => {
                                setConsoleMessages(prev => [...prev, `Image ${variation.image} récupérée`]);
                            });
                        imageUploadPromises.push(uploadPromise);
                        uploadedImages.add(variation.image);
                    }
                }
            }
            await Promise.all(imageUploadPromises);
            setConsoleMessages(prev => [...prev, " ", "Mise à jour des produits..."]);
            setProgress(60);

            // Étape 3 : Créer ou mettre à jour les produits sur WordPress
            const productUpdatePromises = products.map(product =>
                axios.post('https://boutique.extensia-joaillerie.fr/api/wp_import_products.php', product)
                    .then(() => {
                        setConsoleMessages(prev => [...prev, `Produit ${product.ref} mis à jour`]);
                    })
            );
            await Promise.all(productUpdatePromises);
            setProgress(100);
            setStatus('Mise à jour terminée avec succès !');
        } catch (error) {
            console.error('Erreur lors de la mise à jour :', error);
            setStatus('Une erreur est survenue.');
        }
    };

    useEffect(() => {
        if (consoleEndRef.current) {
            consoleEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [consoleMessages]);

    return (
        <Box sx={{ width: '50%', maxWidth: 600, margin: '0 auto', textAlign: 'center', padding: 2 }}>
            <Typography variant="h6" gutterBottom>
                Mise à jour des produits sur WordPress
            </Typography>
            <Button variant="contained" color="primary" onClick={handleUpdate}>
                Mise à jour WordPress
            </Button>
            {progress > 0 && (
                <Box sx={{ width: '100%', mt: 2 }}>
                    <LinearProgress variant="determinate" value={progress} />
                    <Typography variant="body1" style={{ marginTop: 8 }}>
                        {status}
                    </Typography>
                </Box>
            )}
            {consoleMessages.length > 0 && (
                <Paper elevation={3} style={{ marginTop: 16, padding: 16, maxHeight: 400, overflow: 'auto', backgroundColor: '#1e1e1e', color: '#d4d4d4', fontFamily: 'monospace' }}>
                    <Typography variant="h6" style={{ color: '#d4d4d4' }}>Console</Typography>
                    <List dense>
                        {consoleMessages.map((message, index) => (
                            <ListItem key={index} style={{ paddingTop: 2, paddingBottom: 2 }}>
                                <ListItemText primary={message} />
                            </ListItem>
                        ))}
                        <div ref={consoleEndRef} />
                    </List>
                </Paper>
            )}
        </Box>
    );
};

export default UpdateWordpress;
