import React, { useEffect, useState } from 'react';
import { Box, Button, Container, Grid } from '@material-ui/core';
import TableProduit from './TableProduit';
import TableStock from './TableStock';
import TreeClient from '../../treeClient';
import Axios from 'axios';
import { apiATC } from '../../../config';

const ReservationCollection = () => {
    const [client, setClient] = useState(null);
    const [produitSelectionne, setProduitSelectionne] = useState(null);
    const [stockSelectionne, setStockSelectionne] = useState(null);
    const [commande, setCommande] = useState([]);
    const [stock, setStock] = useState([]);

    useEffect(() => {
        afficher();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    const afficher = () => {
        const params = { client };
        Axios.post(`${apiATC}/produit/operation_stock.php?action=reservation`, params)
            .then(({ data: { commande, stock } }) => {
                // Vérifier si les données commande et stock sont présentes
                if (!commande || !stock) {
                    // Gérer le cas où les données sont manquantes
                    console.error("Données de commande ou de stock manquantes");
                    return;
                }

                const updatedCommande = [];
                commande.forEach(cmd => {
                    const stockElement = stock.find(stk =>
                        stk.idp === cmd.idp && stk.type_metal === cmd.type_metal
                    );
                    const dejaReserve = stockElement && stockElement.client_reserve ? true : false;
                    updatedCommande.push({ ...cmd, dejaReserve });
                });

                setCommande(updatedCommande);

                setStock(stock);
                setProduitSelectionne(null)
            })
            .catch(error => {
                // Gérer les erreurs de la requête Axios
                console.error("Une erreur s'est produite lors de la récupération des données:", error);
            });
    };




    const onRowClickProduit = (data) => {
        setProduitSelectionne(data);
    }
    const onRowClickStock = (data) => {
        setStockSelectionne(data)
    }
    const handleChangeClient = (data) => {
        setClient(data);
    };

    const modifierReservation = (type) => {
        try {
            if ((type === 'AJOUTER' && (!produitSelectionne || !stockSelectionne)) ||
                (type === 'DERESERVER' && !stockSelectionne)) {
                return;
            }

            const verif = {
                table: 'of',
                primaryKey: 'of_reservation',
                id: stockSelectionne.of,
                of_reservation: 0,
            };
            const form = {
                table: 'of',
                primaryKey: type === 'AJOUTER' ? 'num_sachet' : 'of_reservation',
                id: type === 'AJOUTER' ? produitSelectionne.of : stockSelectionne.of,
                of_reservation: type === 'AJOUTER' ? stockSelectionne.of : 0,
            };

            try {
                Axios.post(`${apiATC}/general.php?action=modifier`, verif)
                    .then(() => {
                        return Axios.post(`${apiATC}/general.php?action=modifier`, form);
                    })
                    .then(() => {
                        afficher();
                    })
                    .catch(error => {
                        console.error('Erreur lors de la mise à jour du statut:', error);
                    });
            } catch (error) {
                console.error('Erreur inattendue lors de la mise à jour du statut:', error);
            }
        } catch (error) {
            console.error('Erreur inattendue:', error);
        }
    };

    const isReservationPossible = produitSelectionne && stockSelectionne && !stockSelectionne.client_reserve;
    const isDereservationPossible = stockSelectionne && stockSelectionne.client_reserve;

    return (
        <Container maxWidth={false}>
            <Grid container spacing={2} mt={2} >
                <Grid item xs={5} sm={5}>
                    <TableProduit data={commande} onRowClick={onRowClickProduit} />
                </Grid>
                <Grid item xs={5} sm={5}>
                    <TableStock data={stock} onRowClick={onRowClickStock} filtreProduit={produitSelectionne} />
                </Grid>
                <Grid item xs={2} sm={2}>
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <TreeClient handleChange={handleChangeClient} />
                        <Button onClick={afficher} variant="contained" color="primary" style={{ marginTop: '20px', width: '200px', height: '60px' }}>Afficher</Button>
                        <Button disabled={!isDereservationPossible} onClick={() => modifierReservation('DERESERVER')} variant="contained" color="secondary" style={{ marginTop: '20px', width: '200px', height: '60px' }}>Dereserver</Button>
                        <Button disabled={!isReservationPossible} onClick={() => modifierReservation('AJOUTER')} variant="contained" color="primary" style={{ marginTop: '20px', width: '200px', height: '60px' }}>Reserver</Button>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};

export default ReservationCollection;
