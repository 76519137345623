import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getListeImage } from '../../actions';
import { TextField, List, ListItem, Box, Typography, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Axios from 'axios';
import { imagesRepo } from '../../config';

const DeleteConfirmationDialog = ({ open, onConfirm, onCancel, imageToDelete }) => {
    return (
        <Dialog open={open} onClose={onCancel}>
            <DialogTitle style={{ color: 'black', fontSize: '1.2rem' }}>{`Voulez-vous vraiment supprimer l'image ${imageToDelete} ?`}</DialogTitle>
            <DialogContent style={{ display: 'flex', alignItems: 'center' }}>
                <img
                    src={imagesRepo + imageToDelete}
                    alt={imageToDelete}
                    style={{ height: '100px', marginRight: '10px' }}
                />
                <Typography variant="body1">{imageToDelete}</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} color="primary">
                    Non
                </Button>
                <Button onClick={onConfirm} color="primary">
                    Oui
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const RenameConfirmationDialog = ({ open, onConfirm, onCancel, imageToRename, onRenameInputChange, renameInputValue }) => {
    return (
        <Dialog open={open} onClose={onCancel}>
            <DialogTitle style={{ color: 'black', fontSize: '1.2rem' }}>{`Voulez-vous vraiment renommer l'image ${imageToRename} ?`}</DialogTitle>
            <DialogContent style={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                    label="Nouveau nom"
                    value={renameInputValue}
                    onChange={onRenameInputChange}
                    variant="outlined"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} color="primary">
                    Annuler
                </Button>
                <Button onClick={onConfirm} color="primary">
                    Valider
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const ListeImages = ({ filtre, onFiltreChange, onImageSelect, selectedImage, setSelectedImage }) => {
    const dispatch = useDispatch();
    const listeImage = useSelector(state => state.generalData.listeImage);
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [renameConfirmationOpen, setRenameConfirmationOpen] = useState(false);
    const [imageToDelete, setImageToDelete] = useState(null);
    const [imageToRename, setImageToRename] = useState(null);
    const [renameInputValue, setRenameInputValue] = useState('');

    useEffect(() => {
        if (filtre.length >= 2) {
            dispatch(getListeImage());
        }
    }, [dispatch, filtre]);

    const handleFiltreChange = (reference) => {
        onFiltreChange(reference);
    };

    const handleImageClick = (img) => {
        const newSelectedImage = selectedImage === img ? null : img;
        setSelectedImage(newSelectedImage);
        onImageSelect(newSelectedImage);
    };

    const handleDeleteImage = (img) => {
        setImageToDelete(img);
        setConfirmationOpen(true);
    };

    const handleRenameImage = (img) => {
        setImageToRename(img);
        setRenameInputValue(img); // Set initial value to the current image name
        setRenameConfirmationOpen(true);
    };

    const handleConfirmationYes = () => {
        setConfirmationOpen(false);

        const formData = new FormData();
        formData.append('nomImage', imageToDelete);

        Axios.post(
            `${imagesRepo}/delete_img.php`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }
        )
            .then(response => {
                console.log(response.data);
                dispatch(getListeImage());
            })
            .catch(error => {
                console.error(error);
                // Gérez les erreurs ici
            });
    };

    const handleConfirmationNo = () => {
        setConfirmationOpen(false);
    };

    const handleRenameConfirmationYes = () => {
        setRenameConfirmationOpen(false);

        const formData = new FormData();
        formData.append('ancien_nom', imageToRename);
        formData.append('nouveau_nom', renameInputValue);

        Axios.post(
            `${imagesRepo}/rename_img.php`,  // Remplacez par le chemin correct de votre script PHP
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }
        )
            .then(response => {
                console.log(response.data);
                dispatch(getListeImage());
            })
            .catch(error => {
                console.error(error);
                // Gérez les erreurs ici
            });
    };

    const handleRenameConfirmationNo = () => {
        setRenameConfirmationOpen(false);
    };

    return (
        <Box>
            <TextField
                label="Rechercher des images..."
                variant="outlined"
                value={filtre}
                onChange={e => handleFiltreChange(e.target.value)}
                fullWidth
                style={{ marginBottom: '10px' }}
            />
            <List style={{ width: '100%' }}>
                {listeImage
                    .filter(img => img.includes(filtre) && filtre.length >= 2)
                    .map(img => (
                        <ListItem
                            key={img}
                            button
                            onClick={() => handleImageClick(img)}
                            selected={selectedImage === img}
                            style={{
                                backgroundColor: selectedImage === img ? '#4285f4' : 'transparent',
                                color: selectedImage === img ? '#ffffff' : 'inherit',
                                transition: 'none',
                                display: 'flex',
                                alignItems: 'center', // Align items vertically
                                width: '100%',
                            }}
                        >
                            <img src={imagesRepo + img} alt={img} style={{ height: '100px' }} />
                            <Typography variant="body1">{img}</Typography>
                            <div style={{ marginLeft: 'auto', display: 'flex', gap: '10px' }}>
                                <IconButton onClick={() => handleRenameImage(img)}>
                                    <EditIcon />
                                </IconButton>
                                <IconButton onClick={() => handleDeleteImage(img)}>
                                    <DeleteIcon />
                                </IconButton>
                            </div>
                        </ListItem>

                    ))}
            </List>

            <DeleteConfirmationDialog
                open={confirmationOpen}
                onConfirm={handleConfirmationYes}
                onCancel={handleConfirmationNo}
                imageToDelete={imageToDelete}
            />

            <RenameConfirmationDialog
                open={renameConfirmationOpen}
                onConfirm={handleRenameConfirmationYes}
                onCancel={handleRenameConfirmationNo}
                imageToRename={imageToRename}
                onRenameInputChange={(e) => setRenameInputValue(e.target.value)}
                renameInputValue={renameInputValue}
            />
        </Box>
    );
};

export default ListeImages;
