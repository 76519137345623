import { GET_LISTE_AVOIR } from '../actions/action-types';

const initialState = {
    listeAvoir: []
}


export default function FactureReducer(state = initialState, action) {
    switch (action.type) {

        case GET_LISTE_AVOIR:
            return {
                ...state,
                listeAvoir: action.payload
            }
        default:
            return state;

    }

}
