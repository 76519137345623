import Axios from 'axios'
import { List } from 'immutable';
import MaterialTable, { MTableToolbar } from 'material-table';
import React, { useState } from 'react'
import { localization, meekroDB } from '../../config';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import TracerOf from '../../containers/tracerOf';
const TraceIdPierre = ({ id }) => {
    const [data, setData] = useState([])
    const [open, setOpen] = useState(false)
    const [ofToTrace, setOfToTrace] = useState('')

    Axios.get(`${meekroDB}/pierre/historique_conso.php?id=${id}`)
        .then((response) => {
            //console.log(response);
            setData(response.data)
        })
        .catch(error => {
            console.log(error);
        });

    const tracerOf = (of) => {
        //this.props.getOfFromId(of)
        setOpen(true);
        setOfToTrace(of);
    }
    return (
        <div className='container-fluid'>
            <MaterialTable
                style={{ fontSize: 12 }}

                columns={[
                    { title: 'Of', field: 'num_sachet', type: 'numeric' },
                    { title: 'Ref', field: 'ref', type: 'numeric' },
                    { title: 'Description', field: 'reference', width: 400, align: 'center' },
                    { title: 'Conso', field: 'poids_sortant', type: 'numeric' },
                    { title: 'Date', field: 'date_op', align: 'center' },
                    { title: 'Statut', field: 'statut' },

                ]}

                options={{
                    pageSize: 10,
                    actionsColumnIndex: -1,
                    autoSizeColumns: true,
                }}
                actions={[
                    {
                        icon: 'query_stats',
                        tooltip: 'Tracer Of', onClick: (e, rowData) => tracerOf(rowData.num_sachet),
                        position: 'row'
                    },

                ]}
                data={List(data).toJS()}
                title={"Historique Conso Id " + id}

                components={{
                    Toolbar: props => (
                        <div>
                            <MTableToolbar {...props} />
                        </div>
                    ),
                }}

                localization={localization}
            />
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                fullWidth={true}
                maxWidth={'xl'}
            >
                <DialogTitle id="form-dialog-title">Tracer OF</DialogTitle>
                <DialogTitle style={{ position: 'absolute', right: 40 }} onClick={() => setOpen(false)}><button className="btn btn-link">Fermer</button></DialogTitle>
                <DialogContent>
                    <TracerOf of={ofToTrace} open={open} />
                </DialogContent>

            </Dialog>
        </div >
    )
}


export default (TraceIdPierre)