import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper, Typography } from '@material-ui/core';
import DetailOf from './DetailOf';
import FiltrePlanning from './FiltrePlanning';
import html2pdf from 'html2pdf.js';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        textAlign: 'center',
    },
    gridContainer: {
        width: '100%',
        alignItems: 'flex-start',
        justifyContent: 'center',
    },
    paper: {
        padding: '0',
        textAlign: 'center',
        color: theme.palette.text.secondary,
        minHeight: '100px',
        borderRadius: '5px',
        marginBottom: '10px',
        backgroundColor: 'transparent',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    title: {
        marginBottom: '20px',
    },
    dayNumber: {
        fontSize: '1.2rem',
        fontWeight: 'bold',
    },
}));

const PlanningHebdomadaire = () => {
    const classes = useStyles();
    const [semaine, setSemaine] = useState([]);
    const [listeOfs, setListeOfs] = useState([]);

    const handleFiltre = (data) => {
        setSemaine(joursDeLaSemaine(data.dateF));
        setListeOfs(data.dataOfs);
    };

    const joursDeLaSemaine = (date) => {
        const joursSemaine = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi'];
        const joursAvecDates = [];

        const dateObj = new Date(date);
        const jourDeLaSemaine = dateObj.getDay();
        const debutSemaine = new Date(dateObj);
        debutSemaine.setDate(dateObj.getDate() - jourDeLaSemaine + (jourDeLaSemaine === 0 ? -6 : 1));

        for (let i = 0; i < joursSemaine.length; i++) {
            const jour = new Date(debutSemaine);
            jour.setDate(debutSemaine.getDate() + i);
            joursAvecDates.push({
                jour: joursSemaine[i],
                date: jour,
            });
        }
        return joursAvecDates;
    };

    const imprimerVuePDF = () => {
        const element = document.getElementById('planning');
        const options = {
            filename: 'planning_hebdomadaire.pdf',
            jsPDF: { unit: 'px', format: 'a4', orientation: 'landscape' },
            html2canvas: { scale: 1, width: element.offsetWidth, height: element.offsetHeight },
        };
        html2pdf().from(element).set(options).save();
    };

    return (
        <div className={classes.root}>
            <FiltrePlanning setListeOfs={handleFiltre} print={imprimerVuePDF} />
            <Grid container spacing={1} className={classes.gridContainer} id="planning">
                {semaine.map((day) => (
                    <Grid item style={{ width: '20%' }} key={day.jour}>
                        <Paper className={classes.paper} style={{ minHeight: 'auto', padding: '5px' }}>
                            <Typography variant="h6">{day.jour.toUpperCase()}</Typography>
                            <Typography variant="subtitle2" className={classes.dayNumber}>{day.date.toLocaleDateString('fr-FR', { day: '2-digit', month: '2-digit' })}</Typography>
                        </Paper>
                        <Paper className={classes.paper}>
                            <div style={{ padding: '0', flex: '1' }}>
                                <DetailOf data={listeOfs.filter(of => new Date(of.date_livr).toDateString() === day.date.toDateString())} />
                            </div>
                        </Paper>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
};

export default PlanningHebdomadaire;
