import { TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { addDayToToday, getTodaySqlFormat } from '../helpers/functions';
import { Autocomplete } from '@material-ui/lab';

const CreateTraiteFournisseur = () => {
    const [crea, setCrea] = useState(getTodaySqlFormat());
    const [eche, setEche] = useState(addDayToToday(30));
    const [valeur, setValeur] = useState('');
    const [nbDecoupe, setNbDecoupe] = useState(1);
    const [numEffet, setNumEffet] = useState(1500);

    const [raisonSociale, setRaisonSociale] = useState('');
    const [codeAgence, setCodeAgence] = useState('');
    const [codeGuichet, setCodeGuichet] = useState('');
    const [compte, setCompte] = useState('');
    const [cleRib, setCleRib] = useState('');
    const [globalVille, setGlobalVille] = useState('');

    const [leCompteSupplier, setLeCompteSupplier] = useState('30004 03120 00011413276 16');
    const [raisonSocialeSupplier, setRaisonSocialeSupplier] = useState('SHARON SOUSSANA');


    const banks = [
        { value: '1', label: 'LCL' },
        { value: '2', label: 'CREDIT MUTUEL' },
        { value: '3', label: 'BANQUE POPULAIRE' },
        { value: '4', label: 'PERSONNALISE' }
    ];

    const suppliers = [
        { value: '1', label: 'SHARON SOUSSANA' },
        // { value: '2', label: 'VIOLLAND' },
        // { value: '3', label: 'BROCHIER' },
        { value: '4', label: 'PERSONNALISE' }
    ];

    const handleBankChange = (data) => {
        if (data === '1') {
            setRaisonSociale('LCL');
            setCodeAgence('30002');
            setCodeGuichet('02800');
            setCompte('0000901959Y');
            setCleRib('74');
            setGlobalVille('LCL MARSEILLE');
        }
        else if (data === '2') {
            setRaisonSociale('CREDIT MUTUEL');
            setCodeAgence('10278');
            setCodeGuichet('00866');
            setCompte('00020307601');
            setCleRib('60');
            setGlobalVille('CRCMM MARSEILLE ENTREPRISES');
        } else if (data === '3') {
            setRaisonSociale('BANQUE POPULAIRE');
            setCodeAgence('14607');
            setCodeGuichet('00075');
            setCompte('70721002745');
            setCleRib('51');
            setGlobalVille('BANQUE POPULAIRE MEDITERRANEE');
        } else {
            setRaisonSociale('');
            setCodeAgence('');
            setCodeGuichet('');
            setCompte('');
            setCleRib('');
            setGlobalVille('');
        }
    };

    const handleBankSupplier = (data) => {
        if (data === '1') {
            setLeCompteSupplier('30004 03120 00011413276 16');
            setRaisonSocialeSupplier('SHARON SOUSSANA');
        } else if (data === '2') {
            setLeCompteSupplier('9876543210');
            setRaisonSocialeSupplier('VIOLAND');
        }
        else if (data === '3') {
            setLeCompteSupplier('30002 ');
            setRaisonSocialeSupplier('BROCHIER');
        } else {
            setLeCompteSupplier('');
            setRaisonSocialeSupplier('');
        }
    };


    const createTraite = () => {
        const variablesManquantes = [];

        if (!crea && crea === "") variablesManquantes.push("Date Création");
        if (!eche && eche === "") variablesManquantes.push("Date Echéance");
        if (!numEffet && numEffet === "") variablesManquantes.push("N° Effet");
        if (!valeur && valeur === "") variablesManquantes.push("Montant TTC");
        if (!nbDecoupe && nbDecoupe === "") variablesManquantes.push("Nombre Découpe");
        if (!raisonSociale && raisonSociale === "") variablesManquantes.push("Raison Sociale");
        if (!codeAgence && codeAgence === "") variablesManquantes.push("Code Agence");
        if (!codeGuichet && codeGuichet === "") variablesManquantes.push("Code Guichet");
        if (!compte && compte === "") variablesManquantes.push("Compte");
        if (!cleRib && cleRib === "") variablesManquantes.push("Clé Rib");
        if (!globalVille && globalVille === "") variablesManquantes.push("Ville");
        if (!leCompteSupplier && leCompteSupplier === "") variablesManquantes.push("Compte Fournisseur");
        if (!raisonSocialeSupplier && raisonSocialeSupplier === "") variablesManquantes.push("Raison Sociale Fournisseur");


        if (variablesManquantes.length > 0) {
            const message = "Il manque les variables suivantes : " + variablesManquantes.join(", ");
            alert(message);
        } else {
            const params = '?datec=' + crea
                + '&datee=' + eche
                + '&num_effet=' + numEffet
                + '&montant=' + valeur
                + '&nbtraite=' + nbDecoupe
                + '&raisonSociale=' + raisonSociale
                + '&codeAgence=' + codeAgence
                + '&codeGuichet=' + codeGuichet
                + '&compte=' + compte
                + '&cleRib=' + cleRib
                + '&globalVille=' + globalVille
                + '&leCompteSupplier=' + leCompteSupplier
                + '&raisonSocialeSupplier=' + raisonSocialeSupplier;

            window.open(`https://www.extensia-france.com/printers/traite_fournisseur.php${params}`, "_blank");
        }
    };



    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-3" >
                    <div className='form-group'>
                        <Autocomplete
                            options={banks}
                            getOptionLabel={(option) => option.label}
                            getOptionSelected={(option, value) => option.value === value.value}
                            onChange={(event, value) => handleBankChange(value?.value || '')}
                            renderInput={(params) => <TextField {...params} label="Banque" variant="outlined" margin="dense" />}
                        />
                    </div>
                    <div className='form-group'>
                        <TextField fullWidth label='Raison Sociale' value={raisonSociale} onChange={(e) => setRaisonSociale(e.target.value)} variant="outlined" margin="dense" />
                    </div>
                    <div className='form-group'>
                        <TextField fullWidth label='Code Agence' value={codeAgence} onChange={(e) => setCodeAgence(e.target.value)} variant="outlined" margin="dense" />
                    </div>
                    <div className='form-group'>
                        <TextField fullWidth label='Code Guichet' value={codeGuichet} onChange={(e) => setCodeGuichet(e.target.value)} variant="outlined" margin="dense" />
                    </div>
                    <div className='form-group'>
                        <TextField fullWidth label='Compte' value={compte} onChange={(e) => setCompte(e.target.value)} variant="outlined" margin="dense" />
                    </div>
                    <div className='form-group'>
                        <TextField fullWidth label='Clé RIB' value={cleRib} onChange={(e) => setCleRib(e.target.value)} variant="outlined" margin="dense" />
                    </div>
                    <div className='form-group'>
                        <TextField fullWidth label='Ville' value={globalVille} onChange={(e) => setGlobalVille(e.target.value)} variant="outlined" margin="dense" />
                    </div>
                </div>
                <div className="col-3">
                    <div className='form-group'>
                        <Autocomplete
                            options={suppliers}
                            getOptionLabel={(option) => option.label}
                            onChange={(event, value) => handleBankSupplier(value?.value || '')}
                            getOptionSelected={(option, value) => option.value === value.value}
                            renderInput={(params) => <TextField fullWidth {...params} label="Fournisseur" variant="outlined" margin="dense" />}
                            value={suppliers.find(option => option.value === '1')}
                        />
                    </div>
                    <div className='form-group'>
                        <TextField fullWidth label='Raison Sociale' value={raisonSocialeSupplier} onChange={(e) => setRaisonSocialeSupplier(e.target.value)} variant="outlined" margin="dense" />
                    </div>
                    <div className='form-group'>
                        <TextField fullWidth label='Compte' value={leCompteSupplier} onChange={(e) => setLeCompteSupplier(e.target.value)} variant="outlined" margin="dense" />
                    </div>
                </div>
                <div className="col-auto">
                    <div className='form-group'>
                        <TextField fullWidth type='date' value={crea} onChange={(e) => setCrea(e.target.value)} label='Création' variant="outlined" margin="dense" />
                    </div>
                    <div className='form-group'>
                        <TextField fullWidth type='date' value={eche} onChange={(e) => setEche(e.target.value)} label='Echéance' variant="outlined" margin="dense" />
                    </div>
                    <div className='form-group'>
                        <TextField fullWidth value={valeur} onChange={(e) => setValeur(e.target.value)} label='Total TTC' variant="outlined" margin="dense" />
                    </div>
                    <div className='form-group'>
                        <TextField fullWidth value={numEffet} onChange={(e) => setNumEffet(e.target.value)} label='N° Effet' variant="outlined" margin="dense" />
                    </div>
                    <div className='form-group'>
                        <TextField fullWidth value={nbDecoupe} onChange={(e) => setNbDecoupe(e.target.value)} label='Nb Découpe' variant="outlined" margin="dense" />
                    </div>
                    <button className="btn btn-link btn-sm" onClick={createTraite}>Créer Traite</button>
                </div>
            </div>
        </div>
    );
};

export default CreateTraiteFournisseur;
