import { GET_ANALYSE_CA, GET_ANALYSE_CA_TARIF, GET_ANALYSE_FOURNISSEUR, GET_ANALYSE_METAL, GET_ANALYSE_TRANCHE, GET_CA_GROUPED_BY_MONTH, GET_COMPARAION_CA_GROUPED_BY_MONTH, GET_COMPARAION_CA_GROUPED_BY_YEAR, GET_OF_EN_COURS, GET_OF_LIVRE, GET_OF_LIVRE_EXTENSIA } from '../actions/action-types';

const initialState = {
    listeOfEnCours: [],
    listeOfLivre: [],
    listeOfLivreExtensia: [],
    caByMonth: [],
    caComparaisonByYear: [],
    caComparaisonByMonth: [],
    analyseCa: [],
    analyseMetal: [],
    analyseFournisseur: [],
    analyseCaTranche: [],
    analyseCaTarif: [],
}


export default function CoursReducer(state = initialState, action) {
    switch (action.type) {

        case GET_OF_EN_COURS:
            return {
                ...state,
                listeOfEnCours: action.payload
            }
        case GET_OF_LIVRE:
            return {
                ...state,
                listeOfLivre: action.payload
            }
        case GET_OF_LIVRE_EXTENSIA:
            return {
                ...state,
                listeOfLivreExtensia: action.payload
            }
        case GET_CA_GROUPED_BY_MONTH:
            return {
                ...state,
                caByMonth: action.payload
            }
        case GET_COMPARAION_CA_GROUPED_BY_MONTH:
            return {
                ...state,
                caComparaisonByMonth: action.payload
            }
        case GET_COMPARAION_CA_GROUPED_BY_YEAR:
            return {
                ...state,
                caComparaisonByYear: action.payload
            }
        case GET_ANALYSE_CA:
            return {
                ...state,
                analyseCa: action.payload
            }
        case GET_ANALYSE_METAL:
            return {
                ...state,
                analyseMetal: action.payload
            }
        case GET_ANALYSE_FOURNISSEUR:
            return {
                ...state,
                analyseFournisseur: action.payload
            }
        case GET_ANALYSE_TRANCHE:
            return {
                ...state,
                analyseCaTranche: action.payload
            }
        case GET_ANALYSE_CA_TARIF:
            return {
                ...state,
                analyseCaTarif: action.payload
            }

        default:
            return state;

    }

}
