import React from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';

const HighlightedText = ({ children }) => (
    <span style={{ fontWeight: 'bold' }}>{children}</span>
);

const DonneeJoaillierRepport = ({ nombreOf, nombreSav, tempsTotal, tempsPasse, tempsTheorique }) => {
    const productivite = tempsPasse && tempsTheorique ? Math.round(tempsTheorique * 100 / tempsPasse) : 0;

    return (
        <Card style={{ height: '100%' }}>
            <CardContent style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Typography variant="h5" gutterBottom>
                    <HighlightedText>Resumé</HighlightedText>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <HighlightedText>{nombreOf}</HighlightedText> Produit(s) Réalisé(s)
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <HighlightedText>{nombreSav}</HighlightedText> SAV(s) Réalisé(s)
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <HighlightedText>{tempsTotal}</HighlightedText> Minutes
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <HighlightedText>{Math.round(tempsTotal / 60)}</HighlightedText> Heures
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <HighlightedText>{Math.round(tempsTotal / 1440 * 100) / 100}</HighlightedText> Jours
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Productivité <HighlightedText>{productivite}</HighlightedText> %
                </Typography>
            </CardContent>
        </Card>
    );
};

export default DonneeJoaillierRepport;
