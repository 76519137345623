import MaterialTable, { MTableToolbar } from 'material-table'
import React, { useEffect, useState } from 'react'
import { localization, meekroDB } from '../../config'
import { getListeOfSerti, getListeSertisseur } from '../../actions'
import { connect } from 'react-redux'
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, makeStyles, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import Axios from 'axios'
import { getTodaySqlFormat } from '../../helpers/functions'

const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing(1),
    },
}));

const TableSertisseur = ({ listeOfSerti, listeSertisseur, getListeSertisseur, getListeOfSerti, of = null, enCours=true }) => {
    const classes = useStyles();
    const [edit, setEdit] = useState(false)
    const [retour, setRetour] = useState(false)
    const [sertisseur, setSertisseur] = useState(null)
    const [idLigne, setIdLigne] = useState('')
    const [poids, setPoids] = useState('')
    const [poidsRendu, setPoidsRendu] = useState('')
    const [prix, setPrix] = useState('')

    useEffect(() => {
        getListeSertisseur()
    }, [getListeSertisseur]);
    useEffect(() => {
        getListeOfSerti()
    }, [getListeOfSerti]);

    const listeClone = (enCours) => {
        if (listeOfSerti.length !== 0) {
            const data = [...listeOfSerti].filter(item => Number(item.rendu_serti) === (enCours ? 0 : 1));
            return data;
        }
        return [];
    }
    
    const retourOf = (data) => {
        //console.log(data);
        setPoids(data.pds_d)
        setPoidsRendu(data.pds_r)
        setIdLigne(data.id_sert)
        setPrix(data.plus_serti)
        setSertisseur(listeSertisseur.filter(r => r.id === data.id_serto)[0])
        setRetour(true)

    }
    const editOf = (data) => {
        setPoids(data.pds_d)
        setIdLigne(data.id_sert)
        setSertisseur(listeSertisseur.filter(r => r.id === data.id_serto)[0])
        setEdit(true)

    }
    const deleteOf = (data) => {
        const datat = {
            table: 'serti',
            primaryKey: 'id_sert',
            id: data.id_sert
        }
        Axios.post(`${meekroDB}/general_remove.php`, datat)
            .then((response) => {
                //console.log(response.data);
                let id = ''
                if (of !== null && of.num_sachet !== '') id = of.num_sachet
                getListeOfSerti(id)
            })
            .catch(error => {
                console.log(error);
            });
    }
    const retourSerti = () => {
        const data = {
            table: 'serti',
            primaryKey: 'id_sert',
            id: idLigne,
            pds_d: poids,
            pds_r: poidsRendu,
            prix: prix,
            date_r: getTodaySqlFormat(),
            rendu_serti: 1,
        }
        Axios.put(`${meekroDB}/general.php`, data)
            .then((response) => {
                let id = ''
                if (of !== null && of.num_sachet !== '') id = of.num_sachet
                getListeOfSerti(id)
                setRetour(false)
            })
            .catch(error => {
                console.log(error);
            });
    }
    const modifSerti = () => {
        const data = {
            table: 'serti',
            primaryKey: 'id_sert',
            id: idLigne,
            id_serto: sertisseur.id,
            pds_d: poids,
        }
        Axios.put(`${meekroDB}/general.php`, data)
            .then((response) => {
                let id = ''
                if (of !== null && of.num_sachet !== '') id = of.num_sachet
                getListeOfSerti(id)
                setEdit(false)
            })
            .catch(error => {
                console.log(error);
            });
    }
    return (
        <div className='form-group'>
            <MaterialTable
                style={{ fontSize: 12 }}
                columns={[
                    { align: 'left', title: 'OF', field: 'of_serto', type: 'numeric' },
                    { align: 'left', title: 'Sertisseur', field: 'label' },
                    { align: 'left', title: 'Poids', field: 'pds_d' },
                    { align: 'left', title: 'Pds R', field: 'pds_r' },
                    { align: 'left', title: 'Prix', field: 'prix' },
                    { align: 'left', title: 'Ref', field: 'ref' },
                    { align: 'left', title: 'Desc', field: 'refDesc', minWidth: 250 },

                ]}

                options={{
                    actionsColumnIndex: -1,
                    // rowStyle: rowData => ({
                    //     backgroundColor: (this.selectedEnCours === rowData.num_sachet) ? '#AED1FF' : ''
                    // })
                }}
                actions={[

                    rowData => ({
                        icon: CheckIcon,
                        tooltip: 'Retour',
                        onClick: (e, rowData) => retourOf(rowData)
                    }),
                    rowData => ({
                        icon: EditIcon,
                        hidden: rowData.rendu_serti === '1',
                        tooltip: 'Modifier',
                        onClick: (e, rowData) => editOf(rowData)
                    })
                    ,
                    rowData => ({
                        icon: DeleteIcon,
                        tooltip: 'Supprimer',
                        onClick: (e, rowData) => deleteOf(rowData)
                    })
                ]}
                data={listeClone(enCours)}
                title="Sertisseur"

                components={{
                    Toolbar: props => (
                        <div className='row'>

                            <div className="col-sm">
                                <button onClick={() => getListeOfSerti()} className="btn btn-link">Rafraichir</button>
                            </div>

                            <div className="col-8">
                                <MTableToolbar {...props} />
                            </div>

                        </div>
                    ),
                }}

                //onRowClick={this.onRowClick}
                localization={localization}
            />
            <Dialog
                open={retour}
                onClose={() => setRetour(false)}
                fullWidth={true}
            >
                <DialogTitle id="form-dialog-title">Modifier</DialogTitle>
                <DialogContent>
                    <FormControl className={classes.form} >


                        <div className="form-group">
                            <Autocomplete
                                value={sertisseur}
                                options={listeSertisseur.filter(r => r.actif === 1)}
                                getOptionLabel={(option) => option.label}
                                onChange={(event, values) => setSertisseur(values)}
                                getOptionSelected={(option) => option.id === sertisseur.id}
                                autoHighlight
                                autoComplete
                                clearOnEscape
                                style={{ width: 220 }}
                                renderInput={(params) => <TextField  {...params} label="Sertisseur" variant="outlined" margin="normal" size='small' />}
                            />
                        </div>
                        <div className="form-group">
                            <TextField style={{ marginLeft: 3, marginTop: 3 }} value={poids} onChange={e => setPoids(e.target.value)} type='number' label='Poids Donné' variant='outlined' size='small' />

                        </div>
                        <div className="form-group">
                            <TextField style={{ marginLeft: 3, marginTop: 3 }} value={poidsRendu} onChange={e => setPoidsRendu(e.target.value)} type='number' label='Poids Rendu' variant='outlined' size='small' />
                        </div>
                        <div className="form-group">
                            <TextField style={{ marginLeft: 3, marginTop: 3 }} value={prix} onChange={e => setPrix(e.target.value)} type='number' label='Prix' variant='outlined' size='small' />
                        </div>
                        <div className="form-group">
                            <button className='btn btn-link btn-sm' onClick={retourSerti}>Retour Serti</button>

                        </div>


                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setRetour(false)} color="primary">
                        Fermer
                    </Button>
                </DialogActions>
            </Dialog>




            <Dialog
                open={edit}
                onClose={() => setEdit(false)}
                fullWidth={true}
            >
                <DialogTitle id="form-dialog-title">Modifier</DialogTitle>
                <DialogContent>
                    <FormControl className={classes.form} >


                        <div className="form-group">
                            <Autocomplete
                                value={sertisseur}
                                options={listeSertisseur.filter(r => r.actif === 1)}
                                getOptionLabel={(option) => option.label}
                                onChange={(event, values) => setSertisseur(values)}
                                getOptionSelected={(option) => option.id === sertisseur.id}
                                autoHighlight
                                autoComplete
                                clearOnEscape
                                style={{ width: 220 }}
                                renderInput={(params) => <TextField  {...params} label="Sertisseur" variant="outlined" margin="normal" size='small' />}
                            />
                        </div>
                        <div className="form-group">
                            <TextField style={{ marginLeft: 3, marginTop: 3 }} value={poids} onChange={e => setPoids(e.target.value)} type='number' label='Poids' variant='outlined' size='small' />

                        </div>
                        <div className="form-group">
                            <button onClick={modifSerti} className='btn btn-link btn-sm'>Modifier</button>

                        </div>


                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setEdit(false)} color="primary">
                        Fermer
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        listeSertisseur: state.generalData.listeSertisseur,
        listeOfSerti: state.generalData.listeOfSerti
    }
}
const mapDispatchToProps = {
    getListeSertisseur,
    getListeOfSerti
}
export default connect(mapStateToProps, mapDispatchToProps)(TableSertisseur)