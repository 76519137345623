import React from 'react';
import { Card, CardContent, Typography, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';

const TopFlopJoaillier = ({ data }) => {

    // Calculer les 5 meilleurs et les 5 pires
    const filteredData = data.filter(item => parseFloat(item.temps) !== 0 && parseFloat(item.plus_mo) !== 0);
    const sortedData = [...filteredData].sort((a, b) => (parseFloat(b.plus_mo) - parseFloat(b.temps)) - (parseFloat(a.plus_mo) - parseFloat(a.temps)));
    const top = sortedData.slice(0, 13);
    const flop = sortedData.slice(-13).reverse();
    return (
        <Card style={{ height: '100%' }}>
            <CardContent >
                <Grid container spacing={3} style={{ height: '100%' }}>
                    <Grid item xs={6} style={{ height: '100%' }}>
                        <Typography variant="h5" align="center">Top Temps</Typography>
                        <TableContainer component={Paper} style={{ height: 'calc(100% - 48px)' }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Ref</TableCell>
                                        <TableCell>Designation</TableCell>
                                        <TableCell>Temps Théorique</TableCell>
                                        <TableCell>Temps Réel</TableCell>
                                        <TableCell>Ecart</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {top.map(item => (
                                        <TableRow key={item.id_ligne}>
                                            <TableCell>{item.ref}</TableCell>
                                            <TableCell>{item.designation}</TableCell>
                                            <TableCell>{item.plus_mo}</TableCell>
                                            <TableCell>{item.temps}</TableCell>
                                            <TableCell>{item.temps - item.plus_mo}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item xs={6} style={{ height: '100%' }}>
                        <Typography variant="h5" align="center">Flop Temps</Typography>
                        <TableContainer component={Paper} style={{ height: 'calc(100% - 48px)' }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Ref</TableCell>
                                        <TableCell>Designation</TableCell>
                                        <TableCell>Temps Théorique</TableCell>
                                        <TableCell>Temps Réel</TableCell>
                                        <TableCell>Ecart</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {flop.map(item => (
                                        <TableRow key={item.id_ligne}>
                                            <TableCell>{item.ref}</TableCell>
                                            <TableCell>{item.designation}</TableCell>
                                            <TableCell>{item.plus_mo}</TableCell>
                                            <TableCell>{item.temps}</TableCell>
                                            <TableCell>{item.temps - item.plus_mo}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default TopFlopJoaillier;
