import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { euroFormat } from '../../helpers/functions';

const BarChartCaAnnee = ({ data }) => {
    const [chartData, setChartData] = useState({ labels: [], datasets: [] });

    useEffect(() => {
        if (data) {
            const moisEnFrancais = [
                "Janvier", "Février", "Mars", "Avril", "Mai", "Juin",
                "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"
            ];

            const uniqueMonths = Array.from(new Set(data.map(item => item.mois)));
            const updatedChartData = {
                labels: uniqueMonths.map(month => moisEnFrancais[parseInt(month) - 1]),
                datasets: [
                    {
                        label: "CA Tournée",
                        backgroundColor: "rgba(75,192,192,0.4)",
                        borderColor: "rgba(75,192,192,1)",
                        borderWidth: 1,
                        hoverBackgroundColor: "rgba(75,192,192,0.6)",
                        hoverBorderColor: "rgba(75,192,192,1)",
                        data: uniqueMonths.map(month => {
                            const totalTourneePrice = data
                                .filter(item => item.tournee === "1" && item.mois === month)
                                .reduce((acc, item) => acc + parseFloat(item.prix_tournee), 0);
                            return totalTourneePrice;
                        }),
                        stack: 'Stack 0',
                    },
                    {
                        label: "CA Hors Tournée",
                        backgroundColor: "rgba(255,99,132,0.4)",
                        borderColor: "rgba(255,99,132,1)",
                        borderWidth: 1,
                        hoverBackgroundColor: "rgba(255,99,132,0.6)",
                        hoverBorderColor: "rgba(255,99,132,1)",
                        data: uniqueMonths.map(month => {
                            const totalHorsTourneePrice = data
                                .filter(item => item.tournee === "0" && item.mois === month)
                                .reduce((acc, item) => acc + parseFloat(item.prix_hors_tournee), 0);
                            return totalHorsTourneePrice;
                        }),
                        stack: 'Stack 0',
                    },
                ]
            };

            setChartData(updatedChartData);
        }
    }, [data]);

    const options = {
        scales: {
            xAxes: [{
                scaleLabel: {
                    display: true,
                    labelString: "Mois"
                },
                stacked: true,
            }],
            yAxes: [{
                scaleLabel: {
                    display: true,
                    labelString: "CA HT"
                },
                ticks: {
                    beginAtZero: true,
                },
                stacked: true,
            }]
        },
        title: {
            display: true,
            text: "CA HT par Mois"
        },
        tooltips: {
            callbacks: {
                label: function (tooltipItem, data) {
                    let label = data.datasets[tooltipItem.datasetIndex].label || '';
                    if (label) {
                        label += ': ';
                    }
                    //label += (Math.round(tooltipItem.yLabel * 100) / 100).toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' });
                    label += euroFormat(Math.round(tooltipItem.yLabel * 100) / 100);

                    return label;
                },
                afterBody: function (tooltipItem, data) {
                    const total = data.datasets[0].data[tooltipItem[0].index] + data.datasets[1].data[tooltipItem[0].index];
                    return 'Total: ' + euroFormat(total);
                }
            }
        }
    };

    return (
        <div>
            <Bar data={chartData} options={options} height={80} />
        </div>
    );
};

export default BarChartCaAnnee;
