import React, { Component } from 'react'
import { getListeOf, setOfToEdit } from '../actions'
import { connect } from 'react-redux'
import MaterialTable from 'material-table'
import { List } from 'immutable'
import { imagesRepo, localization } from '../config'
import OfEditComponent from '../components/of/OfEditComponent'
import Image from 'react-bootstrap/Image'


class OfEdit extends Component {
    state = {
        id: 0
    }
    componentDidMount() {
        this.props.getListeOf()
    }

    returnList = () => {
        const dataOfs = List(this.props.listeOfs)
        return dataOfs.toJS()
    }
    handleClick = (event, rowData) => {
        event.preventDefault()
        this.props.setOfToEdit(rowData)
        this.setState({ id: rowData.num_sachet })
        //console.log(rowData)

    }
    handleSearchOf = (value) => {
        const filterOfs = value !== '' ? Object.values(this.props.listeOfs).filter(of => of.num_sachet === value) : []
        if (filterOfs.length === 1) {
            this.props.setOfToEdit(filterOfs[0])
            this.setState({ id: filterOfs[0].num_sachet })
        }
        // this.populateForm(filterOfs[0])

    }
    handleAction = () => {
        this.props.getListeOf()
    }
    render() {
        return (

            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm" >
                        <MaterialTable
                            style={{ fontSize: 14, padding: 6, zIndex: 0 }}
                            columns={[
                                { align: 'center', title: 'Image', field: 'image', render: rowData => <Image key={rowData.num_sachet} src={`${imagesRepo}thumbs.php?src=${rowData.image}&size=100`} style={{ width: 100, height: 100 }} rounded /> },

                                { title: 'Of', field: 'num_sachet' },
                                { title: 'Client', field: 'Nom_agence', align: 'center' },
                                { title: 'Ref', field: 'ref_cv' },
                                {
                                    title: 'Desc', field: 'ref',
                                    cellStyle: {
                                        minWidth: 250
                                    },
                                    headerStyle: {
                                        //backgroundColor: '#bbdff7',
                                        fontSize: 16,
                                    }
                                },
                                { title: 'Stade', field: 'descStade', align: 'center' },
                                { title: 'Transport', field: 'descTypeTransp', align: 'center' },
                                { title: 'Métal', field: 'descTypeMetal', align: 'center' },
                                { title: 'Dim', field: 'dim', align: 'center' },
                                { title: 'Statut', field: 'descStatut', align: 'center' },
                                { title: 'Prix', field: 'total3', align: 'right' },

                            ]}
                            options={{
                                rowStyle: rowData => ({
                                    backgroundColor: (this.state.id === rowData.num_sachet) ? '#AED1FF' : ''
                                })
                            }}
                            onSearchChange={this.handleSearchOf}
                            data={List(this.props.listeOfs).toJS()}
                            title={<div style={{ width: 170, fontSize: 16, display: "flex" }}>Ofs</div>}
                            onRowClick={this.handleClick}
                            localization={localization}

                        />
                    </div>
                    <div className="col-sm" >
                        <OfEditComponent onAction={this.handleAction} />
                    </div>
                </div>


            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        listeOfs: state.ofs.listeOf,
    }
}
const mapDispatchToProps = {

    getListeOf,
    setOfToEdit,

}
export default connect(mapStateToProps, mapDispatchToProps)(OfEdit)