import React, { Component } from 'react'
import { getAnalyseFournisseur } from '../../actions'
import { connect } from 'react-redux'
import { TextField } from '@material-ui/core'
import PieChartFournisseur from './PieChartFournisseur'


class AnalyseFournisseur extends Component {
    today = new Date()
    temp = this.today.getFullYear() + '-' + ("0" + (this.today.getMonth() + 1)).slice(-2) + '-' + ("0" + this.today.getDate()).slice(-2)
    currentYear = new Date().getFullYear();

    state = {
        debut: `${this.currentYear - 1}-01-01`,
        fin: `${this.currentYear - 1}-12-31`,
    }

    getFilter = () => {
        const data = {
            debut: this.state.debut,
            fin: this.state.fin,
        }
        return data
    }
    onChange = (data, labelState) => {
        const label = labelState.toLowerCase()
        this.setState({ [label]: data })  //, () => this.refreshLists()
    }

    refreshLists = () => {
        this.props.getAnalyseFournisseur(this.getFilter())
    }


    render() {
        const { analyseFournisseur } = this.props;

        return (
            <div>
                <div style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'center', gap: '10px' }}>
                    <TextField type='date' value={this.state.debut} onChange={e => e.target.value && this.onChange(e.target.value, 'debut')} label='Debut' variant="outlined" margin="dense" />
                    <TextField type='date' value={this.state.fin} onChange={e => e.target.value && this.onChange(e.target.value, 'fin')} label='Fin' variant="outlined" margin="dense" />
                    <button className='btn btn-primary' onClick={() => this.refreshLists()}>Afficher</button>
                </div>
                <PieChartFournisseur data={analyseFournisseur} />
            </div >
        )
    }
}

const mapStateToProps = (state) => {
    return {

        analyseFournisseur: state.analyse.analyseFournisseur,

    }
}
const mapDispatchToProps = {

    getAnalyseFournisseur,
}
export default connect(mapStateToProps, mapDispatchToProps)(AnalyseFournisseur)