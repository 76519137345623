import React, { useEffect, useState } from 'react'
import { getListeTypePierre, getListeFormePierre, getListeCouleurPierre, getListeQualitePierre, getListePierre, getListeFournisseur } from '../../actions'

import { connect } from 'react-redux'
import { getPierre } from '../../selectors/pierre';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import Axios from 'axios';
import { meekroDB } from '../../config';
import { preventEnter } from '../../helpers/functions';

const PierreEdit = ({ data, onAction, getListeTypePierre, getListeFormePierre, getListeCouleurPierre, getListeQualitePierre,
    typePierre, formePierre, listeQualitePierre, listeCouleurPierre, getListePierre, listePierre, listeFournisseur, getListeFournisseur }) => {

    useEffect(() => getListeTypePierre(), [getListeTypePierre]);
    useEffect(() => getListeFormePierre(), [getListeFormePierre]);
    useEffect(() => getListeCouleurPierre(), [getListeCouleurPierre]);
    useEffect(() => getListeQualitePierre(), [getListeQualitePierre]);
    useEffect(() => getListeFournisseur(), [getListeFournisseur]);

    useEffect(() => {
        async function fetchData() {
            // You can await here
            await getListePierre();
            // ...
        }
        fetchData();
    }, [getListePierre]); // Or [] if effect doesn't need props or state
    // useEffect(() => getListePierre(), [getListePierre]);



    useEffect(() => {
        console.log(data);
        if (data) {
            populateForm(data);
        }
    }, [data]);

    const [type, setType] = useState(null)
    const [forme, setForme] = useState(null)
    const [couleur, setCouleur] = useState(null)
    const [qualite, setQualite] = useState(null)
    const [fournisseur, setFournisseur] = useState(null)
    const [dim1, setDim1] = useState('')
    const [dim2, setDim2] = useState('')
    const [dim3, setDim3] = useState('')
    const [euro, setEuro] = useState('')
    const [dollar, setDollar] = useState('')
    const [coef, setCoef] = useState('')


    const [btn, setBtn] = useState('save')


    const populateForm = (data) => {
        // console.log(data);
        setType({ id_typ_pier: data.type_pier, description: data.type })
        setForme({ id_form: data.forme, description: data.laforme })
        setCouleur({ id_color: data.color, description: data.couleur })
        setQualite({ id_qual: data.id_qual, description: data.laqual })
        setFournisseur({ four_id: data.id_four, raison_soc: data.raison_soc })
        setDim1(data.dim1)
        setDim2(data.dim2)
        setDim3(data.dim3)
        setEuro(data.euro)
        setDollar(data.dollar)
        setCoef(data.coeff)
    }
    const handleSubmit = e => {
        e.preventDefault()
        if (btn === 'add') onAdd(e)
        else onSave(e)
    }
    const onAdd = (e) => {
        const existPierre = Object.values(listePierre)
            .filter(t => t.type_pier === type.id_typ_pier)
            .filter(f => f.forme === forme.id_form)
            .filter(c => c.color === couleur.id_color)
            .filter(p => p.dim1 === dim1)
            .filter(p => p.dim2 === dim2)
            .filter(p => p.dim3 === dim3)

        const existTarif = Object.values(existPierre)
            .filter(q => q.id_qual === qualite.id_qual)

        // console.log('existPierre',existPierre);
        // console.log('existTarif',existTarif);
        if (existPierre.length !== 0 && existTarif.length !== 0) alert('Pierre deja existante')
        else if (existPierre.length !== 0) {
            const dataTarif = {
                table: 'tarifs',
                id_pier: existPierre[0].id_pier,
                id_qual: qualite.id_qual,
                id_four: fournisseur.four_id,
                euro,
                dollar,
            }
            Axios.post(`${meekroDB}/general.php`, dataTarif)
                .then(response => {
                    onAction()
                }).catch(error => {
                    console.log(error);
                });
        }
        else {
            const dataPierre = {
                table: 'pierre',
                forme: forme.id_form,
                color: couleur.id_color,
                type_pier: type.id_typ_pier,
                dim1: dim1,
                dim2: dim2,
                dim3: dim3,
                coeff: coef,
            }

            Axios.post(`${meekroDB}/general.php`, dataPierre)
                .then(response => {
                    const dataTarif = {
                        table: 'tarifs',
                        id_pier: response.data,
                        id_four: fournisseur.four_id,
                        id_qual: qualite.id_qual,
                        euro,
                        dollar,
                    }
                    Axios.post(`${meekroDB}/general.php`, dataTarif)
                        .then(response => {
                            onAction()
                        }).catch(error => {
                            console.log(error);
                        });
                }).catch(error => {
                    console.log(error);
                });

        }
    }
    const onSave = e => {
        e.preventDefault()

        const dataTarif = {
            table: 'tarifs',
            primaryKey: 'id_tarif',
            id: data.id_tarif,
            id_four: fournisseur.four_id,
            id_qual: qualite.id_qual,
            euro,
            dollar,
        }
        const dataPierre = {
            table: 'pierre',
            primaryKey: 'id_pier',
            id: data.id_pier,
            forme: forme.id_form,
            color: couleur.id_color,
            type_pier: type.id_typ_pier,
            dim1: dim1,
            dim2: dim2,
            dim3: dim3,
            coeff: coef,
        }
        Axios.put(`${meekroDB}/general.php`, dataTarif)
            .then(response => {
                Axios.put(`${meekroDB}/general.php`, dataPierre)
                    .then(response => {
                        onAction()
                    }).catch(error => {
                        console.log(error);
                    });
            }).catch(error => {
                console.log(error);
            });

    }
    return (
        <div className='container-fluid'>
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-sm">
                        <Autocomplete
                            fullWidth
                            value={type}
                            options={typePierre}
                            getOptionLabel={(option) => option.description}
                            onChange={(event, values) => setType(values)}
                            getOptionSelected={(option) => option.id_typ_pier === type.id_typ_pier}
                            autoHighlight
                            autoComplete
                            clearOnEscape
                            renderInput={(params) => <TextField required onKeyDown={e => preventEnter(e)} {...params} label="Type" variant="outlined" margin='dense' />}
                        />
                    </div>
                    <div className="col-sm">
                        <Autocomplete
                            value={forme}
                            options={formePierre}
                            getOptionLabel={(option) => option.description}
                            onChange={(event, values) => setForme(values)}
                            getOptionSelected={(option) => option.id_form === forme.id_form}
                            autoHighlight
                            autoComplete
                            clearOnEscape
                            renderInput={(params) => <TextField required onKeyDown={e => preventEnter(e)} {...params} label="Forme" variant="outlined" margin='dense' />}
                        />
                    </div>
                    <div className="col-sm">
                        <Autocomplete
                            value={couleur}
                            options={listeCouleurPierre}
                            getOptionLabel={(option) => option.description}
                            onChange={(event, values) => setCouleur(values)}
                            getOptionSelected={(option) => option.id_color === couleur.id_color}
                            autoHighlight
                            autoComplete
                            clearOnEscape
                            renderInput={(params) => <TextField required onKeyDown={e => preventEnter(e)} {...params} label="Couleur" variant="outlined" margin='dense' />}
                        />
                    </div>
                </div>
                <div className="row">

                    <div className="col-sm">
                        <Autocomplete
                            value={qualite}
                            options={listeQualitePierre}
                            getOptionLabel={(option) => option.description}
                            onChange={(event, values) => setQualite(values)}
                            getOptionSelected={(option) => option.id_qual === qualite.id_qual}
                            autoHighlight
                            autoComplete
                            clearOnEscape
                            renderInput={(params) => <TextField required onKeyDown={e => preventEnter(e)} {...params} label="Qualite" variant="outlined" margin='dense' />}
                        />
                    </div>

                    <div className="col-sm">
                        <Autocomplete
                            value={fournisseur}
                            options={listeFournisseur}
                            getOptionLabel={(option) => option.raison_soc}
                            onChange={(event, values) => setFournisseur(values)}
                            getOptionSelected={(option) => option.four_id === fournisseur.four_id}
                            autoHighlight
                            autoComplete
                            clearOnEscape
                            renderInput={(params) => <TextField required onKeyDown={e => preventEnter(e)} {...params} label="Fournisseur" variant="outlined" margin='dense' />}
                        />
                    </div>
                </div>
                <div className="row">

                    <div className="col-sm">
                        <TextField
                            required
                            type='number' label='Dim 1'
                            onChange={e => setDim1(e.target.value)}
                            value={dim1}
                            variant='outlined'
                            margin='dense'
                            onKeyDown={e => preventEnter(e)}
                        />
                    </div>
                    <div className="col-sm">
                        <TextField
                            type='number' label='Dim 2'
                            onChange={e => setDim2(e.target.value)}
                            value={dim2}
                            variant='outlined'
                            margin='dense'
                            onKeyDown={e => preventEnter(e)}
                        />
                    </div>
                    <div className="col-sm">
                        <TextField
                            type='number' label='Dim 3'
                            onChange={e => setDim3(e.target.value)}
                            value={dim3}
                            variant='outlined'
                            margin='dense'
                            onKeyDown={e => preventEnter(e)}
                        />
                    </div>
                </div>
                <div className="row">

                    <div className="col-sm">
                        <TextField
                            type='number' label='Euro'
                            onChange={e => setEuro(e.target.value)}
                            value={euro}
                            variant='outlined'
                            margin='dense'
                            onKeyDown={e => preventEnter(e)}
                        />
                    </div>
                    <div className="col-sm">
                        <TextField
                            type='number' label='Dollar'
                            onChange={e => setDollar(e.target.value)}
                            value={dollar}
                            variant='outlined'
                            margin='dense'
                            onKeyDown={e => preventEnter(e)}
                        />
                    </div>
                    <div className="col-sm">
                        <TextField
                            type='number' label='Coef'
                            onChange={e => setCoef(e.target.value)}
                            value={coef}
                            variant='outlined'
                            margin='dense'
                            onKeyDown={e => preventEnter(e)}
                        />
                    </div>
                </div>
                <button type="submit" className="btn btn-link btn-sm" onClick={() => setBtn('add')}>Ajouter</button>
                <button type="submit" className="btn btn-link btn-sm" onClick={() => setBtn('save')}>Enregistrer</button>
            </form>

        </div >
    )

}

const mapStateToProps = (state) => {
    return {
        typePierre: state.pierre.listeTypePierre,
        formePierre: state.pierre.listeFormePierre,
        listeQualitePierre: state.pierre.listeQualitePierre,
        listeCouleurPierre: state.pierre.listeCouleurPierre,
        listePierre: getPierre(state),
        listeFournisseur: state.generalData.listeFournisseur,

    }
}
const mapDispatchToProps = {
    getListeTypePierre,
    getListeFormePierre,
    getListeCouleurPierre,
    getListeQualitePierre,
    getListePierre,
    getListeFournisseur,
}
export default connect(mapStateToProps, mapDispatchToProps)(PierreEdit)