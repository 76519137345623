import React, { useState } from 'react';
import {
    TextField,
    Button,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Container,
    Box
} from '@material-ui/core/';
import opentype from 'opentype.js';
import paper from 'paper';

// Importez votre police ici
import vijayaFont from '../../fonts/Vijaya.ttf';

const FontOptions = [
    { value: 'Vijaya', label: 'Vijaya' },
    // Ajoutez d'autres polices disponibles ici
];

const SvgGenerator = () => {
    const [text, setText] = useState('FROJO');
    const [font, setFont] = useState('Vijaya');
    const [targetHeightMM, setTargetHeightMM] = useState(0.8); // Hauteur finale en mm
    const strokeWidth = 0.25;
    const height = 5;

    const handleExportSvg = async (e) => {
        e.preventDefault();

        // Chargez la police (remplacez le chemin par le vôtre)
        const font = await opentype.load(vijayaFont);

        // Convertissez la hauteur de mm en pixels
        const fontSize = height * 3.779; // 1 mm = 3.779 pixels

        // Obtenez le chemin du texte
        const path = font.getPath(text, 0, fontSize, fontSize + 15);

        // Utilisez paper.js pour manipuler les chemins SVG
        paper.setup(new paper.Size(1000, 1000));
        const paperPath = new paper.CompoundPath(path.toPathData(3));

        // Vectorisez et fusionnez les contours
        paperPath.strokeWidth = strokeWidth * 3.779;
        paperPath.strokeJoin = 'miter';
        paperPath.strokeCap = 'miter';

        paperPath.strokeColor = 'black';
        paperPath.fillColor = 'black';

        // Créez le contenu SVG
        const svgContent = paper.project.exportSVG({ asString: true });

        // Créez un blob à partir du contenu SVG
        const blob = new Blob([svgContent], { type: 'image/svg+xml' });
        const url = URL.createObjectURL(blob);

        // Créez un lien de téléchargement
        const link = document.createElement('a');
        link.href = url;
        link.download = `${text}.svg`; // Nom du fichier téléchargé
        link.click();

        // Nettoyez l'URL du blob
        URL.revokeObjectURL(url);
    };

    return (
        <Container>
            <Box display="flex" justifyContent="center" alignItems="center">
                <form onSubmit={handleExportSvg} style={{ width: '25%' }}>
                    <style>
                        {`
                        @font-face {
                            font-family: 'Vijaya';
                            src: url(${vijayaFont}) format('truetype');
                        }
                        .vijaya-font {
                            font-family: 'Vijaya';
                        }
                        `}
                    </style>
                    <TextField
                        label="Texte"
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                        fullWidth
                        className="vijaya-font"
                        variant="outlined"
                        margin="normal"
                    />
                    <FormControl variant="outlined" fullWidth margin="normal">
                        <InputLabel>Police</InputLabel>
                        <Select value={font} onChange={(e) => setFont(e.target.value)} label="Police">
                            {FontOptions.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        label="Hauteur finale (en mm)"
                        type="number"
                        value={targetHeightMM}
                        onChange={(e) => setTargetHeightMM(Number(e.target.value))}
                        fullWidth
                        variant="outlined"
                        margin="normal"
                    />
                    <Button variant="contained" color="primary" type="submit" fullWidth>
                        Exporter le SVG
                    </Button>
                </form>
            </Box>
        </Container>
    );
};

export default SvgGenerator;
