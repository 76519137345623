import { TextField } from '@material-ui/core'
import Axios from 'axios'
import React, { Component } from 'react'
import { imagesRepo, meekroDB } from '../config'

class Images extends Component {
    _isMounted = false
    state = {
        imgs: [],
        filtered: [],
        search: '',
    }
    componentDidMount() {
        // this._isMounted = true
        // this._isMounted && this.getImageFromServer()
    }
    componentWillUnmount() {
        this._isMounted = false
    }
    getImageFromServer = () => {
        Axios.get(`${meekroDB}/images/liste.php`)
            .then((response) => {
                this._isMounted = true
                this.setState({ imgs: response.data })
            })
            .catch(error => {
                console.log(error);
            });
    }
    handleEnter = (e) => {
        if (e.key === 'Enter' && !this._isMounted) this.getImageFromServer()
    }
    render() {
        return (
            <div>
                <div className='d-flex justify-content-center'>
                    <TextField inputProps={{ style: { textAlign: 'center' } }}
                        variant='outlined' autoFocus value={this.state.search}
                        margin='dense'
                        onKeyPress={this.handleEnter}
                        onChange={e => this.setState({ search: e.target.value })}
                        placeholder='Rechercher' />
                    <button onClick={() => this.getImageFromServer()} className="btn btn-link btn-sm">Rechercher</button>
                </div>

                <div className="row">
                    {Object.values(this.state.imgs).filter(f => f.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1).map(img => (
                        <div className='col-auto' key={img}>
                            <h3 className='text-center'>{img}</h3>
                            <img onClick={() => alert(img)} key={img} style={{ maxWidth: 300, maxHeight: 300, minWidth: 300, minHeight: 300 }} src={`${imagesRepo}${img}`} alt='' />
                            <h3 className='text-center'>{img}</h3>
                        </div>
                    )
                    )}
                </div>
            </div>
        )
    }
}

export default (Images)