import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import red from '@material-ui/core/colors/red';
import { Button, Dialog, DialogContent, TextField } from '@material-ui/core';
import { localization, meekroDB } from '../../config';
import { euroFormat } from '../../helpers/functions';
import ChartCaAnneeClient from './GraphiqueCaClient';
import Axios from 'axios';

const YearInput = ({ label, value, onChange }) => {
    const handleInputChange = (event) => {
        const newInputValue = event.target.value;

        if (/^\d{4}$/.test(newInputValue) || newInputValue === '') {
            onChange(newInputValue);
        }
    };

    return (
        <TextField
            label={label}
            value={value}
            onChange={handleInputChange}
            variant="outlined"
            size="small"
            type="number"
        />
    );
};

const fetchData = async (startDate, endDate, setTableData, setTopProgressions, setTopRegressions) => {
    try {
        const response = await fetch(`${meekroDB}/analyse/progression_ca.php?action=progression_ca`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                anneeDebut: parseInt(new Date(startDate).getFullYear(), 10),
                anneeFin: parseInt(new Date(endDate).getFullYear(), 10),
            }),
        });

        if (!response.ok) {
            throw new Error('Erreur réseau lors de la récupération des données');
        }

        const newData = await response.json();
        setTableData(newData);

        const filteredData = newData.filter(item => item.totalHT_annee_fin !== null && item.totalHT_annee_deb !== null);
        const sortedData = filteredData.sort((a, b) => (b.totalHT_annee_fin - b.totalHT_annee_deb) - (a.totalHT_annee_fin - a.totalHT_annee_deb));

        const topProgressions = sortedData.slice(0, 20).map(item => ({ ...item, key: `progression_${item.id_bijouterie}` }));
        const topRegressions = sortedData.slice(-20).reverse().map(item => ({ ...item, key: `regression_${item.id_bijouterie}` }));


        setTopProgressions(topProgressions);
        setTopRegressions(topRegressions);
    } catch (error) {
        console.error('Erreur lors de la récupération des données :', error);
    }
};

const ComparaisonCa = ({ data }) => {
    const theme = createMuiTheme({
        palette: {
            primary: blue,
            secondary: red,
        },
        typography: {
            fontFamily: 'Arial, sans-serif',
        },
    });

    const [startDate, setStartDate] = useState(new Date(new Date().getFullYear() - 2, 0, 1));
    const [endDate, setEndDate] = useState(new Date(new Date().getFullYear() - 1, 11, 31));
    const [tableData, setTableData] = useState(data);
    const [topProgressions, setTopProgressions] = useState([]);
    const [topRegressions, setTopRegressions] = useState([]);
    const [selectedDataHistorique, setSelectedDataHistorique] = useState(null); // État pour stocker l'ID de l'élément sélectionné
    const [popupOpen, setPopupOpen] = useState(false); // État pour contrôler l'ouverture de la popup


    useEffect(() => {
        fetchData(startDate, endDate, setTableData, setTopProgressions, setTopRegressions);
    }, [startDate, endDate]);

    const handleRefresh = () => {
        fetchData(startDate, endDate, setTableData, setTopProgressions, setTopRegressions);
    };
    const handleRowClick = async (event, rowData) => {
        try {
            const response = await Axios.post(`${meekroDB}/analyse/progression_ca.php?action=historique_ca&client=${rowData.id_bijouterie}`);
            setSelectedDataHistorique(response.data); // Mettre à jour l'ID de l'élément sélectionné
            setPopupOpen(true); // Ouvrir la popup
        } catch (error) {
            console.log(error);
        }
    };

    const handleClosePopup = () => {
        setPopupOpen(false); // Fermer la popup
    };

    const columns = [
        { title: 'Nom Agence', field: 'Nom_agence' },
        { title: `TotalHT ${new Date(startDate).getFullYear()}`, field: 'totalHT_annee_deb', type: 'numeric', render: rowData => euroFormat(rowData['totalHT_annee_deb']) },
        { title: `TotalHT ${new Date(endDate).getFullYear()}`, field: 'totalHT_annee_fin', type: 'numeric', render: rowData => euroFormat(rowData['totalHT_annee_fin']) },
        { title: `Ecart`, field: '', type: 'numeric', render: rowData => euroFormat(rowData['totalHT_annee_fin'] - rowData['totalHT_annee_deb']) },
        { title: 'Pourcentage Variation', field: 'pourcentage_variation', type: 'numeric' },
    ];

    const cellStyle = {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    };

    return (
        <MuiThemeProvider theme={theme}>
            <div style={{ textAlign: 'center' }}>
                <div>
                    <YearInput label="Année de début" value={new Date(startDate).getFullYear().toString()} onChange={(value) => setStartDate(new Date(value, 0, 1))} />
                    <YearInput label="Année de fin" value={new Date(endDate).getFullYear().toString()} onChange={(value) => setEndDate(new Date(value, 11, 31))} />
                    <Button variant="contained" color="primary" onClick={handleRefresh} style={{ marginLeft: '10px' }}>
                        Rafraîchir
                    </Button>
                </div>
            </div>

            <div className='row'>
                <div className='col-6'>
                    <MaterialTable
                        title="Comparaison Chiffre d'Affaires"
                        columns={columns.map(col => ({ ...col, cellStyle }))}
                        data={tableData}
                        options={{
                            pageSize: 20,
                            pageSizeOptions: [5, 10, 20],
                            sorting: true,
                            exportButton: true,
                            exportAllData: true,
                            padding: 'dense',
                        }}
                        onRowClick={handleRowClick}
                        style={{ width: '100%' }}
                        localization={localization}
                        key={(rowData) => `row2-${rowData.id_bijouterie}`}
                    />
                </div>
                <div className='col-6'>
                    <MaterialTable
                        title="Top Progressions"
                        columns={columns.map(col => ({ ...col, cellStyle }))}
                        data={topProgressions}
                        options={{
                            pageSize: 5,
                            pageSizeOptions: [5, 10],
                            sorting: true,
                            exportButton: true,
                            exportAllData: true,
                            padding: 'dense',
                            rowStyle: { backgroundColor: 'rgba(75,192,192,0.4)' },
                        }}
                        onRowClick={handleRowClick}
                        style={{ width: '100%', marginTop: '20px' }}
                        localization={localization}
                    />
                    <MaterialTable
                        title="Top Régressions"
                        columns={columns.map(col => ({ ...col, cellStyle }))}
                        data={topRegressions}
                        options={{
                            pageSize: 5,
                            pageSizeOptions: [5, 10],
                            sorting: true,
                            exportButton: true,
                            exportAllData: true,
                            padding: 'dense',
                            rowStyle: { backgroundColor: 'rgba(255,99,132,0.4)' },
                        }}
                        onRowClick={handleRowClick}
                        style={{ width: '100%', marginTop: '20px' }}
                        localization={localization}
                    />
                </div>
                <Dialog open={popupOpen} onClose={handleClosePopup} fullWidth maxWidth="md">

                    <DialogContent>
                        {/* Affichez le composant BarChartCaAnnee avec l'ID de l'élément sélectionné */}
                        {selectedDataHistorique && <ChartCaAnneeClient data={selectedDataHistorique} />}
                    </DialogContent>
                </Dialog>
            </div >
        </MuiThemeProvider >
    );
};

export default ComparaisonCa;
