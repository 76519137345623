import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Formik, useFormik } from 'formik'; // 1. Importation de Form
import * as Yup from 'yup';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import { getListeJoaillier, getListeOf } from '../../../actions';
import { getOfs } from '../../../selectors/ofsSelector';

const validationSchema = Yup.object().shape({
    joaillier: Yup.object().required('Joaillier obligatoire.'),
    of: Yup.object().required('N° OF obligatoire.'),
    poids: Yup.number()
        .required('Poids valide obligatoire.')
        .moreThan(-1, 'Le poids rendu doit être un nombre positif.'),
});

const JoaillierEnvoiForm = ({ listeJoaillier, getListeJoaillier, getListeOf, listeOfs, onSubmitForm, dataForm }) => {
    const joaillierRef = useRef(null);
    const ofRef = useRef(null);
    const poidsRef = useRef(null);

    const formik = useFormik({
        initialValues: {
            joaillier: null,
            of: null,
            poids: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            const newValues = { ...values, id_ligne: dataForm?.id_ligne ?? null }
            onSubmitForm(newValues);
            resetForm();
            setFieldValue('joaillier', values.joaillier);
            ofRef.current.focus();
        },
    });

    useEffect(() => {
        getListeJoaillier();
        getListeOf();
    }, [getListeJoaillier, getListeOf]);
    useEffect(() => {
        if (dataForm !== null) {
            populateForm(dataForm);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataForm]);

    const { values, errors, touched, handleChange, handleSubmit, handleBlur, setFieldValue } = formik;

    const populateForm = (data) => {
        if (data) {
            const selectedJoaillier = listeJoaillier.find(joaillier => joaillier.id === data.joaillier);
            const selectedOf = listeOfs.find(of => of.num_sachet === data.of);
            setFieldValue('joaillier', selectedJoaillier);
            setFieldValue('of', selectedOf);
            setFieldValue('poids', data.poids);
            poidsRef.current.focus();
        }
        else {
            formik.resetForm();
            joaillierRef.current.focus();
        }
    };


    useEffect(() => {
        if (values.joaillier && ofRef.current) {
            ofRef.current.focus();
        }
    }, [values.joaillier]);
    useEffect(() => {
        if (values.of && poidsRef.current) {
            poidsRef.current.focus();
        }
    }, [values.of]);

    return (
        <Formik initialValues={values} onSubmit={handleSubmit} >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Autocomplete
                        options={listeJoaillier.filter(r => r.actif === 1)}
                        getOptionLabel={(option) => option.label}
                        value={values.joaillier}
                        onChange={(event, newValue) => setFieldValue('joaillier', newValue)}
                        autoHighlight
                        autoComplete
                        fullWidth
                        clearOnEscape
                        getOptionSelected={(option, values) => option.id === values.id}
                        renderInput={(params) => <TextField {...params} label="Joaillier" variant="outlined" fullWidth
                            inputRef={joaillierRef}
                            autoFocus
                            error={errors.joaillier && touched.joaillier}
                            helperText={(errors.joaillier && touched.joaillier) ? errors.joaillier : null} />}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Autocomplete
                        options={listeOfs}
                        getOptionLabel={(option) => option.num_sachet}
                        value={values.of}
                        onChange={(event, newValue) => setFieldValue('of', newValue)}
                        autoHighlight
                        autoComplete
                        fullWidth
                        clearOnEscape
                        renderInput={(params) => <TextField {...params} label="N° OF" variant="outlined" fullWidth
                            inputRef={ofRef}
                            error={errors.of && touched.of}
                            helperText={(errors.of && touched.of) ? errors.of : null} />}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Poids"
                        variant="outlined"
                        value={values.poids}
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="poids"
                        error={errors.poids && touched.poids}
                        helperText={(errors.poids && touched.poids) ? errors.poids : null}
                        inputRef={poidsRef}
                        onKeyPress={async (e) => {
                            if (e.key === 'Enter') {
                                const errors = await formik.validateForm();
                                formik.setFieldTouched('poids')
                                console.log(errors);
                                if (!errors.poids) {
                                    formik.handleSubmit();
                                    ofRef.current.focus();
                                }
                            }
                        }}
                    />
                </Grid>

                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button variant="contained" color="primary" type="submit" onClick={handleSubmit}>Ajouter</Button>
                </Grid>
            </Grid>
        </Formik>
    );
};

const mapStateToProps = (state) => {
    return {
        listeJoaillier: state.generalData.listeJoaillier,
        listeOfs: getOfs(state),
    };
};

const mapDispatchToProps = {
    getListeJoaillier,
    getListeOf,

};

export default connect(mapStateToProps, mapDispatchToProps)(JoaillierEnvoiForm);
