import React, { Component } from 'react'
import { getListeAvoir } from '../actions'
import { connect } from 'react-redux'
import MaterialTable, { MTableToolbar } from 'material-table'
import { localization } from '../config'
import { List } from 'immutable'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import CreateAvoir from '../components/CreateAvoir'

class Avoir extends Component {
    state = {
        create: false,
    }
    componentDidMount() {
        this.props.getListeAvoir()
    }
    print = data => {
        window.open(`https://www.extensia-france.com/printers/avoirPrinter.php?listeAvoir=${data.id_avoir}`, "_blank")

    }
    createAvoir = () => {
        this.setState({ create: true })
    }
    render() {
        return (
            <div className="container-fluid">
                <div>

                    <MaterialTable
                        style={{ fontSize: 12 }}

                        columns={[
                            { title: 'Id Avoir', field: 'id_avoir', type: 'numeric', align: 'center' },
                            { title: 'Client', field: 'Nom_agence' },
                            { title: 'Description', field: 'description' },
                            { title: 'Ref', field: 'ref' },
                            { title: 'Total HT', field: 'tot_ht', align: 'center' },
                            { title: 'Date', field: 'date' },
                        ]}

                        options={{
                            pageSize: 10,
                            selection: true,
                            actionsColumnIndex: -1,

                        }}
                        actions={[
                            {
                                tooltip: 'Créer',
                                icon: 'add',
                                onClick: this.createAvoir,
                                isFreeAction: true,
                            },
                            {
                                tooltip: 'Imprimer',
                                icon: 'picture_as_pdf',
                                onClick: (evt, data) => this.print(data),
                                isFreeAction: true,
                            },
                            {
                                tooltip: 'Imprimer',
                                icon: 'picture_as_pdf',
                                onClick: (evt, data) => this.print(data),
                                position: 'row'
                            }

                        ]}
                        data={List(this.props.listeAvoir).toJS()}
                        title={"Liste des Avoirs "}

                        components={{
                            Toolbar: props => (
                                <div>
                                    <MTableToolbar {...props} />
                                </div>
                            ),
                        }}

                        localization={localization}
                    />
                    <Dialog
                        open={this.state.create}
                        onClose={() => this.setState({ create: false })}
                        fullWidth={true}
                        maxWidth={'lg'}
                        style={{ textAlign: "center" }}
                    >
                        <DialogTitle id="form-dialog-title">Créer un Avoir</DialogTitle>
                        <DialogContent >
                            <CreateAvoir onCreate={() => this.props.getListeAvoir()} />
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={() => this.setState({ create: false })} color="primary">
                                Fermer
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div >
            </div >
        )
    }
}

const mapStateToProps = (state) => {
    return {
        listeAvoir: state.facturation.listeAvoir
    }
}
const mapDispatchToProps = {
    getListeAvoir
}

export default connect(mapStateToProps, mapDispatchToProps)(Avoir)