import { combineReducers } from "redux";
import AuthentificationReducer from "./authentification"
import ActionInfoReducer from "./action-info"
import RessourcesReducer from "./ressources"
import CoursReducer from "./cours"
import produitReducer from "./produit"
import clientReducer from "./client"
import generalData from "./generalData"
import of from "./of"
import analyse from "./analyse"
import pierre from "./pierre"
import facture from "./facture";
import AtelierReducer from "./atelier";
const rootReducer = combineReducers({
  authentification: AuthentificationReducer,
  actionInfo: ActionInfoReducer,
  ressources: RessourcesReducer,
  cours: CoursReducer,
  produit: produitReducer,
  clients: clientReducer,
  generalData: generalData,
  ofs: of,
  analyse: analyse,
  pierre: pierre,
  facturation: facture,
  atelier: AtelierReducer,
});

export default rootReducer;
