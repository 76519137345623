import React, { useEffect, useState } from 'react'

import { TextField } from '@material-ui/core';
import Axios from 'axios';
import { meekroDB } from '../../config';
import { preventEnter } from '../../helpers/functions';

const PierreChangePrice = ({ data, onAction }) => {

    const [euro, setEuro] = useState('')
    const [dollar, setDollar] = useState('')
    const [coef, setCoef] = useState('')

    useEffect(() => data && data.length !== 0 && populateForm(data[0]), [data]);


    const handleSubmit = e => {
        e.preventDefault()
        onSave(e)
    }
    const populateForm = (data) => {
        // console.log(data);
        setEuro(data.euro)
        setDollar(data.dollar)
        setCoef(data.coeff)
    }
    const onSave = e => {
        e.preventDefault()
        let listeTarif = '', listePierre = '', sep = ''
        Object.values(data).forEach(tarif => {
            listeTarif += sep + tarif.id_tarif
            listePierre += sep + tarif.id_pier
            sep = ','
        })
        const dataTarif = {
            table: 'tarifs',
            primaryKey: 'id_tarif',
            id: listeTarif,
            euro,
            dollar,
        }
        const dataPierre = {
            table: 'pierre',
            primaryKey: 'id_pier',
            id: listePierre,
            coeff: coef,
        }
        //console.log(dataTarif, dataPierre);
        Axios.put(`${meekroDB}/general.php`, dataTarif)
            .then(response => {
                Axios.put(`${meekroDB}/general.php`, dataPierre)
                    .then(response => {
                        onAction()
                    }).catch(error => {
                        console.log(error);
                    });
            }).catch(error => {
                console.log(error);
            });

    }
    return (
        <div className='container-fluid'>
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-sm">
                        <TextField
                            type='number' label='Euro'
                            onChange={e => setEuro(e.target.value)}
                            value={euro}
                            variant='outlined'
                            margin='dense'
                            onKeyDown={e => preventEnter(e)}
                        />
                    </div>
                    <div className="col-sm">
                        <TextField
                            type='number' label='Dollar'
                            onChange={e => setDollar(e.target.value)}
                            value={dollar}
                            variant='outlined'
                            margin='dense'
                            onKeyDown={e => preventEnter(e)}
                        />
                    </div>
                    <div className="col-sm">
                        <TextField
                            type='number' label='Coef'
                            onChange={e => setCoef(e.target.value)}
                            value={coef}
                            variant='outlined'
                            margin='dense'
                            onKeyDown={e => preventEnter(e)}
                        />
                    </div>
                </div>
                <button type="submit" className="btn btn-link btn-sm" >Enregistrer</button>
            </form>

        </div >
    )

}

export default (PierreChangePrice)