import { GET_ATELIER } from '../actions/action-types';

const initialState = {
    listeJoaillier: [],
}


export default function AtelierReducer(state = initialState, action) {
    switch (action.type) {
        case GET_ATELIER:
            return {
                ...state,
                listeJoaillier: action.payload,
            }
        default:
            return state;

    }

}
