import { GET_COURS } from '../actions/action-types';

const initialState = {
    listeCours: []
}


export default function CoursReducer(state = initialState, action) {
    switch (action.type) {

        case GET_COURS:
            return {
                ...state,
                listeCours: action.payload
            }
        default:
            return state;

    }

}
