import { ADD_OF, UPDATE_OF, DELETE_OF, DELETE_OFS, GET_OFS, GET_OF_FROM_ID, ADD_OF_TO_FACT, DELETE_OF_TO_FACT, SET_OF_TO_EDIT } from '../actions/action-types';

const initialState = {
    ofs: [],
    listeOf: [],
    traceOf: [],
    ofsToFact: [],
    ofToEdit: [],
}


export default function OfReducer(state = initialState, action) {
    switch (action.type) {
        case GET_OF_FROM_ID:
            return {
                ...state,
                traceOf: [action.payload]
            }
        case GET_OFS:
            return {
                ...state,
                listeOf: action.payload
            }
        case SET_OF_TO_EDIT:
            return {
                ...state,
                ofToEdit: action.payload
            }
        case ADD_OF_TO_FACT:
            let data = state.listeOf.find(r => r.num_sachet === action.payload)
            if (data !== undefined) {
                return {
                    ...state,
                    ofsToFact: [...state.ofsToFact, data]
                }
            } else {
                return {
                    ...state,
                }
            }

        case DELETE_OF_TO_FACT:
            return {
                ...state,
                ofsToFact: [
                    ...state.ofsToFact.filter(of => of.num_sachet !== action.payload)
                ]
            }
        case ADD_OF:
            return {
                ...state,
                ofs: [
                    ...state.ofs,
                    action.payload
                ]
            }
        case UPDATE_OF:
            return {
                ...state,
                ofs: [
                    ...state.ofs.map(of => {
                        if (of.id === action.payload.id) return action.payload
                        else return of
                    })
                ]
            }
        case DELETE_OF:
            return {
                ...state,
                ofs: [
                    ...state.ofs.filter(of => of.id !== action.payload)
                ]
            }
        case DELETE_OFS:
            return {
                ...state,
                ofs: []
            }
        default:
            return state;

    }

}
