import React, { useState } from 'react';
import { Button, Box, Typography, Checkbox } from '@material-ui/core';
import { imagesRepo } from '../../config';

const FormulaireAssociation = ({ selectedProduits, selectedImage, onSave, isDefaultImage, onDefaultImageChange }) => {
    const [isChecked, setIsChecked] = useState(isDefaultImage);

    // Vous pouvez ajouter ici la logique pour gérer l'association des produits et des images

    const handleCheckboxChange = (event) => {
        const isChecked = event.target.checked;
        setIsChecked(isChecked);
        onDefaultImageChange(isChecked); // Appeler la fonction de rappel du parent avec la nouvelle valeur de la case à cocher
    };

    return (
        <Box display="flex" flexDirection="column" alignItems="center">
            {/* Affiche la liste des produits sélectionnés */}
            <Typography variant="h6">Produits sélectionnés :</Typography>
            {selectedProduits && selectedProduits.length > 0 ? (
                selectedProduits.map((produit, index) => (
                    <Typography key={index} variant="body1">{produit.ref + " " + produit.description}</Typography>
                ))
            ) : (
                <Typography variant="body1">Aucun produit sélectionné.</Typography>
            )}

            {/* Affiche l'image sélectionnée sur la même ligne */}
            <Box display="flex" flexDirection="row" alignItems="center" marginTop="10px">
                {selectedImage ? (
                    <img
                        src={imagesRepo + selectedImage}
                        alt="Icône de maison sélectionnée"
                        style={{ height: '100px', marginRight: '10px' }}
                    />
                ) : (
                    <Typography variant="body1">Aucune image sélectionnée.</Typography>
                )}
            </Box>
            {/* Affiche la case à cocher "Image par Défaut" */}
            <Box display="flex" flexDirection="row" alignItems="center" marginTop="10px">
                <Checkbox
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    style={{ color: '#007bff' }}
                />
                <Typography variant="body1">Image par Défaut</Typography>
            </Box>
            {/* Boutons sur la même ligne */}
            <Box display="flex" flexDirection="row" marginTop="10px">
                <Button
                    variant="outlined"
                    style={{
                        margin: '0 5px',
                        borderColor: '#007bff',
                        color: '#007bff',
                        opacity: selectedProduits.length === 0 || !selectedImage ? 0.5 : 1,
                    }}
                    onClick={() => onSave(false)}
                    disabled={selectedProduits.length === 0 || !selectedImage}
                >
                    Enregistrer
                </Button>
                <Button
                    variant="outlined"
                    style={{
                        margin: '0 5px',
                        borderColor: '#007bff',
                        color: '#007bff',
                        opacity: selectedProduits.length === 0 ? 0.5 : 1,
                    }}
                    onClick={() => onSave(true)}
                    disabled={selectedProduits.length === 0}
                >
                    Désaffecter
                </Button>
            </Box>
        </Box>
    );
};

export default FormulaireAssociation;
