import MaterialTable, { MTableToolbar } from 'material-table'
import React, { useEffect, useState } from 'react'
import { localization, meekroDB } from '../../config'
import { getListeJoaillier, getListeAtelier } from '../../actions'
import { connect } from 'react-redux'
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, makeStyles, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import Axios from 'axios'

const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing(1),
    },
}));

const TableJoaillier = ({ listeAtelier, getListeAtelier, listeJoaillier, of = null,enCours=true }) => {
    const classes = useStyles();
    const [edit, setEdit] = useState(false)
    const [retour, setRetour] = useState(false)
    const [joaillier, setJoaillier] = useState(null)
    const [idLigne, setIdLigne] = useState('')
    const [poids, setPoids] = useState('')
    const [poidsRendu, setPoidsRendu] = useState('')
    const [temps, setTemps] = useState('')

    useEffect(() => {
        getListeAtelier()
    }, [getListeAtelier]);

    const listeAtelierClone = (enCours) => {
        if (listeAtelier.length !== 0) {
            const data = [...listeAtelier].filter(item => item.rendu === (enCours ? 0 : 1));
            return data;
        }
        return [];
    }
    const retourOf = (data) => {
        setPoids(data.poids_d)
        setIdLigne(data.id_ligne)
        setTemps(data.plus_mo)
        setPoidsRendu(data.poids_r)
        setJoaillier(listeJoaillier.filter(r => r.id === data.id_ouvrier)[0])
        setRetour(true)

    }
    const editOf = (data) => {
        setPoids(data.poids_d)
        setIdLigne(data.id_ligne)
        setJoaillier(listeJoaillier.filter(r => r.id === data.id_ouvrier)[0])
        setEdit(true)

    }
    const deleteOf = (data) => {
        const datat = {
            table: 'ouvrier',
            primaryKey: 'id_ligne',
            id: data.id_ligne,
        }
        //console.log(datat);
        Axios.post(`${meekroDB}/general_remove.php`, datat)
            .then((response) => {
                //console.log(response.data);
                let id = ''
                if (of !== null && of.num_sachet !== '') id = of.num_sachet
                getListeAtelier(id)
            })
            .catch(error => {
                console.log(error);
            });
    }
    const retourAtelier = () => {
        const data = {
            table: 'ouvrier',
            primaryKey: 'id_ligne',
            id: idLigne,
            poids_d: poids,
            poids_r: poidsRendu,
            temps: temps,
            rendu: 1,
        }
        Axios.put(`${meekroDB}/general.php`, data)
            .then((response) => {
                let id = ''
                if (of !== null && of.num_sachet !== '') id = of.num_sachet
                getListeAtelier(id)
                setRetour(false)
            })
            .catch(error => {
                console.log(error);
            });
    }
    const modifAtelier = () => {
        const data = {
            table: 'ouvrier',
            primaryKey: 'id_ligne',
            id: idLigne,
            poids_d: poids,
        }
        Axios.put(`${meekroDB}/general.php`, data)
            .then((response) => {
                let id = ''
                if (of !== null && of.num_sachet !== '') id = of.num_sachet
                getListeAtelier(id)
                setEdit(false)
            })
            .catch(error => {
                console.log(error);
            });
    }
    return (
        <div className='form-group'>
            <MaterialTable
                style={{ fontSize: 12, zIndex: 0 }}
                columns={[
                    { align: 'left', title: 'OF', field: 'n_sachet', type: 'numeric' },
                    { align: 'left', title: 'Date_D', field: 'date_envoi' },
                    { align: 'left', title: 'Joaillier', field: 'label' },
                    { align: 'left', title: 'Poids', field: 'poids_d' },
                    { align: 'left', title: 'Pds_R', field: 'poids_r' },
                    { align: 'left', title: 'Temps', field: 'temps' },
                    { align: 'left', title: 'Ref', field: 'ref' },
                    { align: 'left', title: 'Desc', field: 'refDesc', minWidth: 250 },

                ]}

                options={{
                    actionsColumnIndex: -1,
                    // rowStyle: rowData => ({
                    //     backgroundColor: (this.selectedEnCours === rowData.num_sachet) ? '#AED1FF' : ''
                    // })
                }}
                actions={[

                    rowData => ({
                        icon: CheckIcon,
                        tooltip: rowData.rendu === '1' ? 'Modifier Retour' : 'Retour',
                        onClick: (e, rowData) => retourOf(rowData)
                    }),
                    rowData => ({
                        icon: EditIcon,
                        tooltip: 'Modifier',
                        hidden: rowData.rendu === '1',
                        onClick: (e, rowData) => editOf(rowData)
                    }),
                    rowData => ({
                        icon: DeleteIcon,
                        tooltip: 'Supprimer',
                        onClick: (e, rowData) => deleteOf(rowData)
                    })
                ]}
                data={listeAtelierClone(enCours)}
                title="Joaillier"

                components={{
                    Toolbar: props => (
                        <div className='row'>

                            <div className="col-sm">
                                <button onClick={() => getListeAtelier()} className="btn btn-link">Rafraichir</button>
                            </div>

                            <div className="col-8">
                                <MTableToolbar {...props} />
                            </div>

                        </div>
                    ),
                }}

                //onRowClick={this.onRowClick}
                localization={localization}
            />
            <Dialog
                open={retour}
                onClose={() => setRetour(false)}
                fullWidth={true}
            >
                <DialogTitle id="form-dialog-title">Modifier</DialogTitle>
                <DialogContent>
                    <FormControl className={classes.form} >


                        <div className="form-group">
                            <Autocomplete
                                value={joaillier}
                                options={listeJoaillier.filter(r => r.actif === 1)}
                                getOptionLabel={(option) => option.label}
                                onChange={(event, values) => setJoaillier(values)}
                                getOptionSelected={(option) => option.id === joaillier.id}
                                autoHighlight
                                autoComplete
                                clearOnEscape
                                style={{ width: 220 }}
                                renderInput={(params) => <TextField  {...params} label="Joaillier" variant="outlined" margin="normal" size='small' />}
                            />
                        </div>
                        <div className="form-group">
                            <TextField style={{ marginLeft: 3, marginTop: 3 }} value={poids} onChange={e => setPoids(e.target.value)} type='number' label='Poids Donné' variant='outlined' size='small' />

                        </div>
                        <div className="form-group">
                            <TextField autoFocus style={{ marginLeft: 3, marginTop: 3 }} value={poidsRendu} onChange={e => setPoidsRendu(e.target.value)} type='number' label='Poids Rendu' variant='outlined' size='small' />
                        </div>
                        <div className="form-group">
                            <TextField style={{ marginLeft: 3, marginTop: 3 }} value={temps} onChange={e => setTemps(e.target.value)} type='number' label='Temps' variant='outlined' size='small' />
                        </div>
                        <div className="form-group">
                            <button className='btn btn-link btn-sm' onClick={retourAtelier}>Enregistrer</button>

                        </div>


                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setRetour(false)} color="primary">
                        Fermer
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={edit}
                onClose={() => setEdit(false)}
                fullWidth={true}
            >
                <DialogTitle id="form-dialog-title">Modifier</DialogTitle>
                <DialogContent>
                    <FormControl className={classes.form} >


                        <div className="form-group">
                            <Autocomplete
                                value={joaillier}
                                options={listeJoaillier.filter(r => r.actif === 1)}
                                getOptionLabel={(option) => option.label}
                                onChange={(event, values) => setJoaillier(values)}
                                getOptionSelected={(option) => option.id === joaillier.id}
                                autoHighlight
                                autoComplete
                                clearOnEscape
                                style={{ width: 220 }}
                                renderInput={(params) => <TextField  {...params} label="Joaillier" variant="outlined" margin="normal" size='small' />}
                            />
                        </div>
                        <div className="form-group">
                            <TextField style={{ marginLeft: 3, marginTop: 3 }} value={poids} onChange={e => setPoids(e.target.value)} type='number' label='Poids' variant='outlined' size='small' />

                        </div>
                        <div className="form-group">
                            <button onClick={modifAtelier} className='btn btn-link btn-sm'>Modifier</button>

                        </div>


                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setEdit(false)} color="primary">
                        Fermer
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        listeJoaillier: state.generalData.listeJoaillier,
        listeAtelier: state.generalData.listeAtelier
    }
}
const mapDispatchToProps = {
    getListeJoaillier,
    getListeAtelier
}
export default connect(mapStateToProps, mapDispatchToProps)(TableJoaillier)