import {
    GET_TYPE_TRANSPORT, GET_LISTE_METAUX, GET_LISTE_STADE, GET_LISTE_TYPE_PRODUIT,
    GET_LISTE_STATUT, GET_LISTE_JOAILLIER, GET_LISTE_ATELIER,
    GET_LISTE_SERTISSEUR, GET_LISTE_OF_SERTI, GET_LISTE_FOURNISSEUR, GET_LISTE_BOITE_OR, GET_TYPE_TARIF, GET_TYPE_COM, GET_LISTE_IMAGE, GET_TYPE_TVA, GET_TYPE_COMMANDE, SET_SEARCH
} from '../actions/action-types';

const initialState = {
    search: "",
    listeTypeTransport: [],
    listeStade: [],
    listeMetaux: [],
    listeTypeProduit: [],
    listeStatut: [],
    listeJoaillier: [],
    listeAtelier: [],
    listeSertisseur: [],
    listeOfSerti: [],
    listeFournisseur: [],
    listeBoiteOr: [],
    listeTypeTarif: [],
    listeTypeCom: [],
    listeImage: [],
    listeTva: [],
    typeCommande: [],

}


export default function GeneralDataReducer(state = initialState, action) {
    switch (action.type) {
        case SET_SEARCH:
            return {
                ...state,
                search: action.payload
            }
        case GET_TYPE_TRANSPORT:
            return {
                ...state,
                listeTypeTransport: action.payload
            }
        case GET_LISTE_STADE:
            return {
                ...state,
                listeStade: action.payload
            }
        case GET_LISTE_METAUX:
            return {
                ...state,
                listeMetaux: action.payload
            }
        case GET_LISTE_TYPE_PRODUIT:
            return {
                ...state,
                listeTypeProduit: action.payload
            }
        case GET_LISTE_STATUT:
            return {
                ...state,
                listeStatut: action.payload
            }
        case GET_LISTE_JOAILLIER:
            return {
                ...state,
                listeJoaillier: action.payload
            }
        case GET_LISTE_ATELIER:
            return {
                ...state,
                listeAtelier: action.payload
            }
        case GET_LISTE_SERTISSEUR:
            return {
                ...state,
                listeSertisseur: action.payload
            }
        case GET_LISTE_OF_SERTI:
            return {
                ...state,
                listeOfSerti: action.payload
            }
        case GET_LISTE_FOURNISSEUR:
            return {
                ...state,
                listeFournisseur: action.payload
            }
        case GET_LISTE_BOITE_OR:
            return {
                ...state,
                listeBoiteOr: action.payload
            }
        case GET_TYPE_TARIF:
            return {
                ...state,
                listeTypeTarif: action.payload
            }
        case GET_TYPE_COM:
            return {
                ...state,
                listeTypeCom: action.payload
            }
        case GET_LISTE_IMAGE:
            return {
                ...state,
                listeImage: action.payload
            }
        case GET_TYPE_TVA:
            return {
                ...state,
                listeTva: action.payload
            }
        case GET_TYPE_COMMANDE:
            return {
                ...state,
                typeCommande: action.payload
            }

        default:
            return state;

    }

}
