import React, { Component } from 'react'
import { getListeFournisseur, getListePierre } from '../../actions'
import { connect } from 'react-redux'
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { getPierre } from '../../selectors/pierre'
import { getTodaySqlFormat } from '../../helpers/functions'
import Axios from 'axios'
import { meekroDB } from '../../config'

class ReceptionPierre extends Component {
    state = {
        confie: '',
        euro: '',
        dollar: '',
        poids: '',
        certificat: '',
        euroDollar: '',
        fournisseur: null,
        pierre: null,
    }
    componentDidMount() {
        this.props.getListeFournisseur()
        this.props.getListePierre()
    }
    handleSave = () => {
        const data = {
            table: 'pierres_recues',
            no_confie: this.state.confie,
            date_reception: getTodaySqlFormat(),
            id_four: this.state.fournisseur.four_id,
            id_pier: this.state.pierre.id_pier,
            id_qual: this.state.pierre.id_qual,
            euro: this.state.euro,
            dollar: this.state.dollar,
            cours_eur_doll: this.state.euroDollar,
            poids: this.state.poids,
            certificat: this.state.certificat,
        }
        Axios.post(`${meekroDB}/general.php`, data)
            .then((response) => {
                this.resetForm()
            })
            .catch(error => {
                console.log(error);
            });
    }
    resetForm = () => {
        this.setState({
            euro: '',
            dollar: '',
            poids: '',
            certificat: '',
            pierre: null,
        })
    }
    render() {
        return (
            <div>
                <div className="row justify-content-md-center">
                    <div className="col col-lg-2 form-group">
                        <TextField autoFocus required label="N° Confié" variant="outlined"
                            value={this.state.confie}
                            onChange={(e) => this.setState({ confie: e.target.value })}
                        />
                    </div>
                    <div className="col col-lg-2 form-group">
                        <Autocomplete
                            value={this.state.fournisseur}
                            options={this.props.listeFournisseur}
                            getOptionLabel={(option) => option.raison_soc}
                            onChange={(event, values) => this.setState({ fournisseur: values })}
                            getOptionSelected={(option) => option.four_id === this.state.fournisseur.four_id}
                            autoHighlight
                            autoComplete
                            clearOnEscape
                            renderInput={(params) => <TextField onKeyPress={this.prenventEnter} {...params} required label="Fournisseur" variant="outlined" />}
                        />
                    </div>
                </div>
                <div className="row justify-content-md-center">
                    <div className="col col-lg-4 form-group">
                        <Autocomplete
                            value={this.state.pierre}
                            options={this.props.listePierre}
                            getOptionLabel={(option) => option.descriptionPierre}
                            onChange={(event, values) => this.setState({ pierre: values, euro: values.euro, dollar: values.dollar })}
                            autoHighlight
                            autoComplete
                            clearOnEscape
                            renderInput={(params) => <TextField onKeyPress={this.prenventEnter} {...params} label="Pierre" variant="outlined" />}
                        />
                    </div>
                </div>
                <div className="row justify-content-md-center">


                    <div className="col col-lg-2 form-group">
                        <TextField label="Prix $" variant="outlined"
                            value={this.state.dollar}
                            onChange={(e) => this.setState({ dollar: e.target.value })}
                        />
                    </div>
                    <div className="col col-lg-2 form-group">
                        <TextField label="Prix €" variant="outlined"
                            value={this.state.euro}
                            onChange={(e) => this.setState({ euro: e.target.value })}
                        />
                    </div>
                </div>
                <div className="row justify-content-md-center">
                    <div className="col col-lg-2 form-group">
                        <TextField label="Poids Recu" variant="outlined"
                            value={this.state.poids}
                            onChange={(e) => this.setState({ poids: e.target.value })}
                        />
                    </div>
                    <div className="col col-lg-2 form-group">

                        <TextField label="Cours €/$" variant="outlined"
                            value={this.state.euroDollar}
                            onChange={(e) => this.setState({ euroDollar: e.target.value })}
                        />
                    </div>
                    <div className="col col-lg-2 form-group">
                        <TextField label="Certificat" variant="outlined"
                            value={this.state.certificat}
                            onChange={(e) => this.setState({ certificat: e.target.value })}
                        />
                    </div>
                </div>

                <div className="row justify-content-md-center">
                    <button type="button" className="btn btn-link btn-sm" onClick={this.handleSave}>Enregistrer</button>
                </div>
            </div>

        )
    }
}
const mapStateToProps = (state) => {
    return {
        listeFournisseur: state.generalData.listeFournisseur,
        listePierre: getPierre(state),
    }
}
const mapDispatchToProps = {
    getListeFournisseur,
    getListePierre,
}
export default connect(mapStateToProps, mapDispatchToProps)(ReceptionPierre)