import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Formik, useFormik } from 'formik'; // 1. Importation de Form
import * as Yup from 'yup';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import { getListeAtelier } from '../../../actions';

const validationSchema = Yup.object().shape({
    of: Yup.object().required('N° OF obligatoire.'),
    poids: Yup.number()
        .required('Poids valide obligatoire.')
        .moreThan(-1, 'Le poids rendu doit être un nombre positif.'),
    poidsR: Yup.number()
        .required('Poids Rendu valide obligatoire.')
        .moreThan(-1, 'Le poids rendu doit être un nombre positif.'),
    temps: Yup.number().required('Temps valide obligatoire.').moreThan(-1, 'Le poids rendu doit être un nombre positif.'),
});

const JoaillierRetourForm = ({ listeOfsAtelier, getListeAtelier, onSubmitForm, dataForm }) => {
    const ofRef = useRef(null);
    const poidsRef = useRef(null);
    const poidsRRef = useRef(null);
    const tempsRef = useRef(null);

    const formik = useFormik({
        initialValues: {
            of: null,
            poids: '',
            poidsR: '',
            temps: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            onSubmitForm(values);
            resetForm();
        },
    });
    const { values, errors, touched, handleChange, handleSubmit, handleBlur, setFieldValue } = formik;

    useEffect(() => {
        getListeAtelier();
    }, [getListeAtelier]);
    useEffect(() => {
        if (dataForm !== null) {
            populateForm(dataForm);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataForm]);


    const populateForm = (data) => {
        if (!data) {
            formik.resetForm();
            return;
        }
        const selectedOf = listeOfsAtelier.find(item => item.id_ligne === data.id_ligne);
        setFieldValue('of', selectedOf || null);
        setFieldValue('poids', data.poids);
        setFieldValue('poidsR', data.poidsR);
        setFieldValue('temps', data.temps);
        if (!data.of) ofRef.current.focus();
    };

    useEffect(() => {
        if (values.of && poidsRRef.current) {
            setFieldValue('poids', values.of.poids_d);
            setFieldValue('temps', values.of.plus_mo);
            poidsRRef.current.focus();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.of]);

    const handleKeyPress = async (event, nextRef) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            // Persiste l'événement
            event.persist();
            // Valider le champ actuel
            await formik.validateField(event.target.name);
            // Accéder à l'erreur
            const error = formik.errors[event.target.name];
            formik.setFieldTouched(event.target.name)
            // Si le champ actuel est valide
            console.log(error);
            if (!error) {
                nextRef.current.focus();
            }
        }
    };

    return (
        <Formik initialValues={values} onSubmit={handleSubmit} >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Autocomplete
                        options={listeOfsAtelier}
                        getOptionLabel={(option) => option.num_sachet + ' ' + option.label}
                        value={values.of}
                        onChange={(event, newValue) => setFieldValue('of', newValue)}
                        autoHighlight
                        autoComplete
                        fullWidth
                        clearOnEscape
                        getOptionSelected={(option) => option.id_ligne === values.of.id_ligne}
                        renderInput={(params) => <TextField {...params} label="N° OF" variant="outlined" fullWidth
                            inputRef={ofRef}
                            error={errors.of && touched.of}
                            helperText={(errors.of && touched.of) ? errors.of : null} />}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Poids"
                        variant="outlined"
                        value={values.poids}
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="poids"
                        error={errors.poids && touched.poids}
                        helperText={(errors.poids && touched.poids) ? errors.poids : null}
                        inputRef={poidsRef}
                        onKeyPress={(e) => handleKeyPress(e, poidsRRef)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Poids Rendu"
                        variant="outlined"
                        value={values.poidsR}
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="poidsR"
                        error={errors.poidsR && touched.poidsR}
                        helperText={(errors.poidsR && touched.poidsR) ? errors.poidsR : null}
                        inputRef={poidsRRef}
                        onKeyPress={(e) => handleKeyPress(e, tempsRef)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Temps"
                        variant="outlined"
                        value={values.temps}
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="temps"
                        error={errors.temps && touched.temps}
                        helperText={(errors.temps && touched.temps) ? errors.temps : null}
                        inputRef={tempsRef}
                        onKeyPress={async (e) => {
                            if (e.key === 'Enter') {
                                const errors = await formik.validateForm();
                                formik.setFieldTouched('temps')
                                console.log(errors);
                                if (!errors.temps) {
                                    formik.handleSubmit();
                                    ofRef.current.focus();
                                }
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button variant="contained" color="primary" type="submit" onClick={handleSubmit}>Ajouter</Button>
                </Grid>
            </Grid>
        </Formik>
    );
};

const mapStateToProps = (state) => {
    return {
        listeOfsAtelier: state.generalData.listeAtelier,
    };
};

const mapDispatchToProps = {
    getListeAtelier,
};

export default connect(mapStateToProps, mapDispatchToProps)(JoaillierRetourForm);
