import { TextField, Button, Grid, Card, CardContent, Typography, Box } from '@material-ui/core';
import Axios from 'axios';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { meekroDB } from '../config';
import { getTodaySqlFormat } from '../helpers/functions';
import { getListeOf } from '../actions';

const OfTools = ({ getListeOf }) => {
    const [debut, setDebut] = useState('');
    const [ofDebut, setOfDebut] = useState('');
    const [ofFin, setOfFin] = useState('');
    const [fin, setFin] = useState('');
    const [listeToPrint, setListeToPrint] = useState('');
    const [ofToTransfert, setOfToTransfert] = useState('');
    const [ofDestination, setOfDestination] = useState('');
    const [ofStock, setOfStock] = useState('');
    const [ofStockDestination, setOfStockDestination] = useState('');

    const generateListToPrint = (start, end) => {
        const startNum = parseInt(start, 10);
        const endNum = parseInt(end, 10);
        const list = [];

        for (let i = startNum; i <= endNum; i++) {
            list.push(i);
        }

        return list.join(',');
    };

    const printInterval = () => {
        let dateFin = fin === '' ? '99999999' : fin;
        if (debut !== '') window.open(`https://www.extensia-france.com/printers/ofPrinter.php?of_min=${debut}&of_max=${dateFin}`, "_blank");
    }

    const printListeOfs = () => {
        if (listeToPrint !== '') {
            window.open(`https://www.extensia-france.com/printers/ofPrinter.php?ofs=${listeToPrint}`, "_blank");
        }
    }
    const printCommandeOf = () => {
        if (ofDebut !== '' && ofFin !== '') {
            const liste = generateListToPrint(ofDebut, ofFin);
            window.open(`https://www.extensia-france.com/printers/cmdOfPrinter.php?ofs=${liste}`, "_blank");
        }
    }

    const echangerDeuxOfs = () => {
        if (ofToTransfert !== '' && ofDestination !== '') {
            const data = {
                of_source: ofToTransfert,
                of_cible: ofDestination,
            };
            Axios.put(`${meekroDB}/of/of_tools.php`, data)
                .then(() => { })
                .catch(error => {
                    console.log(error);
                });
        }
    }

    const echangerOfAvecStock = () => {
        if (ofStock !== '' && ofStockDestination !== '') {
            const data = {
                of_source: ofStock,
                of_cible: ofStockDestination,
            };
            Axios.post(`${meekroDB}/of/of_tools.php`, data)
                .then((response) => { })
                .catch(error => {
                    console.log(error);
                });
        }
    }

    const createOfStock = () => {
        const data = {
            table: 'of',
            date_crea: getTodaySqlFormat(),
            date_livr: getTodaySqlFormat(),
            idp: 619,
            id_bijouterie: 249,
            statut: 50,
            type_metal: 1001,
            id_stade: 2,
            typ_transp: 1,
        };
        Axios.post(`${meekroDB}/general.php`, data)
            .then((response) => {
                getListeOf();
                alert('Of n° ' + response.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    return (
        <Grid container direction="row" spacing={2} style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
            <Grid item xs={2}>
                <Card style={{ height: '100%', display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                    <CardContent>
                        <Typography variant="h5" style={{ margin: '10px auto' }} >
                            Créer OF
                        </Typography>
                        <Typography variant="h6" style={{ margin: '10px auto' }} >
                            Stock / Collection
                        </Typography>
                    </CardContent>
                    <CardContent>
                        <Button variant="contained" color="primary" size='small' onClick={createOfStock}>Créer Of</Button>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={2} >
                <Card style={{ height: '100%', display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                    <CardContent>
                        <Typography variant="h5" style={{ margin: '10px auto' }} >
                            Imprimer Liste
                        </Typography>
                        <Typography variant="h6" style={{ margin: '10px auto' }} >
                            Of ou liste Ofs séparés par ','
                        </Typography>
                    </CardContent>
                    <CardContent>
                        <TextField
                            fullWidth
                            value={listeToPrint}
                            onChange={e => setListeToPrint(e.target.value)}
                            placeholder="Liste Ofs avec ','"
                            variant="outlined"
                            size="small"
                        />
                    </CardContent>
                    <CardContent>
                        <Button variant="contained" color="primary" size='small' onClick={printListeOfs}>Imprimer</Button>
                    </CardContent>

                </Card>
            </Grid >

            <Grid item xs={2} >
                <Card style={{ height: '100%', display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                    <CardContent>
                        <Typography variant="h5" style={{ margin: '10px auto' }} >
                            Imprimer Intervalle
                        </Typography>
                        <Typography variant="h6" style={{ margin: '10px auto' }} >
                            Of Début et Fin
                        </Typography>
                    </CardContent>
                    <CardContent>
                        <Box mt={2}>
                            <TextField
                                fullWidth
                                label='Of Début'
                                type='number'
                                value={debut}
                                onChange={e => setDebut(e.target.value)}
                                variant='outlined'
                                size='small'
                            />
                        </Box>
                        <Box mt={2}>
                            <TextField
                                fullWidth
                                label='Of Fin'
                                type='number'
                                value={fin}
                                onChange={e => setFin(e.target.value)}
                                variant='outlined'
                                size='small'
                            />
                        </Box>

                    </CardContent>
                    <CardContent>
                        <Button variant="contained" color="primary" size='small' onClick={printInterval}>Imprimer</Button>
                    </CardContent>
                </Card>
            </Grid >

            <Grid item xs={2} >
                <Card style={{ height: '100%', display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                    <CardContent>
                        <Typography variant="h5" style={{ margin: '10px auto' }} >
                            Imprimer Commande OF
                        </Typography>
                        <Typography variant="h6" style={{ margin: '10px auto' }} >
                            Ofs avec Photo
                        </Typography>
                    </CardContent>
                    <CardContent>
                        <Box mt={2}>
                            <TextField
                                fullWidth
                                label='Of Début'
                                type='number'
                                value={ofDebut}
                                onChange={e => setOfDebut(e.target.value)}
                                variant='outlined'
                                size='small'
                            />
                        </Box>
                        <Box mt={2}>
                            <TextField
                                fullWidth
                                label='Of Fin'
                                type='number'
                                value={ofFin}
                                onChange={e => setOfFin(e.target.value)}
                                variant='outlined'
                                size='small'
                            />
                        </Box>

                    </CardContent>
                    <CardContent>
                        <Button variant="contained" color="primary" size='small' onClick={printCommandeOf}>Imprimer</Button>
                    </CardContent>
                </Card>
            </Grid >
            <Grid item xs={2} >
                <Card style={{ height: '100%', display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                    <CardContent>
                        <Typography variant="h5" style={{ margin: '10px auto' }} >
                            Echanger des Ofs
                        </Typography>
                        <Typography variant="h6" style={{ margin: '10px auto' }} >
                            Of à transferer et Déstination
                        </Typography>
                    </CardContent>
                    <CardContent>
                        <Box mt={2}>
                            <TextField
                                fullWidth
                                label='Of à transferer'
                                type='number'
                                value={ofToTransfert}
                                onChange={e => setOfToTransfert(e.target.value)}
                                variant='outlined'
                                size='small'
                            />
                        </Box>
                        <Box mt={2}>
                            <TextField
                                fullWidth
                                label='Of déstination'
                                type='number'
                                value={ofDestination}
                                onChange={e => setOfDestination(e.target.value)}
                                variant='outlined'
                                size='small'
                            />
                        </Box>

                    </CardContent>
                    <CardContent>
                        <Button variant="contained" color="primary" size='small' onClick={echangerDeuxOfs}>Echanger</Button>
                    </CardContent>
                </Card>
            </Grid >

            <Grid item xs={2} >
                <Card style={{ height: '100%', display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                    <CardContent>
                        <Typography variant="h5" style={{ margin: '10px auto' }} >
                            Transfert OF Stock
                        </Typography>
                        <Typography variant="h6" style={{ margin: '10px auto' }} >
                            Transfert Stock vers Client
                        </Typography>
                    </CardContent>
                    <CardContent>
                        <Box mt={2}>
                            <TextField
                                fullWidth
                                label='Of Stock'
                                type='number'
                                value={ofStock}
                                onChange={e => setOfStock(e.target.value)}
                                variant='outlined'
                                size='small'
                            />
                        </Box>
                        <Box mt={2}>
                            <TextField
                                fullWidth
                                label='Of Client'
                                type='number'
                                value={ofStockDestination}
                                onChange={e => setOfStockDestination(e.target.value)}
                                variant='outlined'
                                size='small'
                            />
                        </Box>

                    </CardContent>
                    <CardContent>
                        <Button variant="contained" color="primary" size='small' onClick={echangerOfAvecStock}>Echanger</Button>
                    </CardContent>
                </Card>
            </Grid >

        </Grid >
    );
}

const mapDispatchToProps = {
    getListeOf
};

export default connect(null, mapDispatchToProps)(OfTools);
