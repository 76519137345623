import { GET_LISTE_CLIENT } from '../actions/action-types';

const initialState = {
    listeClient: [],
}


export default function ClientReducer(state = initialState, action) {
    switch (action.type) {
        case GET_LISTE_CLIENT:
            return {
                ...state,
                listeClient: action.payload,
            }
        default:
            return state;

    }

}
