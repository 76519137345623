import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import Axios from 'axios'
import { List } from 'immutable'
import MaterialTable, { MTableToolbar } from 'material-table'
import React, { Component } from 'react'
import { Col, Form } from 'react-bootstrap'
import { connect } from 'react-redux'
import { getListeTypePierre, getListeFormePierre, getListeFournisseur, getListeEtatPierre } from '../actions'
import FilterInput from '../components/filterInput'
import FilterSelect from '../components/filterSelect'
import { localization, meekroDB } from '../config'


import { createDefaultDescPierre, exportCsv, getTodaySqlFormat } from '../helpers/functions'
import TraceIdPierre from './pierre/TraceIdPierre'
class StockPierre extends Component {
    formatter = new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR'
    })
    state = {
        id: '',
        facture: '',
        type: '',
        forme: '',
        fournisseur: '',
        euroDollar: '',
        etat: '',
        stockPierre: null,
        editer: false,
        showHistorique: false,
        idSelected: null,
        labelFact: '',
        pierreEtat: null,
        listeConfie: '',
        historique: false,
        excel: false,
        prixTotal: 0,
        prixTotalNonConso: 0,
        dateDebut: '',
        dateFin: new Date().toISOString().split('T')[0],

    }
    componentDidMount() {
        this.props.getListeTypePierre()
        this.props.getListeFormePierre()
        this.props.getListeEtatPierre()
        this.props.getListeFournisseur()
    }
    onChange = (data, labelState) => {
        const label = labelState.toLowerCase()
        this.setState({ [label]: data })
    }
    afficher = () => {
        const data = {
            id_confs: this.state.id,
            type_pierre: this.state.type,
            type_forme: this.state.forme,
            fournisseurs: this.state.fournisseur,
            etat: this.state.etat,
            label_fact: this.state.facture,
            historique: this.state.historique ? '0' : '1',
            date_d: this.state.dateDebut,
            date_f: this.state.dateFin,
            histo: 1,

        }
        Axios.post(`${meekroDB}/pierre/stock.php`, data)
            .then((response) => {
                console.log(response);
                const stockPierre = createDefaultDescPierre(response.data)
                let tot = 0, totRestant = 0
                Object.values(stockPierre).forEach(p => {
                    tot += parseFloat(p.prix_ligne)
                    totRestant += parseFloat(p.prix_ligne_conso)
                })
                this.setState({ stockPierre, prixTotal: parseInt(tot), prixTotalNonConso: parseInt(totRestant) })
            })
            .catch(error => {
                console.log(error);
            });

    }
    sortirDuStock = (confies) => {
        let idConfs = '', sep = ''
        Object.values(confies).forEach(p => {
            idConfs += sep + p.id_conf
            sep = ','
        })

        const data = {
            table: 'pierres_recues',
            primaryKey: 'id_conf',
            id: idConfs,
            en_cours: '0',
            date_sorti: getTodaySqlFormat(),

        }
        Axios.put(`${meekroDB}/general.php`, data)
            .then((response) => {
                this.afficher()
            })
            .catch(error => {
                console.log(error);
            });
    }
    listeStockPierre = () => {
        const data = List(this.state.stockPierre)
        return data.toJS()
    }
    editConfie = (data) => {
        let idConfs = '', sep = ''

        Object.values(data).forEach(p => {
            idConfs += sep + p.id_conf
            sep = ','
        })
        this.setState({ editer: true, listeConfie: idConfs })
    }

    histoConfie = (data) => {
        this.setState({ showHistorique: true, idSelected: data.id_conf })
    }
    retourStock = (data) => {
        let idConfs = '', sep = ''
        Object.values(data).forEach(p => {
            idConfs += sep + p.id_conf
            sep = ','
        })
        const toSave = {
            id_conf: idConfs,
        }
        Axios.post(`${meekroDB}/pierre/retour_en_stock.php`, toSave)
            .then(() => {
                this.afficher()
            })
            .catch(error => {
                console.log(error);
            });
    }
    facturer = (e) => {
        e.preventDefault()
        const data = {
            table: 'pierres_recues',
            primaryKey: 'id_conf',
            id: this.state.listeConfie,
            cours_eur_doll: this.state.euroDollar,
            label_fact: this.state.labelFact,
            facture: '1',
            pierre_etat: '2',
            date_fact: getTodaySqlFormat(),
        }
        Axios.put(`${meekroDB}/general.php`, data)
            .then((response) => {
                this.afficher()
                this.setState({ editer: false })
                //console.log(response);
            })
            .catch(error => {
                console.log(error);
            });
    }
    enregistrer = (e) => {
        e.preventDefault()
        const data = {
            table: 'pierres_recues',
            primaryKey: 'id_conf',
            id: this.state.listeConfie,
            pierre_etat: this.state.pierreEtat.id_etat,
        }
        Axios.put(`${meekroDB}/general.php`, data)
            .then((response) => {
                this.afficher()
                this.setState({ editer: false })
            })
            .catch(error => {
                console.log(error);
            });
    }

    supprimer = () => {
        // if (window.confirm('Voulez vous supprimer ?')) {
        //     const datat = {
        //         table: 'pierres_recues',
        //         primaryKey: 'id_op',
        //         id: data.id_op,
        //     }
        //     //console.log(datat);
        //     Axios.post(`${meekroDB}/general_remove.php`, datat)
        //         .then((response) => {
        //             this.selectOf(this.state.of)
        //         })
        //         .catch(error => {
        //             console.log(error);
        //         });
        // }
    }
    render() {
        return (
            <div>
                <div className='row' style={{ minHeight: 200 }}>
                    <div className="col-auto">
                        <FilterInput label='Id' type='text' onChange={this.onChange} />
                    </div>
                    <div className="col-auto">
                        <FilterInput label='Facture' type='text' onChange={this.onChange} />
                    </div>
                    <div className="col-sm">
                        <FilterSelect label='Type' labelId='id_typ_pier' onChange={this.onChange} options={this.props.typePierre} />
                    </div>
                    <div className="col-sm">
                        <FilterSelect label='Forme' labelId='id_form' onChange={this.onChange} options={this.props.formePierre} />
                    </div>
                    <div className="col-sm">
                        <FilterSelect label='Fournisseur' labelId='four_id' labelDescription='raison_soc' onChange={this.onChange} options={this.props.fournisseur} />
                    </div>
                    <div className="col-sm">
                        <FilterSelect label='Etat' labelId='id_etat' onChange={this.onChange} options={this.props.etatPierre} />
                    </div>
                    <div className="col-sm">
                        <TextField
                            label="Date Réception Début"
                            type="date"
                            variant="outlined"
                            value={this.state.dateDebut}
                            onChange={(e) => this.setState({ dateDebut: e.target.value })}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <div style={{ marginBottom: '1rem' }}></div>
                        <TextField
                            label="Date Réception Fin"
                            type="date"
                            variant="outlined"
                            value={this.state.dateFin}
                            onChange={(e) => this.setState({ dateFin: e.target.value })}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        /></div>
                    <div className="col col-lg-1">
                        <button style={{ minHeight: 150 }} className='btn btn-primary btn-lg' onClick={this.afficher}>Afficher</button>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.historique}
                                    onChange={(e) => this.setState({ historique: e.target.checked })}
                                    color="primary"
                                />
                            }
                            label="Historique"
                        />
                    </div>
                    {/* <div className="col col-lg-1">
                        <button style={{ minHeight: 150 }} className='btn btn-primary btn-lg' onClick={this.imprimer}>Imprimer</button>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.excel}
                                    onChange={(e) => this.setState({ excel: e.target.checked })}
                                    color="primary"
                                />
                            }
                            label="Excel"
                        />
                    </div> */}
                </div>
                <div>
                    <MaterialTable
                        style={{ fontSize: 12 }}

                        columns={[
                            { align: 'left', title: 'Id', field: 'id_conf', type: 'numeric' },
                            { align: 'left', title: 'Description', field: 'descriptionPierre', width: 'auto' },
                            { align: 'left', title: 'Certificat', field: 'certificat', export: false },
                            { align: 'left', title: 'Recu', field: 'poids', type: 'numeric' },
                            { align: 'left', title: 'Conso', field: 'conso_tot', type: 'numeric', export: false },
                            { align: 'left', title: 'Retour', field: 'retour_tot', type: 'numeric', export: false },
                            { align: 'left', title: 'Reste', field: 'pds_rest', type: 'numeric' },
                            { align: 'left', title: 'Prix/Ct', field: 'prix' },
                            { align: 'left', title: 'Monnaie', field: 'currency', export: false },
                            { align: 'left', title: 'Prix Euro', field: 'prix_ligne', type: 'numeric' },
                            { align: 'left', title: 'Fournisseur', field: 'lefour' },
                            { align: 'left', title: 'Cours', field: 'cours_eur_doll', type: 'numeric', export: false },
                            { align: 'left', title: 'Confie', field: 'no_confie' },
                            { align: 'left', title: 'Date Recu', field: 'dr', export: false },
                            { align: 'left', title: 'Etat', field: 'labelEtat' },
                            { align: 'left', title: 'Facture', field: 'label_fact' },
                        ]}

                        options={{
                            exportButton: true,
                            exportAllData: true,
                            actionsColumnIndex: -1,

                            exportCsv: (allColumns, allData) => exportCsv(allColumns, allData, "stockPierre"),
                            pageSize: 10,
                            selection: true,

                        }}
                        actions={[
                            {
                                tooltip: 'Editer',
                                icon: 'edit',
                                onClick: (evt, data) => this.editConfie(data),
                                position: 'toolbarOnSelect'
                            },
                            {
                                tooltip: 'Réintegrer',
                                icon: 'reply',
                                onClick: (evt, data) => this.retourStock(data),
                                position: 'toolbarOnSelect',
                            },
                            {
                                tooltip: 'Historique Conso',
                                icon: 'manage_search',
                                onClick: (evt, data) => this.histoConfie(data),
                                position: 'row'
                            },

                            {
                                tooltip: 'Sortir du Stock',
                                icon: 'run_circle',
                                onClick: (evt, data) => this.sortirDuStock(data),
                                position: 'toolbarOnSelect'

                            },
                            // {
                            //     tooltip: 'Supprimer',
                            //     icon: 'delete',
                            //     onClick: (evt, data) => this.supprimer(data)
                            // }
                        ]}
                        data={this.listeStockPierre()}
                        title="Stock Pierre"

                        components={{
                            Toolbar: props => (
                                <div>
                                    <MTableToolbar {...props} />
                                    <div style={{ padding: '0px 25px' }}>
                                        <h6>Prix Total : {this.formatter.format(this.state.prixTotal)}</h6>
                                        <h6>Prix non Consommé :{this.formatter.format(this.state.prixTotalNonConso)}</h6>
                                    </div>
                                </div>
                            ),
                        }}

                        onRowClick={this.onRowClick}
                        localization={localization}
                    />
                </div>



                <Dialog
                    open={this.state.editer}
                    onClose={() => this.setState({ editer: false })}
                    fullWidth={true}
                    maxWidth={'sm'}
                    style={{ textAlign: "center" }}
                >
                    <DialogTitle id="form-dialog-title">Opération sur pierres de stock</DialogTitle>
                    <DialogContent >
                        <div className='d-flex justify-content-center'>
                            <Form>
                                <Form.Group controlId="formEnvoiAtelier">
                                    <Form.Row>
                                        <Col >
                                            <TextField
                                                autoFocus
                                                type='number' label='Cours €/$'
                                                onChange={e => this.setState({ euroDollar: e.target.value })}
                                                value={this.state.euroDollar}
                                                variant='outlined'
                                                size='small'
                                            />
                                        </Col>
                                        <Col>
                                            <TextField
                                                label='Label Facture'
                                                onChange={e => this.setState({ labelFact: e.target.value })}
                                                value={this.state.labelFact}
                                                variant='outlined'
                                                size='small'
                                            />
                                        </Col>


                                    </Form.Row>
                                    <Form.Row>
                                        <Col>
                                            <button
                                                onClick={this.facturer}
                                                className='btn btn-link btn-sm'
                                            >Facturer
                                            </button>
                                        </Col>

                                    </Form.Row>
                                    <Form.Row >
                                        <Col>
                                            <Autocomplete
                                                fullWidth
                                                value={this.state.pierreEtat}
                                                options={this.props.etatPierre}
                                                getOptionLabel={(option) => option.description}
                                                onChange={(event, values) => this.setState({ pierreEtat: values })}
                                                autoHighlight
                                                autoComplete
                                                clearOnEscape
                                                style={{ width: 220 }}
                                                renderInput={(params) => <TextField onKeyPress={this.preventEnter}  {...params} label="Etat" autoFocus variant="outlined" size='small' />}
                                            />
                                        </Col>

                                    </Form.Row>
                                    <Form.Row>
                                        <Col>
                                            <button onClick={this.enregistrer} className='btn btn-link btn-sm' >Modifier Etat </button>
                                        </Col>

                                    </Form.Row>
                                </Form.Group>

                            </Form>
                        </div>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={() => this.setState({ editer: false })} color="primary">
                            Fermer
                        </Button>
                    </DialogActions>
                </Dialog>


                <Dialog
                    open={this.state.showHistorique}
                    onClose={() => this.setState({ showHistorique: false })}
                    fullWidth={true}
                    maxWidth={'lg'}
                    style={{ textAlign: "center" }}
                >
                    <DialogTitle id="form-dialog-title">Historique Consommation</DialogTitle>
                    <DialogContent >
                        <TraceIdPierre id={this.state.idSelected} />
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={() => this.setState({ showHistorique: false })} color="primary">
                            Fermer
                        </Button>
                    </DialogActions>
                </Dialog>



            </div >
        )
    }
}
const mapStateToProps = (state) => {
    return {
        typePierre: state.pierre.listeTypePierre,
        formePierre: state.pierre.listeFormePierre,
        etatPierre: state.pierre.listeEtatPierre,
        fournisseur: state.generalData.listeFournisseur,
    }
}
const mapDispatchToProps = {
    getListeTypePierre,
    getListeFormePierre,
    getListeEtatPierre,
    getListeFournisseur,
}
export default connect(mapStateToProps, mapDispatchToProps)(StockPierre)