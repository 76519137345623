import React, { useEffect, useState } from 'react';
import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import axios from 'axios';
import { meekroDB } from '../../config';
import { dollarFormat, euroFormat } from '../../helpers/functions';

const ValorisationStock = () => {
    const [data, setData] = useState(null);
    const [goldPrice, setGoldPrice] = useState(null);

    const HighlightedText = ({ children }) => (
        <span style={{ fontWeight: 'bold' }}>{children}</span>
    );

    useEffect(() => {
        const fetchData = async () => {
            const result = await axios.post(`${meekroDB}/compta/valorisation_stock.php?action=valorisation`);
            setData(result.data);
        };

        fetchData();
    }, []);

    useEffect(() => {
        const fetchGoldPrice = async () => {
            try {
                const response = await axios.get('https://api.gold-api.com/price/XAU');
                // Convertir le prix de l'once en dollars en prix au gramme en euros
                const pricePerGramInDollar = (response.data.price * 28.3495 / 1000); // Convertir dollars par once en euros par gramme
                setGoldPrice(pricePerGramInDollar);
            } catch (error) {
                console.error('Error fetching gold price:', error);
                // En cas d'échec, définir le prix de l'or sur null
                setGoldPrice(null);
            }
        };

        fetchGoldPrice();
    }, []);

    // Calcul du solde total du prix de revient
    const totalPrixRevientPierres = data ?
        parseFloat(data.pierres[0].prixRevientDiamant) + parseFloat(data.pierres[0].prixRevientAutre)
        : null;
    const totalPrixRevientProduitsFinis = data ?
        parseFloat(data.produits[0].totalRevient) + parseFloat(data.produits_argents[0].totalRevient)
        : null;
    const totalPrixRevientOr = data && goldPrice ?
        (parseFloat(data.metalAtelier[0].totalOf) + parseFloat(data.metalBoiteOr[0].totalBoiteOr)) * goldPrice
        : null;

    const totalPoidsOr = data ?
        parseFloat(data.metalAtelier[0].totalOf) + parseFloat(data.metalBoiteOr[0].totalBoiteOr)
        : null;

    return (
        <Grid container spacing={2} style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
            <Grid item xs={2}>
                <Card>
                    <CardContent>
                        <Typography variant="h5" style={{ margin: '10px auto' }} >
                            Produit Finis
                        </Typography>
                        {data && (
                            <>
                                <Typography color="textSecondary" gutterBottom>
                                    Produits Finis Or: <HighlightedText>{euroFormat(data.produits[0].totalRevient)}</HighlightedText>
                                </Typography>
                                <Typography color="textSecondary" gutterBottom>
                                    Produits Finis Factices: <HighlightedText>{euroFormat(data.produits_argents[0].totalRevient)}</HighlightedText>
                                </Typography>

                                <div style={{ borderBottom: '1px solid #000', margin: '10px auto', width: '50%' }}></div>
                                <Typography color="error" style={{ textAlign: 'center' }} gutterBottom>
                                    TOTAL: <HighlightedText>{euroFormat(totalPrixRevientProduitsFinis)}</HighlightedText>
                                </Typography>
                            </>
                        )}
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={2}>
                <Card>
                    <CardContent>
                        <Typography variant="h5" style={{ margin: '10px auto' }}>
                            Pierres
                        </Typography>
                        {data && (
                            <>
                                <Typography color="textSecondary" gutterBottom>
                                    Diamants: <HighlightedText>{euroFormat(data.pierres[0].prixRevientDiamant)}</HighlightedText>
                                </Typography>
                                <Typography color="textSecondary" gutterBottom>
                                    Couleurs: <HighlightedText>{euroFormat(data.pierres[0].prixRevientAutre)}</HighlightedText>
                                </Typography>
                                <div style={{ borderBottom: '1px solid #000', margin: '10px auto', width: '50%' }}></div>
                                <Typography color="error" style={{ textAlign: 'center' }} gutterBottom>
                                    TOTAL: <HighlightedText>{euroFormat(totalPrixRevientPierres)}</HighlightedText>
                                </Typography>
                            </>
                        )}
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={2}>
                <Card>
                    <CardContent>
                        <Typography variant="h5" style={{ margin: '10px auto' }} >
                            Métal
                        </Typography>
                        {data && (
                            <>
                                <Typography color="textSecondary" gutterBottom>
                                    OF En Cours: <HighlightedText>{data.metalAtelier[0].totalOf + ' Gr'}</HighlightedText>
                                </Typography>
                                <Typography color="textSecondary" gutterBottom>
                                    Boite Or: <HighlightedText>{data.metalBoiteOr[0].totalBoiteOr + ' Gr'}</HighlightedText>
                                </Typography>
                                <div style={{ borderBottom: '1px solid #000', margin: '10px auto', width: '50%' }}></div>
                                <Typography color="error" style={{ textAlign: 'center' }} gutterBottom>
                                    TOTAL: <HighlightedText>{goldPrice ? dollarFormat(totalPrixRevientOr) : (totalPoidsOr ? `${totalPoidsOr} Gr` : 'N/A')}</HighlightedText>
                                </Typography>

                            </>
                        )}
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default ValorisationStock;
