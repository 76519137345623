import React from 'react';
import { Grid, Card, CardContent } from '@material-ui/core';
import MaterialTable from 'material-table';
import { connect } from 'react-redux';
import { clientOnlyChild } from '../../selectors/clients';
import { getListeClient } from '../../actions';
import { localization } from '../../config';

const TableSuiviMetalFacture = ({ data }) => {
    const totalPoids = data.reduce((sum, item) => sum + Number(item.poids), 0);
    const title = totalPoids !== 0 ? `Facture : ${totalPoids.toFixed(2)} Gr` : '';

    const columns = [
        { title: 'Client', field: 'client', align: 'center' },
        { title: 'N° Facture', field: 'id_fact' },
        { title: 'Poids', field: 'poids' },
        { title: 'Date', field: 'date', render: rowData => rowData['df'] },
    ]
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <MaterialTable
                                title={title}
                                options={{
                                    sorting: true,
                                    paging: true,
                                    padding: 'dense',
                                    pageSize: 10,
                                }}
                                columns={columns}
                                data={data}
                                localization={localization}
                            />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        listeClientInput: clientOnlyChild(state),
    }
}
const mapDispatchToProps = {
    getListeClient,
};
export default connect(mapStateToProps, mapDispatchToProps)(TableSuiviMetalFacture);
