import React, { Component } from 'react'
import { getOfFromId, getStade, getTypeTransport, getOfEnCours, getOfLivre, getStatut, getListeOf } from '../actions'
import { connect } from 'react-redux'
import FicheProduitOf from '../components/ficheProduitOf'
import { Checkbox, FormControlLabel, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import Axios from 'axios'
import { imagesRepo, meekroDB } from '../config'
import { addDayToToday, getTodaySqlFormat } from '../helpers/functions'
import imageCompression from 'browser-image-compression';

class TracerOf extends Component {
    updates = false
    state = {
        search: this.props.of || '',
        poids: '',
        stade: null,
        dim: '',
        ref: '',
        refClient: '',
        prixFacturation: '',
        prixRevient: '',
        dateEcheance: '',
        imperatif: false,
        facon: false,
        typeTransport: null,
        statut: null,
        description: '',
        information: '',
        Client: false,
        signe: false,
        grave: false,
        remise: '',
        remiseN: '',
        remiseSP: '',
        echeance: '',
        echeanceN: '',
        echeanceSP: '',
        leType: '',
        files: [],
    }

    componentDidMount() {
        this.props.getStade()
        this.props.getTypeTransport()
        this.props.getStatut()
        let search = this.state.search
        if (this.props.traceOf.length && this.props.traceOf[0].produit.length && search === '') {
            search = this.props.traceOf[0].produit[0].num_sachet
        }
        if (search !== '') {
            this.setState({ search }, () => this.props.getOfFromId(search).then(() => this.actionsSearch()))

        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.open === false) {
            if (this.updates && this.props.traceOf[0].produit[0].statut < 600 && window.confirm('Voulez vous enregister les modifications ?')) {
                this.handleSave(true)
            }
            this.updates = false
        }
    }
    componentWillUnmount() {
        if ((this.updates && this.props.traceOf[0].produit[0].statut < 600) && window.confirm('Voulez vous enregister les modifications ?')) {
            this.handleSave()
        }
    }
    actionsSearch = (exit = false) => {
        if (this.updates && window.confirm('Voulez vous enregister les modifications ?')) {
            this.handleSave()
        }
        //this.updates = false
        !exit && this.props.traceOf.length && this.props.traceOf[0].produit.length && this.populateForm(this.props.traceOf[0].produit[0])
    }
    searchOnEnter = (e) => {
        e.preventDefault()
        if (e.key === 'Enter' && this.state.search !== '') {
            this.props.getOfFromId(this.state.search).then(() => this.actionsSearch())
            //this.actionsSearch()
            //this.setState({ poids: this.props.traceOf.poids })
        }
    }
    populateForm = (of) => {
        const { poids, dim, notes_confie, description, prix_fact,
            prix_r, remiseN, remiseSP, echeance, echeanceN, echeanceSP, remise, ref_clt, ref1, suffix, information, pierre_clt, devis, leType } = of
        const stade = { id_stade: of.id_stade, description: of.descStade }
        const typeTransport = { id_type: of.typ_transp, description: of.descTypeTransp }
        const statut = Object.values(this.props.listeStatut).find(r => Number(r.Statut) === of.statut)
        const refDesc = ref1 !== '0' ? ref1 : description
        const signe = of.signe === 1 ? true : false
        const imperatif = of.date_l_imp === 1 ? true : false
        const facon = of.facon === 1 ? true : false
        const grave = of.grave === 1 ? true : false
        const prixFacturation = prix_fact
        const refClient = ref_clt
        const prixRevient = prix_r
        const informations = information !== '' ? information : notes_confie !== '' ? 'En retour : ' + notes_confie : ''
        const sp = pierre_clt === 0 ? false : true
        const ledevis = devis === 0 ? false : true
        //console.log(refDesc, ref1);
        this.setState({
            refClient,
            ref: refDesc,
            search: of.num_sachet,
            poids,
            stade,
            dim,
            typeTransport,
            statut,
            signe,
            grave,
            imperatif,
            facon,
            prixRevient,
            prixFacturation,
            remise,
            remiseN,
            remiseSP,
            echeance,
            echeanceN,
            echeanceSP,
            suffix,
            sp,
            devis: ledevis,
            leType,
            information: informations,
            dateEcheance: getTodaySqlFormat(),
        }, () => this.createDescription())
    }
    createDescription = () => {
        if (!this.state.stade) return
        const { dim, signe, grave, ref, remiseN, remiseSP, echeance, echeanceN, echeanceSP, remise, leType } = this.state
        let suffix = '', rem = '', eche = ''
        if (dim !== 0 && dim !== '') {
            if (leType === 'PENDENTIF' || leType === 'BRACELET') suffix = ' ' + dim + ' Cms'
            else suffix = ' au ' + dim
        }
        if (signe && grave) suffix += ' Signée & Gravée'
        else if (signe) suffix += ' Signée'
        else if (grave) suffix += ' Gravée'

        switch (this.state.stade.id_stade) {
            case 1:
            case 3:
                rem = remise !== 0 ? remise : remiseSP
                eche = echeance !== 0 ? echeance : echeanceN
                break
            case 2:
                rem = remise
                eche = echeance !== 0 ? echeance : echeanceN
                break
            case 4:
                rem = remise !== 0 ? remise : remiseN
                eche = echeance !== 0 ? echeance : echeanceN
                break
            default:
                rem = remise
                eche = echeance !== 0 ? echeance : echeanceSP
                break

        }
        const dateEcheance = addDayToToday(echeance)
        this.setState({ remise: rem, suffix, dateEcheance, echeance: eche, description: ref })
    }
    prenventEnter = e => {
        if (e.key === 'Enter') e.preventDefault()
    }
    changeEcheance = e => {
        const dateEcheance = addDayToToday(e.target.value !== '' ? e.target.value : 0)
        this.updates = true
        this.setState({ dateEcheance, echeance: e.target.value })
    }
    handleSave = (exit = false) => {
        const data = {
            table: 'of',
            primaryKey: 'num_sachet',
            id: this.state.search,
            poids: this.state.poids,
            id_stade: this.state.stade.id_stade,
            dim: this.state.dim,
            signe: this.state.signe ? 1 : 0,
            date_l_imp: this.state.imperatif ? 1 : 0,
            facon: this.state.facon ? 1 : 0,
            grave: this.state.grave ? 1 : 0,
            prix_fact: this.state.prixFacturation,
            prix_r: this.state.prixRevient,
            remise: this.state.remise,
            echeance: this.state.echeance,
            date_paiement: this.state.dateEcheance,
            typ_transp: this.state.typeTransport.id_type,
            statut: this.state.statut.Statut,
            ref_clt: this.state.refClient,
            suffix: this.state.suffix,
            ref1: this.state.description,
            information: this.state.information,
            //statut: '575',
        }
        Axios.put(`${meekroDB}/general.php`, data)
            .then((response) => {
                this.updates = false
                this.props.getOfFromId(this.state.search).then(() => this.actionsSearch(exit))
                this.props.getOfEnCours()
                this.props.getOfLivre()
                this.props.getListeOf()
            })
            .catch(error => {
                console.log(error);
            });
        //console.log(data);
    }
    createErrorSearch = () => {
        if (this.state.search === '') return ''
        else if (this.props.traceOf.length === 0 || this.props.traceOf[0].produit.length === 0) return "OF non trouvé"
        else if (this.props.traceOf[0].produit[0].statut === 600) {
            return "L'Of n'est plus éditable "
        }

    }
    handleChange = (e) => {
        const { name, value } = e.target
        if (name !== 'search') {
            this.updates = true
        }
        this.setState({ [name]: value })

        // else if (this.updates && this.props.traceOf[0].produit[0].statut < 600) {
        //     this.updates = false
        //     window.confirm('Voulez vous enregister les modifications ?') && this.handleSave(true)

        // }

    }
    handleChangeSearch = e => {
        const { name, value } = e.target
        if (this.updates && this.props.traceOf[0].produit[0].statut < 600) {
            this.updates = false
            if (window.confirm('Voulez vous enregister les modifications ?')) {
                const data = {
                    table: 'of',
                    primaryKey: 'num_sachet',
                    id: this.state.search,
                    poids: this.state.poids,
                    id_stade: this.state.stade.id_stade,
                    dim: this.state.dim,
                    signe: this.state.signe ? 1 : 0,
                    date_l_imp: this.state.imperatif ? 1 : 0,
                    facon: this.state.facon ? 1 : 0,
                    grave: this.state.grave ? 1 : 0,
                    prix_fact: this.state.prixFacturation,
                    prix_r: this.state.prixRevient,
                    remise: this.state.remise,
                    echeance: this.state.echeance,
                    date_paiement: this.state.dateEcheance,
                    typ_transp: this.state.typeTransport.id_type,
                    statut: this.state.statut.Statut,
                    ref_clt: this.state.refClient,
                    suffix: this.state.suffix,
                    ref1: this.state.description,
                    information: this.state.information,
                    //statut: '575',
                }
                Axios.put(`${meekroDB}/general.php`, data)
                    .then((response) => {
                        this.setState({ [name]: value })
                        this.props.getOfFromId(this.state.search).then(() => this.actionsSearch(true))
                        this.props.getOfEnCours()
                        this.props.getOfLivre()
                        this.props.getListeOf()
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
            else {
                this.setState({ [name]: value })
                this.props.getOfFromId(value).then(() => this.actionsSearch(true))
                this.props.getOfEnCours()
                this.props.getOfLivre()
                this.props.getListeOf()
            }


        }
        else this.setState({ [name]: value })

    }
    resizeImage = async (imageFile, size) => {
        // console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
        // console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: size,
            useWebWorker: true
        }
        try {
            const resized = await imageCompression(imageFile, options);
            return resized

        } catch (error) {
            console.log(error);
        }
    }
    handleImageUpload = async (event) => {
        if (!event.target.files) return;

        const imageFile = event.target.files[0]; // Utiliser le premier fichier uniquement

        try {
            const resized = await this.resizeImage(imageFile, 1000);
            // const resizedThumb = await this.resizeImage(imageFile, 250);

            const tab = {
                initialSize: imageFile.size,
                image: resized,
                // thumb: resizedThumb,
            };
            this.setState({ files: tab });
        } catch (error) {
            console.log(error);
        }
    }

    renderImage = (file) => {
        return (
            <div className="col-auto " key={file.image.name}>
                <div >
                    <div className='d-flex justify-content-center'>
                        <img style={{ maxWidth: 300, maxHeight: 300, minWidth: 300, minHeight: 300 }} src={URL.createObjectURL(file.image)} alt='' />
                    </div>
                    {/* <div className='d-flex justify-content-center'>
                        <button onClick={() => this.removeFile(file)} className="btn btn-link btn-sm">Supprimer</button>
                    </div> */}
                </div>

            </div>
        )
    }
    renameBeforeUpload = () => {
        let tab = this.state.files;
        let originalExtension = tab.image.name.split('.').pop();
        tab.image.name = this.state.search + '.' + originalExtension;
        this.setState({ files: tab }, () => { this.onFileUpload() });
    }

    onFileUpload = async () => {
        let f = this.state.files;

        const formData = new FormData();

        formData.append(
            "image",
            f.image,
            f.image.name
        );

        await Axios.post(`${imagesRepo}upload.php`, formData);
        this.setState({ files: [] });

        const data = {
            table: 'of',
            primaryKey: 'num_sachet',
            id: this.state.search,
            photo_of: f.image.name
        }
        Axios.put(`${meekroDB}/general.php`, data)
            .then((response) => {
                this.props.getOfFromId(this.state.search)
            })
            .catch(error => {
                console.log(error);
            });
    }

    removeFile = () => {
        const data = {
            table: 'of',
            primaryKey: 'num_sachet',
            id: this.state.search,
            photo_of: 'no_pic.jpg',
        }
        Axios.put(`${meekroDB}/general.php`, data)
            .then((response) => {
                this.setState({ files: [] });
                this.props.getOfFromId(this.state.search)

            })
            .catch(error => {
                console.log(error);
            });
    }

    render() {
        return (
            <div className='container-fluid'>

                <div className="form">
                    <div className="row">
                        <div className="col-7">
                            {this.props.traceOf.length !== 0 && <FicheProduitOf produit={this.props.traceOf[0]} />}
                        </div>
                        <div className="col-sm">
                            <div className="row">
                                <div className="col-sm">
                                    <div className="form-group">
                                        <TextField autoFocus fullWidth type='number' value={this.state.search} variant="outlined" label="OF" name='search' onChange={this.handleChangeSearch} onKeyUp={this.searchOnEnter} />
                                    </div>
                                </div>
                                {this.props.traceOf && this.props.traceOf.length !== 0 && this.props.traceOf[0].produit.length !== 0 &&
                                    <div className="col-sm">
                                        <div className="form-group">
                                            <Autocomplete
                                                value={this.state.statut}
                                                options={Object.values(this.props.listeStatut)}
                                                getOptionLabel={(option) => option.OF_interne || ''}
                                                onChange={(event, values) => {
                                                    this.updates = true
                                                    this.setState({ statut: values })
                                                }}
                                                disabled={this.state.statut && this.state.statut.Statut === 600}
                                                getOptionSelected={(option) => option.Statut === this.state.statut.Statut}
                                                autoHighlight
                                                autoComplete
                                                clearOnEscape
                                                renderInput={(params) => <TextField onKeyPress={this.prenventEnter} {...params} required label="Statut" variant="outlined" />}
                                            />
                                        </div>
                                    </div>}
                            </div>


                            {this.props.traceOf && this.props.traceOf.length !== 0 && this.props.traceOf[0].produit.length !== 0 &&
                                <>
                                    <div className="row" >
                                        <div className="col-sm">
                                            <div className="form-group">
                                                <TextField required fullWidth value={this.state.poids}
                                                    disabled={this.state.statut && this.state.statut.Statut === 600}
                                                    variant="outlined" label="Poids" name="poids" onChange={this.handleChange} />
                                            </div>
                                        </div>

                                        <div className="col-sm">
                                            <div className="form-group">
                                                <Autocomplete
                                                    fullWidth
                                                    value={this.state.stade}
                                                    options={this.props.listeStade}
                                                    getOptionLabel={(option) => option.description}
                                                    onChange={(event, values) => this.setState({ stade: values }, () => {
                                                        this.createDescription()
                                                        this.updates = true
                                                    })}
                                                    getOptionSelected={(option) => option.id_stade === this.state.stade.id_stade}
                                                    disabled={this.state.statut && this.state.statut.Statut === 600}

                                                    autoHighlight
                                                    autoComplete
                                                    clearOnEscape

                                                    renderInput={(params) => <TextField onKeyPress={this.prenventEnter} {...params} required label="Stade" variant="outlined" />}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm">
                                            <div className="form-group">
                                                <TextField fullWidth value={this.state.dim} variant="outlined"
                                                    disabled={this.state.statut && this.state.statut.Statut === 600}
                                                    label="Doigt / Longueur" onChange={(e) => this.setState({ dim: e.target.value }, () => {
                                                        this.createDescription()
                                                        this.updates = true
                                                    })} />
                                            </div>
                                        </div>
                                        <div className="col-sm">
                                            <div className="form-group">
                                                <Autocomplete
                                                    value={this.state.typeTransport}
                                                    options={this.props.listeTypeTransport}
                                                    getOptionLabel={(option) => option.description}
                                                    onChange={(event, values) => {
                                                        this.updates = true
                                                        this.setState({ typeTransport: values })
                                                    }}
                                                    disabled={this.state.statut && this.state.statut.Statut === 600}
                                                    getOptionSelected={(option) => option.id_type === this.state.typeTransport.id_type}
                                                    fullWidth
                                                    autoHighlight
                                                    autoComplete
                                                    clearOnEscape
                                                    renderInput={(params) => <TextField onKeyPress={this.prenventEnter} {...params} required label="Type Transport" variant="outlined" />}
                                                />

                                            </div>

                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm">
                                            <div className="form-group">
                                                <TextField fullWidth value={this.state.prixFacturation}
                                                    disabled={this.state.statut && this.state.statut.Statut === 600}
                                                    variant="outlined" label="Prix Facturation" name="prixFacturation" onChange={this.handleChange} />
                                            </div>
                                        </div>
                                        <div className="col-sm">
                                            <div className="form-group">
                                                <TextField fullWidth value={this.state.prixRevient}
                                                    disabled={this.state.statut && this.state.statut.Statut === 600}
                                                    variant="outlined" label="Prix Revient" name="prixRevient" onChange={this.handleChange} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm">
                                            <div className="form-group">
                                                <TextField fullWidth value={this.state.refClient}
                                                    disabled={this.state.statut && this.state.statut.Statut === 600}
                                                    variant="outlined" label="Réf Client" name="refClient" onChange={this.handleChange} />
                                            </div>
                                        </div>
                                        <div className="col-sm">
                                            <div className="form-group">
                                                <TextField fullWidth value={this.state.remise}
                                                    disabled={this.state.statut && this.state.statut.Statut === 600} variant="outlined" label="Remise %" name="remise" onChange={this.handleChange} />
                                            </div>
                                        </div>
                                        <div className="col-sm">
                                            <div className="form-group">

                                                <TextField fullWidth value={this.state.echeance}
                                                    disabled={this.state.statut && this.state.statut.Statut === 600} variant="outlined" label="Echéance" onChange={this.changeEcheance} />
                                            </div>
                                        </div>
                                        <div className="col-sm">
                                            <div className="form-group">
                                                <TextField
                                                    variant="outlined"
                                                    value={this.state.dateEcheance}
                                                    label="Date Paiement"
                                                    type="date"
                                                    disabled={true}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </div>
                                        </div>

                                    </div>

                                    <div className="row">
                                        <div className="col-sm">
                                            <div className="form-group">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            disabled={this.state.facon && this.state.statut.Statut === 600}
                                                            checked={this.state.facon}
                                                            onChange={(e) => { this.updates = true; this.setState({ facon: e.target.checked }) }}
                                                            color="primary"
                                                        />
                                                    }
                                                    label="Façon"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm">
                                            <div className="form-group">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            disabled={this.state.statut && this.state.statut.Statut === 600}
                                                            checked={this.state.imperatif}
                                                            onChange={(e) => {
                                                                this.updates = true; this.setState({ imperatif: e.target.checked })
                                                            }}
                                                            color="primary"
                                                        />
                                                    }
                                                    label="Impératif"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm">
                                            <div className="form-group">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            disabled={this.state.statut && this.state.statut.Statut === 600}
                                                            checked={this.state.signe}
                                                            onChange={(e) => this.setState({ signe: e.target.checked }, () => {
                                                                this.createDescription()
                                                                this.updates = true
                                                            })}
                                                            color="primary"
                                                        />
                                                    }
                                                    label="Signée"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm">
                                            <div className="form-group">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            disabled={this.state.statut && this.state.statut.Statut === 600}
                                                            checked={this.state.grave}
                                                            onChange={(e) => this.setState({ grave: e.target.checked }, () => {
                                                                this.createDescription()
                                                                this.updates = true
                                                            })}
                                                            color="primary"
                                                        />
                                                    }
                                                    label="Gravée"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <TextField fullWidth value={this.state.ref}
                                            disabled={this.state.statut && this.state.statut.Statut === 600}
                                            variant="outlined" label="Description" onChange={(e) => this.setState({ ref: e.target.value }, () => {
                                                this.createDescription()
                                                this.updates = true
                                            })} />
                                    </div>
                                    <div className="form-group">
                                        <TextField disabled={true} fullWidth value={this.state.description + ' ' + this.state.suffix}

                                            variant="outlined" label="Description Auto" />
                                    </div>
                                    <div className="form-group">
                                        <TextField fullWidth value={this.state.information}
                                            disabled={this.state.statut && this.state.statut.Statut === 600}
                                            variant="outlined" label="Information" name="information" onChange={this.handleChange} multiline={true} rows={3} />
                                    </div>
                                </>
                            }
                            {this.props.traceOf && this.props.traceOf.length !== 0 && this.props.traceOf[0].produit.length !== 0 && (
                                <div >
                                    <div className='form-group'>
                                        <input style={{ display: 'none' }} ref={fileInput => this.fileInput = fileInput} type="file" accept="image/*" onLoadedData={() => console.log('ok')} onChange={this.handleImageUpload} />
                                        <button onClick={() => this.fileInput.click()} style={{ color: '#007bff' }} className='btn btn-link btn-sm'>Importer Image</button>
                                        <button onClick={() => this.removeFile()} style={{ color: '#007bff' }} className='btn btn-link btn-sm'>Supprimer Image</button>
                                        {this.state.files.length !== 0 && (
                                            <button onClick={this.renameBeforeUpload} style={{ color: '#007bff' }} className='btn btn-link btn-sm'>
                                                Uploader!
                                            </button>
                                        )}
                                    </div>
                                    {this.state.files.length !== 0 && (
                                        <div className="row">
                                            {this.renderImage(this.state.files)}
                                        </div>
                                    )}

                                </div>
                            )}
                            {/* <div>{this.createErrorSearch()}</div> */}
                            {this.props.traceOf.length !== 0 && this.props.traceOf[0].produit.length !== 0 && this.props.traceOf[0].produit[0].statut !== 600 &&
                                <button type="button" disabled={!this.updates || (this.state.statut && this.state.statut.Statut === 600)} className="btn btn-link btn-sm" onClick={this.handleSave}>Enregistrer</button>
                            }
                        </div>


                    </div>
                </div>
            </div >
        )
    }
}

const mapStateToProps = (state) => {
    return {
        selectedProduct: state.produit.selectedProduct,
        listeOfs: state.ofs.listeOf,
        traceOf: state.ofs.traceOf,
        listeTypeTransport: state.generalData.listeTypeTransport,
        listeStade: state.generalData.listeStade,
        listeStatut: state.generalData.listeStatut,
    }
}
const mapDispatchToProps = {
    getOfFromId,
    getStade,
    getTypeTransport,
    getOfEnCours,
    getOfLivre,
    getStatut,
    getListeOf,
}
export default connect(mapStateToProps, mapDispatchToProps)(TracerOf)