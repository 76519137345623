import React from 'react';
import BoiteOrForm from '../form/BoiteOrForm';
import { ajouterBoiteOr } from '../action/AtelierAction';
import BoiteOr from '../analyse/BoiteOr';
import { Typography } from '@material-ui/core';

const EnvoiBoiteOr = () => {

    const handleAjouter = (formData) => {
        const { joaillier, poidsD, poidsR } = formData;
        const newData = {
            joaillier: joaillier.label,
            id_joaillier: joaillier.id,
            poidsD,
            poidsR,
        };
        save(newData)
    };

    const save = (data) => {
        ajouterBoiteOr(data)
            .catch(error => {
                console.error("Erreur lors de l'ajout :", error);
            });
    };
    return (
        <div>
            <h1 style={{ textAlign: 'center' }}>Boite OR</h1>
            <div className="row" style={{ display: 'flex', justifyContent: 'center' }}>
                <div className="col-md-6">
                    <BoiteOr />
                </div>
                <div className="col-md-6 mx-auto">
                    <Typography variant="h6" align='center'>Ajouter Boite Or</Typography>
                    <div className="col-md-3 mx-auto">
                        <BoiteOrForm onSubmitForm={handleAjouter} />
                    </div>
                        
                </div>

            </div>
        </div>
    );
};


export default EnvoiBoiteOr;
