import Axios from 'axios';
import { apiATC, meekroDB } from '../config';
import { addDayToToday, getTodaySqlFormat, getValueFromId } from '../helpers/functions'

export const sortirStockFacture = async (data) => {
    try {
        // Vérifier si les données contiennent 'client' et 'of_reservation' et 'of_reservation' n'est pas égal à 0
        if (data.client && data.of_reservation && data.of_reservation !== 0 && data.of_reservation !== '0') {
            return await Axios.post(`${apiATC}/produit/operation_stock.php?action=facturer_reservation`, data);
        } else {
            return null; // Retourner null pour continuer normalement
        }
    } catch (error) {
        console.error('Erreur lors de la sortie de stock pour la facture:', error);
        throw error;
    }
};

export const ajouterFacture = async (data) => {
    try {
        const response = await Axios.post(`${meekroDB}/general.php`, data);
        return response.data;
    } catch (error) {
        console.error('Erreur lors de l\'ajout de la facture:', error);
        throw error;
    }
};

export const ajouterConstituantFact = async (data) => {
    try {
        await Axios.post(`${meekroDB}/general.php`, data);
    } catch (error) {
        console.error('Erreur lors de l\'ajout du constituant de la facture:', error);
        throw error;
    }
};

export const modifierOf = async (data) => {
    try {
        await Axios.put(`${meekroDB}/general.php`, data);
    } catch (error) {
        console.error('Erreur lors de la modification de l\'OF:', error);
        throw error;
    }
};


export const facturerExtensia = async (facture, port, cours) => {
    const factExtensiaData = {
        table: 'facture_extensia',
        echeance: facture[0].echeance,
        client: facture[0].Nom_agence,
        id_bijouterie: facture[0].id_bijouterie,
        date_fact: getTodaySqlFormat(),
        port: port,
        taux_tva: facture[0].taux_tva,
        cp: facture[0].cp,
        ville: facture[0].ville,
        adresse: facture[0].adresse,
        date_paiement: addDayToToday(facture[0].echeance),
    };

    const factureData = {
        table: 'facture',
        echeance: facture[0].echeance,
        id_bijouterie: '502',
        date_fact: getTodaySqlFormat(),
        cours_fixe: getValueFromId('10', cours),
        cours_courant: getValueFromId('4', cours),
        port: 0,
        taux_tva: facture[0].taux_tva,
        date_paiement: addDayToToday(facture[0].echeance),
    };

    try {
        const [factExtensiaId, factureId] = await Promise.all([
            ajouterFacture(factExtensiaData),
            ajouterFacture(factureData)
        ]);

        // Ajout des constituants de facture normale
        const constituantDataFact = facture.map(of => {
            const prixUHt = Math.ceil(parseInt(of.prix_fact) - (parseInt(of.prix_fact) - parseInt(of.prix_r)) * 0.9);
            const prixht = Math.ceil(prixUHt * (1 - parseInt(of.remise) / 100)) ;

            return {
                table: 'constituant_fact',
                of: of.num_sachet,
                reaco: of.reaco,
                totht: prixht,
                rem: of.remise,
                prix_u_ht: prixUHt,
                fact: factureId,
                designation: of.id_stade === '12' ? of.information : of.ref1 + ' ' + of.suffix,
                poids_u: of.poids,
                commentaire: of.id_stade === '12' ? of.ref1 : of.information,
                id_bijouterie: of.id_bijouterie,
                of_reservation: of.of_reservation
            };
        });

        await Promise.all(constituantDataFact.map(async (constituant) => {
            const { id_bijouterie, of_reservation, ...rest } = constituant;
            await ajouterConstituantFact(rest);
            await modifierOfStatut(rest.of);
            await sortirStockFacture({ client: id_bijouterie, of_reservation });
        }));

        // Ajout des constituants de facture Extensia
        const constituantDataExtensia = facture.map(of => ({
            table: 'constituant_fact_extensia',
            of: of.num_sachet,
            prix: Math.ceil((parseInt(of.prix_fact) + parseInt(of.reaco)) * (1 - parseInt(of.remise) / 100)),
            fact_id: factExtensiaId,
            description: of.id_stade === '12' ? of.information : of.ref1 + ' ' + of.suffix,
            poids: of.poids,
            prenom: of.prenom_ligne,
        }));

        await Promise.all(constituantDataExtensia.map(ajouterConstituantFact));

        return { factExtensiaId, factureId };
    } catch (error) {
        console.error('Erreur lors de la facturation Extensia:', error);
        throw error;
    }
};



export const facturerNormale = async (facture, port, cours) => {
    const factureData = {
        table: 'facture',
        echeance: facture[0].echeance,
        id_bijouterie: facture[0].id_bijouterie,
        date_fact: getTodaySqlFormat(),
        cours_fixe: getValueFromId('10', cours),
        cours_courant: getValueFromId('4', cours),
        port: port,
        taux_tva: facture[0].taux_tva,
        date_paiement: addDayToToday(facture[0].echeance),
    };
    try {
        const factureId = await ajouterFacture(factureData);

        const constituantData = facture.map(of => ({
            table: 'constituant_fact',
            of: of.num_sachet,
            reaco: of.reaco,
            totht: Math.ceil((parseInt(of.prix_fact) + parseInt(of.reaco)) * (1 - parseInt(of.remise) / 100)),
            rem: of.remise,
            prix_u_ht: of.prix_fact,
            fact: factureId,
            designation: of.id_stade === '12' ? of.information : of.ref1 + ' ' + of.suffix,
            poids_u: of.poids,
            commentaire: of.id_stade === '12' ? of.ref1 : of.information,
            id_bijouterie: of.id_bijouterie,
            of_reservation: of.of_reservation
        }));

        await Promise.all(constituantData.map(async (constituant) => {
            const { id_bijouterie, of_reservation, ...rest } = constituant;
            await ajouterConstituantFact(rest);
            await modifierOfStatut(rest.of);
            await sortirStockFacture({ client: id_bijouterie, of_reservation });
        }));

        return { factureId };

    } catch (error) {
        console.error('Erreur lors de la facturation normale:', error);
        throw error;
    }
};

export const modifierOfExtensia = async (ofTosave) => {
    try {
        const ofUpdate = {
            table: 'of',
            primaryKey: 'num_sachet',
            id: ofTosave.of,
            id_bijouterie: '502',
            prix_fact: ofTosave.prix_u_ht,
            statut: '600',
        };
        await modifierOf(ofUpdate);
    } catch (error) {
        console.error('Erreur lors de la modification du prix de l\'OF:', error);
        throw error;
    }
};

export const modifierOfStatut = async (ofId) => {
    try {
        const ofUpdate = {
            table: 'of',
            primaryKey: 'num_sachet',
            id: ofId,
            statut: '600',
        };
        await modifierOf(ofUpdate);
    } catch (error) {
        console.error('Erreur lors de la modification du statut de l\'OF:', error);
        throw error;
    }
};