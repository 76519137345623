import React, { Component } from 'react'
import { getStade, getMetaux, getTypeProduit, getStatut, getOfEnCours, getOfLivre, getOfLivreExtensia, getOfFromId, setOfToEdit, getListeOf, getTypeCommande } from '../actions'
import { connect } from 'react-redux'
import FilterSelect from '../components/filterSelect'
import FilterInput from '../components/filterInput'
import TreeClient from '../components/treeClient'
import { Accordion, AccordionDetails, AccordionSummary, Button, Dialog, DialogContent, DialogTitle, TextField } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { imagesRepo, localization } from '../config'
import MaterialTable, { MTableToolbar } from 'material-table'
import { Autocomplete } from '@material-ui/lab'
import TracerOf from './tracerOf'
import { addDayToToday, euroFormat, exportCsv } from '../helpers/functions'
import OfEditComponent from '../components/of/OfEditComponent'
import Image from 'react-bootstrap/Image'



class FilterTester extends Component {
    today = new Date()
    temp = this.today.getFullYear() + '-' + ("0" + (this.today.getMonth() + 1)).slice(-2) + '-' + ("0" + this.today.getDate()).slice(-2)
    selectedEnCours = ''
    selectedLivre = ''
    selectedLivreExtensia = ''
    dataDevis = [{ id: 2, description: 'TOUT' }, { id: 1, description: 'DEVIS' }, { id: 0, description: 'NORMAL' }]
    dataImperatif = [{ id: 2, description: 'TOUT' }, { id: 1, description: 'IMPERATIF' }, { id: 0, description: 'NORMAL' }]
    dataTypeCommande = [{ id: 2, description: 'TOUT' }, { id: 1, description: 'TOURNEE' }, { id: 0, description: 'NORMAL' }]
    dataFacon = [{ id: 2, description: 'TOUT' }, { id: 1, description: 'A FACON' }, { id: 0, description: 'NORMAL' }]
    dataResa = [{ id: 2, description: 'NON RESERVE' }, { id: 1, description: 'RESERVATION' }, { id: 0, description: 'TOUT' }]


    currentYear = new Date().getFullYear();

    state = {
        ofToTrace: '',
        stade: '',
        metal: '',
        type: '',
        statut: '',
        ref: '',
        of: '',
        facture: '',
        client: '',
        ref_clt: '',
        tournee: null,
        debut: `${this.currentYear}-01-01`,
        fin: addDayToToday(30),
        open: false,
        devis: null,
        facon: null,
        openEditOf: false,
        imperatif: null,
        totalheure: 0,
        totalCA: 0,
        poidsTotal: 0,
        dataClone: [],
        dataLivreClone: [],
        nbARticlesLivre: 0,
        totalCALivre: 0,
        poidsTotalLivre: 0,
        prixRevientLivre: 0,
        dataLivreCloneExtensia: [],
        totalCALivreExtensia: 0,
        typecommande: null,
        typeResa: null,

    }

    componentDidMount() {
        this.props.getStade()
        this.props.getMetaux()
        this.props.getTypeProduit()
        this.props.getStatut()
        this.props.getListeOf()
        this.props.getTypeCommande()

        // this.refreshLists()
        // this.listeEnCours()
        // this.listeLivre()

    }
    getFilter = () => {
        const data = {
            stade: this.state.stade,
            metal: this.state.metal,
            type: this.state.type,
            statut: this.state.statut,
            ref: this.state.ref,
            of: this.state.of,
            facture: this.state.facture,
            client: this.state.client,
            ref_clt: this.state.ref_clt,
            tournee: this.state.tournee ? this.state.tournee.id : 2,
            debut: this.state.debut,
            fin: this.state.fin,
            devis: this.state.devis ? this.state.devis.id : 2,
            facon: this.state.facon ? this.state.facon.id : 2,
            imperatif: this.state.imperatif ? this.state.imperatif.id : 2,
            type_commande: this.state.typecommande ? this.state.typecommande : 0,
            type_resa: this.state.typeResa ? this.state.typeResa.id : 0,

        }
        //console.log(data);
        return data
    }
    onChange = (data, labelState) => {
        const label = labelState.toLowerCase()
        this.setState({ [label]: data })  //, () => this.refreshLists()
    }
    handleChangeClient = (data) => {
        this.setState({ client: data })  //, () => this.refreshLists()
    }

    refreshLists = () => {
        this.props.getListeOf()
        this.props.getOfEnCours(this.getFilter()).then(() => this.listeEnCours())
        this.props.getOfLivre(this.getFilter()).then(() => this.listeLivre())
        this.props.getOfLivreExtensia(this.getFilter()).then(() => this.listeLivreExtensia())

    }

    onRowClick = (e, rowData) => {
        e.preventDefault()
        this.selectedEnCours = rowData.num_sachet
    }
    onRowClickLivre = (event, rowData) => {
        this.selectedLivre = rowData.num_sachet
    }
    onRowClickLivreExtensia = (event, rowData) => {
        this.selectedLivreExtensia = rowData.num_sachet
    }

    // getListeEnCours = () => {

    //     this.props.getOfEnCours(this.getFilter())
    // }
    // getListeLivre = () => {
    //     this.props.getOfLivre(this.state)
    // }
    listeEnCours = () => {

        let totalCA = 0, poidsTotal = 0
        console.log(this.props.listeOfEnCours);
        this.props.listeOfEnCours.length && Object.values(this.props.listeOfEnCours).forEach(r => {
            totalCA += parseFloat(r.prix_fact)
            poidsTotal += parseFloat(r.poids)
        })
        // console.log(this.getFilter());

        const dataClone = [...this.props.listeOfEnCours]
        this.setState({ dataClone, totalCA, poidsTotal })
    }
    listeLivre = () => {
        let totalCALivre = 0, poidsTotalLivre = 0, prixRevientLivre = 0
        this.props.listeOfLivre.length && Object.values(this.props.listeOfLivre).forEach(r => {
            totalCALivre += parseFloat(r.totht)
            poidsTotalLivre += parseFloat(r.poids_u)
            prixRevientLivre += parseFloat(r.prix_r)
        })
        const dataLivreClone = [...this.props.listeOfLivre]
        this.setState({ dataLivreClone, totalCALivre, poidsTotalLivre, prixRevientLivre })

    }
    listeLivreExtensia = () => {
        let totalCALivreExtensia = 0
        this.props.listeOfLivreExtensia.length && Object.values(this.props.listeOfLivreExtensia).forEach(r => {
            totalCALivreExtensia += parseFloat(r.totht)
        })
        const dataLivreCloneExtensia = [...this.props.listeOfLivreExtensia]
        this.setState({ dataLivreCloneExtensia, totalCALivreExtensia })

    }


    tracerOf = (of) => {
        //this.props.getOfFromId(of)
        this.setState({ open: true, ofToTrace: of })
        //this.props.history.push("/of/tracer")
    }
    editerOf = (of) => {
        const data = Object.values(this.props.listeOfs).find(f => f.num_sachet === of)
        // console.log(data);
        this.props.setOfToEdit(data)
        this.setState({ openEditOf: true })
        //this.props.history.push("/of/tracer")
    }
    createLabelEnCours = () => {
        let label = "OFS EN COURS";
        if (this.props.listeOfEnCours.length > 0) {
            return (
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
                    <span style={{ fontSize: '1.2em', color: '#115293' }}>{this.props.listeOfEnCours.length}</span> OFS EN COURS : <span style={{ fontSize: '1.2em', color: '#115293' }}>{euroFormat(this.state.totalCA)}</span>
                    <span>-</span>
                    <span>Poids Théorique <span style={{ fontSize: '1.2em', color: '#115293' }}>{this.state.poidsTotal.toFixed(2)}</span> Gr</span>
                </div>
            );
        } else {
            return <div>{label}</div>;
        }
    }
    createLabelLivre = () => {
        let labelLivre = "OFS LIVRES";
        if (this.props.listeOfLivre.length > 0) {
            return (
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
                    <span style={{ fontSize: '1.2em', color: '#115293' }}>{this.props.listeOfLivre.length}</span> OFS LIVRES : <span style={{ fontSize: '1.2em', color: '#115293' }}>{euroFormat(this.state.totalCALivre)}</span>
                    <span>-</span>
                    <span>Poids Total <span style={{ fontSize: '1.2em', color: '#115293' }}>{this.state.poidsTotalLivre.toFixed(2)}</span> Gr</span>
                    <span>-</span>
                    <span>Total Revient <span style={{ fontSize: '1.2em', color: '#115293' }}>{euroFormat(this.state.prixRevientLivre)}</span></span>
                </div>
            );
        } else {
            return <div>{labelLivre}</div>;
        }
    }

    createLabelExtensia = () => {
        let label = "OFS EXTENSIA";
        if (this.props.listeOfLivreExtensia.length > 0) {
            return (
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
                    <span style={{ fontSize: '1.2em', color: '#115293' }}>{this.props.listeOfLivreExtensia.length}</span> OFS EXTENSIA : <span style={{ fontSize: '1.2em', color: '#115293' }}>{euroFormat(this.state.totalCALivreExtensia)}</span>
                </div>
            );
        } else {
            return <div>{label}</div>;
        }
    }
    render() {
        return (
            <div>
                <div className="col-auto" style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button variant="contained" color="primary" onClick={() => this.refreshLists()}>Afficher</Button>
                </div>
                <div className='row ' >
                    <div className="col-auto" >
                        <TreeClient handleChange={this.handleChangeClient} />
                    </div>

                    <div className="col-sm" style={{ padding: '4px' }}>
                        <TextField margin='dense' fullWidth type='date' value={this.state.debut} onChange={e => e.target.value && this.onChange(e.target.value, 'debut')} label='Debut' variant="outlined" size='small' />
                        <TextField margin='dense' fullWidth type='date' value={this.state.fin} onChange={e => e.target.value && this.onChange(e.target.value, 'fin')} label='Fin' variant="outlined" size='small' />
                        <Autocomplete
                            options={this.dataImperatif}
                            getOptionLabel={(option) => option.description}
                            onChange={(e, values) => this.setState({ imperatif: values })} //, () => this.refreshLists()
                            autoHighlight
                            autoComplete
                            clearOnEscape
                            renderInput={(params) => <TextField margin='dense'  {...params} label="Impératif" variant="outlined" size='small' />}
                        />
                        <Autocomplete
                            options={this.dataDevis}
                            getOptionLabel={(option) => option.description}
                            onChange={(e, values) => this.setState({ devis: values })} //, () => this.refreshLists()
                            autoHighlight
                            autoComplete
                            clearOnEscape
                            renderInput={(params) => <TextField {...params} label="Devis" variant="outlined" margin='dense' size='small' />}
                        />
                    </div>
                    <div className="col-sm" style={{ padding: '4px' }}>
                        <Autocomplete
                            options={this.dataTypeCommande}
                            getOptionLabel={(option) => option.description}
                            onChange={(e, values) => this.setState({ tournee: values })} //, () => this.refreshLists()
                            autoHighlight
                            autoComplete
                            clearOnEscape
                            renderInput={(params) => <TextField margin='dense' {...params} label="Tournée" variant="outlined" size='small' />}
                        />
                        <Autocomplete
                            options={this.dataFacon}
                            getOptionLabel={(option) => option.description}
                            onChange={(e, values) => this.setState({ facon: values })} //, () => this.refreshLists()
                            autoHighlight
                            autoComplete
                            clearOnEscape
                            renderInput={(params) => <TextField margin='dense'  {...params} label="Façon" variant="outlined" size='small' />}
                        />
                        <Autocomplete
                            options={this.dataResa}
                            getOptionLabel={(option) => option.description}
                            onChange={(e, values) => this.setState({ typeResa: values })} //, () => this.refreshLists()
                            autoHighlight
                            autoComplete
                            clearOnEscape
                            renderInput={(params) => <TextField margin='dense' {...params} label="Reservation" variant="outlined" size='small' />}
                        />
                    </div>

                    <div className="col-sm" style={{ padding: '4px 2px 0px 2px' }}>
                        <FilterSelect label='Type Commande' labelId='id' labelDescription="label" onChange={(e) => { this.onChange(e, 'typeCommande') }} options={this.props.listeTypeCommande} />
                    </div>
                    <div className="col-sm" style={{ padding: '4px 2px 0px 2px' }}>
                        <FilterSelect label='Stade' labelId='id_stade' onChange={this.onChange} options={this.props.listeStade} />
                    </div>
                    <div className="col-sm" style={{ padding: '4px 2px 0px 2px' }}>
                        <FilterSelect label='Metal' labelId='id_type' onChange={this.onChange} options={this.props.listeMetaux} />
                    </div>
                    <div className="col-sm" style={{ padding: '4px 2px 0px 2px' }}>
                        <FilterSelect label='Type' labelId='id_typ_prod' onChange={this.onChange} options={this.props.listeTypeProduit} />
                    </div>
                    <div className="col-sm" style={{ padding: '4px 2px 0px 2px' }}>
                        <FilterSelect label='Statut' labelId='Statut' onChange={this.onChange} options={this.props.listeStatut} labelDescription='OF_interne' />
                    </div>
                    <div className="col-auto" style={{ padding: '4px 2px 0px 2px' }}>
                        <FilterInput label='Ref_Clt' type='text' onChange={this.onChange} />
                    </div>
                    <div className="col-auto" style={{ padding: '4px 2px 0px 2px' }}>
                        <FilterInput label='Ref' type='text' onChange={this.onChange} />
                    </div>
                    <div className="col-auto" style={{ padding: '4px 2px 0px 2px' }}>
                        <FilterInput label='Of' onChange={this.onChange} />
                    </div>
                    <div className="col-auto" style={{ padding: '4px 2px 0px 2px' }}>
                        <FilterInput label='Facture' onChange={this.onChange} />
                    </div>




                </div>
                <Accordion >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                        {this.createLabelEnCours()}
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="container-fluid">
                            <MaterialTable
                                style={{ fontSize: 14 }}
                                columns={[
                                    { align: 'center', title: 'Image', export: false, field: 'image', render: rowData => <Image key={rowData.num_sachet} src={`${imagesRepo}thumbs.php?src=${rowData.image}&size=100`} style={{ width: 100, height: 100 }} rounded /> },
                                    { align: 'left', title: 'Type', field: 'type_produit', hidden: true },
                                    { align: 'left', title: 'OF', field: 'num_sachet', type: 'numeric' },
                                    { align: 'left', title: 'Date Créa', field: 'date_crea' },
                                    { align: 'left', title: 'Client', field: 'bijouterie' },
                                    { align: 'left', title: 'Stade', field: 'lestade' },
                                    { align: 'left', title: 'Ref Clt', field: 'ref_clt' },
                                    { align: 'left', title: 'Métal', field: 'lemetal' },
                                    { align: 'left', title: 'Doigt', field: 'dim', type: 'numeric' },
                                    { align: 'left', title: 'Référence', field: 'ref_cv', type: 'numeric' },
                                    { align: 'left', title: 'Désignation', field: 'ref' },
                                    { align: 'left', title: 'Notes', field: 'Notes' },
                                    { align: 'left', title: 'Date Livraison', field: 'date_livr' },
                                    { align: 'left', title: 'Prix HT', field: 'prix_fact', type: 'numeric' },
                                    { align: 'left', title: 'Statut', field: 'lestatut' },
                                ]}

                                options={{
                                    exportButton: true,
                                    exportAllData: true,
                                    actionsColumnIndex: -1,
                                    padding: 'dense',

                                    exportCsv: (allColumns, allData) => exportCsv(allColumns, allData, "of_en_cours"),
                                    rowStyle: rowData => ({
                                        // backgroundColor: (this.selectedEnCours === rowData.num_sachet) ? '#AED1FF' : ''
                                        color: (rowData.date_l_imp === '1') ? '#ff0000' :
                                            rowData.of_reservation !== '0' ? '#1976d2' : '',
                                        backgroundColor: rowData.statut === '575' ? '#b4f998' : '',
                                    })

                                }}
                                actions={[
                                    {
                                        icon: 'query_stats',
                                        tooltip: 'Tracer Of',
                                        onClick: (e, rowData) => this.tracerOf(rowData.num_sachet)
                                    },
                                    {
                                        icon: 'edit',
                                        tooltip: 'Editer Of',
                                        onClick: (e, rowData) => this.editerOf(rowData.num_sachet)
                                    },
                                    {
                                        icon: 'print',
                                        tooltip: 'Imprimer OF',
                                        onClick: (event, rowData) => window.open(`https://www.extensia-france.com/printers/ofPrinter.php?ofs=${rowData.num_sachet}`, "_blank")
                                    },
                                    {
                                        icon: 'refresh',
                                        tooltip: 'Rafraichir',
                                        isFreeAction: true,
                                        onClick: () => this.props.getOfEnCours(this.getFilter()).then(() => this.listeEnCours())
                                    }
                                ]}

                                data={this.state.dataClone}
                                title="Ofs en cours"

                                components={{
                                    Toolbar: props => (
                                        <MTableToolbar {...props} />
                                    ),
                                }}

                                onRowClick={this.onRowClick}
                                localization={localization}
                            />

                        </div>

                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}  >
                        {this.createLabelLivre()}
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="container-fluid">
                            <MaterialTable
                                style={{ fontSize: 14 }}

                                columns={[
                                    { align: 'center', title: 'Image', export: false, field: 'image', width: 130, render: rowData => <Image key={rowData.num_sachet} src={`${imagesRepo}thumbs.php?src=${rowData.image}&size=100`} style={{ width: 100, height: 100 }} rounded /> },
                                    { align: 'left', title: 'Ref', field: 'ref', type: 'numeric', width: 70 },
                                    { align: 'left', title: 'Type', field: 'type_produit', hidden: true },
                                    { align: 'left', title: 'OF', field: 'num_sachet', type: 'numeric', width: 70 },
                                    { align: 'left', title: 'Date Créa', field: 'date_crea', width: 130 },
                                    { align: 'left', title: 'Client', field: 'Nom_agence', width: 200 },
                                    { align: 'left', title: 'Stade', field: 'lestade', width: 70 },
                                    { align: 'left', title: 'Ref Clt', field: 'ref_clt', width: 130 },
                                    { align: 'left', title: 'Métal', field: 'lemetal', width: 60 },
                                    { align: 'left', title: 'Désignation', field: 'designation', width: 300 },
                                    { align: 'left', title: 'Poids', field: 'poids_u', width: 70 },
                                    { align: 'left', title: 'Prix HT', field: 'totht', width: 130 },
                                    { align: 'left', title: 'Date Facture', field: 'date_fact', width: 130 },
                                    { align: 'left', title: 'Facture', field: 'id_fact', width: 130 },
                                ]}
                                options={{
                                    exportButton: true,
                                    exportAllData: true,
                                    actionsColumnIndex: -1,
                                    padding: 'dense',
                                    exportCsv: (allColumns, allData) => exportCsv(allColumns, allData, "of_livre"),
                                    rowStyle: rowData => ({
                                        backgroundColor: (this.selectedLivre === rowData.num_sachet) ? '#AED1FF' : ''
                                    })
                                }}
                                actions={[
                                    {
                                        icon: 'create',
                                        tooltip: 'Tracer Of',
                                        onClick: (e, rowData) => this.tracerOf(rowData.num_sachet)
                                    },
                                    {
                                        icon: 'picture_as_pdf',
                                        tooltip: 'Afficher Facture',
                                        onClick: (event, rowData) => window.open(`https://www.extensia-france.com/printers/factPrinter2024.php?listeFact=${rowData.id_fact}`, "_blank")
                                    },
                                    {
                                        icon: 'refresh',
                                        tooltip: 'Rafraichir',
                                        isFreeAction: true,
                                        onClick: () => this.props.getOfLivre(this.getFilter()).then(() => this.listeLivre())
                                    }
                                ]}
                                data={this.state.dataLivreClone}
                                title="Ofs Livrés"
                                components={{
                                    Toolbar: props => (
                                        <MTableToolbar {...props} />
                                    ),
                                }}
                                onRowClick={this.onRowClickLivre}
                                localization={localization}
                            />
                            <Dialog
                                open={this.state.open}
                                onClose={() => this.setState({ open: false })}
                                fullWidth={true}
                                maxWidth={'xl'}
                            >
                                <DialogTitle id="form-dialog-title">Tracer OF</DialogTitle>
                                <DialogTitle style={{ position: 'absolute', right: 40 }} onClick={() => this.setState({ open: false })}><button className="btn btn-link">Fermer</button></DialogTitle>
                                <DialogContent>
                                    <TracerOf of={this.state.ofToTrace} open={this.state.open} />
                                </DialogContent>

                            </Dialog>

                            <Dialog
                                open={this.state.openEditOf}
                                onClose={() => this.setState({ openEditOf: false })}
                                fullWidth={true}
                            >
                                <DialogTitle id="form-dialog-title">Editer OF</DialogTitle>
                                <DialogTitle style={{ position: 'absolute', right: 40 }} onClick={() => this.setState({ openEditOf: false })}><button className="btn btn-link">Fermer</button></DialogTitle>
                                <DialogContent>
                                    <OfEditComponent onAction={this.refreshLists} />
                                </DialogContent>

                            </Dialog>
                        </div>
                    </AccordionDetails>
                </Accordion>


                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                        {this.createLabelExtensia()}
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="container-fluid">
                            <MaterialTable
                                style={{ fontSize: 14 }}

                                columns={[
                                    { align: 'center', title: 'Image', export: false, field: 'image', render: rowData => <Image key={rowData.num_sachet} src={`${imagesRepo}thumbs.php?src=${rowData.image}&size=100`} style={{ width: 100, height: 100 }} rounded /> },
                                    { align: 'left', title: 'Ref', field: 'ref', type: 'numeric' },
                                    { align: 'left', title: 'OF', field: 'num_sachet', type: 'numeric' },
                                    { align: 'left', title: 'Date Créa', field: 'date_crea' },
                                    { align: 'left', title: 'Client', field: 'Nom_agence' },
                                    { align: 'left', title: 'Stade', field: 'lestade' },
                                    { align: 'left', title: 'Ref Clt', field: 'ref_clt' },
                                    { align: 'left', title: 'Métal', field: 'lemetal' },
                                    { align: 'left', title: 'Référence', field: 'ref' },
                                    { align: 'left', title: 'Désignation', field: 'designation', minWidth: 150 },
                                    { align: 'left', title: 'Poids', field: 'poids_u' },
                                    { align: 'left', title: 'Prix HT', field: 'totht' },
                                    { align: 'left', title: 'Date Facture', field: 'date_fact' },
                                    { align: 'left', title: 'Facture', field: 'id_fact' },
                                ]}
                                options={{
                                    exportButton: true,
                                    exportAllData: true,
                                    actionsColumnIndex: -1,
                                    padding: 'dense',
                                    exportCsv: (allColumns, allData) => exportCsv(allColumns, allData, "of_livre_extensia"),
                                    rowStyle: rowData => ({
                                        backgroundColor: (this.selectedLivreExtensia === rowData.num_sachet) ? '#AED1FF' : ''
                                    })
                                }}
                                actions={[
                                    {
                                        icon: 'create',
                                        tooltip: 'Tracer Of',
                                        onClick: (e, rowData) => this.tracerOf(rowData.num_sachet)
                                    },
                                    {
                                        icon: 'picture_as_pdf',
                                        tooltip: 'Afficher Facture',
                                        onClick: (event, rowData) => window.open(`https://www.extensia-france.com/printers/factExtensiaPrinter.php?listeFact=${rowData.id_fact}`, "_blank")
                                    },
                                    {
                                        icon: 'refresh',
                                        tooltip: 'Rafraichir',
                                        isFreeAction: true,
                                        onClick: () => this.props.getOfLivreExtensia(this.getFilter()).then(() => this.listeLivreExtensia())
                                    }
                                ]}
                                data={this.state.dataLivreCloneExtensia}
                                title="Ofs Livrés EXTENSIA"
                                components={{
                                    Toolbar: props => (
                                        <MTableToolbar {...props} />
                                    ),
                                }}
                                onRowClick={this.onRowClickLivreExtensia}
                                localization={localization}
                            />
                            <Dialog
                                open={this.state.open}
                                onClose={() => this.setState({ open: false })}
                                fullWidth={true}
                                maxWidth={'xl'}
                            >
                                <DialogTitle id="form-dialog-title">Tracer OF</DialogTitle>
                                <DialogTitle style={{ position: 'absolute', right: 40 }} onClick={() => this.setState({ open: false })}><button className="btn btn-link">Fermer</button></DialogTitle>
                                <DialogContent>
                                    <TracerOf of={this.state.ofToTrace} open={this.state.open} />
                                </DialogContent>

                            </Dialog>

                            <Dialog
                                open={this.state.openEditOf}
                                onClose={() => this.setState({ openEditOf: false })}
                                fullWidth={true}
                            >
                                <DialogTitle id="form-dialog-title">Editer OF</DialogTitle>
                                <DialogTitle style={{ position: 'absolute', right: 40 }} onClick={() => this.setState({ openEditOf: false })}><button className="btn btn-link">Fermer</button></DialogTitle>
                                <DialogContent>
                                    <OfEditComponent onAction={this.refreshLists} />
                                </DialogContent>

                            </Dialog>
                        </div>
                    </AccordionDetails>
                </Accordion>


            </div >
        )
    }
}

const mapStateToProps = (state) => {
    return {
        listeStade: state.generalData.listeStade,
        listeTypeProduit: state.generalData.listeTypeProduit,
        listeMetaux: state.generalData.listeMetaux,
        listeStatut: state.generalData.listeStatut,
        listeTypeCommande: state.generalData.typeCommande,
        listeOfEnCours: state.analyse.listeOfEnCours,
        totalListeOfEnCours: state.analyse.totalListeOfEnCours,
        listeOfLivre: state.analyse.listeOfLivre,
        listeOfLivreExtensia: state.analyse.listeOfLivreExtensia,
        listeOfs: state.ofs.listeOf,

    }
}
const mapDispatchToProps = {
    getStade,
    getMetaux,
    getTypeProduit,
    getStatut,
    getOfEnCours,
    getOfLivre,
    getOfLivreExtensia,
    getOfFromId,
    setOfToEdit,
    getListeOf,
    getTypeCommande,


}
export default connect(mapStateToProps, mapDispatchToProps)(FilterTester)