import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TracerOf from '../containers/tracerOf'
import { getListeOf } from '../actions'
import Image from 'react-bootstrap/Image'
import { imagesRepo } from '../config'

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import { roundPrecision2 } from '../helpers/functions';
import { connect } from 'react-redux';


const useStyles = makeStyles({
    table: {
        minWidth: 700,
        paddingBottom: 20,
    },
    header: {
        backgroundColor: '#AED1FF',
        fontSize: 20
    },
});

const createTotalHt = ofs => {
    return roundPrecision2(ofs.map(({ prix_fact, reaco, remise }) => (parseInt(prix_fact) + parseInt(reaco)) * (1 - parseInt(remise) / 100)).reduce((sum, prixHt) => sum + prixHt));
}
const calculatePort = (ofs) => {
    if (createTotalHt(ofs) < 1000) {
        return 23
    }
    return 0
}

const LivraionItem = ({ ofs, facturerOfs, getListeOf }) => {
    console.log(ofs);
    const [port, setPort] = useState(calculatePort(ofs));
    const [of, setOf] = useState('');
    const [open, setOpen] = useState(false);
    const classes = useStyles();

    const tracerOf = (of) => {
        setOf(of)
        setOpen(true)
    }
    const onClose = () => {
        setOpen(false)
        getListeOf()
    }
    //console.log(ofs);
    return (
        <TableContainer component={Paper} className={classes.table}>
            <Table className={classes.table} aria-label="spanning table">
                <TableHead>
                    <TableRow className={classes.header}>
                        <TableCell className={classes.header} align="center" colSpan={9}>
                            {ofs[0].nom_ligne === "" ? ofs[0].Nom_agence + '   /   ' + ofs[0].descTypeTransp.toLowerCase() : ofs[0].Nom_agence + '   /   ' + ofs[0].descTypeTransp.toLowerCase() + '   /   ' + ofs[0].nom_ligne}
                        </TableCell>
                        <TableCell align="right" colSpan={2}>
                            <button onClick={() => facturerOfs(ofs, port)} type="button" className="btn btn-link btn-sm" style={{ lineHeight: 0.5, fontSize: '1rem' }}>Facturer</button>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell align="center">Img</TableCell>
                        <TableCell align="center">Ref</TableCell>
                        <TableCell align="center">OF</TableCell>
                        <TableCell align="center">Client</TableCell>
                        <TableCell align="center">Description</TableCell>
                        <TableCell align="center">Prix</TableCell>
                        <TableCell align="center">Remise</TableCell>
                        <TableCell align="center">Reaco</TableCell>
                        <TableCell align="center">Echeance</TableCell>
                        <TableCell align="center">Total HT</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {ofs && Object.values(ofs).map((of) => (
                        <TableRow key={of.num_sachet}>
                            <TableCell><button onClick={() => tracerOf(of.num_sachet)} className="btn btn-link btn-sm">Tracer</button></TableCell>
                            <TableCell align="center">
                                <Image key={of.num_sachet} src={`${imagesRepo}thumbs.php?src=${of.image}&w=100`} style={{ width: 100, height: 100 }} rounded />
                            </TableCell>
                            <TableCell align="center">{of.ref_cv}</TableCell>
                            <TableCell align="center">{of.num_sachet}</TableCell>
                            <TableCell align="center">{of.Nom_agence}</TableCell>
                            <TableCell align="center">{of.ref1 + ' ' + of.suffix}</TableCell>
                            <TableCell align="center">{of.prix_fact}</TableCell>
                            <TableCell align="center">{of.remise}</TableCell>
                            <TableCell align="center">{of.reaco}</TableCell>
                            <TableCell align="center">{of.echeance}</TableCell>
                            <TableCell align="center">{roundPrecision2((parseInt(of.prix_fact) + parseInt(of.reaco)) * (1 - parseInt(of.remise) / 100))}</TableCell>
                        </TableRow>
                    ))}

                    <TableRow >
                        <TableCell align="right" colSpan={8} />
                        <TableCell align="center" colSpan={1}>
                            <button onClick={() => setPort(0)} type="button" className="btn btn-link btn-sm" style={{ lineHeight: 0.5, fontSize: '1rem' }}>Offrir Port</button>

                        </TableCell>

                        <TableCell align="center" colSpan={1}>

                            <TextField value={port} onChange={e => setPort(e.target.value)} type='number' inputProps={{ min: 0, style: { textAlign: 'center' } }} label="Port" variant="outlined" />
                        </TableCell>
                        <TableCell align="center" colSpan={1}>
                            {createTotalHt(ofs)}
                        </TableCell>
                    </TableRow>
                </TableBody>

            </Table>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                fullWidth={true}
                maxWidth={'lg'}
            >

                <DialogContent>
                    <TracerOf of={of} open={open} />
                </DialogContent>
                <DialogActions>
                    <DialogTitle id="form-dialog-title">Tracer OF</DialogTitle>
                    <Button onClick={onClose} color="primary">
                        Fermer
                    </Button>
                </DialogActions>
            </Dialog>
        </TableContainer>

    );
}

const mapDispatchToProps = {
    getListeOf,
}
export default connect(null, mapDispatchToProps)(LivraionItem)