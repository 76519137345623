import React from 'react';
import { Card, CardContent, Typography, Grid } from '@material-ui/core';
import { Bar } from 'react-chartjs-2';

const TempsRepport = ({ tempsPasse, tempsTheorique }) => {

    const barData = {
        labels: [''],
        datasets: [
            {
                label: 'Temps Passé',
                backgroundColor: tempsPasse && tempsPasse > tempsTheorique ? '#FF6384' : '#4CAF50',
                data: [tempsPasse || 0],
            },
            {
                label: 'Temps Théorique',
                backgroundColor: '#36A2EB',
                data: [tempsTheorique || 0, 0],
            },
        ],
    };

    const options = {
        scales: {
            y: {
                beginAtZero: true,
            },
        },
        legend: {
            display: false,
        },
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: 'y', // Afficher les barres horizontalement
    };

    return (
        <>
            {tempsPasse !== 0 && (
                <Card style={{ height: '100%' }}>
                    <CardContent style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <div style={{ textAlign: 'center' }}>
                                        <div style={{ backgroundColor: tempsPasse && tempsPasse > tempsTheorique ? '#FF6384' : '#4CAF50', height: '10px', width: '50px', margin: 'auto' }}></div>
                                        <Typography variant="h6">Réel</Typography>
                                        <Typography variant="body2">Temps : {tempsPasse || 0} minutes</Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={6} style={{ borderLeft: '1px solid #ccc' }}>
                                    <div style={{ textAlign: 'center' }}>
                                        <div style={{ backgroundColor: '#36A2EB', height: '10px', width: '50px', margin: 'auto' }}></div>
                                        <Typography variant="h6">Théorique</Typography>
                                        <Typography variant="body2">Temps : {tempsTheorique || 0} minutes</Typography>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                        <div style={{ flexGrow: 1 }}>
                            <Bar data={barData} options={options} />
                        </div>
                    </CardContent>
                </Card>
            )}
        </>
    );
};

export default TempsRepport;
