import React from 'react';
import MaterialTable from 'material-table';
import { localization } from '../../config';

const DetailBesoin = ({ data, title }) => {
    const columns = [
        { title: 'OF', field: 'num_sachet', align: 'center', defaultSort: null, type: 'numeric' },
        { title: 'Qte', field: 'qte', align: 'center', defaultSort: null, type: 'numeric' },
        {
            title: 'Pds',
            field: '',
            align: 'center',
            defaultSort: null,
            type: 'numeric',
            render: rowData => {
                if (rowData.forme === "1005") {
                    return rowData.qte * rowData.dim1; // Calculer le poids si la forme est "1005"
                } else {
                    return ""; // Retourner une chaîne vide sinon
                }
            }
        },
        { title: 'Client', field: 'Nom_agence', align: 'center', defaultSort: null, type: 'numeric' },
    ]
    return (
        <div className="text-center" style={{ maxWidth: 800, margin: '0 auto' }}>
            <MaterialTable
                style={{ fontSize: 12, margin: '0 auto' }}
                columns={columns}
                options={{
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 30, 100],
                    padding: 'dense',
                }}
                data={[...data]}
                title={"Détail " + title}
                localization={localization}
            />

        </div>
    );
};
export default (DetailBesoin);
