import React, { useState } from 'react';
import FiltreBesoin from './FiltreBesoin';
import MaterialTable from 'material-table';
import { localization } from '../../config';
import DetailBesoin from './DetailBesoin';
import { exportCsv } from '../../helpers/functions';

const Besoin = () => {
    const [listeBesoin, setListeBesoin] = useState([]);
    const [dataDetail, setDataDetail] = useState([]);
    const [title, setTitle] = useState("");
    const [selectedRowId, setSelectedRowId] = useState(null);

    const columns = [
        { title: 'Qte', field: 'qte_total', align: 'center', defaultSort: null, type: 'numeric' },
        { title: 'Description', field: 'description', align: 'left', defaultSort: null },
        { title: 'Poids Total', field: 'poidsTotal', align: 'center', defaultSort: null, type: 'numeric' },
    ];

    const detailConso = (conso) => {
        setDataDetail(conso.details);
        setTitle(conso.description);
    };
    const afficher = (data) => {
        setListeBesoin(data)
        setDataDetail([])
        setTitle("")
    }

    return (
        <div className="text-center">
            <FiltreBesoin setListeBesoin={afficher} />

            <div className="row justify-content-center align-items-stretch">
                <div className="col-6">
                    <MaterialTable
                        style={{ fontSize: 12 }}
                        columns={columns}
                        options={{
                            exportButton: true,
                            exportAllData: true,
                            pageSize: 10,
                            pageSizeOptions: [10, 20, 30, 100],
                            padding: 'dense',
                            rowStyle: rowData => ({
                                backgroundColor: rowData.id_tarif === selectedRowId ? 'rgba(86, 186, 237, 0.5)' : 'inherit'
                            }),
                            onRowClick: (event, rowData) => setSelectedRowId(rowData.id_tarif === selectedRowId ? null : rowData.id_tarif),
                            exportCsv: (allColumns, allData) => exportCsv(allColumns, allData, "besoin"),
                        }}

                        data={[...Object.values(listeBesoin)]}
                        title="Edition des Besoins"
                        localization={localization}
                        onRowClick={(e, row) => detailConso(row)}
                    />
                </div>
                <div className="col-6">
                    <DetailBesoin data={dataDetail} title={title} />
                </div>
            </div>
        </div>
    );
};

export default Besoin;
