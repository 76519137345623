import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { Col, Form } from 'react-bootstrap'
import TreeClient from '../treeClient';

export default function EnvoyerProduitsDialog(props) {
    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            fullWidth={true}
            maxWidth={'sm'}
            style={{ textAlign: "center" }}
        >
            <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
            <DialogContent>
                <div className='d-flex justify-content-center'>
                    <Form>
                        <Form.Group>
                            <Form.Row>
                                <TreeClient handleChange={props.choixClient} multiSelection={false} />
                            </Form.Row>
                            <Form.Row>
                                <Col>
                                    <button
                                        onClick={props.handleSave}
                                        className='btn btn-link btn-sm'
                                    >Enregistrer
                                    </button>
                                </Col>
                            </Form.Row>
                        </Form.Group>
                    </Form>
                </div>
            </DialogContent>

            <DialogActions>
                <Button onClick={props.onClose} color="primary">
                    Fermer
                </Button>
            </DialogActions>
        </Dialog>
    );
}
