import React, { useEffect, useState } from 'react'
import SearchProd from '../searchProd'

import {
    getListeProduit, getListeClient, getMetaux, getTypeTransport, getStade, getListeOf, getTypeCom, getTypeCommande
} from '../../actions'
import { connect } from 'react-redux'
import { Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { clientOnlyChild } from '../../selectors/clients';
import Axios from 'axios';
import { meekroDB } from '../../config';
import { AjouterJourDateOuvrable, getTodaySqlFormat } from '../../helpers/functions';

const OfEditComponent = ({
    ofToEdit,
    listeClientInput,
    listeTypeTransport,
    listeStade,
    listeMetaux,
    getListeProduit,
    getListeClient,
    getMetaux,
    getTypeTransport,
    getStade,
    getTypeCom,
    getTypeCommande,
    listeTypeCom,
    listeTypeCommande,
    onAction, }) => {




    const [id, setId] = useState(0)
    const [produit, setProduit] = useState(null)
    const [refClient, setRefClient] = useState('')
    const [notes, setNotes] = useState('')
    const [descriptionConfie, setDescriptionConfie] = useState('')
    const [poidsConfie, setPoidsConfie] = useState('')
    const [openListe, setOpenListe] = useState(true)
    const [dateCrea, setDateCrea] = useState(getTodaySqlFormat())
    const [date, setDate] = useState(AjouterJourDateOuvrable(15))
    const [client, setClient] = useState(null)
    const [stade, setStade] = useState(null)
    const [typeTransport, setTypeTransport] = useState(null)
    const [typeMetal, setTypeMetal] = useState(null)
    const [imperatif, setImperatif] = useState(false)
    const [tournee, setTournee] = useState(false)
    const [dim, setDim] = useState('')
    const [refSelected, setRefSelected] = useState('')
    const [prixFact, setPrixFact] = useState('')
    const [sp, setSp] = useState(false)
    const [facon, setFacon] = useState(false)
    const [devis, setDevis] = useState(false)
    const [listeToPrint, setListeToPrint] = useState('')
    const [commission, setCommission] = useState(null)
    const [typeCommande, setTypeCommande] = useState(null)


    useEffect(() => getListeProduit(), [getListeProduit]);
    useEffect(() => getListeClient(), [getListeClient]);
    useEffect(() => getMetaux(), [getMetaux]);
    useEffect(() => getTypeTransport(), [getTypeTransport]);
    useEffect(() => getStade(), [getStade]);
    useEffect(() => getTypeCom(), [getTypeCom]);
    useEffect(() => getTypeCommande(), [getTypeCommande]);

    useEffect(() => {
        ofToEdit && ofToEdit.length !== 0 && populateForm(ofToEdit)

    }, [ofToEdit]);

    const onSubmit = (e) => {

    }
    const handleSave = e => {
        e.preventDefault()
        let idProduit = '1'
        if (produit && produit.id_centre !== '0') idProduit = produit.id_centre
        const data = {
            table: 'of',
            primaryKey: 'num_sachet',
            id,
            idp: idProduit,
            id_bijouterie: client.id,
            typ_transp: typeTransport.id_type,
            id_stade: stade.id_stade,
            type_metal: typeMetal.id_type,
            date_livr: date,
            ref_clt: refClient,
            poids_confie: poidsConfie,
            notes_confie: descriptionConfie,
            Notes: notes,
            dim,
            prix_fact: prixFact,
            tournee: tournee ? '1' : '0',
            date_l_imp: imperatif ? '1' : '0',
            pierres_clt: sp === false ? '0' : '1',
            facon: facon === false ? '0' : '1',
            devis: devis ? '1' : '0',
            typ_com: commission.id_typ_com,
            id_type_commande: typeCommande.id,

        }
        Axios.put(`${meekroDB}/general.php`, data)
            .then(response => {
                onAction()
            }).catch(error => {
                console.log(error);
            });
    }
    const handleRemove = e => {
        alert('Suppréssion en cours de developpement')
    }
    const prenventEnter = e => {
        if (e.key === 'Enter') e.preventDefault()
    }
    const populateForm = (data) => {
        setId(data.num_sachet)
        setClient({ id: data.id_bijouterie, label: data.Nom_agence })
        setTypeTransport({ id_type: data.typ_transp, description: data.descTypeTransp })
        setStade({ id_stade: data.id_stade, description: data.descStade })
        setTypeMetal({ id_type: data.type_metal, description: data.descTypeMetal })
        setDate(data.date_livr)
        setDateCrea(data.date_crea)
        setTournee(data.tournee === '0' ? false : true)
        setImperatif(data.date_l_imp === '0' ? false : true)
        setPrixFact(data.prix_fact)
        setRefClient(data.ref_clt)
        setPoidsConfie(data.poids_confie)
        setDescriptionConfie(data.notes_confie)
        setDim(data.dim)
        setNotes(data.Notes)
        // setRefSelected(data.ref_cv + ' ' + data.ref)
        setProduit({ ref: data.ref_cv + ' ' + data.ref, id_centre: data.idp })
        setOpenListe(false)
        setSp(data.pierres_clt === '0' ? false : true)
        setDevis(data.devis === '0' ? false : true)
        setFacon(data.facon === '0' ? false : true)
        setListeToPrint(data.num_sachet)
        setCommission({ id_typ_com: data.typ_com, desc: data.descCom })
        setTypeCommande({ id: data.id_type_commande, label: data.labelTypeCommande })
    }
    const createPrixFact = () => {
        let prixFact = 0
        if (produit && stade) {
            switch (stade.id_stade) {
                case '1':
                    prixFact = produit.mfs
                    break;
                case '3':
                    prixFact = produit.totalcv
                    break;
                default:
                    prixFact = produit.total3
                    break;
            }
            setPrixFact(prixFact)
        }
    }
    const changeStade = values => {
        setStade(values)
        createPrixFact()
    }
    const handleSelect = (prod, isOpenListe = true) => {
        setProduit(prod)
        setRefSelected(prod.ref + ' ' + prod.description)
        setOpenListe(isOpenListe)
        createPrixFact()
    }

    const print = () => {
        const debut = listeToPrint.split('-')[0]
        const fin = listeToPrint.split('-')[1] ? listeToPrint.split('-')[1] : ''
        if (listeToPrint.split('-')[1] === undefined) window.open(`https://www.extensia-france.com/printers/ofPrinter.php?ofs=${debut}`, "_blank")
        else window.open(`https://www.extensia-france.com/printers/ofPrinter.php?of_min=${debut}&of_max=${fin}`, "_blank")
    }
    return (
        <div className="container-fluid">
            <div className="form-group">
                {produit && <button type="submit" className="btn btn-link btn-sm" >Ajouter</button>}
                {id !== 0 &&
                    <div style={{
                        display: 'flex',
                        alignItems: 'baseline'
                    }}>
                        <button type="button" className="btn btn-link btn-sm" onClick={handleSave}>Enregistrer</button>
                        <button type="button" className="btn btn-link btn-sm" onClick={handleRemove}>Supprimer</button>

                        <TextField onKeyPress={prenventEnter}
                            variant="outlined"
                            value={listeToPrint}
                            label="Impression"
                            onChange={(e) => setListeToPrint(e.target.value)}
                            size='small'
                            margin='dense'
                        />
                        <button type="button" className="btn btn-link btn-sm" onClick={print}>Imprimer</button>



                    </div>
                }
            </div>
            <div className="row">
                <div className="col-sm">
                    <form onSubmit={onSubmit}  >
                        <div className='row'>

                            <div className="col-sm">
                                <div className="form-group">
                                    <TextField onKeyPress={prenventEnter}
                                        variant="outlined"
                                        value={dateCrea}
                                        label="Création"
                                        type="date"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-sm">
                                <div className="form-group">
                                    <TextField onKeyPress={prenventEnter}
                                        variant="outlined"
                                        value={date}
                                        id="date"
                                        label="Livraison"
                                        type="date"
                                        onChange={(e) => setDate(e.target.value)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-sm">
                                <div className="form-group">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={imperatif}
                                                onChange={(e) => setImperatif(e.target.checked)}
                                                name="checkedB"
                                                color="primary"
                                            />
                                        }
                                        label="Impératif"
                                    />
                                </div>
                            </div>

                            <div className="col-sm">
                                <div className="form-group">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={tournee}
                                                onChange={(e) => setTournee(e.target.checked)}
                                                color="primary"
                                            />
                                        }
                                        label="Tournée"
                                    />
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-sm">
                                <div className="form-group">
                                    <Autocomplete
                                        value={commission}
                                        options={listeTypeCom}
                                        getOptionLabel={(option) => option.desc}
                                        onChange={(event, values) => setCommission(values)}
                                        getOptionSelected={(option) => option.id_typ_com === commission.id_typ_com}

                                        autoHighlight
                                        autoComplete
                                        clearOnEscape

                                        renderInput={(params) => <TextField onKeyPress={prenventEnter} {...params} label="Commission" variant="outlined" />}
                                    />
                                </div>
                            </div>
                            <div className="col-sm">
                                <div className="form-group">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={facon}
                                                onChange={(e) => setFacon(e.target.checked)}
                                                color="primary"
                                            />
                                        }
                                        label="Façon"
                                    />
                                </div>
                            </div>
                            <div className="col-sm">
                                <div className="form-group">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={sp}
                                                onChange={(e) => setSp(e.target.checked)}
                                                color="primary"
                                            />
                                        }
                                        label="Spécial"
                                    />
                                </div>
                            </div>
                            <div className="col-sm">
                                <div className="form-group">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={devis}
                                                onChange={(e) => setDevis(e.target.checked)}
                                                color="primary"
                                            />
                                        }
                                        label="Devis"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm">
                                <Autocomplete
                                    value={client}
                                    options={listeClientInput}
                                    getOptionLabel={(option) => option.label}
                                    onChange={(event, values) => setClient(values)}
                                    getOptionSelected={(option) => option.id === client.id}
                                    autoHighlight
                                    autoComplete
                                    clearOnEscape
                                    renderInput={(params) => <TextField onKeyPress={prenventEnter} {...params} required label="Client" variant="outlined" margin="normal" />}
                                />
                            </div>
                            <div className="col-sm">
                                <Autocomplete
                                    value={typeCommande}
                                    options={listeTypeCommande}
                                    getOptionLabel={(option) => option.label}
                                    onChange={(event, values) => setTypeCommande(values)}
                                    getOptionSelected={(option) => option.id === typeCommande.id}
                                    autoHighlight
                                    autoComplete
                                    clearOnEscape
                                    renderInput={(params) => <TextField onKeyPress={prenventEnter} {...params} required label="Type Commande" variant="outlined" margin="normal" />}
                                />
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-sm">
                                <div className="form-group">
                                    <TextField onKeyPress={prenventEnter} fullWidth label="Réf Clt" variant="outlined"
                                        value={refClient}
                                        onChange={(e) => setRefClient(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="col-sm">
                                <div className="form-group">
                                    <Autocomplete
                                        value={typeTransport}
                                        options={listeTypeTransport}
                                        getOptionLabel={(option) => option.description}
                                        onChange={(event, values) => setTypeTransport(values)}
                                        getOptionSelected={(option) => option.id_type === typeTransport.id_type}
                                        autoHighlight
                                        autoComplete
                                        clearOnEscape
                                        renderInput={(params) => <TextField onKeyPress={prenventEnter} {...params} required label="Type Transport" variant="outlined" />}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-sm">
                                <div className="form-group">
                                    <Autocomplete
                                        value={typeMetal}
                                        options={listeMetaux}
                                        getOptionLabel={(option) => option.description}
                                        onChange={(event, values) => setTypeMetal(values)}
                                        getOptionSelected={(option) => option.id_type === typeMetal.id_type}
                                        autoHighlight
                                        autoComplete
                                        clearOnEscape
                                        renderInput={(params) => <TextField onKeyPress={prenventEnter} {...params} required label="Type Métal" variant="outlined" margin="normal" />}
                                    />
                                </div>
                            </div>
                            <div className="col-sm">
                                <div className="form-group">
                                    <Autocomplete
                                        value={stade}
                                        options={listeStade}
                                        getOptionLabel={(option) => option.description}
                                        onChange={(event, values) => changeStade(values)}
                                        getOptionSelected={(option) => option.id_stade === stade.id_stade}

                                        autoHighlight
                                        autoComplete
                                        clearOnEscape

                                        renderInput={(params) => <TextField onKeyPress={prenventEnter} {...params} required label="Stade" variant="outlined" margin="normal" />}
                                    />
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-sm">
                                <div className="form-group">
                                    <TextField onKeyPress={prenventEnter} fullWidth label="Notes" variant="outlined" multiline={true} rows={3}
                                        value={notes}
                                        onChange={(e) => setNotes(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="col-sm">
                                <div className="form-group">
                                    <TextField onKeyPress={prenventEnter} fullWidth label="Desc Confié" variant="outlined" multiline={true} rows={3}
                                        value={descriptionConfie}
                                        onChange={(e) => setDescriptionConfie(e.target.value)}
                                    />
                                </div>
                            </div>

                        </div>

                        <div className="row">
                            <div className="col-sm">
                                <div className="form-group">
                                    <TextField onKeyPress={prenventEnter} fullWidth label="Dim / Longueur" variant="outlined"
                                        value={dim}
                                        onChange={(e) => setDim(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="col-sm">
                                <div className="form-group">
                                    <TextField onKeyPress={prenventEnter} fullWidth label="Poids Confié" variant="outlined"
                                        value={poidsConfie}
                                        onChange={(e) => setPoidsConfie(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="col-sm">
                                <div className="form-group">
                                    <TextField onKeyPress={prenventEnter} fullWidth label="Prix Fact" variant="outlined"
                                        value={prixFact}
                                        onChange={(e) => setPrixFact(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <SearchProd onEnter={() => setOpenListe(!openListe)} refSelected={refSelected} produit={produit} handleSelect={handleSelect} autoFocus={false} viewList={openListe} />
                        </div>

                    </form>

                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        listeClientInput: clientOnlyChild(state),
        listeTypeTransport: state.generalData.listeTypeTransport,
        listeStade: state.generalData.listeStade,
        listeMetaux: state.generalData.listeMetaux,
        ofToEdit: state.ofs.ofToEdit,
        listeTypeCom: state.generalData.listeTypeCom,
        listeTypeCommande: state.generalData.typeCommande,
    }
}
const mapDispatchToProps = {
    getListeProduit,
    getListeClient,
    getMetaux,
    getTypeTransport,
    getStade,
    getListeOf,
    getTypeCom,
    getTypeCommande,
}
export default connect(mapStateToProps, mapDispatchToProps)(OfEditComponent)