import React, { useState } from 'react'
import { connect } from 'react-redux'
import { TextField } from '@material-ui/core'
import Axios from 'axios';
import { meekroDB } from '../../config';

const EditStockProduit = ({ ligneStock, onSave }) => {
    //console.log(ligneStock);
    const [description, setDescription] = useState(ligneStock.description)
    const [etiquette, setEtiquette] = useState(ligneStock.label_produit)
    const [prix, setPrix] = useState(ligneStock.leprix)
    const [prixRevient, setPrixRevient] = useState(ligneStock.prix_r)
    const [poids, setPoids] = useState(ligneStock.poids)
    const [plateau, setPlateau] = useState(ligneStock.plateau)
    const save = () => {
        const of = {
            table: 'of',
            primaryKey: 'num_sachet',
            id: ligneStock.of,
            ref1: description,
            label_produit: etiquette,
            prix_fact: prix,
            prix_r: prixRevient,
            poids,
        }
        Axios.put(`${meekroDB}/general.php`, of)
            .then((response) => {
                const histo = {
                    ref1: description,
                    label_produit: etiquette,
                    prix_fact: prix,
                    of: ligneStock.of,
                    plateau,
                    poids,
                }
                Axios.put(`${meekroDB}/produit/mouvement.php`, histo)
                    .then((response) => {
                        //console.log(response);

                        onSave()
                    })
                    .catch(error => {
                        console.log(error);
                    });
                onSave()
            })
            .catch(error => {
                console.log(error);
            });

    }
    return (
        <div className='d-flex justify-content-center'>

            <div className="form">
                <h6>{'OF :' + ligneStock.of + ' REF : ' + ligneStock.ref + ' ' + ligneStock.description}</h6>

                <div className="row">
                    <div className="col-auto">
                        <div className="form-group">
                            <TextField fullWidth label="Description" variant="outlined"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-auto">
                        <div className="form-group">
                            <TextField fullWidth label="Etiquette" variant="outlined"
                                value={etiquette}
                                onChange={(e) => setEtiquette(e.target.value)}
                                inputProps={{ maxLength: 15 }}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-auto">
                        <div className="form-group">
                            <div className="form-group">
                                <TextField fullWidth label="Prix" variant="outlined"
                                    value={prix}
                                    onChange={(e) => setPrix(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-auto">
                        <div className="form-group">
                            <TextField fullWidth label="Prix de Revient" variant="outlined"
                                value={prixRevient}
                                onChange={(e) => setPrixRevient(e.target.value)}

                            />
                        </div>
                    </div>

                </div>
                <div className="row">
                    <div className="col-auto">
                        <div className="form-group">
                            <div className="form-group">
                                <TextField fullWidth label="Poids" variant="outlined"
                                    value={poids}
                                    onChange={(e) => setPoids(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-auto">
                        <div className="form-group">
                            <TextField fullWidth label="Plateau" variant="outlined"
                                value={plateau}
                                onChange={(e) => setPlateau(e.target.value)}
                            />
                        </div>
                    </div>

                </div>
                <button
                    onClick={save}
                    className='btn btn-link btn-sm'
                >Enregistrer
                                        </button>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
    }
}
const mapDispatchToProps = {

}
export default connect(mapStateToProps, mapDispatchToProps)(EditStockProduit)