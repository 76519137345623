import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getAtelier, getListeBoiteOr, getListeJoaillier } from '../../../actions';
import CardOuvrier from './CardOuvrier';
import DetailOuvrier from './DetailOuvrier';

function AnalyseAtelier({ listeJoailliers, getAtelier, getListeJoaillier, getListeBoiteOr }) {
    const [selectedOuvrier, setSelectedOuvrier] = useState(null);

    useEffect(() => {
        getAtelier();
        getListeJoaillier()
    }, [getAtelier, getListeJoaillier]);
    useEffect(() => {
        getListeBoiteOr()
    }, [getListeBoiteOr]);
    // Mettre à jour selectedOuvrier lorsque listeJoailliers change
    useEffect(() => {
        if (selectedOuvrier) {
            const updatedOuvrier = listeJoailliers.find(j => j.id === selectedOuvrier.id);
            setSelectedOuvrier(updatedOuvrier || null);
        }
    }, [listeJoailliers, selectedOuvrier]);

    const handleClick = (ouvrier) => {
        setSelectedOuvrier(ouvrier);
    };

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                {listeJoailliers.map((joaillier) => (
                    <div key={joaillier.id} onClick={() => handleClick(joaillier)} style={{ cursor: 'pointer', margin: '10px' }}>
                        <CardOuvrier
                            joaillier={joaillier.joaillier}
                            boiteOr={joaillier.boiteOr}
                            ofs={joaillier.ofs}
                            isSelected={selectedOuvrier && selectedOuvrier.id === joaillier.id}
                            handleCardClick={handleClick}
                        />
                    </div>
                ))}
            </div>
            {selectedOuvrier && (
                <DetailOuvrier data={selectedOuvrier} />
            )}
        </div>
    );
}

const mapStateToProps = (state) => ({
    listeJoailliers: state.atelier.listeJoaillier,
});

const mapDispatchToProps = {
    getAtelier,
    getListeJoaillier,
    getListeBoiteOr,
};

export default connect(mapStateToProps, mapDispatchToProps)(AnalyseAtelier);
