import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { getListeOf, getCours } from '../actions';
import { getOfsGroupByClientAndTransport, getOfsToFact } from '../selectors/ofsSelector';
import LivraisonItem from '../components/livraisonItem';
import { facturerExtensia, facturerNormale } from '../actions/facturation';

const Livraison = ({ getListeOf, getCours, ofsToFact, cours }) => {
    useEffect(() => {
        getListeOf();
        getCours();
    }, [getListeOf, getCours]);

    const facturerOfs = useCallback(async (ofs, port) => {
        if (window.confirm('Enregistrer les factures ?')) {
            const ofsParEcheance = ofs.reduce((groupedData, of) => {
                if (!groupedData[of.echeance]) {
                    groupedData[of.echeance] = [];
                }
                groupedData[of.echeance].push(of);
                return groupedData;
            }, {});

            for (const echeance in ofsParEcheance) {
                const facture = ofsParEcheance[echeance];
                const prodExtensia = facture[0].nom_ligne === "EXTENSIA";
                let factureIds;
                if (prodExtensia) {
                    factureIds = await facturerExtensia(facture, port, cours);
                } else {
                    factureIds = await facturerNormale(facture, port, cours);
                }
                getListeOf()
                const { factExtensiaId, factureId } = factureIds;

                if (prodExtensia) {
                    window.open(`https://www.extensia-france.com/printers/factExtensiaPrinter.php?listeFact=${factExtensiaId}`, "_blank");
                    window.open(`https://www.extensia-france.com/printers/factPrinter2024.php?listeFact=${factureId}`, "_blank");
                } else {
                    window.open(`https://www.extensia-france.com/printers/factPrinter2024.php?listeFact=${factureId}`, "_blank");
                }

                port = 0;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cours]);


    return (
        <div>
            {Object.values(ofsToFact).flatMap((ofs, i) =>
                Object.values(ofs).map((clientType, j) => (
                    <LivraisonItem key={`${i}-${j}`} ofs={clientType} facturerOfs={facturerOfs} />
                ))
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    ofsToFact: getOfsGroupByClientAndTransport(state),
    ofs: getOfsToFact(state),
    cours: state.cours.listeCours
});

const mapDispatchToProps = {
    getListeOf,
    getCours
};

export default connect(mapStateToProps, mapDispatchToProps)(Livraison);
