import React from "react";

const requireUserAdmin = (WrappedComponent, ...roles) => {
    return class extends React.Component {
        render() {
            const user = JSON.parse(localStorage.getItem('user'))[0];
            if (user && roles.includes(user.role)) {
                return <WrappedComponent {...this.props} />;
            } else {
                // Afficher un message d'erreur ou rediriger l'utilisateur
                return null;
            }
        }
    };
};

export default requireUserAdmin;
