import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import { MTableToolbar } from 'material-table';
import { euroFormat } from '../../helpers/functions';


const AvoirTable = ({ data }) => {
    const columns = [
        { title: 'N° Avoir', field: 'id_avoir', align: 'left', defaultSort: null, type: 'numeric' },
        { title: 'Client', field: 'Nom_agence', align: 'left', defaultSort: null },
        { title: 'Date Avoir', field: 'date', align: 'left', defaultSort: null },
        { title: 'Total HT', field: 'tot_ht', align: 'left', defaultSort: null, type: 'numeric' },
        // { title: 'TVA', field: 'tva', align: 'left', defaultSort: null, type: 'numeric' },

    ]
    const [totalSelection, setTotalSelection] = useState(0);
    const [nbSelection, setNbSelection] = useState(0);
    const [totalFinal, setTotalFinal] = useState(0);



    useEffect(() => {
        let total = 0;
        data.forEach(item => {
            total += parseFloat(item.tot_ht);
        });
        setTotalFinal(total);
        setTotalSelection(0);
        setNbSelection(0);
    }, [data]);

    const printAvoir = (rowData) => {
        let ids = '', sep = ''
        Object.values(rowData).forEach(avoir => {
            ids += sep + avoir.id_avoir
            sep = ','
        })
        window.open(`https://www.extensia-france.com/printers/avoirPrinter.php?listeAvoir=${ids}`, "_blank")
    }
    const calculateTotalSelected = () => {
        let totalSelection = 0, nbSelection = 0;
        Object.values(data)
            .filter(f => f.tableData.checked)
            .forEach(item => {
                if (item.tableData.checked) {
                    totalSelection += parseFloat(item.tot_ht);
                    nbSelection++;
                }
            });
        setTotalSelection(totalSelection);
        setNbSelection(nbSelection);
    }
    return (
        <div>
            <h5 className="d-flex justify-content-around">Total Avoir HT : {euroFormat(totalFinal)}</h5>
            <MaterialTable
                style={{ fontSize: 12 }}
                columns={columns}
                options={{
                    showTextRowsSelected: false,
                    pageSize: 10,
                    selection: true,
                    actionsColumnIndex: -1,
                    pageSizeOptions: [10, 20, 30, 100],
                    padding: 'dense',
                }}
                actions={[
                    rowData => ({
                        icon: 'picture_as_pdf',
                        tooltip: 'Imprimer Avoir',
                        onClick: () => printAvoir(rowData),
                        position: 'toolbarOnSelect',
                    }),
                    {
                        icon: 'picture_as_pdf',
                        tooltip: 'Imprimer avoir',
                        onClick: (e, rowData) => window.open(`https://www.extensia-france.com/printers/avoirPrinter.php?listeAvoir=${rowData.id_avoir}`, "_blank"),
                        position: 'row',
                    }
                ]}
                onSelectionChange={calculateTotalSelected}
                data={[...data]}
                title="Liste Avoirs "
                components={{
                    Toolbar: props => (
                        <div>
                            <MTableToolbar {...props} />
                            <div className="d-flex justify-content-around">

                                <h5>{'Sélectionné(s) : ' + nbSelection}</h5>
                                <h5>{'Total Sélectionné : ' + totalSelection}</h5>
                            </div>
                        </div>
                    ),
                }}
            />
        </div>

    );
}

export default AvoirTable;
