import React, { useState, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import MaterialTable from 'material-table';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';

const PieChartFournisseur = ({ data }) => {
    const theme = createMuiTheme({
        palette: {
            primary: blue,
        },
        typography: {
            fontFamily: 'Arial, sans-serif',
        },
    });

    const columns = [
        { title: 'Raison Sociale', field: 'raison_soc' },
        { title: 'Total HT', field: 'totalHT', type: 'numeric' },
    ];

    const cellStyle = {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    };

    const [pieChartData, setPieChartData] = useState(null);

    useEffect(() => {
        if (data) {
            const chartData = {
                labels: data.map(item => item.raison_soc),
                datasets: [
                    {
                        data: data.map(item => parseFloat(item.totalHT)),
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.7)',   // Rouge
                            'rgba(54, 162, 235, 0.7)',   // Bleu
                            'rgba(255, 206, 86, 0.7)',   // Jaune
                            'rgba(240, 10, 10, 0.7)',    // Rouge foncé
                            'rgba(120, 206, 10, 0.7)',   // Vert clair
                            'rgba(187, 88, 210, 0.7)',   // Violet clair
                            'rgba(50, 168, 82, 0.7)',    // Vert foncé
                            'rgba(255, 159, 64, 0.7)',   // Orange
                            'rgba(75, 192, 192, 0.7)',   // Turquoise
                            'rgba(0, 123, 255, 0.7)',    // Bleu foncé
                            'rgba(255, 0, 0, 0.7)',      // Rouge vif
                            'rgba(255, 192, 203, 0.7)',  // Rose
                            'rgba(0, 255, 0, 0.7)',      // Vert vif
                            'rgba(0, 0, 255, 0.7)',      // Bleu vif
                            'rgba(128, 0, 128, 0.7)',    // Pourpre
                            'rgba(255, 255, 0, 0.7)',    // Jaune vif
                            'rgba(0, 255, 255, 0.7)',    // Cyan
                            'rgba(255, 165, 0, 0.7)',    // Orange foncé
                            'rgba(139, 69, 19, 0.7)',    // Brun
                            'rgba(0, 128, 0, 0.7)',      // Vert foncé
                            // Ajoutez des couleurs supplémentaires au besoin
                        ],
                    },
                ],
            };

            setPieChartData(chartData);
        }
    }, [data]);

    return (
        <MuiThemeProvider theme={theme}>
            <div className='row'>
                <div className="col-9">
                    <MaterialTable
                        title="Ventes"
                        columns={columns.map(col => ({ ...col, cellStyle }))}
                        data={data}
                        options={{
                            pageSize: 10,
                            pageSizeOptions: [5, 10, 20],
                            sorting: true,
                            exportButton: true,
                            exportAllData: true,
                            padding: 'dense',
                        }}
                    />
                </div>
                <div className="col-3">
                    {pieChartData && (
                        <div className="d-flex justify-content-center align-items-center">
                            <Doughnut data={pieChartData} legend={{ display: true }} width={150} height={150} />
                        </div>
                    )}
                </div>
            </div>
        </MuiThemeProvider>
    );

};

export default PieChartFournisseur;
