import { GET_LISTE_CONFIE_PIERRE, GET_LISTE_COULEUR_PIERRE, GET_LISTE_ETAT_PIERRE, GET_LISTE_FORME_PIERRE, GET_LISTE_PIERRE, GET_LISTE_QUALITE_PIERRE, GET_LISTE_RETOUR_PIERRE, GET_LISTE_TYPE_PIERRE } from '../actions/action-types';

const initialState = {
    listePierre: [],
    listeConfiePierre: [],
    listeTypePierre: [],
    listeFormePierre: [],
    listeCouleurPierre: [],
    listeQualitePierre: [],
    listeEtatPierre: [],
    listeRetourPierre: [],
}


export default function CoursReducer(state = initialState, action) {
    switch (action.type) {

        case GET_LISTE_PIERRE:
            return {
                ...state,
                listePierre: action.payload
            }
        case GET_LISTE_CONFIE_PIERRE:
            return {
                ...state,
                listeConfiePierre: action.payload
            }
        case GET_LISTE_TYPE_PIERRE:
            return {
                ...state,
                listeTypePierre: action.payload
            }
        case GET_LISTE_FORME_PIERRE:
            return {
                ...state,
                listeFormePierre: action.payload
            }
        case GET_LISTE_ETAT_PIERRE:
            return {
                ...state,
                listeEtatPierre: action.payload
            }
        case GET_LISTE_COULEUR_PIERRE:
            return {
                ...state,
                listeCouleurPierre: action.payload
            }
        case GET_LISTE_QUALITE_PIERRE:
            return {
                ...state,
                listeQualitePierre: action.payload
            }
        case GET_LISTE_RETOUR_PIERRE:
            return {
                ...state,
                listeRetourPierre: action.payload
            }

        default:
            return state;

    }

}
