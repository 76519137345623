import React, { useEffect, useState } from 'react';
import { Grid, TextField, Card, CardContent, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { connect } from 'react-redux';
import { getListeBoiteOr } from '../../../actions';
import { getVendrediSemaineEnCours } from '../../../helpers/functions';
import TableBoiteOr from './TableBoiteOr';

const BoiteOr = ({ ouvriers, listeBoiteOr, getListeBoiteOr }) => {

    useEffect(() => {
        getListeBoiteOr()
    }, [getListeBoiteOr]);


    const calculTotal = (data) => {
        const totalPoids = data.reduce((total, item) => {
            return total + (item.done_ouvr - item.rendu_ouvr);
        }, 0);
        return totalPoids.toFixed(2)
    }


    const [joaillierSelected, setJoaillierSelected] = useState(null);
    const [filteredData, setFilteredData] = useState(listeBoiteOr);
    const [totalPoids, setTotalPoids] = useState(calculTotal(listeBoiteOr));
    const [dateDebut, setDateDebut] = useState('');
    const [dateFin, setDateFin] = useState(getVendrediSemaineEnCours());

    useEffect(() => {
        setFilteredData(listeBoiteOr)
        setTotalPoids(calculTotal(listeBoiteOr))
    }, [listeBoiteOr]);


    const handleJoaillierChange = (event, newValue) => {
        setJoaillierSelected(newValue);
        filterData(newValue, dateDebut, dateFin);
    };

    const handleDateDebutChange = (event) => {
        const value = event.target.value;
        setDateDebut(value);
        filterData(joaillierSelected, value, dateFin);
    };

    const handleDateFinChange = (event) => {
        const value = event.target.value;
        setDateFin(value);
        filterData(joaillierSelected, dateDebut, value);
    };
    const filterData = (selectedJoaillier, debut, fin) => {
        let filteredData = [...listeBoiteOr];

        if (selectedJoaillier) {
            filteredData = filteredData.filter(item => item.id_ouvr === selectedJoaillier.id);
        }
        if (debut !== "" && fin === "") {
            filteredData = filteredData.filter(item => item.date >= debut);
        } else if (debut === "" && fin !== "") {
            filteredData = filteredData.filter(item => item.date <= fin);
        } else if (debut !== "" && fin !== "") {
            filteredData = filteredData.filter(item => item.date >= debut && item.date <= fin);
        }

        setFilteredData(filteredData);
        setTotalPoids(calculTotal(filteredData))

    };
    const getOptionSelected = (option, value) => option && value && option.id === value.id;


    return (
        <>
            <Typography variant="h6" align='center'>Détail Boite Or / Solde : {totalPoids} Gr</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent >
                            <Typography variant="h6" align='center'>Filtres</Typography>

                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Autocomplete
                                        options={ouvriers.filter(r => r.actif === 1)}
                                        getOptionLabel={(option) => option.label}
                                        value={joaillierSelected}
                                        onChange={handleJoaillierChange}
                                        autoHighlight
                                        autoComplete
                                        clearOnEscape
                                        getOptionSelected={getOptionSelected}
                                        renderInput={(params) => <TextField {...params} label="Joaillier" margin="dense" variant="outlined" fullWidth />}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        id="date-debut"
                                        label="Date Début"
                                        type="date"
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                        value={dateDebut}
                                        onChange={handleDateDebutChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        id="date-fin"
                                        label="Date Fin"
                                        type="date"
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                        value={dateFin}
                                        onChange={handleDateFinChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <TableBoiteOr data={filteredData} />

                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    );

}

const mapStateToProps = (state) => {
    return {
        ouvriers: state.generalData.listeJoaillier,
        listeBoiteOr: state.generalData.listeBoiteOr,
    }
}
const mapDispatchToProps = {
    getListeBoiteOr,
};
export default connect(mapStateToProps, mapDispatchToProps)(BoiteOr);
