import React, { useState, useEffect } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { euroFormat } from '../../helpers/functions';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

const ChartCaAnneeClient = ({ data }) => {
    const [chartData, setChartData] = useState({ labels: [], datasets: [] });
    const [chartType, setChartType] = useState('bar');

    const genererLabel = (type = "all") => {
        let meilleureAnnee = "";
        let totalMax = 0;
        let totalSomme = 0;
        let nombreDonnees = 0;
        let sommeCarreEcarts = 0;

        // Année en cours
        const anneeEnCours = new Date().getFullYear().toString();

        // Parcours des données pour trouver la meilleure année et calculer la somme totale
        data.forEach(entree => {
            const annee = entree.annee;
            const total = parseInt(entree.totalHT);

            // Exclure l'année en cours et "2008" des calculs
            if (annee !== anneeEnCours && annee !== "2008") {
                if (total > totalMax) {
                    totalMax = total;
                    meilleureAnnee = annee;
                }
                totalSomme += total;
                nombreDonnees++;
            }
        });

        // Calcul de la moyenne
        const moyenne = totalSomme / nombreDonnees;

        // Calcul de l'écart type
        data.forEach(entree => {
            const annee = entree.annee;
            const total = parseInt(entree.totalHT);

            // Exclure l'année en cours et "2008" des calculs
            if (annee !== anneeEnCours && annee !== "2008") {
                sommeCarreEcarts += Math.pow(total - moyenne, 2);
            }
        });
        const ecartType = Math.sqrt(sommeCarreEcarts / nombreDonnees);

        if (type === "all") return `Meilleure année ${meilleureAnnee} : ${euroFormat(totalMax)} | Moyenne : ${euroFormat(moyenne)} | Écart type : ${euroFormat(ecartType)}`;
        else if (type === "moyenne") return `Moyenne : ${euroFormat(moyenne)} | Écart type : ${euroFormat(ecartType)}`;
        else if (type === "bestYear") return `Meilleure année ${meilleureAnnee}: ${euroFormat(totalMax)}`;
        else return "";
    }

    useEffect(() => {
        if (data) {
            const uniqueYears = Array.from(new Set(data.map(item => item.annee)));
            const updatedChartData = {
                labels: uniqueYears.map(year => year.toString()),
                datasets: [
                    {
                        label: "Chiffre d'Affaires",
                        backgroundColor: "rgba(75,192,192,0.4)",
                        borderColor: "rgba(75,192,192,1)",
                        borderWidth: 1,
                        hoverBackgroundColor: "rgba(75,192,192,0.6)",
                        hoverBorderColor: "rgba(75,192,192,1)",
                        data: uniqueYears.map(year => {
                            const caForYear = data
                                .filter(item => item.annee === year)
                                .reduce((acc, item) => acc + parseFloat(item.totalHT), 0);
                            return caForYear;
                        }),
                    }
                ]
            };

            setChartData(updatedChartData);
        }
    }, [data]);

    const handleChangeChartType = (type) => {
        setChartType(type); // Mettre à jour le type de graphique sélectionné
    };

    const options = {
        scales: {
            xAxes: [{
                scaleLabel: {
                    display: true,
                    labelString: "Année"
                },
                stacked: true,
            }],
            yAxes: [{
                scaleLabel: {
                    display: true,
                    labelString: "Chiffre d'Affaires HT"
                },
                ticks: {
                    beginAtZero: true,
                    callback: function (value, index, values) {
                        return euroFormat(value); // Formatage en euros
                    }
                },
                stacked: true,
            }]
        },
        title: {
            display: true,
            text: genererLabel("moyenne")
        },
        legend: {
            display: false,
        },
        tooltips: {
            callbacks: {
                label: function (tooltipItem, data) {
                    let label = data.datasets[tooltipItem.datasetIndex].label || '';
                    if (label) {
                        label += ': ';
                    }
                    label += euroFormat(Math.round(tooltipItem.yLabel * 100) / 100);
                    return label;
                },
                afterBody: function (tooltipItem, data) {
                    const total = data.datasets[0].data[tooltipItem[0].index];
                    return 'Total: ' + euroFormat(total);
                }
            }
        }
    };

    return (
        <div style={{ textAlign: 'center' }}> {/* Centrer le groupe de boutons */}
            <h1>{data[0].Nom_agence}</h1>
            <h3>{genererLabel("bestYear")}</h3>
            <ButtonGroup variant="outlined" aria-label="Basic button group">
                <Button onClick={() => handleChangeChartType('bar')}>Barres</Button>
                <Button onClick={() => handleChangeChartType('line')}>Lignes</Button>
            </ButtonGroup>
            <div style={{ marginTop: '10px' }}>
                {chartType === 'bar' ? (
                    <Bar data={chartData} options={options} height={80} />
                ) : (
                    <Line data={chartData} options={options} height={80} />
                )}
            </div>
        </div>
    );
};

export default ChartCaAnneeClient;
