import React, { useState } from 'react'
import * as actions from '../../actions'
import { connect } from 'react-redux'
import { TextField } from '@material-ui/core'

const ClientForm = ({ client }) => {
    const [nom, setNom] = useState('')
    return (
        <form>
            <div className="form-group">
                <TextField value={nom} onChange={e => setNom(e.target.value)} placeholder="Nom" variant="outlined" margin="dense" />
            </div>
            <div className="form-group">
                <TextField value={nom} onChange={e => setNom(e.target.value)} placeholder="Adresse" variant="outlined" margin="dense" />
            </div>
            <div className="form-group">
                <TextField value={nom} onChange={e => setNom(e.target.value)} placeholder="CP" variant="outlined" margin="dense" />
                <TextField value={nom} onChange={e => setNom(e.target.value)} placeholder="Ville" variant="outlined" margin="dense" />
            </div>
        </form>
    )
}

const mapStateToProps = (state) => {
    return {

    }
}
export default connect(mapStateToProps, actions)(ClientForm)