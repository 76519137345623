import React, { Component } from "react";
import MaterialTable from 'material-table'
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import { connect } from "react-redux";
import { getCours } from '../actions'
import { localization, meekroDB } from '../config'
class Cours extends Component {
    state = {
        id: null,
        description: "",
        valeur: "",
    }
    componentDidMount() {
        this.props.getCours()
    }
    reset = () => {
        this.setState({ id: null, description: '', valeur: '' })
    }
    handleClick = (event, rowData) => {

        const { description, valeur } = rowData
        this.setState({ id: rowData.id_cours, description, valeur })
    }
    handleSave = () => {
        const { state } = this
        const typeAxios = state.id !== null ? 'put' : 'post'
        const data = {
            id: state.id,
            description: state.description,
            valeur: state.valeur,
            primaryKey: 'id_cours',
            table: 'cours'
        }
        axios[typeAxios](`${meekroDB}/general.php`, data)
            .then((response) => {
                this.props.getCours()
            })
            .catch(error => {
                console.log(error);
            });

    }
    handleRemove = () => {
        const datat = {
            id: this.state.id,
            primaryKey: 'id_cours',
            table: 'cours'
        }
        axios.delete(`${meekroDB}/general.php`, { params: datat })
            .then((response) => {
                //console.log(response);
                this.props.getCours()
            })
            .catch(error => {
                console.log(error);
            });
    }
    render() {
        return (
            <div className="container-fluid" >
                <div className="row">
                    <div className="col-sm" >
                        <MaterialTable

                            style={{ fontSize: 12 }}
                            columns={[
                                { title: 'Id', field: 'id_cours' },
                                { title: 'Nom', field: 'description' },
                                { title: 'Valeur', field: 'valeur' },
                            ]}
                            options={{
                                rowStyle: rowData => ({
                                    backgroundColor: (this.state.id === rowData.id_cours) ? '#AED1FF' : ''
                                })
                            }}
                            data={[...this.props.liste]}
                            title={<div style={{ width: 170, fontSize: 16, display: "flex" }}>Cours</div>}
                            onRowClick={this.handleClick}


                            localization={localization}
                        />
                    </div>
                    <div className="col-sm">
                        <form >
                            <div className="form-group">
                                <TextField fullWidth required label="Nom" variant="outlined"
                                    value={this.state.description}
                                    onChange={(e) => this.setState({ description: e.target.value })}
                                />
                            </div>
                            <div className="form-group">
                                <TextField fullWidth required label="Valeur" variant="outlined"
                                    value={this.state.valeur}
                                    onChange={(e) => this.setState({ valeur: e.target.value })}
                                />
                            </div>
                            <div className="form-group">
                                {this.state.id &&
                                    <button type="button" className="btn btn-link btn-sm" onClick={this.reset}>Ajouter</button>
                                }
                                {this.state.description !== '' && this.state.valeur !== '' &&
                                    <button type="button" className="btn btn-link btn-sm" onClick={this.handleSave}>Enregistrer</button>
                                }
                                {this.state.id &&
                                    <button type="button" className="btn btn-link btn-sm" onClick={this.handleRemove}>Supprimer</button>
                                }

                            </div>

                        </form>
                    </div>
                </div>
            </div>

        );
    }
}
const mapStateToProps = ({ cours }) => {
    return {
        liste: cours.listeCours
    }
}
const mapDispatchToProps = {
    getCours
}
export default connect(mapStateToProps, mapDispatchToProps)(Cours)