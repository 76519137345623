import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { Box, Card, TextField, Typography, Toolbar } from '@material-ui/core';
import { localization } from '../../../config';

const TableStock = ({ data, onRowClick, filtreProduit }) => {
    const [filteredData, setFilteredData] = useState(data || []);
    const [filter, setFilter] = useState('');
    const [clickedOf, setClickedOf] = useState(null);

    // useEffect(() => {
    //     setFilteredData(
    //         data.filter((row) => filter === '' || row.of.includes(filter))
    //     );
    // }, [filter, data]);

    useEffect(() => {
        const filteredRows = data.filter((row) => filtreProduit && (row.idp === filtreProduit.idp && row.type_metal === filtreProduit.type_metal))
        setFilteredData(filteredRows);
        if (filteredRows.length === 1 && filteredRows[0]['client_reserve'] === null) {
            setClickedOf(filteredRows[0]?.of || null);
            onRowClick(filteredRows[0] || null);
        } else {
            setClickedOf(null);
            onRowClick(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filtreProduit, data]);

    const handleRowClick = (event, rowData) => {
        if (rowData.of === clickedOf) {
            setClickedOf(null);
            onRowClick(null);
        } else {
            onRowClick(rowData);
            setClickedOf(rowData.of);
        }
    };

    return (
        <Card>
            <Box display="flex" justifyContent="center" mb={2} mt={2}>
                <TextField
                    label="N° OF"
                    variant="outlined"
                    value={filter}
                    size='small'
                    onChange={(e) => setFilter(e.target.value)}
                />
            </Box>
            <MaterialTable
                columns={[
                    { title: 'OF', field: 'of', width: 'auto', type: 'numeric' },
                    { title: 'Métal', field: 'metal', width: 'auto' },
                    { title: 'Ref', field: 'ref', width: 'auto', type: 'numeric', align: 'center' },
                    {
                        title: 'Designation',
                        field: 'designation',
                        width: 'auto',
                        render: rowData => rowData.designation.length > 25
                            ? `${rowData.designation.substring(0, 25)}...`
                            : rowData.designation
                    },
                    {
                        title: 'Lieu', field: 'client', width: 'auto',
                        render: rowData => rowData.client.length > 20
                            ? `${rowData.client.substring(0, 20)}...`
                            : rowData.client
                    },
                    {
                        title: 'Reservation',
                        field: 'client_reserve',
                        width: 'auto',
                        render: rowData => {
                            if (!rowData.client_reserve) {
                                return ''; // ou tout autre texte par défaut si nécessaire
                            }
                            return rowData.client_reserve.length > 20
                                ? `${rowData.client_reserve.substring(0, 20)}...`
                                : rowData.client_reserve;
                        }
                    }

                ]}
                data={filteredData}
                onRowClick={handleRowClick} // Utilisation de la nouvelle fonction handleRowClick
                options={{
                    maxBodyHeight: 150,
                    minBodyHeight: 150,
                    pageSize: 12,
                    overflowY: 'hidden',
                    pageSizeOptions: [12, 15, 30],
                    search: false,
                    paging: false,
                    padding: 'dense',
                    // rowStyle: rowData => ({

                    //     backgroundColor: rowData.of === clickedOf && (filtreProduit && Number(filtreProduit.of_reservation) !== 0) ? 'rgba(41, 237, 81, 0.5)' :
                    //         !clickedOf && (filtreProduit && Number(filtreProduit.of_reservation) !== 0) ? 'rgba(41, 237, 81, 1)' :
                    //             clickedOf && (filtreProduit && filtreProduit.client_reserve) ? 'rgba(53, 154, 255,1)' :
                    //                 !clickedOf && (filtreProduit && filtreProduit.client_reserve) ? 'rgba(53, 154, 255,0.55)' :
                    //                     rowData.of === clickedOf ? 'rgba(53, 154, 255,1)' : 'inherit'
                    // })
                    rowStyle: rowData => ({

                        backgroundColor: rowData.of === clickedOf ? 'rgba(53, 154, 255,1)' : 'inherit'
                    })
                }}
                title={""}
                components={{
                    Toolbar: props => (
                        <Toolbar>
                            <div style={{ flex: 1, textAlign: 'center' }}>
                                <Typography variant="h6">Produit Identique en Stock</Typography>
                            </div>
                        </Toolbar>
                    )
                }}
                localization={localization}
                style={{ height: '100%' }}
            />
        </Card>
    );
};

export default TableStock;
