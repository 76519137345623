import React, { useEffect, useState } from 'react'
import { setAuthentification, setLogin } from '../actions'
import { connect } from 'react-redux'
import { Button, TextField } from '@material-ui/core'

const Login = ({ setAuthentification, isLoggedIn, history, setLogin, user }) => {
    const [userName, setUserName] = useState('')
    const [password, setPassword] = useState('')
    const [err, setErr] = useState(false)

    useEffect(() => {
        const userString = localStorage.getItem('user');
        if (userString) {
            try {
                const userArray = JSON.parse(userString);
                const user = userArray[0];

                if (user && user.Num_agence && user.id_user) {
                    setAuthentification(true);
                    history.push("/produit");
                } else {
                    // Supprimer l'utilisateur du localStorage et recharger la page
                    localStorage.removeItem('user');
                    window.location.reload(true);
                }
            } catch (e) {
                console.error('Erreur de parsing JSON:', e);
                localStorage.removeItem('user');
                window.location.reload(true);
            }
        }
    }, [history, setAuthentification]);
    const login = e => {
        e.preventDefault()
        setErr(false)
        setLogin(userName, password).then((response) => {
            if (response) {
                setAuthentification(true)
                history.push("/produit");

            }
            else {
                setErr(true)
            }
        })
    }
    return (
        <div className="container d-flex justify-content-center align-items-center">
            < div className="row justify-content-center" >
                <div className="col-md-4">
                    <div className="card">
                        <div className="card-body">
                            <h3 className="card-title text-center">Connexion</h3>
                            <form onSubmit={login} className="text-center">
                                <div className="mb-4">
                                    <TextField
                                        id="username"
                                        label="Nom d'utilisateur"
                                        variant="outlined"
                                        fullWidth
                                        value={userName}
                                        onChange={e => {
                                            setUserName(e.target.value)
                                            setErr(false)
                                        }}
                                    />
                                </div>
                                <div className="mb-4">
                                    <TextField
                                        id="password"
                                        label="Mot de passe"
                                        type="password"
                                        variant="outlined"
                                        fullWidth
                                        value={password}
                                        onChange={e => {
                                            setPassword(e.target.value)
                                            setErr(false)
                                        }}
                                    />
                                </div>
                                {(user === -1 && err) && <div className="mb-4"><small className="text-danger">Nom d'utilisateur ou mot de passe invalide</small></div>}
                                <div>
                                    <Button variant="contained" color="primary" type="submit" >Connexion</Button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div >
        </div >


    )
}

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.authentification.isLoggedIn,
        user: state.authentification.user
    }
}
const mapDispatchToProps = {
    setAuthentification,
    setLogin
}
export default connect(mapStateToProps, mapDispatchToProps)(Login);