import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux'
import { getCours } from '../actions'

import Image from 'react-bootstrap/Image'
import { getValueFromId, createPrixPierre, createPrixCoefPierre, createDescPierre } from '../helpers/functions'
import { imagesRepo } from '../config';


const useStyles = makeStyles({
    table: {
        minWidth: 650,
        marginTop: 5,
        fontSize: 14,
        padding: '3px !important',

    },
});
const headerSize = 16
let marge = '', colorMarge = ''
const createTableCell = (produit, listeCours) => {
    if (produit.produit.length !== 0) {
        const prod = produit.produit[0]
        const { centre, pierre, serti, ouvrier } = produit

        if (produit.ouvrier.length === 0) ouvrier.push({ temps_total: 0 })

        const cours = listeCours ? listeCours : []
        const prixSerti = serti.length !== 0 ? serti[0].prix_total : 0
        // console.log(produit);
        let revientEntourage = 0, prixEntourage = 0, revientcentre = 0, prixCentre = 0, prixRevientPierre = 0, prixPierres = 0
        // const totalMfs = Math.ceil(prod.plus_mo * getValueFromId('2', cours)) +
        //     Math.ceil(prod.plus_fonte) +
        //     Math.ceil(prod.plus_poids / 1000 * getValueFromId('3', cours)) +
        //     Math.ceil(prod.plus_serti) + Math.ceil(prod.prix_divers)
        const totalMfsReel = Math.ceil(ouvrier[0].temps_total * getValueFromId('2', cours)) +
            Math.ceil(prod.plus_fonte) +
            Math.ceil(prod.poids / 1000 * getValueFromId('3', cours)) +
            Math.ceil(prixSerti) + Math.ceil(prod.prix_divers)

        const tab = [
            { id: 1, qte: prod.plus_mo + ' / ' + ouvrier[0].temps_total, description: 'MO', prixR: '', coef: "", prix: Math.ceil(prod.plus_mo * getValueFromId('2', cours)) + ' / ' + Math.ceil(ouvrier[0].temps_total * getValueFromId('2', cours)) },
            { id: 2, qte: prod.plus_fonte, description: 'Fonte', prixR: '', coef: "", prix: Math.ceil(prod.plus_fonte) },
            { id: 3, qte: prod.plus_poids + ' / ' + prod.poids, description: 'Poids', prixR: '', coef: "", prix: Math.ceil(prod.plus_poids / 1000 * getValueFromId('3', cours)) + ' / ' + Math.ceil(prod.poids / 1000 * getValueFromId('3', cours)) },
            { id: 4, qte: prod.plus_serti + ' / ' + prixSerti, description: 'Serti', prixR: '', coef: "", prix: Math.ceil(prod.plus_serti) + ' / ' + Math.ceil(prixSerti) },
            { id: 5, qte: prod.prix_divers, description: 'Divers', prixR: '', coef: "", prix: Math.ceil(prod.prix_divers) },
            // { id: 6, qte: 'MFS', description: '', prixR: '', coef: '', prix: '' + totalMfs + ' / ' + totalMfsReel, color: totalMfs >= totalMfsReel ? '#31b254' : 'red', size: headerSize },
            { id: 6, qte: 'MFS', description: '', prixR: '', coef: '', prix: prod.revient_mfs + ' / ' + totalMfsReel, color: prod.revient_mfs >= totalMfsReel ? '#31b254' : 'red', size: headerSize },
            { id: 85, qte: '', description: '', prixR: '', coef: '', prix: '' },

        ]

        centre.forEach(pierre => {
            //console.log(prod);
            let final_desc = createDescPierre(pierre, pierre.pds_r);
            let prix_r = createPrixPierre(pierre, pierre.pds_r, getValueFromId('1', cours));
            let prix_v = createPrixCoefPierre(pierre, pierre.pds_r, getValueFromId('1', cours));
            // console.log(pierre.pds_r);
            if (pierre.centre === '1') {
                revientcentre += Math.ceil(prix_r)
                prixCentre += Math.ceil(prix_v)
                tab.push({ id: final_desc, qte: pierre.qte, description: final_desc, prixR: prix_r, coef: pierre.coeff, prix: prix_v, centre: pierre.centre })

            } else {
                revientEntourage += Math.ceil(prix_r)
                prixEntourage += Math.ceil(prix_v)
                tab.push({ id: final_desc, qte: pierre.qte, description: final_desc, prixR: prix_r, coef: pierre.coeff, prix: prix_v, centre: pierre.centre })

            }
        })
        tab.push({ id: -1, qte: '', description: '', prixR: '', coef: '', prix: '' })
        pierre.forEach(pierre => {
            let final_desc = createDescPierre(pierre, pierre.poids_sortant);
            let prix_r = createPrixPierre(pierre, pierre.poids_sortant, pierre.cours_eur_doll);
            let prix_v = createPrixCoefPierre(pierre, pierre.poids_sortant, pierre.cours_eur_doll);
            prixRevientPierre += Math.ceil(prix_r);
            prixPierres += Math.ceil(prix_v)
            tab.push({ id: pierre.id_stock, qte: 'Alimentées id:' + pierre.id_stock + ' -' + pierre.raison_soc, description: final_desc, prixR: prix_r, coef: pierre.coeff, prix: prix_v, centre: pierre.centre, color: 'blue' })
        })


        //let totalR = Math.ceil(revientEntourage + revientcentre + totalMfs)
        let totalRReel = Math.ceil(prixRevientPierre + totalMfsReel)

        //let totalV = Math.ceil(prixEntourage + prixCentre + totalMfs * 1.59)
        //let prix_calc = prixParType(prod.id_tarif_produit, totalR, totalV)
        let colorPiece = '', colorCv = '', colorMfs = ''
        if (prod.id_stade === '2' || prod.id_stade === '4' || prod.id_stade === '8' || prod.id_stade === '11') {
            colorPiece = '#007bff'
            colorCv = ''
            colorMfs = ''
        }
        else if (prod.id_stade === '3' || prod.id_stade === '7' || prod.id_stade === '9') {
            colorPiece = ''
            colorCv = '#007bff'
            colorMfs = ''
        } else if (prod.id_stade === '1' || prod.id_stade === '6' || prod.id_stade === '10') {
            colorPiece = ''
            colorCv = ''
            colorMfs = '#007bff'
        }
        tab.push(
            { id: 9, qte: 'TOTAL PIERRES', description: '', prixR: Math.ceil(revientEntourage + revientcentre) + ' / ' + prixRevientPierre, coef: '', prix: Math.ceil(prixEntourage + prixCentre) + ' / ' + prixPierres, color: Math.ceil(prixEntourage + prixCentre) >= prixPierres ? '#31b254   ' : 'red', size: headerSize },
            // { id: 11, qte: 'MFS + PIERRES', description: '', prixR: totalR, coef: '', prix: totalV },
            { id: 11, qte: '', description: '', prixR: '', coef: '', prix: '' },
            { id: 14, qte: 'PRIX PIECE', description: '', prixR: prod.revient_piece, coef: '', prix: prod.total3, color: colorPiece },
            { id: 15, qte: 'PRIX CV', description: '', prixR: prod.revient_cv, coef: '', prix: prod.totalcv, color: colorCv },
            { id: 16, qte: 'PRIX MFS', description: '', prixR: prod.revient_mfs, coef: '', prix: prod.mfs, color: colorMfs },
        )
        marge = 'Théorique (' + Math.ceil(prod.total3 - prod.revient_piece) + ' : ' + Math.round((prod.total3 - prod.revient_piece) * 100 / prod.total3) + '%)   -    Réel (' + Math.ceil(prod.total3 - totalRReel) + ' : ' + Math.round((prod.total3 - totalRReel) * 100 / prod.total3) + '%)'
        colorMarge = (prod.total3 - prod.revient_piece) <= totalRReel ? '#31b254   ' : 'red'
        return tab
    }
}
const FicheProduitOf = ({ produit, cours, getCours }) => {
    useEffect(() => {
        getCours()
    }, [getCours]);
    const classes = useStyles();
    const prod = produit.produit[0]
    //console.log(prod);
    return (
        <>
            {produit.produit.length !== 0 &&
                <div className="d-flex justify-content-center text-danger">
                    {prod.Nom_agence}
                </div>}
            {produit.produit.length !== 0 &&
                <div className="d-flex justify-content-center">
                    <div>
                        {/* {prod.img !== '' && <Image
                            src={imagesRepo + prod.img}
                            alt="Icône de maison sélectionnée"
                            style={{ height: '70px', marginRight: '10px' }}
                        />} */}
                        {prod.img !== '' && <Image style={{ width: 70, height: 70, marginLeft: -15, marginTop: -15 }} src={`${imagesRepo}thumbs.php?src=${prod.img}&size=150`} />}
                    </div>
                    <span>
                        {prod.leType + ' ' + prod.ref_cv + ' ' + prod.description}
                        <br />
                        {'Type Tarif : ' + prod.descTarif}
                    </span>
                    <span>

                    </span>



                </div>
            }
            {produit.produit.length !== 0 && <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>

                            <TableCell align="center">Qte</TableCell>
                            <TableCell colSpan={3} align="center">Description</TableCell>
                            <TableCell align="center">Revient</TableCell>
                            <TableCell align="center">Coef</TableCell>
                            <TableCell colSpan={3} align="right">Prix</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {createTableCell(produit, cours).map((row, index) => (
                            <TableRow key={index} >
                                <TableCell align="center" component="th" scope="row" style={{ color: row.color, padding: 3, maxWidth: 20 }}>
                                    {row.qte}
                                </TableCell>
                                <TableCell align="center" colSpan={3} style={{ color: row.color, padding: 3 }}>{row.description}</TableCell>
                                <TableCell align="center" style={{ color: row.color, padding: 3 }}>{row.prixR}</TableCell>
                                <TableCell align="center" style={{ color: row.color, padding: 3 }}>{row.coef}</TableCell>
                                <TableCell align="right" colSpan={3} style={{ color: row.color, padding: 3, fontSize: row.seize }}>{row.prix}</TableCell>
                            </TableRow>

                        ))}
                        <TableRow >
                            <TableCell style={{ color: colorMarge, fontSize: 16 }} align="center">MARGE</TableCell>
                            <TableCell style={{ color: colorMarge, fontSize: 16 }} colSpan={4} align="right">{marge}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>}
        </>
    );
}
const mapStateToProps = ({ cours }) => {
    return {
        cours: cours.listeCours
    }
}
const mapDispatchToProps = {
    getCours
}
export default connect(mapStateToProps, mapDispatchToProps)(FicheProduitOf)
