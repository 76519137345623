import React, { useState } from 'react';
import { Grid } from '@material-ui/core';

import ListeProduits from './ListeProduits';
import ListeImages from './ListeImages';
import FormulaireAssociation from './FormulaireAssociation';
import { meekroDB } from '../../config';
import Axios from 'axios';

const AffectationImage = () => {
    const [filtre, setFiltre] = useState('');
    const [selectedProduits, setSelectedProduits] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [isDefaultImage, setIsDefaultImage] = useState(true);

    const handleFiltreChange = (reference) => {
        setFiltre(reference);
    };

    const handleProduitsSelect = (produits) => {
        setSelectedProduits(produits);
    };

    const handleImageSelect = (image) => {
        setSelectedImage(image);
    };

    const handleSave = (unLink = false) => {
        // Logique à exécuter lorsqu'on clique sur "Enregistrer"
        if (selectedProduits && selectedProduits.length > 0) {
            const idCentres = selectedProduits.map(produit => produit.id_centre);
            const idCentresString = idCentres.join(',');
            const data = {
                table: 'centre',
                primaryKey: 'id_centre',
                id: idCentresString,
                img_centre: unLink ? "no_pic.jpg" : selectedImage,
            }
            if (isDefaultImage) {
                const dataCv = {
                    table: 'centre_vide',
                    primaryKey: 'id_cv',
                    id: selectedProduits[0]['id_cv'],
                    image: unLink ? "no_pic.jpg" : selectedImage,
                }
                Axios.put(`${meekroDB}/general.php`, dataCv)
                    .catch(error => {
                        console.log(error);
                    });

            }
            Axios.put(`${meekroDB}/general.php`, data)
                .then(() => {
                    setSelectedProduits([])
                    setSelectedImage(null)
                    const ref = filtre
                    setFiltre('')
                    setFiltre(ref)
                })
                .catch(error => {
                    console.log(error);
                });
        }
    };

    const handleDefaultImageChange = (isChecked) => {
        setIsDefaultImage(isChecked);
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={4}>
                <ListeProduits filtre={filtre} onFiltreChange={handleFiltreChange} onProduitsSelect={handleProduitsSelect} setSelectedProduits={setSelectedProduits} selectedProduits={selectedProduits} />
            </Grid>
            <Grid item xs={4}>
                <ListeImages filtre={filtre} onFiltreChange={handleFiltreChange} onImageSelect={handleImageSelect} setSelectedImage={setSelectedImage} selectedImage={selectedImage} />
            </Grid>
            <Grid item xs={4}>
                <FormulaireAssociation
                    selectedProduits={selectedProduits}
                    selectedImage={selectedImage}
                    onSave={handleSave}
                    isDefaultImage={isDefaultImage}
                    onDefaultImageChange={handleDefaultImageChange} // Passer la fonction de rappel
                />
            </Grid>
        </Grid>
    );
};

export default AffectationImage;
