import React from 'react';
import RetourJoaillier from './retour/RetourJoaillier';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import EnvoiJoaillier from './envoi/EnvoiJoaillier';

const MouvementJoaillier = () => {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', height: '100vh', padding: '10px' }}>
            <div style={{ marginRight: '10px', paddingRight: '10px', width: '50%' }}>
                <Card>
                    <CardContent>
                        <EnvoiJoaillier autoFocus />
                    </CardContent>
                </Card>
            </div>
            <div style={{ marginLeft: '10px', paddingLeft: '10px', width: '50%' }}>
                <Card>
                    <CardContent>
                        <RetourJoaillier />
                    </CardContent>
                </Card>
            </div>
        </div>
    );
};

export default MouvementJoaillier;
