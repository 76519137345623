import React, { Component } from 'react'
import { getListeTva } from '../../actions'
import { connect } from 'react-redux'
import { addDayToToday, dateToFrench, euroFormat } from '../../helpers/functions'
import { Button } from '@material-ui/core'
import { meekroDB } from '../../config'
import Axios from 'axios'
import FiltreCompta from './FiltresCompta'
import FactureTable from './FactureTable'
import AvoirTable from './AvoirTable'

class Comtpa extends Component {

    currentYear = new Date().getFullYear();
    state = {
        temp: '',
        facture: '',
        client: '',
        debut: `${this.currentYear}-01-01`,
        fin: addDayToToday(30),
        dataFacture: [],
        dataAvoir: [],
        totalFinal: 0,
        totalSelection: 0,
        nbSelection: 0,
        tva: null,
        soldeFinal: 0,

    }
    componentDidMount() {
        this.props.getListeTva()
    }
    getFilter = () => {
        let tva = ''
        if (this.state.tva) tva = this.state.tva.id_tva
        const data = {
            facture: this.state.facture,
            client: this.state.client,
            debut: this.state.debut,
            fin: this.state.fin,
            tva,
        }
        return data
    }

    handleChangeClient = (data) => {
        this.setState({ client: data })
    }
    onChange = (data, labelState) => {
        const label = labelState.toLowerCase()
        this.setState({ [label]: data })
    }

    refreshLists = () => {
        const dataF = this.getFilter()
        Axios.put(`${meekroDB}/compta/liste.php`, dataF)
            .then(response => {
                const { data } = response;
                const { facture, avoir } = data;
                const totalFacture = facture.reduce((acc, curr) => acc + parseFloat(curr.totalHt), 0);
                const totalAvoir = avoir.reduce((acc, curr) => acc + parseFloat(curr.tot_ht), 0);
                const soldeFinal = totalFacture - totalAvoir;
                this.setState({ dataFacture: facture, dataAvoir: avoir, soldeFinal });
            })
            .catch(error => {
                console.log(error);
            });
    }
    printFact = (rowData) => {
        let ids = '', sep = ''
        Object.values(rowData).forEach(fact => {
            ids += sep + fact.id_fact
            sep = ','
        })
        window.open(`https://www.extensia-france.com/printers/factPrinter2024.php?listeFact=${ids}`, "_blank")
    }

    exportFact = () => {
        const params = '?dated=' + this.state.debut
            + '&datef=' + this.state.fin
            + '&datedeb=' + dateToFrench(this.state.debut)
            + '&datefin=' + dateToFrench(this.state.fin)
        // console.log(params);
        window.open(`https://www.extensia-france.com/printers/export_quadra_facture.php${params}`, "_blank")

    }


    render() {

        return (
            <div className="d-flex flex-column align-items-center">
                <h3 >Solde Total HT : {euroFormat(this.state.soldeFinal)}</h3>
                <div className='row'>
                    <div className="col-md auto">
                        <div className="col-auto d-flex flex-column align-items-start">
                            <div className="row justify-content-start">
                                <Button style={{ color: '#007bff' }} className='btn btn-primary btn-lg' onClick={() => this.refreshLists()}>Afficher</Button>
                                <Button style={{ color: '#007bff' }} className='btn btn-primary btn-lg' onClick={this.exportFact}>Export Quadra</Button>
                            </div>

                        </div>
                        <FiltreCompta
                            debut={this.state.debut}
                            fin={this.state.fin}
                            tva={this.state.tva}
                            onChange={this.onChange}
                            handleChangeClient={this.handleChangeClient}
                            exportFact={this.exportFact}
                            refreshLists={this.refreshLists} />
                    </div>
                    <div className="col-md auto">
                        <FactureTable data={this.state.dataFacture} />
                    </div>
                    <div className="col-md auto">
                        <AvoirTable data={this.state.dataAvoir} />
                    </div>


                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        listeTva: state.generalData.listeTva,
    }
}
const mapDispatchToProps = {
    getListeTva
}
export default connect(mapStateToProps, mapDispatchToProps)(Comtpa)