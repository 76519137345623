export const getOfs = state => {
    return state.ofs.listeOf
}
export const getOfsToFact = state => {
    return getOfs(state).filter(ofs => ofs.statut === '575').sort((a, b) => a.id_bijouterie - b.id_bijouterie || a.typ_transp - b.typ_transp || b.echeance - a.echeance || b.prix_fact - a.prix_fact)
}
export const getOfsGroupByClientAndTransport = state => {
    return getOfsToFact(state).reduce((groupedData, r) => {
        let lab = r.descTypeTransp + ' ' + r.nom_ligne;
        if (!groupedData[r.id_bijouterie]) groupedData[r.id_bijouterie] = [];
        if (!groupedData[r.id_bijouterie][lab]) groupedData[r.id_bijouterie][lab] = [];
        groupedData[r.id_bijouterie][lab].push(r);
        return groupedData;
    }, {});
}