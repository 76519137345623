import React, { useState, useEffect } from 'react';
import { TextField, Button, Paper, Box } from '@material-ui/core';
import { TabPanel } from '@material-ui/lab';
import TabContext from '@material-ui/lab/TabContext';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    tabs: {
        marginBottom: theme.spacing(2),
    },
    tabPanel: {
        padding: theme.spacing(2),
    },
    tabContext: {
        maxWidth: 'fit-content',
        margin: '0 auto',
    },
    buttonGroup: {
        display: 'flex',
        justifyContent: 'center',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));

const FiltreRapport = ({ onFilter }) => {
    const classes = useStyles();
    const [tabValue, setTabValue] = useState('semaine');
    const [startDate, setStartDate] = useState('');

    const getWeekString = (date) => {
        const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
        const pastDaysOfYear = (date - firstDayOfYear) / 86400000 + firstDayOfYear.getDay() + 1;
        const weekNumber = Math.ceil(pastDaysOfYear / 7);
        return `${date.getFullYear()}-W${String(weekNumber).padStart(2, '0')}`;
    };

    useEffect(() => {
        const currentDate = new Date();
        let initialDate;

        if (tabValue === 'semaine') {
            const startOfWeek = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay() + 1));
            initialDate = getWeekString(startOfWeek);
        } else if (tabValue === 'mois') {
            initialDate = currentDate.toISOString().substring(0, 7);
        } else if (tabValue === 'annee') {
            initialDate = currentDate.getFullYear().toString();
        }

        setStartDate(initialDate);
    }, [tabValue]);
    useEffect(() => {
        startDate !== '' && onFilter({ startDate, tabValue });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate]);

    const handleChange = (newValue) => {
        setTabValue(newValue);
    };

    return (
        <Paper>
            <TabContext value={tabValue}>
                <Box className={classes.buttonGroup}>
                    <Button
                        size="small"
                        variant="contained"
                        color={tabValue === 'semaine' ? 'primary' : 'default'}
                        onClick={() => handleChange('semaine')}
                    >
                        Semaine
                    </Button>
                    <Button
                        size="small"
                        variant="contained"
                        color={tabValue === 'mois' ? 'primary' : 'default'}
                        onClick={() => handleChange('mois')}
                    >
                        Mois
                    </Button>
                    <Button
                        size="small"
                        variant="contained"
                        color={tabValue === 'annee' ? 'primary' : 'default'}
                        onClick={() => handleChange('annee')}
                    >
                        Année
                    </Button>
                </Box>
                <Box className={classes.tabPanel}>
                    <TabPanel value="semaine">
                        <TextField
                            id="startDate"
                            label="Semaine de début"
                            type="week"
                            variant="outlined"
                            value={startDate}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                            margin="dense"
                            onChange={(e) => setStartDate(e.target.value)}
                        />
                    </TabPanel>
                    <TabPanel value="mois">
                        <TextField
                            id="startMonth"
                            label="Mois de début"
                            type="month"
                            variant="outlined"
                            value={startDate}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                            margin="dense"
                            onChange={(e) => setStartDate(e.target.value)}
                        />
                    </TabPanel>
                    <TabPanel value="annee">
                        <TextField
                            id="startYear"
                            label="Année de début"
                            type="number"
                            variant="outlined"
                            value={startDate}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                            margin="dense"
                            onChange={(e) => setStartDate(e.target.value)}
                        />
                    </TabPanel>
                </Box>
            </TabContext>
        </Paper >
    );
};

export default FiltreRapport;
