export const SET_AUTHENTIFICATION = 'SET_AUTHENTIFICATION';
export const SET_SEARCH = 'SET_SEARCH';
export const SET_OF_TO_EDIT = 'SET_OF_TO_EDIT';
export const GET_USER = 'GET_USER';
export const INCREMENT_ACTIONS_COUNT = 'INCREMENT_ACTIONS_COUNT';
export const ADD_RESSOURCE = 'ADD_RESSOURCE';
export const GET_COURS = 'GET_COURS';
export const GET_ATELIER = 'GET_ATELIER';
export const GET_LISTE_PRODUIT = 'GET_LISTE_PRODUIT';
export const GET_STOCK_PRODUIT = 'GET_STOCK_PRODUIT';
export const SET_PRODUIT = 'SET_PRODUIT';
export const GET_LISTE_CLIENT = 'GET_LISTE_CLIENT';
export const GET_TYPE_TRANSPORT = 'GET_TYPE_TRANSPORT';
export const GET_LISTE_METAUX = 'GET_LISTE_METAUX';
export const GET_LISTE_STADE = 'GET_LISTE_STADE';
export const GET_LISTE_TYPE_PRODUIT = 'GET_LISTE_TYPE_PRODUIT';
export const GET_LISTE_STATUT = 'GET_LISTE_STATUT';
export const GET_LISTE_JOAILLIER = 'GET_LISTE_JOAILLIER';
export const GET_LISTE_ATELIER = 'GET_LISTE_ATELIER';
export const GET_LISTE_BOITE_OR = 'GET_LISTE_BOITE_OR';
export const GET_LISTE_SERTISSEUR = 'GET_LISTE_SERTISSEUR';
export const GET_LISTE_FOURNISSEUR = 'GET_LISTE_FOURNISSEUR';
export const GET_LISTE_OF_SERTI = 'GET_LISTE_OF_SERTI';
export const GET_LISTE_PIERRE = 'GET_LISTE_PIERRE';
export const GET_LISTE_TYPE_PIERRE = 'GET_LISTE_TYPE_PIERRE';
export const GET_LISTE_FORME_PIERRE = 'GET_LISTE_FORME_PIERRE';
export const GET_LISTE_COULEUR_PIERRE = 'GET_LISTE_COULEUR_PIERRE';
export const GET_LISTE_QUALITE_PIERRE = 'GET_LISTE_QUALITE_PIERRE';
export const GET_LISTE_CONFIE_PIERRE = 'GET_LISTE_CONFIE_PIERRE';
export const GET_LISTE_RETOUR_PIERRE = 'GET_LISTE_RETOUR_PIERRE';
export const GET_LISTE_ETAT_PIERRE = 'GET_LISTE_ETAT_PIERRE';
export const GET_TYPE_COM = 'GET_TYPE_COM';
export const GET_TYPE_TVA = 'GET_TYPE_TVA';
export const GET_TYPE_COMMANDE = 'GET_TYPE_COMMANDE';
export const GET_LISTE_AVOIR = 'GET_LISTE_AVOIR';
export const GET_LISTE_IMAGE = 'GET_LISTE_IMAGE';


export const ADD_OF = 'ADD_OF';
export const UPDATE_OF = 'UPDATE_OF';
export const DELETE_OF = 'DELETE_OF';
export const DELETE_OFS = 'DELETE_OFS';
export const GET_OFS = 'GET_OFS';
export const GET_OF_FROM_ID = 'GET_OF_FROM_ID';
export const ADD_OF_TO_FACT = 'ADD_OF_TO_FACT';
export const DELETE_OF_TO_FACT = 'DELETE_OF_TO_FACT';
export const GET_OF_EN_COURS = 'GET_OF_EN_COURS';
export const GET_OF_LIVRE = 'GET_OF_LIVRE';
export const GET_OF_LIVRE_EXTENSIA = 'GET_OF_LIVRE_EXTENSIA';
export const GET_TYPE_TARIF = 'GET_TYPE_TARIF';
export const GET_CA_GROUPED_BY_MONTH = 'GET_CA_GROUPED_BY_MONTH';
export const GET_COMPARAION_CA_GROUPED_BY_MONTH = 'GET_COMPARAION_CA_GROUPED_BY_MONTH';
export const GET_COMPARAION_CA_GROUPED_BY_YEAR = 'GET_COMPARAION_CA_GROUPED_BY_YEAR';
export const GET_ANALYSE_CA = 'GET_ANALYSE_CA';
export const GET_ANALYSE_TRANCHE = 'GET_ANALYSE_TRANCHE';
export const GET_ANALYSE_CA_TARIF = 'GET_ANALYSE_CA_TARIF';
export const GET_ANALYSE_METAL = 'GET_ANALYSE_METAL';
export const GET_ANALYSE_FOURNISSEUR = 'GET_ANALYSE_FOURNISSEUR';
export const SET_TARIF_PRODUIT = 'SET_TARIF_PRODUIT';




