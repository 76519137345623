import Axios from "axios";
import { getTodaySqlFormat } from "../../../helpers/functions";
import { apiATC } from "../../../config";
import store from "../../..";
import { getAtelier, getListeAtelier, getListeOfSerti, getListeBoiteOr } from "../../../actions";

/* eslint-disable eqeqeq */
export const ajouterJoaillier = async (forms) => {
    try {
        const data = Array.isArray(forms) ? forms : [forms];

        // Récupérer la liste des ateliers
        store.dispatch(getListeAtelier());
        const state = store.getState();
        const listeOfsAtelier = state.generalData.listeAtelier;
        const axiosRequests = data.map(item => {
            // Vérification si la combinaison id_ouvrier et n_sachet existe déjà
            const existingItem = listeOfsAtelier.find(
                atelier => atelier.id_ouvrier == item.id_joaillier && atelier.n_sachet == item.of
            );
            const dataToSave = {
                table: 'ouvrier',
                date_envoi: getTodaySqlFormat(),
                date_retour: item.rendu === 1 ? getTodaySqlFormat() : '0000-00-00',
                poids_d: item.poids,
                poids_r: item.poidsR || 0,
                id_ouvrier: item.id_joaillier,
                n_sachet: item.of,
                temps: item.temps,
                rendu: item.rendu || 0,
                id_ligne: item.id_ligne || null,

            };
            if (existingItem) {
                if (item.id_ligne) {
                    dataToSave.id = existingItem.id_ligne;
                    dataToSave.primaryKey = 'id_ligne';
                    modifierStatut(item.of, 200);
                    return Axios.post(`${apiATC}/general.php?action=modifier`, dataToSave)
                }
                else {
                    console.log('erreur deja existant');
                    throw new Error('OF déjà présent');
                }
            } else {
                // Sinon, ajouter un nouveau joaillier

                modifierStatut(item.of, 200);
                return Axios.post(`${apiATC}/general.php?action=ajouter`, dataToSave)
            }
        });

        try {
            const responses = await Promise.all(axiosRequests);
            store.dispatch(getListeAtelier());
            store.dispatch(getAtelier());
            return responses;
        } catch (error) {
            console.log("Une erreur s'est produite lors de l'exécution des requêtes :", error);
            throw error;
        }
    } catch (error) {
        console.log("Une erreur s'est produite lors du traitement des données :", error);
        throw error;
    }
};

export const modifierJoaillier = async (forms) => {
    try {
        const data = Array.isArray(forms) ? forms : [forms];
        const axiosRequests = data.map(item => {
            console.log(item);
            const dataToSave = {
                table: 'ouvrier',
                primaryKey: 'id_ligne',
                id: item.id_ligne,
                date_retour: item.date_retour || getTodaySqlFormat(),
                poids_r: item.poidsR,
                poids_d: item.poids,
                temps: item.temps,
                rendu: item.rendu ?? 1,
            };
            console.log(dataToSave);
            modifierStatut(item.of, 200);
            return Axios.post(`${apiATC}/general.php?action=modifier`, dataToSave)
        });

        // Retourne la promesse générée par Promise.all()
        try {
            const responses = await Promise.all(axiosRequests);
            store.dispatch(getListeAtelier());
            store.dispatch(getAtelier());
            return responses;
        } catch (error) {
            console.log("Une erreur s'est produite lors de l'exécution des requêtes :", error);
            throw error; // Propage l'erreur pour la gérer dans le dossier parent
        }
    } catch (error) {
        console.log("Une erreur s'est produite lors du traitement des données :", error);
        throw error; // Propage l'erreur pour la gérer dans le dossier parent
    }
};

export const supprimerJoaillier = async (forms) => {
    try {
        const data = Array.isArray(forms) ? forms : [forms];
        const axiosRequests = data.map(item => {
            const dataToSave = {
                table: 'ouvrier',
                primaryKey: 'id_ligne',
                id: item.id_ligne,
            };

            return Axios.post(`${apiATC}/general.php?action=supprimer`, dataToSave);
        });

        // Retourne la promesse générée par Promise.all()
        try {
            const responses = await Promise.all(axiosRequests);
            store.dispatch(getListeAtelier());
            store.dispatch(getAtelier());
            return responses;
        } catch (error) {
            console.log("Une erreur s'est produite lors de l'exécution des requêtes :", error);
            throw error; // Propage l'erreur pour la gérer dans le dossier parent
        }
    } catch (error) {
        console.log("Une erreur s'est produite lors du traitement des données :", error);
        throw error; // Propage l'erreur pour la gérer dans le dossier parent
    }
};

export const ajouterSertisseur = async (forms) => {
    try {
        const data = Array.isArray(forms) ? forms : [forms];

        // Récupérer la liste des ateliers
        store.dispatch(getListeOfSerti());
        const state = store.getState();
        const listeSerti = state.generalData.listeOfSerti;
        const axiosRequests = data.map(item => {
            // Vérification si deja donné
            const existingItem = listeSerti.find(
                serti => serti.of_serto === item.of && serti.id_serto === item.id_sertisseur
            );
            const dataToSave = {
                table: 'serti',
                date_e: getTodaySqlFormat(),
                date_r: item.rendu_serti === 1 ? getTodaySqlFormat() : '0000-00-00',
                pds_d: item.poids,
                pds_r: item.poidsR || 0,
                id_serto: item.id_sertisseur,
                of_serto: item.of,
                prix: item.prix || 0,
                rendu_serti: item.rendu_serti || 0,

            };
            if (existingItem) {
                if (item.id_sert) {
                    dataToSave.id = existingItem.id_sert;
                    dataToSave.primaryKey = 'id_sert';
                    modifierStatut(item.of, 200);
                    return Axios.post(`${apiATC}/general.php?action=modifier`, dataToSave)
                }
                else {
                    console.log('erreur deja existant');
                    throw new Error('OF déjà présent');
                }
            } else {
                // Sinon, ajouter un nouveau joaillier

                modifierStatut(item.of, 200);
                return Axios.post(`${apiATC}/general.php?action=ajouter`, dataToSave)
            }
        });
        try {
            const responses = await Promise.all(axiosRequests);
            store.dispatch(getListeOfSerti())
            return responses;
        } catch (error) {
            console.log("Une erreur s'est produite lors de l'exécution des requêtes :", error);
            throw error;
        }
    } catch (error) {
        console.log("Une erreur s'est produite lors du traitement des données :", error);
        throw error;
    }
};

export const modifierSertisseur = async (forms) => {
    try {
        const data = Array.isArray(forms) ? forms : [forms];
        const axiosRequests = data.map(item => {
            const dataToSave = {
                table: 'serti',
                primaryKey: 'id_sert',
                id: item.id_sert,
                date_r: getTodaySqlFormat(),
                pds_r: item.poidsR,
                pds_d: item.poids,
                prix: item.prix,
                rendu_serti: "1",

            };
            modifierStatut(item.of, 500);
            return Axios.post(`${apiATC}/general.php?action=modifier`, dataToSave)
        });

        // Retourne la promesse générée par Promise.all()
        try {
            const responses = await Promise.all(axiosRequests);
            store.dispatch(getListeOfSerti())
            return responses;
        } catch (error) {
            console.log("Une erreur s'est produite lors de l'exécution des requêtes :", error);
            throw error; // Propage l'erreur pour la gérer dans le dossier parent
        }
    } catch (error) {
        console.log("Une erreur s'est produite lors du traitement des données :", error);
        throw error; // Propage l'erreur pour la gérer dans le dossier parent
    }
};

export const supprimerSertisseur = async (forms) => {
    try {
        const data = Array.isArray(forms) ? forms : [forms];
        const axiosRequests = data.map(item => {
            const dataToSave = {
                table: 'serti',
                primaryKey: 'id_sert',
                id: item.id_sert,
            };

            return Axios.post(`${apiATC}/general.php?action=supprimer`, dataToSave);
        });

        // Retourne la promesse générée par Promise.all()
        try {
            const responses = await Promise.all(axiosRequests);
            store.dispatch(getListeOfSerti())
            return responses;
        } catch (error) {
            console.log("Une erreur s'est produite lors de l'exécution des requêtes :", error);
            throw error; // Propage l'erreur pour la gérer dans le dossier parent
        }
    } catch (error) {
        console.log("Une erreur s'est produite lors du traitement des données :", error);
        throw error; // Propage l'erreur pour la gérer dans le dossier parent
    }
};
export const ajouterBoiteOr = async (forms) => {
    try {
        const data = Array.isArray(forms) ? forms : [forms];
        const axiosRequests = data.map(item => {
            const dataToSave = {
                table: 'boite_or',
                id_ouvr: item.id_joaillier,
                done_ouvr: item.poidsD,
                rendu_ouvr: item.poidsR,
                date: getTodaySqlFormat(),
            };
            return Axios.post(`${apiATC}/general.php?action=ajouter`, dataToSave)
        });
        // Retourne la promesse générée par Promise.all()
        try {
            const responses = await Promise.all(axiosRequests);
            store.dispatch(getListeBoiteOr());
            return responses;
        } catch (error) {
            console.log("Une erreur s'est produite lors de l'exécution des requêtes :", error);
            throw error; // Propage l'erreur pour la gérer dans le dossier parent
        }
    } catch (error) {
        console.log("Une erreur s'est produite lors du traitement des données :", error);
        throw error; // Propage l'erreur pour la gérer dans le dossier parent
    }
};
export const modifierBoiteOr = async (forms) => {
    try {
        const data = Array.isArray(forms) ? forms : [forms];
        const axiosRequests = data.map(item => {
            const dataToSave = {
                table: 'boite_or',
                primaryKey: 'id_boite',
                id: item.id_boite,
                done_ouvr: item.done_ouvr,
                rendu_ouvr: item.rendu_ouvr,

            };
            return Axios.post(`${apiATC}/general.php?action=modifier`, dataToSave)
        });

        // Retourne la promesse générée par Promise.all()
        try {
            const responses = await Promise.all(axiosRequests);
            store.dispatch(getListeBoiteOr());
            return responses;
        } catch (error) {
            console.log("Une erreur s'est produite lors de l'exécution des requêtes :", error);
            throw error; // Propage l'erreur pour la gérer dans le dossier parent
        }
    } catch (error) {
        console.log("Une erreur s'est produite lors du traitement des données :", error);
        throw error; // Propage l'erreur pour la gérer dans le dossier parent
    }
};

export const supprimerBoiteOr = async (forms) => {
    try {
        const data = Array.isArray(forms) ? forms : [forms];
        const axiosRequests = data.map(item => {
            const dataToSave = {
                table: 'boite_or',
                primaryKey: 'id_boite',
                id: item.id_boite,
            };
            return Axios.post(`${apiATC}/general.php?action=supprimer`, dataToSave);
        });

        // Retourne la promesse générée par Promise.all()
        try {
            const responses = await Promise.all(axiosRequests);
            store.dispatch(getListeBoiteOr());
            return responses;
        } catch (error) {
            console.log("Une erreur s'est produite lors de l'exécution des requêtes :", error);
            throw error; // Propage l'erreur pour la gérer dans le dossier parent
        }
    } catch (error) {
        console.log("Une erreur s'est produite lors du traitement des données :", error);
        throw error; // Propage l'erreur pour la gérer dans le dossier parent
    }
};

const modifierStatut = (of, statut) => {
    // Vérification des paramètres
    if (!of || !statut) {
        console.error('Erreur: of ou statut manquant.');
        return;
    }

    const form = {
        table: 'of',
        primaryKey: 'num_sachet',
        id: of,
        statut,
    };

    try {
        Axios.post(`${apiATC}/general.php?action=modifier`, form)

            .then(response => {
            })
            .catch(error => {
                console.error('Erreur lors de la mise à jour du statut:', error);
            });
    } catch (error) {
        console.error('Erreur inattendue:', error);
    }
};
