import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core'
import Axios from 'axios'
import { List } from 'immutable'
import MaterialTable, { MTableToolbar } from 'material-table'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getTypeProduit, getStade, getMetaux } from '../actions'
import FilterInput from './filterInput'
import FilterSelect from './filterSelect'
import { apiATC, imagesRepo, localization, meekroDB } from '../config'
import TreeClient from '../components/treeClient'
import Image from 'react-bootstrap/Image'

import { euroFormat, exportCsv } from '../helpers/functions'
import AddStockProduit from './stock/AddStockProduit'
import EditStockProduit from './stock/EditStockProduit'
import HistoriqueStockProduit from './stock/HistoriqueStockProduit'
import TracerOf from '../containers/tracerOf'
import EnvoyerProduitsDialog from './stockProduit/ReservationDialog'
class StockPierre extends Component {

    state = {
        ref: '',
        of: '',
        listeOf: '',
        plateau: '',
        plateauForUptade: '',
        client: '',
        reservation: '',
        type: '',
        stade: '',
        metal: '',
        editer: false,
        modifier: false,
        ajouter: false,
        showPlateau: false,
        cpt: 0,
        totalVente: 0,
        totalRevient: 0,
        cptRevientNull: 0,
        totalRevientReproduction: 0,
        cptRevientReprodNull: 0,
        affecterClient: '',
        listeProduits: '',
        historique: false,
        dataHistorique: null,
        stockProduit: null,
        ofToTrace: '',
        open: false,
    }
    componentDidMount() {
        this.props.getTypeProduit()
        this.props.getStade()
        this.props.getMetaux()
    }
    onChange = (data, labelState) => {
        const label = labelState.toLowerCase()
        this.setState({ [label]: data })
    }
    afficher = () => {
        const data = {
            agences: this.state.client,
            reservation: this.state.reservation,
            ref: this.state.ref,
            stade: this.state.stade,
            of: this.state.of,
            metal: this.state.metal,
            type: this.state.type,
            plateau: this.state.plateau,
            stockProduit: null,

        }
        Axios.post(`${apiATC}/produit/stock.php?action=liste`, data)
            .then((response) => {
                //console.log(response.data);
                const stockProduit = response.data
                let cpt = 0, totalVente = 0, totalRevient = 0, totalRevientReproduction = 0, cptRevientNull = 0, cptRevientReprodNull = 0;

                Object.values(stockProduit).forEach(p => {
                    cpt++

                    totalVente += parseFloat(p.leprix !== 0 ? p.leprix : p.total3)
                    if (p.prix_r === 0) cptRevientNull++;
                    totalRevient += parseFloat(p.prix_r)
                    if (p.id_stade === 1) p.revient_prod = p.revient_mfs;
                    else if (p.id_stade === 3) p.revient_prod = p.revient_cv;
                    else if (p.id_stade === 4 || p.id_stade === 2) p.revient_prod = p.revient_piece;
                    else p.revient_prod = 0;
                    if (p.revient_prod === 0) cptRevientReprodNull++;
                    totalRevientReproduction += parseFloat(p.revient_prod)

                })
                this.setState({ stockProduit, cpt, totalVente: parseInt(totalVente), totalRevient: parseInt(totalRevient), totalRevientReproduction: parseInt(totalRevientReproduction), cptRevientNull, cptRevientReprodNull })
            })
            .catch(error => {
                console.log(error);
            });

    }
    mouvementStock = (stockProduit, client) => {
        const data = {
            prods: stockProduit,
            id_clt: client,
        }
        Axios.post(`${meekroDB}/produit/mouvement.php`, data)
            .then((response) => {
                //console.log(response);
                this.afficher()
            })
            .catch(error => {
                console.log(error);
            });
    }
    supprimerResa = (stockProduit) => {
        let listeOF = "";
        stockProduit.forEach(element => {
            if (element.of) {
                if (listeOF !== "") {
                    listeOF += ",";
                }
                listeOF += element.of;
            }
        });
        const data = {
            table: 'of',
            primaryKey: 'of_reservation',
            id: listeOF,
            of_reservation: 0,
        };

        Axios.put(`${meekroDB}/general.php`, data)
            .then((response) => {
                this.afficher();
            })
            .catch(error => {
                alert("Erreur lors de la requête Axios : " + error);
            });

    }


    mouvementPlateau = (stockProduit) => {
        let listeOf = '', sep = ''
        Object.values(stockProduit).forEach(r => {
            listeOf += sep + r.of
            sep = ','
        })
        this.setState({ showPlateau: true, listeOf })

    }
    facturer = (stockProduit) => {
        let listeOf = '', sep = ''
        Object.values(stockProduit).forEach(r => {
            const ofs = {
                table: 'of',
                primaryKey: 'num_sachet',
                id: r.of,
                prix_fact: r.leprix === 0 ? r.total3 : r.leprix,
                statut: 575,
                ref_clt: 'Confie ' + r.de,
                echeance: 0,
                date_paiement: '0000-00-00',
            }
            listeOf += sep + r.of
            sep = ','
            Axios.put(`${meekroDB}/general.php`, ofs)
                .then((response) => {

                })
                .catch(error => {
                    console.log(error);
                });
        })
        const data = {
            ofids: listeOf
        }
        Axios.post(`${meekroDB}/produit/facturer_stock.php`, data)
            .then((response) => {
                this.afficher()
            })
            .catch(error => {
                console.log(error);
            });
    }
    affectMouvementPlateau = () => {
        const data = {
            table: 'new_stock_prod',
            primaryKey: 'of',
            id: this.state.listeOf,
            plateau: this.state.plateauForUptade,
        }
        Axios.put(`${meekroDB}/general.php`, data)
            .then((response) => {
                console.log(response);
                this.afficher()
            })
            .catch(error => {
                console.log(error);
            });
    }
    listeStockProduit = () => {
        const data = List(this.state.stockProduit)
        return data.toJS()
    }
    editStock = (data) => {
        this.setState({ modifier: true, listeProduits: data })
    }
    handleChangeClient = (data) => {
        this.setState({ client: data })
    }
    handleChangeReservation = (data) => {
        this.setState({ reservation: data })
    }
    EnvoiClient = (data) => {
        this.setState({ affecterClient: data })
    }
    handleSave = e => {
        e.preventDefault()
        this.mouvementStock(this.state.listeProduits, this.state.affecterClient)
    }
    handleResa = e => {
        e.preventDefault()
        this.mouvementResa(this.state.listeProduits, this.state.affecterClient)
    }
    add = e => {
        this.setState({ ajouter: true })
    }
    historique = (of) => {

        Axios.get(`${meekroDB}/produit/mouvement.php?of=${of}`)
            .then((response) => {
                //console.log(response);
                this.setState({ dataHistorique: response.data, historique: true })
            })
            .catch(error => {
                console.log(error);
            });

    }
    etiquette = () => {
        var output = '';
        var errorString = '';
        var price = 0;
        const getLabelMetal = (metal) => {
            switch (metal) {
                case "OR BLANC":
                    return "OR B ";
                case "OR ROSE":
                    return "OR R ";
                case "OR JAUNE":
                    return "OR J ";
                default:
                    return metal + " ";
            }
        }
        //var priceRep = 0;
        this.state.stockProduit && Object.values(this.state.stockProduit).forEach(p => {
            console.log(p);
            if (p.leprix !== 0) price = p.leprix;
            else price = p.total3;
            let marque = "";
            if (p.nom_ligne === "EXTENSIA") marque = "EXTENSIA ";
            else marque = "ATC ";
            // priceRep = Math.round(price / 0.9);
            if (p.label_produit.length > 15) errorString += 'Attention OF N° ' + p.of + ' : Description trop grande\n';
            output += 'VAR1$="' + p.ref + ' - ' + p.poids + ' Gr" \n';
            output += 'VAR2$="' + p.label_produit + '"\n';
            //output+='VAR3$="'+ p.lemetal+ ' "\n' ;
            output += 'VAR3$="' + price + '/' + Math.round(price * 2.5) + '"\n';
            output += 'VAR4$="' + p.prenom_ligne.toUpperCase() + '"\n';
            output += 'VAR5$="' + getLabelMetal(p.lemetal) + p.of + '"\n';
            output += 'VAR6$="' + marque + '"\n';
            output += 'A=0001\n';
            output += 'LAYOUT1\n\n';
        })

        if (errorString !== '') alert(errorString);
        return output
    }
    reserver = () => {

    }
    imprimer = (data, mode = "STOCK") => {
        if (data.length !== 0) {
            let ofs = '', sep = '', diffClient = false, client = data[0].id_bijouterie
            Object.values(data).forEach(r => {
                if (client !== r.id_bijouterie) {
                    diffClient = true
                }
                ofs += sep + r.of
                sep = ','
            })
            if (!diffClient) window.open(`https://www.extensia-france.com/printers/stockProdPrinter.php?ofs=${ofs}&mode=${mode}`, "_blank")
            else alert('Impression bon de confié impossible car plusieurs clients sont présents')
        }
    }
    imprimerResa = (data) => {
        if (data.length !== 0) {
            let ofs = '', sep = ''
            Object.values(data).forEach(r => {
                ofs += sep + r.of
                sep = ','
            })
            window.open(`https://www.extensia-france.com/printers/reservationPrinter.php?ofs=${ofs}`, "_blank")
        }
    }
    tracerOf = (of) => {
        //this.props.getOfFromId(of)
        this.setState({ open: true, ofToTrace: of })
        //this.props.history.push("/of/tracer")
    }

    render() {
        return (
            <div >
                <div className='row' style={{ minHeight: 250, maxHeight: 250 }}>
                    <div className="col-auto">
                        <TreeClient handleChange={this.handleChangeClient} />
                    </div>
                    <div className="col-auto " style={{ padding: 4 }}>
                        <FilterInput label='Ref' type='text' onChange={this.onChange} />
                    </div>
                    <div className="col-auto" style={{ padding: 4 }}>
                        <FilterInput label='Of' type='text' onChange={this.onChange} />
                    </div>
                    <div className="col-auto" style={{ padding: 4 }}>
                        <FilterInput label='Plateau' type='text' onChange={this.onChange} />
                    </div>
                    <div className="col-1" style={{ padding: 4 }}>
                        <FilterSelect label='Type' labelId='id_typ_prod' onChange={this.onChange} options={this.props.listeTypeProduit} />
                    </div>
                    <div className="col-1" style={{ padding: 4 }}>
                        <FilterSelect label='Stade' labelId='id_stade' onChange={this.onChange} options={this.props.listeStade} />
                    </div>
                    <div className="col-1" style={{ padding: 4 }}>
                        <FilterSelect label='Metal' labelId='id_type' onChange={this.onChange} options={this.props.listeMetaux} />
                    </div>
                    <div className="col-2">
                        <TreeClient handleChange={this.handleChangeReservation} defautLabel="Réservation Coll" />
                    </div>

                    <div className="col-auto">
                        <button style={{ minHeight: 180 }} className='btn btn-primary btn-lg' onClick={this.afficher}>Afficher</button>
                    </div>
                    <div className="col-auto">
                        <button style={{ minHeight: 180 }} className='btn btn-primary btn-lg' onClick={() => { navigator.clipboard.writeText(this.etiquette()) }}>Etiquette</button>
                    </div>

                    {/* <div className="col col-lg-1">
                        <button style={{ minHeight: 150 }} className='btn btn-primary btn-lg' onClick={this.imprimer}>Imprimer</button>
                    </div> */}

                </div>
                <div>
                    <MaterialTable
                        style={{ fontSize: 12 }}

                        columns={[
                            { align: 'center', title: 'Image', field: 'image', render: rowData => <Image key={rowData.of} src={`${imagesRepo}thumbs.php?src=${rowData.image}&size=100`} style={{ width: 100, height: 100 }} rounded /> },
                            { align: 'left', title: 'Of', field: 'of', type: 'numeric' },
                            { align: 'left', title: 'Métal', field: 'lemetal' },
                            { align: 'left', title: 'Type', field: 'letype' },
                            { align: 'left', title: 'Stade', field: 'lestade' },
                            { align: 'left', title: 'Ref', field: 'ref' },
                            { align: 'left', title: 'Description', field: 'description' },
                            { align: 'left', title: 'Description Site', field: 'descOrdi' },
                            { align: 'left', title: 'Etiquette', field: 'label_produit' },
                            { align: 'left', title: 'Poids', field: 'poids', type: 'numeric' },
                            { align: 'left', title: 'Doigt', field: 'ladim', type: 'numeric' },
                            { align: 'left', title: 'Prix Fab', field: 'prix_r' },
                            //align:'left',  {title: 'Prix Reprod', field: 'revient_prod', type: 'numeric' },
                            { align: 'left', title: 'Prix', field: 'leprix' },
                            { align: 'left', title: 'Prix Fiche', field: 'total3' },
                            //align:'left', {title: 'PrixRep', field: 'total3bis', type: 'numeric' },
                            { align: 'left', title: 'Lieu', field: 'Nom_agence' },
                            { align: 'left', title: 'Date Entrée', field: 'de' },
                            //align:'left', {title: 'Date créa', field: 'format_date' },
                            { align: 'left', title: 'Plateau', field: 'plateau' },
                            { align: 'left', title: 'Réservation', field: 'client_reserve' },
                        ]}

                        options={{
                            pageSize: 10,
                            selection: true,
                            actionsColumnIndex: -1,
                            exportButton: true,
                            exportAllData: true,
                            exportCsv: (allColumns, allData) => exportCsv(allColumns, allData, "stockProduit"),
                            autoSizeColumns: true,

                        }}

                        actions={[
                            {
                                icon: 'query_stats',
                                tooltip: 'Tracer Of', onClick: (e, rowData) => this.tracerOf(rowData.of),
                                position: 'row'
                            },
                            {
                                icon: 'edit',
                                tooltip: 'Editer',
                                onClick: (e, rowData) => this.editStock(rowData),
                                position: 'row'


                            },
                            {
                                icon: 'group_add',
                                tooltip: 'Confier',
                                onClick: (e, rowData) => this.setState({ editer: true, listeProduits: rowData }),
                                position: 'toolbarOnSelect'

                            },

                            {
                                icon: 'group_remove',
                                tooltip: 'Supprimer Réservation',
                                onClick: (e, rowData) => this.supprimerResa(rowData),
                                position: 'toolbarOnSelect'
                            },

                            rowData => ({
                                icon: 'run_circle',
                                tooltip: 'Retour Stock',
                                onClick: (e, rowData) => this.mouvementStock(rowData, '249'),
                                position: 'toolbarOnSelect'

                            }),
                            rowData => ({
                                icon: 'dashboard',
                                tooltip: 'Gestion Plateau',
                                onClick: (e, rowData) => this.mouvementPlateau(rowData),
                                position: 'toolbarOnSelect'

                            }),
                            rowData => ({
                                icon: 'receipt_long',
                                tooltip: 'Facturer',
                                onClick: (e, rowData) => this.facturer(rowData),
                                position: 'toolbarOnSelect'

                            }),
                            {
                                icon: 'add',
                                tooltip: 'Ajouter',
                                isFreeAction: true,
                                onClick: (e) => this.add(),
                                position: 'toolbar'

                            },
                            rowData => ({
                                icon: 'history',
                                tooltip: 'Historique',
                                onClick: (e, values) => this.historique(values[0].of),
                                disabled: rowData.length > 1,
                                position: 'toolbarOnSelect'

                            }),
                            rowData => ({
                                icon: 'local_printshop',
                                tooltip: 'Imprimer Plateau',
                                onClick: (e, values) => this.imprimer(values, 'PLATEAU'),
                                position: 'toolbarOnSelect'

                            }),
                            rowData => ({
                                icon: 'print',
                                tooltip: 'Imprimer Confie',
                                onClick: (e, values) => this.imprimer(values),
                                position: 'toolbarOnSelect'

                            }),
                            rowData => ({
                                icon: 'print',
                                tooltip: 'Imprimer Reservation',
                                onClick: (e, values) => this.imprimerResa(values),
                                position: 'toolbarOnSelect'

                            })

                        ]}
                        data={this.listeStockProduit()}
                        title="Stock Produit"

                        components={{
                            Toolbar: props => (
                                <div>
                                    <MTableToolbar {...props} />
                                    <div style={{ padding: '0px 25px' }}>

                                        <h6> {this.state.cpt + ' Produits(s)'}</h6>
                                        <h6>Prix Revient :{euroFormat(this.state.totalRevient)} ({this.state.cptRevientNull} Prix à 0)</h6>
                                        <h6>Prix Revient Reproduction : {euroFormat(this.state.totalRevientReproduction)} ({this.state.cptRevientReprodNull} Prix à 0)</h6>
                                        <h6>Prix Vente : {euroFormat(this.state.totalVente)}</h6>

                                    </div>
                                </div>
                            ),
                        }}

                        localization={localization}
                    />

                </div>


                <Dialog
                    open={this.state.open}
                    onClose={() => this.setState({ open: false })}
                    fullWidth={true}
                    maxWidth={'xl'}
                >
                    <DialogTitle id="form-dialog-title">Tracer OF</DialogTitle>
                    <DialogTitle style={{ position: 'absolute', right: 40 }} onClick={() => this.setState({ open: false })}><button className="btn btn-link">Fermer</button></DialogTitle>
                    <DialogContent>
                        <TracerOf of={this.state.ofToTrace} open={this.state.open} />
                    </DialogContent>

                </Dialog>
                <EnvoyerProduitsDialog
                    title={"Envoyer les produits sélectionnés"}
                    open={this.state.editer}
                    onClose={() => this.setState({ editer: false })}
                    choixClient={this.EnvoiClient}
                    handleSave={this.handleSave} />

                <Dialog
                    open={this.state.ajouter}
                    onClose={() => this.setState({ ajouter: false })}
                    fullWidth={true}
                    maxWidth={'sm'}
                    style={{ textAlign: "center" }}
                >
                    <DialogTitle id="form-dialog-title">Rentrer en Stock</DialogTitle>
                    <DialogContent >
                        <AddStockProduit />
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={() => this.setState({ ajouter: false })} color="primary">
                            Fermer
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.state.modifier}
                    onClose={() => this.setState({ modifier: false })}
                    fullWidth={true}
                    maxWidth={'sm'}
                    style={{ textAlign: "center" }}
                >
                    <DialogTitle id="form-dialog-title">Editer</DialogTitle>
                    <DialogContent >
                        <EditStockProduit onSave={this.afficher} ligneStock={this.state.listeProduits} />
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={() => this.setState({ modifier: false })} color="primary">
                            Fermer
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.state.historique}
                    onClose={() => this.setState({ historique: false })}
                    fullWidth={true}
                    maxWidth={'lg'}
                    style={{ textAlign: "center" }}
                >
                    <DialogTitle id="form-dialog-title">Historique</DialogTitle>
                    <DialogContent >
                        <HistoriqueStockProduit dataHisto={this.state.dataHistorique} />
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={() => this.setState({ historique: false })} color="primary">
                            Fermer
                        </Button>
                    </DialogActions>
                </Dialog>


                <Dialog
                    open={this.state.showPlateau}
                    onClose={() => this.setState({ showPlateau: false })}
                    fullWidth={true}
                    maxWidth={'lg'}
                    style={{ textAlign: "center" }}
                >
                    <DialogTitle id="form-dialog-title">Mouvement Plateau</DialogTitle>
                    <DialogContent >
                    </DialogContent>
                    <div>
                        <TextField label="Plateau" variant="outlined"
                            value={this.state.plateauForUptade}
                            onChange={(e) => this.setState({ plateauForUptade: e.target.value })}
                        />
                        <button type="button" className="btn btn-link btn-sm" onClick={this.affectMouvementPlateau}>Enregistrer</button>
                    </div>
                    <DialogActions>
                        <Button onClick={() => this.setState({ showPlateau: false })} color="primary">
                            Fermer
                        </Button>
                    </DialogActions>
                </Dialog>

            </div >
        )
    }
}
const mapStateToProps = (state) => {
    return {
        listeTypeProduit: state.generalData.listeTypeProduit,
        listeStade: state.generalData.listeStade,
        listeMetaux: state.generalData.listeMetaux,
        listeClients: state.clients.listeClient,
    }
}
const mapDispatchToProps = {
    getTypeProduit,
    getStade,
    getMetaux,



}
export default connect(mapStateToProps, mapDispatchToProps)(StockPierre)