import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Formik, useFormik } from 'formik';
import * as Yup from 'yup';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import { getListeClient } from '../../../actions';
import { clientOnlyChild } from '../../../selectors/clients';

const validationSchema = Yup.object().shape({
    client: Yup.object().required('Client obligatoire.'),
    poids: Yup.number().required('Poids valide obligatoire.').moreThan(-1, 'Le poids rendu doit être un nombre positif.'),
    date: Yup.string(),
});

const ReceptionMetalForm = ({ listeClientInput, getListeClient, onSubmitForm }) => {
    const clientRef = useRef(null);
    const poidsRef = useRef(null);
    const [dateDebut, setDateDebut] = useState(new Date().toISOString().split('T')[0]);

    const formik = useFormik({
        initialValues: {
            date: dateDebut,
            client: null,
            poids: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            onSubmitForm(values);
            resetForm();
            clientRef.current.focus();
        },
    });

    useEffect(() => {
        getListeClient();
    }, [getListeClient]);

    const { values, errors, touched, handleChange, handleSubmit, handleBlur, setFieldValue } = formik;

    const handleDateDebutChange = (event) => {
        setDateDebut(event.target.value);
        setFieldValue('date', event.target.value);
        clientRef.current.focus();
    };

    useEffect(() => {
        if (values.client && poidsRef.current) {
            poidsRef.current.focus();
        }
    }, [values.client]);

    return (
        <Formik initialValues={values} onSubmit={handleSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        id="date-debut"
                        label="Date Début"
                        type="date"
                        variant="outlined"
                        fullWidth
                        value={dateDebut}
                        onChange={handleDateDebutChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Autocomplete
                        options={listeClientInput}
                        getOptionLabel={(option) => option.label}
                        value={values.client}
                        onChange={(event, newValue) => setFieldValue('client', newValue)}
                        autoHighlight
                        autoComplete
                        fullWidth
                        clearOnEscape
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Client"
                                variant="outlined"
                                fullWidth
                                inputRef={clientRef}
                                autoFocus
                                error={errors.client && touched.client}
                                helperText={(errors.client && touched.client) ? errors.client : null}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Poids Reçu Fin"
                        variant="outlined"
                        value={values.poids}
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="poids"
                        error={errors.poids && touched.poids}
                        helperText={(errors.poids && touched.poids) ? errors.poids : null}
                        inputRef={poidsRef}
                        onKeyPress={async (e) => {
                            if (e.key === 'Enter') {
                                const errors = await formik.validateForm();
                                formik.setFieldTouched('poids');
                                if (!errors.poids) {
                                    formik.handleSubmit();
                                    clientRef.current.focus();
                                }
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button variant="contained" color="primary" type="submit" onClick={handleSubmit}>
                        Ajouter
                    </Button>
                </Grid>
            </Grid>
        </Formik>
    );
};

const mapStateToProps = (state) => {
    return {
        listeClientInput: clientOnlyChild(state),
    };
};

const mapDispatchToProps = {
    getListeClient,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReceptionMetalForm);
