import React, { useState } from 'react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import { Doughnut } from 'react-chartjs-2';
import { euroFormat } from '../../helpers/functions';
import PopupTable from '../../components/PopupTable';
import Axios from 'axios';
import { meekroDB } from '../../config';

const VenteParTarifs = ({ analyseCaTranche, analyseCaTarif, getFilter }) => {
    console.log(analyseCaTarif);
    const theme = createMuiTheme({
        palette: {
            primary: blue,
        },
        typography: {
            fontFamily: 'Arial, sans-serif',
        },
    });


    const [columnsData, setColumnsData] = useState(null);
    const [title, setTitle] = useState(null);
    const [data, setData] = useState(null);
    const [popupOpen, setPopupOpen] = useState(false);

    const handleTarifClick = (segmentData) => {

        Axios.post(`${meekroDB}/analyse/analyse_ca.php?action=analyse_1_tarif&tarif=${segmentData.id_tarif}`, getFilter())
            .then(response => {
                const columnsTranche = [
                    { title: 'Ref', field: 'ref', align: 'center', type: "numeric" },
                    { title: 'Designation', field: 'designation', align: 'center' },
                    { title: 'Vente HT', field: 'totht', type: "numeric", align: 'center', render: rowData => euroFormat(rowData.totht) },
                    { title: 'Client', field: 'Nom_agence', align: 'center' },
                    { title: 'Tarif', field: 'tarif', align: 'center' },
                ];
                setColumnsData(columnsTranche);
                setTitle(`Détail Tarif : ${segmentData.tarif}`);
                setData(response.data);

                console.log(data);
                setPopupOpen(true); // Ouvrez la popup
            })
            .catch(error => {
                console.log(error);
            });

    };

    const handleTrancheClick = (segmentData) => {
        let min, max;
        const { intervalle_ca_ht } = segmentData;
        if (intervalle_ca_ht.includes("à")) {
            const segmentParts = intervalle_ca_ht.split(" à ");
            min = segmentParts[0];
            max = segmentParts[1];
        } else if (intervalle_ca_ht.startsWith(">")) {
            min = intervalle_ca_ht.substring(1); // Supprime le caractère ">"
            max = 100000000;
        } else if (intervalle_ca_ht === "0") {
            min = 0;
            max = 0;
        }
        Axios.post(`${meekroDB}/analyse/analyse_ca.php?action=analyse_1_tranche&min=${min}&max=${max}`, getFilter())
            .then(response => {
                const columnsTranche = [
                    { title: 'Ref', field: 'ref', align: 'center', type: "numeric" },
                    { title: 'Designation', field: 'designation', align: 'center' },
                    { title: 'Vente HT', field: 'totht', type: "numeric", align: 'center', render: rowData => euroFormat(rowData.totht) },
                    { title: 'Client', field: 'Nom_agence', align: 'center' },
                ];
                setColumnsData(columnsTranche);
                setTitle(`Détail  Tranche Prix : ${segmentData.intervalle_ca_ht} €`);
                setData(response.data);

                console.log(data);
                setPopupOpen(true); // Ouvrez la popup
            })
            .catch(error => {
                console.log(error);
            });

    };



    const totalHT = euroFormat(analyseCaTranche.reduce((total, item) => total + parseInt(item.total_ca_ht), 0));
    const doughnutTranche = {
        labels: analyseCaTranche.map(item => item.intervalle_ca_ht),
        datasets: [{
            data: analyseCaTranche.map(item => parseInt(item.total_ca_ht)),
            backgroundColor: [
                'rgba(255, 99, 132, 0.7)',
                'rgba(54, 162, 235, 0.7)',
                'rgba(255, 206, 86, 0.7)',
                'rgba(240, 10, 10, 0.7)',
                'rgba(120, 206, 10, 0.7)',
            ],
        }],
    };
    // const totalHTTarif = euroFormat(analyseCaTarif.reduce((total, item) => total + parseInt(item.total_ca_ht), 0));
    const doughnutTarif = {
        labels: analyseCaTarif.map(item => item.tarif),
        datasets: [{
            data: analyseCaTarif.map(item => parseInt(item.total_ca_ht)),
            backgroundColor: [
                'rgba(255, 99, 132, 0.7)',
                'rgba(255, 206, 86, 0.7)',
                'rgba(54, 162, 235, 0.7)',
                'rgba(240, 10, 10, 0.7)',
                'rgba(120, 206, 10, 0.7)',
            ],
        }],
    };

    const options = {
        legend: {
            display: true,
            position: 'right',
            onClick: () => { },
            labels: {
                fontSize: 16,
                generateLabels: function (chart) {
                    const data = chart.data;
                    if (data.labels.length && data.datasets.length) {
                        const ds = data.datasets[0];
                        const legendLabels = [];
                        console.log(data);


                        const titreLegend = `Ventes HT : ${totalHT}`; // Utilisation de la quantité fournie dans les données
                        legendLabels.push({
                            text: titreLegend,
                            fillStyle: 'transparent',
                            lineWidth: 0,
                            index: -1 // Indice arbitraire pour placer ce label en haut
                        });
                        legendLabels.push({
                            text: "",
                            fillStyle: 'transparent',
                            lineWidth: 0,
                            index: -2 // Indice arbitraire pour placer ce label en haut
                        });

                        // Détails pour chaque segment
                        data.labels.forEach((label, i) => {
                            const montantHT = analyseCaTranche[i].total_ca_ht;
                            legendLabels.push({
                                text: `${label} (${euroFormat(montantHT)})`, // Utilisation des données fournies pour chaque segment
                                fillStyle: ds.backgroundColor[i],
                                hidden: isNaN(ds.data[i]) || chart.getDatasetMeta(0).data[i].hidden,
                                index: i
                            });
                        });

                        return legendLabels;
                    } else {
                        return [];
                    }
                }
            }
        },
        tooltips: {
            enabled: true,
            callbacks: {
                title: function (tooltipItems, data) {
                    return 'Cliquez pour voir le détail';
                },
                label: function (tooltipItem, data) {
                    var label = data.labels[tooltipItem.index] || '';

                    if (label) {
                        label += ': ';
                    }
                    const value = data.datasets[0].data[tooltipItem.index];
                    label += euroFormat(value); // Utilisez votre propre formatage des données ici si nécessaire
                    return label;
                }
            }
        }
    };

    const optionsPrixR = {

        legend: {
            display: true,
            position: 'right',
            onClick: () => { },
            labels: {
                fontSize: 16,
                generateLabels: function (chart) {
                    const data = chart.data;
                    if (data.labels.length && data.datasets.length) {
                        const ds = data.datasets[0];
                        const legendLabels = [];

                        const titreLegend = `Types Tarif`;
                        legendLabels.push({
                            text: titreLegend,
                            fillStyle: 'transparent',
                            lineWidth: 0,
                            index: -1 // Indice arbitraire pour placer ce label en haut
                        });
                        legendLabels.push({
                            text: "",
                            fillStyle: 'transparent',
                            lineWidth: 0,
                            index: -2 // Indice arbitraire pour placer ce label en haut
                        });

                        data.labels.forEach((label, i) => {
                            const montantHTparTarif = analyseCaTarif[i].total_ca_ht;

                            legendLabels.push({
                                text: `${label} (${euroFormat(montantHTparTarif)})`, // Utilisation des données fournies pour chaque segment
                                fillStyle: ds.backgroundColor[i],
                                hidden: isNaN(ds.data[i]) || chart.getDatasetMeta(0).data[i].hidden,
                                index: i
                            });
                        });

                        return legendLabels;
                    } else {
                        return [];
                    }
                }
            }
        },
        tooltips: {
            enabled: true,
            callbacks: {
                title: function (tooltipItems, data) {
                    return 'Cliquez pour voir le détail';
                },
                label: function (tooltipItem, data) {
                    var label = data.labels[tooltipItem.index] || '';

                    if (label) {
                        label += ': ';
                    }
                    const value = data.datasets[0].data[tooltipItem.index];
                    label += euroFormat(value); // Utilisez votre propre formatage des données ici si nécessaire
                    return label;
                }
            }
        }
    };

    return (

        <MuiThemeProvider theme={theme}>
            <div className="row">
                <div className="col-6">
                    <Doughnut data={doughnutTranche} options={options} getElementAtEvent={(elems) => {
                        if (elems.length > 0) {
                            const segmentIndex = elems[0]._index;
                            const segmentData = analyseCaTranche[segmentIndex];
                            handleTrancheClick(segmentData);
                        }
                    }}
                    />
                </div>
                <div className="col-6">
                    <Doughnut data={doughnutTarif} options={optionsPrixR} getElementAtEvent={(elems) => {
                        if (elems.length > 0) {
                            const segmentIndex = elems[0]._index;
                            const segmentData = analyseCaTarif[segmentIndex];
                            handleTarifClick(segmentData);
                        }
                    }}
                    />
                </div>
            </div>
            <PopupTable
                open={popupOpen}
                handleClose={() => setPopupOpen(false)}
                columns={columnsData}
                title={title}
                data={data}
            />
        </MuiThemeProvider>
    );

};

export default VenteParTarifs;
