import React, { Component } from 'react'
import { getListeOf } from '../../actions'
import { connect } from 'react-redux'
import { Autocomplete } from '@material-ui/lab'
import { TextField } from '@material-ui/core'
import { getTodaySqlFormat } from '../../helpers/functions'
import Axios from 'axios'
import { meekroDB } from '../../config'

class AddStockProduit extends Component {
    state = {
        of: null,
        ref: '',
        prix: '',
        prixRevient: '',
        poids: '',
        labelProduit: '',
        etiquette: '',
    }
    componentDidMount() {
        this.props.getListeOf()
    }

    reset = () => {
        this.setState({
            of: null,
            ref: '',
            prix: '',
            prixRevient: '',
            poids: '',
            labelProduit: '',
            etiquette: '',
        })

    }
    selectOf = (e, of) => {
        if (!of) this.reset()
        else {
            this.setState({
                of,
                ref: of.ref1,
                prix: of.prix_fact,
                prixRevient: of.prix_r,
                poids: of.poids,
                labelProduit: of.ref_cv + ' ' + of.ref,
                etiquette: of.label_produit,
            })
        }
    }
    handleAdd = () => {
        const data = {
            table: 'new_stock_prod',
            of: this.state.of.num_sachet,
        }
        Axios.post(`${meekroDB}/general.php`, data)
            .then((response) => {
                const of = {
                    table: 'of',
                    primaryKey: 'num_sachet',
                    id: this.state.of.num_sachet,
                    ref1: this.state.ref,
                    poids: this.state.poids,
                    prix_fact: this.state.prix,
                    statut: '650',
                    prix_r: this.state.prixRevient,
                    label_produit: this.state.etiquette,
                }
                Axios.put(`${meekroDB}/general.php`, of)
                    .then((response) => {
                        const histo = {
                            table: 'new_historique_conf',
                            of: this.state.of.num_sachet,
                            description: this.state.ref,
                            id_stade: this.state.of.id_stade,
                            type_metal: this.state.of.type_metal,
                            ladim: this.state.of.dim,
                            date_entre: getTodaySqlFormat(),
                            id_bijouterie: this.state.of.id_bijouterie,
                            poids: this.state.poids,
                            leprix: this.state.prix,

                        }
                        Axios.post(`${meekroDB}/general.php`, histo)
                            .then((response) => {
                                this.reset()
                                this.props.getListeOf()
                            })
                            .catch(error => {
                                console.log(error);
                            });

                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(error => {
                console.log(error);
            });

    }
    render() {
        return (
            <div className='d-flex justify-content-center'>

                <div className="form">
                    <h6>{this.state.labelProduit}</h6>
                    <div className="row">
                        <div className="col-sm">
                            <div className="form-group">
                                <Autocomplete
                                    value={this.state.of}
                                    options={this.props.listeOfs}
                                    getOptionLabel={(option) => option.num_sachet}
                                    onChange={this.selectOf}

                                    getOptionSelected={(option) => option.num_sachet === this.state.of.num_sachet}
                                    autoHighlight
                                    autoComplete
                                    clearOnEscape
                                    renderInput={(params) => <TextField fullWidth autoFocus onKeyPress={this.preventEnter} {...params} label="of" variant="outlined" />}
                                />
                            </div>
                        </div>


                    </div>
                    <div className="row">
                        <div className="col-auto">
                            <div className="form-group">
                                <TextField fullWidth label="Référence" variant="outlined"
                                    value={this.state.ref}
                                    onChange={(e) => this.setState({ ref: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className="col-auto">
                            <div className="form-group">
                                <TextField fullWidth label="Poids" variant="outlined"
                                    value={this.state.poids}
                                    onChange={(e) => this.setState({ poids: e.target.value })}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-auto">
                            <div className="form-group">
                                <div className="form-group">
                                    <TextField fullWidth label="Prix" variant="outlined"
                                        value={this.state.prix}
                                        onChange={(e) => this.setState({ prix: e.target.value })}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-auto">
                            <div className="form-group">
                                <TextField fullWidth label="Prix de Revient" variant="outlined"
                                    value={this.state.prixRevient}
                                    onChange={(e) => this.setState({ prixRevient: e.target.value })}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-auto">
                        <TextField fullWidth label="Etiquette" variant="outlined"
                            value={this.state.etiquette}
                            onChange={(e) => this.setState({ etiquette: e.target.value })}
                            inputProps={{ maxLength: 15 }}

                        />
                    </div>
                    <button
                        onClick={this.handleAdd}
                        className='btn btn-link btn-sm'
                    >Enregistrer
                                        </button>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        listeOfs: state.ofs.listeOf
    }
}
const mapDispatchToProps = {
    getListeOf

}
export default connect(mapStateToProps, mapDispatchToProps)(AddStockProduit)