import React, { useState, useEffect, useRef } from 'react';
import Button from '@material-ui/core/Button';
import MaterialTable from 'material-table';
import { localization } from '../../../config';
import JoaillierEnvoiForm from '../form/JoaillierEnvoiForm';
import { ajouterJoaillier, supprimerJoaillier } from '../action/AtelierAction';

const EnvoiJoaillier = () => {
    const [data, setData] = useState([]);
    const [formValues, setFormValues] = useState(null);

    const dataRef = useRef(data);

    useEffect(() => {
        dataRef.current = data;
    }, [data]);

    const handleAjouter = (formData) => {
        const { joaillier, of, poids, id_ligne } = formData;

        const newData = {
            joaillier: joaillier.label,
            id_joaillier: joaillier.id,
            of: of.num_sachet,
            poids,
            id_ligne,
        };
        ajouterJoaillier(newData)
            .then((response) => {
                newData.id_ligne = response[0].data;
                const existingIndex = data.findIndex(item => item.of === of.num_sachet && item.id_joaillier === joaillier.id);
                if (existingIndex !== -1) {
                    // Si le "N° OF" existe déjà, mettez à jour les données
                    setData(prevData => {
                        const updatedData = [...prevData];
                        updatedData[existingIndex] = newData;
                        return updatedData;
                    });
                } else {
                    // Sinon, ajoutez une nouvelle entrée
                    setData([newData, ...data]);
                }
            })
            .catch(error => {
                console.error("Erreur lors de l'ajout :", error);
            });

    };

    const handleSupprimer = async (rowData) => {
        try {
            const index = data.indexOf(rowData);
            await supprimerJoaillier(rowData);

            const newData = [...data];
            newData.splice(index, 1);
            setData(newData);
            setFormValues();
        } catch (error) {
            console.error("Erreur lors de la suppression :", error);
        }
    };

    const handleRowClick = (event, rowData) => {
        const populateData = {
            joaillier: rowData.id_joaillier,
            of: rowData.of,
            poids: rowData.poids,
            id_ligne: rowData.id_ligne,
        }
        setFormValues(populateData);
    };
    return (
        <div>

            <div className="row" style={{ display: 'flex', justifyContent: 'center' }}>
                <div className="col-md-4">
                    <JoaillierEnvoiForm onSubmitForm={handleAjouter} dataForm={formValues} />
                </div>
                <div className="col-md-7">
                    <MaterialTable
                        title=""
                        options={{
                            search: false,
                            showTitle: false,
                        }}
                        columns={[
                            { title: 'Joaillier', field: 'joaillier', align: 'center' },
                            { title: 'OF', field: 'of' },
                            { title: 'Poids', field: 'poids' },
                            {
                                title: '',
                                field: 'action',
                                render: rowData => (
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={(e) => { e.stopPropagation(); handleSupprimer(rowData) }
                                        }>
                                        Supprimer
                                    </Button>
                                ),
                                align: 'center'
                            }
                        ]}
                        components={{
                            Toolbar: props => (
                                <h1 style={{ textAlign: 'center' }}>Envoi Joaillier</h1>
                            ),
                        }}
                        data={data}
                        localization={localization}
                        onRowClick={handleRowClick}
                    />

                </div>
            </div>
        </div>
    );
};


export default EnvoiJoaillier;
