import React, { useState, useEffect } from 'react';
import TempsRepport from './cardRepport/TempsRepport';
import FiltreRapport from './FiltreRapport';
import DonneeJoaillierRepport from './cardRepport/DonneeJoaillierRepport';
import { getEndDateOfMonth, getEndDateOfWeek, getEndDateOfYear, getStartDateOfMonth, getStartDateOfWeek, getStartDateOfYear } from '../../../helpers/functions';
import Axios from 'axios';
import { apiATC } from '../../../config';
import TopFlopJoaillier from './cardRepport/TopFlopJoaillier';

const DetailOuvrier = ({ data }) => {
    const [piecesTraites, setPiecesTraites] = useState(0);
    const [tempsPasse, setTempsPasse] = useState(0);
    const [tempsTheorique, setTempsTheorique] = useState(0);
    const [savTraites, setSavTraites] = useState(0);
    const [tempsSav, setTempsSav] = useState(0);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [donnee, setDonnee] = useState([]);

    // Fonction pour effectuer la requête Axios
    const fetchData = async () => {
        if (startDate && endDate) {
            try {
                const response = await Axios.post(`${apiATC}/atelier/ouvrier.php?action=stat_production&id_ouvrier=${data.id}&date_d=${startDate}&date_f=${endDate}`);
                const responseData = response.data;
                setDonnee(response.data);
                const piecesTraitesCount = responseData.filter(obj => obj.id_stade !== "12").length;
                const tempsPasseSum = responseData.filter(obj => obj.id_stade !== "12").reduce((acc, obj) => acc + parseInt(obj.temps), 0);
                const tempsTheoriqueSum = responseData.filter(obj => obj.id_stade !== "12").reduce((acc, obj) => acc + parseInt(obj.plus_mo), 0);
                const savTraitesCount = responseData.filter(obj => obj.id_stade === "12").length;
                const tempsSavSum = responseData.filter(obj => obj.id_stade === "12").reduce((acc, obj) => acc + parseInt(obj.temps), 0);
                setPiecesTraites(piecesTraitesCount);
                setTempsPasse(tempsPasseSum);
                setTempsTheorique(tempsTheoriqueSum);
                setSavTraites(savTraitesCount);
                setTempsSav(tempsSavSum);
            } catch (error) {
                console.error("Une erreur s'est produite lors de la récupération des données :", error);
            }
        }
    };

    useEffect(() => {
        // Lancer fetchData lorsque data.id change
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.id, startDate, endDate]);

    // Fonction de filtrage
    const filtrer = (dataFiltre) => {
        let newStartDate, newEndDate;

        switch (dataFiltre.tabValue) {
            case "semaine":
                newStartDate = getStartDateOfWeek(dataFiltre.startDate);
                newEndDate = getEndDateOfWeek(dataFiltre.startDate);
                break;
            case "mois":
                newStartDate = getStartDateOfMonth(dataFiltre.startDate);
                newEndDate = getEndDateOfMonth(dataFiltre.startDate);
                break;
            case "annee":
                newStartDate = getStartDateOfYear(dataFiltre.startDate);
                newEndDate = getEndDateOfYear(dataFiltre.startDate);
                break;
            default:
                // Gérer un cas par défaut si nécessaire
                break;
        }

        // Mettre à jour les états startDate et endDate
        setStartDate(newStartDate);
        setEndDate(newEndDate);
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', height: '500px' }}>
            <div className="col-md-7">
                {donnee.length !== 0 && <TopFlopJoaillier data={donnee} />}
            </div>
            <div className='col-md-2'>
                {tempsPasse.length !== 0 && <TempsRepport
                    tempsPasse={tempsPasse}
                    tempsTheorique={tempsTheorique}
                />}
            </div>
            <div className='col-sm-2'>
                <FiltreRapport onFilter={filtrer} />
                {tempsPasse !== 0 && <div style={{ marginTop: '20px' }}>
                    <DonneeJoaillierRepport
                        nombreOf={piecesTraites}
                        nombreSav={savTraites}
                        tempsTotal={tempsPasse + tempsSav}
                        tempsPasse={tempsPasse}
                        tempsTheorique={tempsTheorique}
                    />
                </div>}
            </div>
        </div>
    );
}

export default DetailOuvrier;
