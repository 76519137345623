import React from 'react';
import MaterialTable from 'material-table';
import { Dialog, DialogContent } from '@material-ui/core';
import { localization } from '../config';


const PopupTable = ({ columns, title, data, open, handleClose }) => {

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="lg" >

            <DialogContent>
                <h5 style={{ textAlign: 'center' }}>{title}</h5>
                <MaterialTable
                    style={{ fontSize: 15 }}
                    title={""}
                    columns={columns && columns.map(col => ({
                        ...col,
                        cellStyle: {
                            whiteSpace: 'nowrap', // Empêche le retour à la ligne
                            overflow: 'hidden', // Masque tout contenu qui dépasse
                            textOverflow: 'ellipsis', // Affiche ... pour le contenu qui dépasse
                        },
                    }))}
                    data={data}
                    options={{
                        pageSize: 15,
                        pageSizeOptions: [10, 15, 20],
                        sorting: true,
                        exportButton: true,
                        exportAllData: true,
                        padding: 'dense',
                    }}
                    localization={localization}
                />
            </DialogContent>
        </Dialog>
    );
};

export default PopupTable;
