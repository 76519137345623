import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import { localization, meekroDB } from '../../config';

const SuiviConnection = () => {
    const [data, setData] = useState([]);
    const [dataProd, setDataProd] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(meekroDB + '/user/log_repport.php');
                const result = await response.json();
                setData(result);

                const responseProd = await fetch(meekroDB + '/user/prod_repport.php');
                const resultProd = await responseProd.json();
                setDataProd(resultProd);

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const formatDate = (dateString) => {
        const originalDate = new Date(dateString);
        const formattedDate = `${originalDate.getDate().toString().padStart(2, '0')}-${(originalDate.getMonth() + 1).toString().padStart(2, '0')}-${originalDate.getFullYear()} à ${originalDate.getHours().toString().padStart(2, '0')}:${originalDate.getMinutes().toString().padStart(2, '0')}:${originalDate.getSeconds().toString().padStart(2, '0')}`;
        return formattedDate;
    };

    const columns = [
        { title: 'Client', field: 'Nom_agence', cellStyle: { padding: '8px' } },
        { title: 'Log In', field: 'userLogin', cellStyle: { padding: '8px' } },
        {
            title: 'Type', field: 'admin', cellStyle: { padding: '8px' }, render: rowData => (
                rowData.admin === 1 ? 'Admin' : 'Boutique'
            ),
        },
        {
            title: 'date',
            field: 'date',
            cellStyle: { padding: '8px' },
            render: rowData => formatDate(rowData.date),
        },
    ];
    const columnsProd = [
        { title: 'Produit', field: 'ref', cellStyle: { padding: '8px' } },
        { title: 'Client', field: 'Nom_agence', cellStyle: { padding: '8px' } },
        { title: 'Log In', field: 'userLogin', cellStyle: { padding: '8px' } },
        {
            title: 'Type', field: 'admin', cellStyle: { padding: '8px' }, render: rowData => (
                rowData.admin === 1 ? 'Admin' : 'Boutique'
            ),
        },
        {
            title: 'date',
            field: 'date',
            cellStyle: { padding: '8px' },
            render: rowData => formatDate(rowData.date),
        },
    ];

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div className="col-sm">
                <MaterialTable
                    title="Historique Connections"
                    columns={columns}
                    data={data}

                    options={{
                        pageSize: 10,
                        pageSizeOptions: [5, 10, 20],
                        sorting: true,
                        exportButton: true,
                        exportAllData: true,
                        padding: 'dense',
                    }}
                    localization={localization}
                />
            </div>
            <div className="col-sm">
                <MaterialTable
                    title="Produits Consultés"
                    columns={columnsProd}
                    data={dataProd}

                    options={{
                        pageSize: 10,
                        pageSizeOptions: [5, 10, 20],
                        sorting: true,
                        exportButton: true,
                        exportAllData: true,
                        padding: 'dense',
                    }}
                    localization={localization}
                />
            </div>


        </div>

    );
};

export default SuiviConnection;
