import { TextField } from '@material-ui/core'
import Axios from 'axios'
import React, { useState } from 'react'
import { meekroDB } from '../config'
import { getTodaySqlFormat } from '../helpers/functions'
import TreeClient from './treeClient'

const CreateAvoir = ({ onCreate }) => {
    const [client, setClient] = useState(null)
    const [ref, setRef] = useState('')
    const [designation, setDesignation] = useState('')
    const [total, setTotal] = useState('')

    const handleChangeClient = (data) => {
        setClient(data)
    }
    const handleCreate = (e) => {
        const data = {
            table: 'avoir',
            id_clt: client,
            date: getTodaySqlFormat(),
            ref,
            description: designation,
            tot_ht: total,
        }
        Axios.post(`${meekroDB}/general.php`, data)
            .then((response) => {
                onCreate()
            })
            .catch(error => {
                console.log(error);
            });
        console.log(data);
    }
    return (
        <div className='container-fluid'>
            <div className="row">
                <div className="col-3">
                    <TreeClient handleChange={handleChangeClient} multiSelection={false} />
                </div>
                <div className="col-7">
                    <div className="col-auto">
                        <TextField fullWidth value={ref} onChange={e => setRef(e.target.value)} label='Référence' variant="outlined" margin="dense" />
                    </div>
                    <div className="col-auto">
                        <TextField fullWidth value={designation} onChange={e => setDesignation(e.target.value)} label='Désignation' variant="outlined" margin="dense" />
                    </div>
                    <div className="col-auto">
                        <TextField fullWidth value={total} onChange={e => setTotal(e.target.value)} label='Total HT' variant="outlined" margin="dense" />
                    </div>
                    <div className="col-auto">
                        <button className="btn btn-link btn-sm" onClick={handleCreate}>Créer Avoir</button>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default (CreateAvoir)