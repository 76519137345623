import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { getListeProduit } from '../actions'
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Image from 'react-bootstrap/Image'
import ListItemText from '@material-ui/core/ListItemText';
import { imagesRepo } from '../config';






const SearchProd = ({ listeProduit, getListeProduit, handleSelect, produit, onEnter = () => { }, viewList = true, autoFocus = true }) => {

    const [search, setSearch] = useState('')
    const [matchesProduct, setMatchesProduct] = useState([])

    useEffect(() => {
        getListeProduit()
    }, [getListeProduit]);

    useEffect(() => {

        if (produit) {
            const searchText = produit.ref.substring(0, 4).toLowerCase()
            const matchesProduct = Object.values(listeProduit).filter(r => r.ref === searchText)
            setMatchesProduct(matchesProduct)
            setSearch(produit.ref)
            // handleSelect(produit)
        }
        else setSearch('')
    }, [listeProduit, produit, handleSelect]);



    const handleSearch = (e) => {
        const search = e.target.value
        searchProduit(search)
    }
    const searchProduit = (search, autoSelect = true) => {
        const searchText = search.substring(0, 4).toLowerCase()
        let matchesProduct = []
        if (search.length >= 3 || search === 'sav') matchesProduct = Object.values(listeProduit).filter(r => r.ref.toString().toLowerCase().indexOf(searchText) > -1)
        setMatchesProduct(matchesProduct)
        setSearch(search)

        if ((search.length === 4 || search.toLowerCase() === 'sav') && matchesProduct.length !== 0 && autoSelect) handleSelect(matchesProduct[0])
    }
    const handleEnter = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault()
            search !== '' && matchesProduct.length !== 0 && handleSelect(matchesProduct[0]) && setSearch(matchesProduct[0].ref + ' ' + matchesProduct[0].description)
            onEnter()
        }
    }
    const createListe = (produit) => {
        if (search.length >= 3 || search.toLowerCase() === 'sav') {
            return (
                Object.values(produit)
                    .map((produit) =>
                        <ListItem style={{ width: '100%' }} button key={produit.id_centre} onClick={() => {
                            handleSelect(produit, false)
                            setSearch(produit.ref + ' ' + produit.description)
                        }}>

                            <ListItemIcon><Image src={`${imagesRepo}thumbs.php?src=${produit.img_centre !== 'no_pic.jpg' ? produit.img_centre : produit.image}&size=60`} style={{ width: 60, height: 60 }} rounded /></ListItemIcon>
                            <ListItemText primary={produit.description + ' - ' + produit.total3 + ' €'} />
                        </ListItem>
                    )
            )
        }
        else return null

    }
    return (
        <div  >
            <TextField fullWidth style={{ paddingTop: 3 }} label="Rechercher Produit" variant="outlined"

                value={search}
                autoFocus={autoFocus}
                onClick={onEnter}
                autoComplete="true"
                onChange={handleSearch}
                onKeyPress={handleEnter}
            />

            {matchesProduct.length !== 0 && listeProduit && search.length > 3 && viewList &&
                < List style={{ maxHeight: 300 }} >
                    {createListe(matchesProduct)}
                </List>
            }
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        listeProduit: state.produit.listeProduit,
    }
}
const mapDispatchToProps = {
    getListeProduit,
}
export default connect(mapStateToProps, mapDispatchToProps)(SearchProd)
