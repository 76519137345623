import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import { MTableToolbar } from 'material-table';
import { euroFormat } from '../../helpers/functions';


const FactureTable = ({ data }) => {
    const columns = [
        { title: 'N° Facture', field: 'id_fact', align: 'left', defaultSort: null, type: 'numeric' },
        { title: 'Client', field: 'Nom_agence', align: 'left', defaultSort: null },
        {
            title: 'Date Fact',
            field: 'date_fact',
            align: 'left',
            defaultSort: null,
            render: rowData => rowData.df, // Utilisez la fonction formatDate pour afficher la date
        },
        { title: 'Total HT', field: 'totalHt', align: 'left', defaultSort: null, type: 'numeric' },
        { title: 'TVA', field: 'tva', align: 'left', defaultSort: null, type: 'numeric' },

    ]
    const [totalSelection, setTotalSelection] = useState(0);
    const [nbSelection, setNbSelection] = useState(0);
    const [totalFinal, setTotalFinal] = useState(0);



    useEffect(() => {
        let total = 0;
        data.forEach(item => {
            total += parseFloat(item.totalHt);
        });
        setTotalFinal(total);
        setTotalSelection(0);
        setNbSelection(0);
    }, [data]);

    const printFact = (rowData) => {
        let ids = '', sep = ''
        Object.values(rowData).forEach(fact => {
            ids += sep + fact.id_fact
            sep = ','
        })
        window.open(`https://www.extensia-france.com/printers/factPrinter2024.php?listeFact=${ids}`, "_blank")
    }
    const calculateTotalSelected = () => {
        let totalSelection = 0, nbSelection = 0;
        Object.values(data)
            .filter(f => f.tableData.checked)
            .forEach(item => {
                if (item.tableData.checked) {
                    totalSelection += parseFloat(item.totalHt);
                    nbSelection++;
                }
            });
        setTotalSelection(totalSelection);
        setNbSelection(nbSelection);
    }
    return (
        <div>
            <h5 className="d-flex justify-content-around">Total Facture HT : {euroFormat(totalFinal)}</h5>
            <MaterialTable
                style={{ fontSize: 12 }}
                columns={columns}
                options={{
                    showTextRowsSelected: false,
                    pageSize: 10,
                    selection: true,
                    actionsColumnIndex: -1,
                    pageSizeOptions: [10, 20, 30, 100],
                    padding: 'dense',
                }}
                actions={[
                    rowData => ({
                        icon: 'picture_as_pdf',
                        tooltip: 'Imprimer Facture',
                        onClick: () => printFact(rowData),
                        position: 'toolbarOnSelect',
                    }),
                    {
                        icon: 'picture_as_pdf',
                        tooltip: 'Imprimer Facture',
                        onClick: (e, rowData) => window.open(`https://www.extensia-france.com/printers/factPrinter2024.php?listeFact=${rowData.id_fact}`, "_blank"),
                        position: 'row',
                    }
                ]}
                onSelectionChange={calculateTotalSelected}
                data={[...data]}
                title=""
                components={{
                    Toolbar: props => (
                        <div>
                            <MTableToolbar {...props} />
                            <div className="d-flex justify-content-around">

                                <h5>{'Sélectionné(s) : ' + nbSelection}</h5>
                                <h5>{'Total Sélectionné : ' + totalSelection}</h5>
                            </div>
                        </div>
                    ),
                }}
            />
        </div>

    );
}

export default FactureTable;
