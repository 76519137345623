import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getListeAtelier, getListeOf, getListeOfSerti } from '../../../actions';
import { getOfs } from '../../../selectors/ofsSelector';
import Grid from '@material-ui/core/Grid';
import Axios from 'axios';
import { apiATC } from '../../../config';
import TableFinalise from './TableFinalise';
import JoaillierRetourForm from '../form/JoaillierRetourForm';
import { Card } from 'react-bootstrap';

import { CardContent, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { ajouterJoaillier, ajouterSertisseur, modifierJoaillier, modifierSertisseur } from '../action/AtelierAction';
import JoaillierForm from '../form/JoaillierForm';
import SertisseurForm from '../form/SertisseurForm';

import { makeStyles } from '@material-ui/core/styles';
import SertisseurRetourForm from '../form/SertisseurRetourForm';

const useStyles = makeStyles({
    cell: {
        fontSize: '16px',
    },
});
const FinaliserOf = ({ getListeAtelier, getListeOfSerti, getListeOf, listeOfs }) => {
    const classes = useStyles();
    const ofRef = useRef(null);
    const [ofSelected, setOfSelected] = useState(null);
    const [dataAtelier, setDataAtelier] = useState([]);
    const [dataAddAtelier, setDataAddAtelier] = useState([]);
    const [dataAtelierFinalise, setDataAtelierFinalise] = useState([]);
    const [dataSerti, setDataSerti] = useState([]);
    const [dataAddSerti, setDataAddSerti] = useState([]);
    const [dataSertiFinalise, setDataSertiFinalise] = useState([]);
    const [validatedInput, setValidatedInput] = useState(false);
    const [ofFinalise, setOfFinalise] = useState([]);


    useEffect(() => {
        getListeOf();
        getListeAtelier();
        getListeOfSerti();
    }, [getListeOf, getListeAtelier, getListeOfSerti]);



    const searchOf = async (of) => {
        if (!of) return;
        try {
            Axios.put(`${apiATC}/atelier/ouvrier.php?action=detail_ouvrier&of=${of.num_sachet}`)
                .then((response) => {
                    ofRef.current.focus();
                    const { atelier, serti } = response.data;
                    const newDataAtelier = atelier
                        .filter(item => item.rendu !== 1)
                        .map(item => ({
                            of: item.num_sachet,
                            id_ligne: item.id_ligne,
                            poids: item.poids_d,
                            poidsR: item.poids_r,
                            temps: item.plus_mo,
                        }));
                    const newDataAtelierFinalise = atelier.filter(item => item.rendu === 1);
                    const newDataSerti = serti
                        .filter(item => item.rendu_serti !== 1).map(item => ({
                            of: item.of_serto,
                            id_sert: item.id_sert,
                            poids: item.pds_d,
                            poidsR: item.pds_r,
                            prix: item.prix
                        }));;
                    const newDataSertiFinalise = serti.filter(item => item.rendu_serti === 1);

                    setDataAtelier(newDataAtelier);
                    setDataAtelierFinalise(newDataAtelierFinalise);
                    setDataSerti(newDataSerti);
                    setDataSertiFinalise(newDataSertiFinalise);
                    setDataAddAtelier([])
                    setDataAddSerti([])
                    setOfSelected(of)
                    if (newDataAtelier.length === 0 && newDataSerti.length === 0) {
                        setValidatedInput(true)
                    }
                    else setValidatedInput(false)

                })
                .catch(error => {
                    console.log(error);
                });
        } catch (error) {
            console.error("Une erreur s'est produite lors de l'envoi de la requête PUT :", error);
        }
    }

    const handleEnterPress = (event) => {
        if (event.key === 'Enter') {
            if (ofSelected && validatedInput) {
                const data = {
                    table: 'of',
                    primaryKey: 'num_sachet',
                    id: ofSelected.num_sachet,
                    statut: '550',
                };
                try {
                    Axios.put(`${apiATC}/general.php?action=modifier`, data)
                        .then((response) => {
                            setOfSelected(null);
                            setValidatedInput(false);
                            const isAlreadyPresent = ofFinalise.some(item => item.of === ofSelected.num_sachet);
                            if (!isAlreadyPresent) setOfFinalise([{ of: ofSelected.num_sachet }, ...ofFinalise])
                        })
                        .catch(error => {
                            console.log(error);
                        });
                } catch (error) {
                    console.log('Une erreur s\'est produite lors de la requête Axios :', error);
                }
            }
        }
    };

    const retourJoaillier = (formData) => {
        const { of, poids, poidsR, temps } = formData;
        const newData = {
            id_ligne: of.id_ligne,
            joaillier: of.label,
            of: of.n_sachet,
            poids,
            poidsR,
            temps,
        };
        modifierJoaillier(newData)
            .then(() => {
                searchOf(ofSelected)
            })
            .catch(error => {
                console.error("Une erreur s'est produite lors de l'exécution des requêtes :", error);
            });
    }
    const creerJoaillier = (formData) => {
        const { of, joaillier, poids, poidsR, temps } = formData;
        const newData = {
            id_joaillier: joaillier.id,
            of: of.num_sachet,
            poids,
            poidsR,
            temps,
            rendu: 1,
        };
        ajouterJoaillier(newData)
            .then(() => {
                setDataAddAtelier([])
                searchOf(ofSelected)

            })
            .catch(error => {
                console.error("Une erreur s'est produite lors de l'exécution des requêtes :", error);
            });
    }
    const retourSerti = (formData) => {
        const { of, poids, poidsR, prix } = formData;

        const newData = {
            of: of.of_serto,
            sertisseur: of.label,
            id_sert: of.id_sert,
            poids,
            poidsR,
            prix,
        };
        modifierSertisseur(newData)
            .then(() => {
                searchOf(ofSelected)
            })
            .catch(error => {
                console.error("Une erreur s'est produite lors de l'exécution des requêtes :", error);
            });
    }
    const ajouterSerti = (formData) => {
        const { of, sertisseur, poids, poidsR, prix } = formData;
        const newData = {
            id_sertisseur: sertisseur.id,
            of: of.num_sachet,
            poids,
            poidsR,
            prix,
            rendu_serti: 1,

        };
        ajouterSertisseur(newData)
            .then(() => {
                setDataAddSerti([])
                searchOf(ofSelected)

            })
            .catch(error => {
                console.error("Une erreur s'est produite lors de l'exécution des requêtes :", error);
            });
    }

    const addForm = (type) => {
        switch (type) {
            case 'joaillier':
                const existingJoaillierIndex = dataAddAtelier.findIndex(item => item.of === ofSelected.num_sachet);
                if (existingJoaillierIndex === -1) {
                    setDataAddAtelier(prevState => [...prevState, {
                        of: ofSelected.num_sachet,
                        id_ligne: null,
                        poids: null,
                        poidsR: null,
                        temps: null
                    }]);
                }
                break;
            case 'serti':
                const existingIndex = dataAddSerti.findIndex(item => item.of === ofSelected.num_sachet);
                if (existingIndex === -1) {
                    setDataAddSerti(prevState => [...prevState, {
                        of: ofSelected.num_sachet,
                        id_sert: null,
                        poids: null,
                        poidsR: null,
                        prix: null
                    }]);
                }
                break;
            default:
                break;
        }
    }
    const generateLabelError = () => {
        if (ofSelected) {
            if (ofSelected.statut === "550") return 'Of Déja Finalisé';
            else if (validatedInput) return "Pret Pour Finalisation"
            else return ''
        }
    }
    return (
        <div style={{ textAlign: 'center', paddingLeft: '15px', paddingRight: '15px' }}>
            <div style={{ position: 'relative' }}>
                <h1>Finaliser OF</h1>

                <div style={{ width: 'fit-content', margin: '0 auto' }}>
                    <Autocomplete
                        options={listeOfs}
                        getOptionLabel={(option) => option.num_sachet}
                        value={ofSelected}
                        onChange={(event, newValue) => searchOf(newValue)}
                        autoHighlight
                        autoComplete
                        clearOnEscape
                        getOptionSelected={(option) => option.num_sachet === ofSelected.num_sachet}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="N° OF"
                                variant="outlined"
                                autoFocus
                                inputRef={ofRef}
                                style={{ width: '240px', paddingBottom: '15px' }}
                                onKeyDown={handleEnterPress}
                                error={!validatedInput}
                                helperText={generateLabelError()} // Message d'erreur ou de validation
                            />
                        )}
                    />
                </div>
                {ofFinalise.length !== 0 && <div style={{ position: 'absolute', top: 0, right: 0 }}>
                    <h3>Historique</h3>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center" className={classes.cell}>N° OF</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {ofFinalise.map((row) => (
                                <TableRow key={row.of}>
                                    <TableCell align="center" className={classes.cell}>{row.of}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>}

            </div>

            {ofSelected && <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <TableFinalise addForm={addForm} dataOuvrier={dataAtelierFinalise} dataSerti={dataSertiFinalise} setDataOuvrier={setDataAtelierFinalise} setDataSerti={setDataSertiFinalise} />
                </Grid>
                <Grid item xs={12} sm={6} style={{ display: 'flex', flexWrap: 'wrap', paddingTop: 0, height: '200px' }}>
                    {dataAtelier.length > 0 && dataAtelier.map((atelier, index) => (
                        <Card key={index}>
                            <CardContent>
                                <h3>Retour Joaillier</h3>
                                <JoaillierRetourForm onSubmitForm={retourJoaillier} dataForm={atelier} />
                            </CardContent>
                        </Card>
                    ))}
                    {dataAddAtelier.length > 0 && dataAddAtelier.map((atelierAdd, index) => (
                        <Card key={index}>
                            <CardContent>
                                <h3>Joaillier</h3>
                                <JoaillierForm onSubmitForm={creerJoaillier} dataForm={atelierAdd} />
                            </CardContent>
                        </Card>
                    ))}
                    {dataSerti.length > 0 && dataSerti.map((serti, index) => (
                        <Card key={index}>
                            <CardContent>
                                <h3>Retour Sertisseur</h3>
                                <SertisseurRetourForm onSubmitForm={retourSerti} dataForm={serti} />
                            </CardContent>
                        </Card>
                    ))}
                    {dataAddSerti.length > 0 && dataAddSerti.map((sertiAdd, index) => (
                        <Card key={index}>
                            <CardContent>
                                <h3>Serti</h3>
                                <SertisseurForm onSubmitForm={ajouterSerti} dataForm={sertiAdd} />
                            </CardContent>
                        </Card>
                    ))}


                </Grid>
            </Grid>}


            {/* <FinalisationSerti sertisseurs={sertisseurs} /> */}
        </div>
    );


};

const mapStateToProps = (state) => {
    return {
        listeOfs: getOfs(state),
    };
};

const mapDispatchToProps = {
    getListeOf,
    getListeAtelier,
    getListeOfSerti,
};

export default connect(mapStateToProps, mapDispatchToProps)(FinaliserOf);
