import React, { Component } from 'react'
import ReactDOM from 'react-dom';
import { getListeJoaillier, getListeAtelier, getListeOf, getListeSertisseur, getListeOfSerti } from '../actions'
import { connect } from 'react-redux'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Radio, RadioGroup, TextField } from '@material-ui/core'
import { meekroDB } from '../config'
import TableJoaillier from './atelier/TableJoaillier'
import { Autocomplete } from '@material-ui/lab'
import { Col, Form } from 'react-bootstrap';
import { getTodaySqlFormat } from '../helpers/functions';
import Axios from 'axios';
import { getOfs } from '../selectors/ofsSelector';
import TableSertisseur from './atelier/TableSertisseur';

class Atelier extends Component {

    state = {
        of: null,
        joaillier: null,
        sertisseur: null,
        poids: '',
        poidsRendu: '',
        poidsSerti: '',
        poidsRenduSerti: '',
        temps: '',
        prix: '',
        ofAutoComplete: null,
        ofSerti: null,
        editer: false,
        retourner: false,
        ajouter: false,
        retourSerti: false,
        authorizedToFinalize: false,
        enCours:true,

    }
    componentDidMount() {
        this.props.getListeJoaillier()
        this.props.getListeOf()
        this.props.getListeSertisseur()
        this.props.getListeOfSerti()
    }
    setFocus = (node) => {
        node && ReactDOM.findDOMNode(node).focus();
    }
    change = (name, value) => {
        this.setState({ [name]: value })
    }

    envoiAtelier = e => {
        const data = {
            date_envoi: getTodaySqlFormat(),
            poids_d: this.state.poids,
            n_sachet: this.state.ofAutoComplete.num_sachet,
            id_ouvrier: this.state.joaillier.id,
        }
        Axios.post(`${meekroDB}/atelier/atelier.php`, data)
            .then((response) => {
                let of = ''
                if (this.state.of !== null && this.state.of.num_sachet !== '') {
                    of = this.state.of.num_sachet
                }
                this.props.getListeAtelier(of)
                    .then(() => {
                        this.setState({ authorizedToFinalize: this.getAuthorizedToFinalize() })
                    })
            })
            .catch(error => {
                console.log(error);
            });


        e.preventDefault()
        this.setFocus(this.myInputRef)
        this.setState({ ofAutoComplete: null, poids: '', poidsRendu: '', temps: '' })
    }
    retourAtelier = e => {
        e.preventDefault()

        this.setFocus(this.myInputRef)
        const data = {
            table: 'ouvrier',
            primaryKey: 'id_ligne',
            id: this.state.ofAutoComplete.id_ligne,
            poids_d: this.state.poids,
            poids_r: this.state.poidsRendu,
            temps: this.state.temps,
            rendu: 1,
            date_retour: getTodaySqlFormat(),
        }

        Axios.put(`${meekroDB}/general.php`, data)
            .then((response) => {
                let of = ''
                if (this.state.of !== null && this.state.of.num_sachet !== '') {
                    of = this.state.of.num_sachet
                }
                this.props.getListeAtelier(of)
                    .then(() => {
                        this.setState({ authorizedToFinalize: this.getAuthorizedToFinalize() })
                    })
                this.setState({ ofAutoComplete: null, poids: '', poidsRendu: '', temps: '' })
            })
            .catch(error => {
                console.log(error);
            });
    }
    envoiSerti = e => {
        const data = {
            date_e: getTodaySqlFormat(),
            pds_d: this.state.poidsSerti,
            of_serto: this.state.ofAutoComplete.num_sachet,
            id_serto: this.state.sertisseur.id,
        }
        Axios.post(`${meekroDB}/atelier/serti_envoi.php`, data)
            .then((response) => {
                let of = ''
                if (this.state.of !== null && this.state.of.num_sachet !== '') {
                    of = this.state.of.num_sachet
                }
                this.props.getListeOfSerti(of)
                    .then(() => {
                        this.setState({ authorizedToFinalize: this.getAuthorizedToFinalize() })
                    })
            })
            .catch(error => {
                console.log(error);
            });


        e.preventDefault()
        this.setFocus(this.myInputRef)
        this.setState({ ofAutoComplete: null, poidsSerti: '', poidsRenduSerti: '', prix: '' })
    }
    retourSerti = e => {
        e.preventDefault()
        this.setFocus(this.myInputRef)

        const data = {
            table: 'serti',
            primaryKey: 'id_sert',
            id: this.state.ofSerti.id_sert,
            pds_d: this.state.poidsSerti,
            pds_r: this.state.poidsRenduSerti,
            prix: this.state.prix,
            rendu_serti: 1,
        }

        Axios.put(`${meekroDB}/general.php`, data)
            .then((response) => {
                const of = {
                    table: 'of',
                    primaryKey: 'num_sachet',
                    id: this.state.ofSerti.of_serto,
                    statut: '500'
                }
                Axios.put(`${meekroDB}/general.php`, of)
                    .then((response) => {
                        let of = ''
                        if (this.state.of !== null && this.state.of.num_sachet !== '') {
                            of = this.state.of.num_sachet
                        }
                        this.props.getListeOfSerti(of)
                            .then(() => {
                                this.setState({ authorizedToFinalize: this.getAuthorizedToFinalize() })
                            })
                        this.setState({ ofSerti: null, poidsSerti: '', poidsRenduSerti: '', prix: '' })
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(error => {
                console.log(error);
            });
    }
    handleEnter = (e) => {
        if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault()
            this.envoiAtelier(e)
        }
    }

    handleEnterSerti = (e) => {
        if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault()
            this.envoiSerti(e)
        }
    }
    handleRetour = e => {
        if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault()
            this.retourAtelier(e)
        }
    }
    handleRetourSerti = e => {
        if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault()
            this.retourSerti(e)
        }
    }
    enter = e => {

        if (e.key === 'Enter' || e.key === ' ') {
            this.state.poidsRendu !== '' && this.setFocus(this.myInputTemps)
            e.preventDefault()
        }
    }
    close = () => {
        this.setState({ editer: false, retourner: false })
    }
    preventEnter = e => {
        e.key === 'Enter' && e.preventDefault()
    }
    handleFocus = (e, node) => {
        //console.log(node);
        if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault()
            this.setFocus(node)
        }
    }
    finaliserOf = () => {
        const of = {
            table: 'of',
            primaryKey: 'num_sachet',
            id: this.state.of.num_sachet,
            statut: '500'
        }
        Axios.put(`${meekroDB}/general.php`, of)
            .then((response) => {

            })
            .catch(error => {
                console.log(error);
            });
        //console.log(this.getAuthorizedToFinalize());
    }
    onChangeSearch = (e, values) => {
        if (values === null) {
            const of = ''
            this.props.getListeAtelier(of)
                .then(() => {
                    this.props.getListeOfSerti(of)
                        .then(() => {
                            this.setState({ of: values, poidsSerti: '', poidsRenduSerti: '', temps: '', ofAutoComplete: null, ofSerti: null, authorizedToFinalize: false })

                        })

                })
        }
        else {
            const of = values.num_sachet
            this.props.getListeAtelier(of)
                .then(() => {
                    this.props.getListeOfSerti(of)
                        .then(() => {
                            this.setState({ of: values, ofAutoComplete: values, ofSerti: values, authorizedToFinalize: this.getAuthorizedToFinalize() })
                        })

                })
        }
    }
    getAuthorizedToFinalize = () => {
        const atelierToBack = Object.values(this.props.listeAtelier).filter(r => r.rendu === '0')
        const sertiToBack = Object.values(this.props.listeOfSerti).filter(r => r.rendu_serti === '0')

        if (sertiToBack.length || atelierToBack.length) return false
        else return true
    }
    handleRadioChange = (event) => {
        this.setState({
            enCours: event.target.value === 'en cours'
        });
    }
    render() {
        return (
            <div className='container-fluid form form-group'>
                <div className="d-flex justify-content-center  form-group">

                    <Autocomplete
                        value={this.state.of}
                        options={this.props.listeOfs}
                        getOptionLabel={(option) => option.num_sachet}
                        onChange={this.onChangeSearch}

                        //getOptionSelected={(option) => option.num_sachet === this.state.ofAutoComplete.num_sachet}
                        autoHighlight
                        autoComplete
                        clearOnEscape
                        style={{ width: 220, zIndex: 0 }}
                        renderInput={(params) => <TextField autoFocus onKeyPress={this.preventEnter} {...params} label="of" variant="outlined" size='small' />}
                    />
                    <button
                        type="button"
                        className="btn btn-link btn-sm"
                        onClick={this.finaliserOf}
                        disabled={this.state.of === null || this.state.of.num_sachet === '' || !this.state.authorizedToFinalize}
                    >
                        Finaliser OF
                    </button>
                    <FormControl component="fieldset">
                        <RadioGroup
                            row
                            aria-label="status"
                            name="status"
                            defaultValue="en cours"
                            onChange={this.handleRadioChange}
                        >
                            <FormControlLabel value="en cours" control={<Radio color="primary" />} label="En cours" />
                            <FormControlLabel value="rendu" control={<Radio color="primary" />} label="Rendu" />
                        </RadioGroup>
                    </FormControl>



                </div>
                <div className="row">

                    <div className="col-sm ">
                        <button onClick={() => this.setState({ editer: true })} className='btn btn-link btn-sm'>Ajouter</button>
                        <button
                            onClick={() => this.setState({ retourner: true })}
                            disabled={Object.values(this.props.listeAtelier).filter(r => r.rendu === '0').length === 0}
                            className='btn btn-link btn-sm'>
                            Retourner
                        </button>
                        <TableJoaillier of={this.state.of} enCours={this.state.enCours} />
                    </div>
                    <div className="col-sm ">
                        <button onClick={() => this.setState({ ajouter: true })} className='btn btn-link btn-sm'>Ajouter</button>
                        <button
                            onClick={() => this.setState({ retourSerti: true })}
                            disabled={Object.values(this.props.listeOfSerti).filter(r => r.rendu_serti === '0').length === 0}
                            className='btn btn-link btn-sm'>
                            Retourner
                        </button>
                        <TableSertisseur of={this.state.of} enCours={this.state.enCours} />
                    </div>
                    <Dialog
                        open={this.state.editer}
                        onClose={this.close}
                        fullWidth={true}
                        maxWidth={'lg'}
                        style={{ textAlign: "center" }}
                    >
                        <DialogTitle id="form-dialog-title">Envoi Atelier {this.state.ofAutoComplete && this.state.ofAutoComplete.ref_cv + ' - ' + this.state.ofAutoComplete.ref}</DialogTitle>
                        <DialogContent >
                            <div className='d-flex justify-content-center'>
                                <Form>
                                    <Form.Group controlId="formEnvoiAtelier">
                                        <Form.Row>
                                            <Col>
                                                <Autocomplete
                                                    value={this.state.joaillier}
                                                    options={this.props.listeJoaillier.filter(r => r.actif === 1)}
                                                    getOptionLabel={(option) => option.label}
                                                    onChange={(event, values) => this.setState({ joaillier: values }, () => {
                                                        if (this.state.ofAutoComplete === null) this.setFocus(this.myInputRef)
                                                        else this.setFocus(this.myInputPds)
                                                    }
                                                    )}
                                                    getOptionSelected={(option) => option.id === this.state.joaillier.id}
                                                    autoHighlight
                                                    autoComplete
                                                    clearOnEscape
                                                    style={{ width: 220 }}
                                                    renderInput={(params) => <TextField onKeyPress={this.preventEnter}  {...params} label="Joaillier" autoFocus variant="outlined" size='small' />}
                                                />
                                            </Col>
                                            <Col>
                                                <Autocomplete
                                                    value={this.state.ofAutoComplete}
                                                    options={this.props.listeOfs}
                                                    getOptionLabel={(option) => option.num_sachet}
                                                    onChange={(event, values) => this.setState({ ofAutoComplete: values }, () => this.setFocus(this.myInputPds))}
                                                    getOptionSelected={(option) => option.num_sachet === this.state.ofAutoComplete.num_sachet}
                                                    autoHighlight
                                                    autoComplete
                                                    clearOnEscape
                                                    style={{ width: 220 }}
                                                    renderInput={(params) => <TextField onKeyPress={this.preventEnter} inputRef={c => (this.myInputRef = c)}  {...params} label="of" variant="outlined" size='small' />}
                                                />
                                            </Col>
                                            <Col>
                                                <TextField style={{ marginLeft: 3 }}
                                                    type='number' label='Poids'
                                                    inputRef={c => (this.myInputPds = c)}
                                                    onChange={e => this.setState({ poids: e.target.value })}
                                                    value={this.state.poids}
                                                    onKeyPress={this.handleEnter}
                                                    variant='outlined'
                                                    size='small'

                                                />
                                            </Col>
                                            <Col>
                                                <button
                                                    onClick={this.envoiAtelier}
                                                    className='btn btn-link btn-sm'
                                                >ENVOYER
                                                </button>
                                            </Col>



                                        </Form.Row>
                                    </Form.Group>

                                </Form>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.setState({ editer: false })} color="primary">
                                Fermer
                            </Button>
                        </DialogActions>
                    </Dialog>


                    <Dialog
                        open={this.state.retourner}
                        onClose={this.close}
                        fullWidth={true}
                        maxWidth={'lg'}
                        style={{ textAlign: "center" }}
                    >
                        <DialogTitle id="form-dialog-title">Retour Atelier {this.state.ofAutoComplete && this.state.ofAutoComplete.label + '  ' + this.state.ofAutoComplete.ref + '  ' + this.state.ofAutoComplete.refDesc}</DialogTitle>
                        <DialogContent >
                            <div className='d-flex justify-content-center'>
                                <Form>
                                    <Form.Group controlId="formEnvoiAtelier">

                                        <Form.Row>

                                            <Col>
                                                <Autocomplete
                                                    value={this.state.ofAutoComplete}
                                                    options={Object.values(this.props.listeAtelier).filter(r => r.rendu === '0')}
                                                    getOptionLabel={(option) => option.num_sachet}
                                                    onChange={(event, values) => this.setState({ ofAutoComplete: values, poids: values.poids_d, temps: values.plus_mo }, () => this.setFocus(this.myInpuPdsRendu))}
                                                    getOptionSelected={(option) => option.num_sachet === this.state.ofAutoComplete.num_sachet}
                                                    autoHighlight
                                                    autoComplete
                                                    clearOnEscape

                                                    style={{ width: 220 }}
                                                    renderInput={(params) => <TextField onKeyPress={this.preventEnter} inputRef={c => (this.myInputRef = c)} autoFocus  {...params} label="of" variant="outlined" size='small' />}
                                                />
                                            </Col>
                                            <Col>
                                                <TextField style={{ marginLeft: 3 }}
                                                    type='number' label='Poids'
                                                    onChange={e => this.setState({ poids: e.target.value })}
                                                    value={this.state.poids}
                                                    onKeyPress={this.preventEnter}
                                                    variant='outlined'
                                                    size='small'

                                                />
                                            </Col>
                                            <Col>
                                                <TextField style={{ marginLeft: 3 }}
                                                    type='number' label='Poids Rendu'
                                                    inputRef={c => (this.myInpuPdsRendu = c)}
                                                    onChange={e => this.setState({ poidsRendu: e.target.value })}
                                                    value={this.state.poidsRendu}
                                                    onKeyPress={this.enter}
                                                    variant='outlined'
                                                    size='small'
                                                    autoFocus={this.state.ofAutoComplete !== null}

                                                />
                                            </Col>
                                            <Col>
                                                <TextField style={{ marginLeft: 3 }}
                                                    type='number' label='Temps'
                                                    inputRef={c => (this.myInputTemps = c)}
                                                    onChange={e => this.setState({ temps: e.target.value })}
                                                    value={this.state.temps}
                                                    onKeyPress={this.handleRetour}
                                                    variant='outlined'
                                                    size='small'

                                                />
                                            </Col>
                                            <Col>
                                                <button
                                                    onClick={this.retourAtelier}
                                                    className='btn btn-link btn-sm'
                                                >RETOURNER
                                                </button>
                                            </Col>



                                        </Form.Row>
                                    </Form.Group>

                                </Form>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.setState({ retourner: false })} color="primary">
                                Fermer
                            </Button>
                        </DialogActions>
                    </Dialog>



                    <Dialog
                        open={this.state.ajouter}
                        onClose={() => this.setState({ ajouter: false })}
                        fullWidth={true}
                        maxWidth={'lg'}
                        style={{ textAlign: "center" }}
                    >
                        <DialogTitle id="form-dialog-title">Envoi Serti {this.state.ofAutoComplete && this.state.ofAutoComplete.ref_cv + ' - ' + this.state.ofAutoComplete.ref}</DialogTitle>
                        <DialogContent >
                            <div className='d-flex justify-content-center'>
                                <Form>
                                    <Form.Group controlId="formEnvoiAtelier">
                                        <Form.Row>
                                            <Col>
                                                <Autocomplete
                                                    value={this.state.sertisseur}
                                                    options={this.props.listeSertisseur.filter(r => r.actif === 1)}
                                                    getOptionLabel={(option) => option.label}
                                                    onChange={(event, values) => this.setState({ sertisseur: values }, () => {
                                                        if (this.state.ofAutoComplete === null) this.setFocus(this.myInputRef)
                                                        else this.setFocus(this.myInputPds)
                                                    })}
                                                    getOptionSelected={(option) => option.id === this.state.sertisseur.id}
                                                    autoHighlight
                                                    autoComplete
                                                    clearOnEscape
                                                    style={{ width: 220 }}
                                                    renderInput={(params) => <TextField onKeyPress={this.preventEnter}  {...params} label="Sertisseur" autoFocus variant="outlined" size='small' />}
                                                />
                                            </Col>
                                            <Col>
                                                <Autocomplete
                                                    value={this.state.ofAutoComplete}
                                                    options={this.props.listeOfs}
                                                    getOptionLabel={(option) => option.num_sachet}
                                                    onChange={(event, values) => this.setState({ ofAutoComplete: values }, () => this.setFocus(this.myInputPds))}
                                                    getOptionSelected={(option) => option.num_sachet === this.state.ofAutoComplete.num_sachet}
                                                    autoHighlight
                                                    autoComplete
                                                    clearOnEscape
                                                    style={{ width: 220 }}
                                                    renderInput={(params) => <TextField onKeyPress={this.preventEnter} inputRef={c => (this.myInputRef = c)}  {...params} label="of" variant="outlined" size='small' />}
                                                />
                                            </Col>
                                            <Col>
                                                <TextField style={{ marginLeft: 3 }}
                                                    type='number' label='Poids'
                                                    inputRef={c => (this.myInputPds = c)}
                                                    onChange={e => this.setState({ poidsSerti: e.target.value })}
                                                    value={this.state.poidsSerti}
                                                    onKeyPress={this.handleEnterSerti}
                                                    variant='outlined'
                                                    size='small'

                                                />
                                            </Col>
                                            <Col>
                                                <button
                                                    onClick={this.envoiSerti}
                                                    className='btn btn-link btn-sm'
                                                >ENVOYER
                                                </button>
                                            </Col>



                                        </Form.Row>
                                    </Form.Group>

                                </Form>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.setState({ ajouter: false })} color="primary">
                                Fermer
                            </Button>
                        </DialogActions>
                    </Dialog>




                    <Dialog
                        open={this.state.retourSerti}
                        onClose={() => this.setState({ retourSerti: false })}
                        fullWidth={true}
                        maxWidth={'lg'}
                        style={{ textAlign: "center" }}
                    >
                        <DialogTitle id="form-dialog-title">Retour Serti {this.state.ofSerti && this.state.ofSerti.label + '  ' + this.state.ofSerti.ref + '  ' + this.state.ofSerti.refDesc}</DialogTitle>
                        <DialogContent >
                            <div className='d-flex justify-content-center'>
                                <Form>
                                    <Form.Group >

                                        <Form.Row>

                                            <Col>
                                                <Autocomplete
                                                    value={this.state.ofSerti}
                                                    options={Object.values(this.props.listeOfSerti).filter(r => r.rendu_serti === '0')}
                                                    getOptionLabel={(option) => option.of_serto}
                                                    onChange={(event, values) => this.setState({ ofSerti: values, poidsSerti: values.pds_d, prix: values.plus_serti }, () => this.setFocus(this.myInpuPdsRendu))}
                                                    getOptionSelected={(option) => option.of_serto === this.state.ofSerti.of_serto}
                                                    autoHighlight
                                                    autoComplete
                                                    clearOnEscape

                                                    style={{ width: 220 }}
                                                    renderInput={(params) => <TextField onKeyPress={this.preventEnter} inputRef={c => (this.myInputRef = c)} autoFocus  {...params} label="of" variant="outlined" size='small' />}
                                                />
                                            </Col>
                                            <Col>
                                                <TextField style={{ marginLeft: 3 }}
                                                    type='number' label='Poids'
                                                    onChange={e => this.setState({ poidsSerti: e.target.value })}
                                                    value={this.state.poidsSerti}
                                                    onKeyPress={this.preventEnter}
                                                    variant='outlined'
                                                    size='small'

                                                />
                                            </Col>
                                            <Col>
                                                <TextField style={{ marginLeft: 3 }}
                                                    type='number' label='Poids Rendu'
                                                    inputRef={c => (this.myInpuPdsRendu = c)}
                                                    onChange={e => this.setState({ poidsRenduSerti: e.target.value })}
                                                    value={this.state.poidsRenduSerti}
                                                    onKeyPress={this.enter}
                                                    variant='outlined'
                                                    size='small'
                                                    autoFocus={this.state.ofSerti !== null}

                                                />
                                            </Col>
                                            <Col>
                                                <TextField style={{ marginLeft: 3 }}
                                                    type='number' label='Prix'
                                                    inputRef={c => (this.myInputTemps = c)}
                                                    onChange={e => this.setState({ prix: e.target.value })}
                                                    value={this.state.prix}
                                                    onKeyPress={this.handleRetourSerti}
                                                    variant='outlined'
                                                    size='small'

                                                />
                                            </Col>
                                            <Col>
                                                <button
                                                    onClick={this.retourSerti}
                                                    className='btn btn-link btn-sm'
                                                >RETOURNER
                                                </button>
                                            </Col>



                                        </Form.Row>
                                    </Form.Group>

                                </Form>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.setState({ retourSerti: false })} color="primary">
                                Fermer
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        listeJoaillier: state.generalData.listeJoaillier,
        listeSertisseur: state.generalData.listeSertisseur,
        listeAtelier: state.generalData.listeAtelier,
        listeOfs: getOfs(state),
        listeOfSerti: state.generalData.listeOfSerti,
    }
}
const mapDispatchToProps = {
    getListeJoaillier,
    getListeAtelier,
    getListeOf,
    getListeSertisseur,
    getListeOfSerti,
}
export default connect(mapStateToProps, mapDispatchToProps)(Atelier)