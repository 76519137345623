import { List } from 'immutable'
import MaterialTable, { MTableToolbar } from 'material-table'
import React from 'react'
import { localization } from '../../config'

const HistoriqueStockProduit = ({ dataHisto }) => {
    const returnList = () => {
        const data = List(dataHisto)
        return data.toJS()
    }
    return (
        <div className='d-flex justify-content-center'>
            <MaterialTable
                style={{ fontSize: 12 }}

                columns={[
                    { title: 'Of', field: 'of' },
                    { title: 'Métal', field: 'lemetal' },
                    { title: 'Type', field: 'letype' },
                    { title: 'Stade', field: 'lestade' },
                    { title: 'Ref', field: 'ref' },
                    { title: 'Description', field: 'description' },
                    { title: 'Poids', field: 'poids', type: 'numeric' },
                    { title: 'Prix Fab', field: 'prix_r' },
                    { title: 'Prix', field: 'leprix' },
                    { title: 'Client', field: 'Nom_agence' },
                    { title: 'Date Entrée', field: 'de' },
                    { title: 'Date Sortie', field: 'dr' },
                ]}

                options={{
                    pageSize: 10,
                }}

                data={returnList()}
                title="Historique"

                components={{
                    Toolbar: props => (
                        <MTableToolbar {...props} />
                    ),
                }}

                localization={localization}
            />

        </div>
    )
}

export default (HistoriqueStockProduit)