import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux'
import { getCours } from '../actions'

import Image from 'react-bootstrap/Image'
import { getValueFromId, createPrixPierre, createPrixCoefPierre, createDescPierre } from '../helpers/functions'
import { imagesRepo } from '../config';


const useStyles = makeStyles(() => ({
    table: {
        minWidth: 400,
    },
    tableContainer: {
        paddingRight: 15,
        paddingLeft: 15,
    },
    image: {
        width: 150,
        height: 150,
        marginLeft: -15,
        marginTop: -30,
    },
    redText: {
        color: 'red',
    },
    bigFontSize: {
        fontSize: 20,
    },
}));


const createTableCell = (prodSelected, listeCours) => {
    const prod = prodSelected[0].produit
    const { entourage, centre } = prodSelected[0]
    const cours = listeCours ? listeCours : []
    let revientEntourage = 0, prixEntourage = 0, revientcentre = 0, prixCentre = 0

    const tab = [
        { id: 1, qte: prod.plus_mo, description: 'MO', prixR: '', coef: "", prix: Math.ceil(prod.plus_mo * getValueFromId('2', cours)) },
        { id: 2, qte: prod.plus_fonte, description: 'Fonte', prixR: '', coef: "", prix: Math.ceil(prod.plus_fonte) },
        { id: 3, qte: prod.plus_poids, description: 'Poids', prixR: '', coef: "", prix: Math.ceil(prod.plus_poids / 1000 * getValueFromId('3', cours)) },
        { id: 4, qte: prod.plus_serti, description: 'Serti', prixR: '', coef: "", prix: Math.ceil(prod.plus_serti) },
        { id: 5, qte: prod.prix_divers, description: 'Divers', prixR: '', coef: "", prix: Math.ceil(prod.prix_divers) },
        { id: 6, qte: 'MFS', description: '', prixR: '', coef: '', prix: '' + prod.revient_mfs },
        { id: 98, qte: '', description: '', prixR: '', coef: '', prix: '' },

    ]
    entourage.forEach(pierre => {
        let final_desc = createDescPierre(pierre, pierre.pds_r);
        let prix_r = createPrixPierre(pierre, pierre.pds_r, getValueFromId('1', cours));
        let prix_v = createPrixCoefPierre(pierre, pierre.pds_r, getValueFromId('1', cours));
        revientEntourage += Math.ceil(prix_r)
        prixEntourage += Math.ceil(prix_v)
        tab.push({ id: final_desc, qte: pierre.qte, description: final_desc, prixR: prix_r, coef: pierre.coeff, prix: prix_v })
    })
    centre.forEach(pierre => {
        let final_desc = createDescPierre(pierre, pierre.pds_r);
        let prix_r = createPrixPierre(pierre, pierre.pds_r, getValueFromId('1', cours));
        let prix_v = createPrixCoefPierre(pierre, pierre.pds_r, getValueFromId('1', cours));

        if (pierre.centre === '1') {
            revientcentre += Math.ceil(prix_r)
            prixCentre += Math.ceil(prix_v)
        } else {
            revientEntourage += Math.ceil(prix_r)
            prixEntourage += Math.ceil(prix_v)
        }
        tab.push({ id: final_desc, qte: pierre.qte, description: final_desc, prixR: prix_r, coef: pierre.coeff, prix: prix_v, centre: pierre.centre })
    })

    tab.push(
        { id: 9, qte: 'TOTAL PIERRES', description: '', prixR: Math.ceil(revientEntourage + revientcentre), coef: '', prix: Math.ceil(prixEntourage + prixCentre) },
        { id: 10, qte: '', description: '', prixR: '', coef: '', prix: '' },

        { id: 14, qte: 'PRIX PIECE', description: '', prixR: prod.revient_piece, coef: '', prix: prod.total3 },
        { id: 15, qte: 'PRIX CV', description: '', prixR: prod.revient_cv, coef: '', prix: prod.totalcv },
        { id: 16, qte: 'PRIX MFS', description: '', prixR: prod.revient_mfs, coef: '', prix: prod.mfs },
        { id: 17, qte: '', description: '', prixR: '', coef: '', prix: '' },
        { id: 18, qte: 'MARGE', description: '', prixR: '', coef: '', prix: prod.total3 - prod.revient_piece + ' : ' + Math.round((prod.total3 - prod.revient_piece) * 100 / prod.total3) + ' %' },

    )
    return tab
}

const FicheProduit = ({ prodSelected, cours, getCours }) => {
    const classes = useStyles();

    const [prod, setProd] = useState([]);

    useEffect(() => {
        getCours();
        if (prodSelected.length !== 0) {
            setProd(prodSelected[0].produit);
        }
    }, [prodSelected, getCours]);

    return (
        <>
            {prodSelected.length !== 0 &&
                <div className="d-flex justify-content-center">
                    {prod.image !== '' && <Image style={{ width: 150, height: 150, marginLeft: -15, marginTop: -30 }} src={`${imagesRepo}thumbs.php?src=${prod.img_centre !== 'no_pic.jpg' ? prod.img_centre : prod.image}&size=150`} />}


                    <div >
                        <div>
                            {prod.leType + ' ' + prod.ref_cv}
                        </div>
                        <div>
                            {prod.ref}
                        </div>
                        <div>
                            {'Type Tarif : ' + prod.descTarif}
                        </div>

                    </div>

                </div>
            }
            {prodSelected.length !== 0 && <TableContainer className={classes.tableContainer} component={Paper}>
                <Table style={{ minWidth: 30 }} className={classes.table} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Qte</TableCell>
                            <TableCell align="left">Description</TableCell>
                            <TableCell align="right">Revient</TableCell>
                            <TableCell align="right">Coef</TableCell>
                            <TableCell align="right">Prix</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {createTableCell(prodSelected, cours).map((row, index) => (
                            <TableRow key={index} >
                                <TableCell align="center" component="th" scope="row" className={row.centre === 1 ? classes.redText : ''}>
                                    {row.qte}
                                </TableCell>
                                <TableCell align="left" className={row.centre === 1 ? classes.redText : ''}>{row.description}</TableCell>
                                <TableCell align="right" className={row.centre === 1 ? classes.redText : ''}>{row.prixR}</TableCell>
                                <TableCell align="right" className={row.centre === 1 ? classes.redText : ''}>{row.coef}</TableCell>
                                <TableCell align="right" className={`${row.centre === 1 ? classes.redText : ''} ${row.id === 14 ? classes.bigFontSize : ''}`}>{row.prix}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer >}
        </>
    );
}
const mapStateToProps = (state) => {
    return {
        cours: state.cours.listeCours,
        prodSelected: state.produit.selectedProduct,
    }
}
const mapDispatchToProps = {
    getCours
}
export default connect(mapStateToProps, mapDispatchToProps)(FicheProduit)
