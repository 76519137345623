import React, { useState, useEffect, useRef } from 'react';
import Button from '@material-ui/core/Button';
import MaterialTable from 'material-table';
import { localization } from '../../../config';
import SertisseurRetourForm from '../form/SertisseurRetourForm';
import { modifierSertisseur } from '../action/AtelierAction';

const RetourSerti = () => {
    const [data, setData] = useState([]);
    const [formValues, setFormValues] = useState(null);

    const dataRef = useRef(data);

    useEffect(() => {
        dataRef.current = data;
    }, [data]);

    const handleAjouter = (formData) => {
        const { of, poids, poidsR, prix } = formData;

        const newData = {
            of: of.of_serto,
            sertisseur: of.label,
            id_sert: of.id_sert,
            poids,
            poidsR,
            prix,
        };
        modifierSertisseur(newData)
            .then(() => {
                const existingIndex = data.findIndex(item => item.of === of.of_serto);
                if (existingIndex !== -1) {
                    // Si le "N° OF" existe déjà, mettez à jour les données
                    setData(prevData => {
                        const updatedData = [...prevData];
                        updatedData[existingIndex] = newData;
                        return updatedData;
                    });
                } else {
                    // Sinon, ajoutez une nouvelle entrée
                    setData([newData, ...data]);
                }
            })
    };
    const handleSupprimer = async (rowData) => {
        const { of, sertisseur, id_sert, poids } = rowData
        const newData = {
            of,
            sertisseur,
            id_sert,
            poids,
            poidsR: 0,
            prix: 0,
            rendu_serti: 0,
        };
        modifierSertisseur(newData)
            .then(async () => {
                const index = data.indexOf(rowData);
                const newData = [...data];
                newData.splice(index, 1);
                setData(newData);
                setFormValues();
            })
    };



    const handleRowClick = (event, rowData) => {
        const populateData = {
            of: rowData.of,
            poids: rowData.poids,
            poidsR: rowData.poidsR,
            prix: rowData.prix,
        }
        setFormValues(populateData);

    };

    return (
        <div>
            <div className="row" style={{ display: 'flex', justifyContent: 'center' }}>
                <div className="col-md-4">
                    <SertisseurRetourForm onSubmitForm={handleAjouter} dataForm={formValues} />
                </div>
                <div className="col-md-7">
                    <MaterialTable
                        title=""
                        options={{
                            search: false,
                        }}
                        columns={[
                            { title: 'Sertisseur', field: 'sertisseur', align: 'center' },
                            { title: 'OF', field: 'of' },
                            { title: 'Poids', field: 'poids' },
                            { title: 'PoidsR', field: 'poidsR' },
                            { title: 'Prix', field: 'prix' },
                            {
                                title: '',
                                field: 'action',
                                render: rowData => (
                                    <Button
                                        variant="contained"
                                        size='small'
                                        color="secondary"
                                        onClick={(e) => { e.stopPropagation(); handleSupprimer(rowData) }}
                                    >
                                        Supprimer
                                    </Button>
                                ),
                                align: 'center'
                            }
                        ]}
                        components={{
                            Toolbar: props => (
                                <h1 style={{ textAlign: 'center' }}>Retour Serti</h1>
                            ),
                        }}
                        data={data}
                        localization={localization}
                        onRowClick={handleRowClick}
                    />
                </div>
            </div>
        </div>
    );
};



export default RetourSerti;
