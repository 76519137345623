import { TextField } from '@material-ui/core'
import React, { useState } from 'react'
import { addDayToToday, getTodaySqlFormat } from '../helpers/functions'
import TreeClient from './treeClient'

const CreateEffect = () => {
    const [crea, setCrea] = useState(getTodaySqlFormat())
    const [eche, setEche] = useState(addDayToToday(30))
    const [client, setClient] = useState('')
    const [valeur, setValeur] = useState('')
    const [nbDecoupe, setNbDecoupe] = useState(1)
    const [numEffet, setNumEffet] = useState(1500)

    const handleChangeClient = (data) => {
        const idClient = data.split(',')
        console.log(idClient[0]);
        setClient(idClient[0])
    }
    const createTraite = (e) => {
        const params = '?id_bij=' + client
            + '&datec=' + crea
            + '&datee=' + eche
            + '&num_effet=' + numEffet
            + '&montant=' + valeur
            + '&nbtraite=' + nbDecoupe
        //console.log(params);

        window.open(`https://www.extensia-france.com/printers/traite_unique.php${params}`, "_blank")

    }
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-auto" style={{ minWidth: 275, maxWidth: 275 }} >
                    <TreeClient handleChange={handleChangeClient} />
                </div>
                <div className="col-auto">
                    <div className='form-group'>
                        <TextField type='date' value={crea} onChange={e => setCrea(e.target.value)} label='Création' variant="outlined" margin="dense" />
                    </div>
                    <div className='form-group'>
                        <TextField type='date' value={eche} onChange={e => setEche(e.target.value)} label='Echéance' variant="outlined" margin="dense" />
                    </div>
                    <div className='form-group'>
                        <TextField value={valeur} onChange={e => setValeur(e.target.value)} label='Total TTC' variant="outlined" margin="dense" />
                    </div>
                    <div className='form-group'>
                        <TextField value={numEffet} onChange={e => setNumEffet(e.target.value)} label='N° Effet' variant="outlined" margin="dense" />
                    </div>
                    <div className='form-group'>
                        <TextField value={nbDecoupe} onChange={e => setNbDecoupe(e.target.value)} label='Nb Découpe' variant="outlined" margin="dense" />
                    </div>
                    <button className="btn btn-link btn-sm" onClick={createTraite}>Créer Traite</button>
                </div>
            </div>




        </div>
    )
}

export default (CreateEffect)