import React from 'react';
import MaterialTable from 'material-table';
import { Button } from '@material-ui/core';
import { localization } from '../../../config';
import { modifierBoiteOr, supprimerBoiteOr } from '../action/AtelierAction';

const TableBoiteOr = ({ data }) => {

    const handleSupprimer = (row) => {
        const confirmation = window.confirm("Voulez vous supprimer ?");
        if (confirmation) {
            supprimerBoiteOr(row);
        }
    }

    const handleRowUpdate = (newData, oldData) => {
        return new Promise((resolve, reject) => {
            modifierBoiteOr(newData)
                .then(response => {
                    resolve();
                })
                .catch(error => {
                    console.error('Erreur lors de la mise à jour des données:', error);
                    reject();
                });
        });
    }

    return (
        <MaterialTable
            title="Liste Boite Or"
            options={{
                sorting: true,
                paging: true,
                padding: 'dense',
                pageSize: 10,
            }}
            columns={[
                { title: 'Joaillier', field: 'label', align: 'center', editable: 'never' },
                { title: 'Poids Donne', field: 'done_ouvr', editable: 'onUpdate' },
                { title: 'Poids Rendu', field: 'rendu_ouvr', editable: 'onUpdate' },
                { title: 'Date', field: 'date', editable: 'never', render: rowData => rowData['db'] },
                {
                    title: '',
                    field: 'action',
                    editable: 'never',
                    render: rowData => (
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={(e) => { e.stopPropagation(); handleSupprimer(rowData) }}>
                            Supprimer
                        </Button>
                    ),
                    align: 'center'
                }
            ]}
            data={data}
            localization={localization}
            editable={{
                onRowUpdate: handleRowUpdate // Associer la fonction de mise à jour des données
            }}
        />

    );
}

export default TableBoiteOr;
