import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { Box, Card, TextField, Typography, Toolbar, Checkbox, FormControlLabel } from '@material-ui/core';
import { localization } from '../../../config';

const TableProduit = ({ data, onRowClick }) => {
    const [filteredData, setFilteredData] = useState(data || []);
    const [filter, setFilter] = useState('');
    const [refFilter, setRefFilter] = useState('');
    const [clickedOf, setClickedOf] = useState(null);
    const [showReservable, setShowReservable] = useState(false);
    const [showReserved, setShowReserved] = useState(false);

    useEffect(() => {
        setFilteredData(
            data.filter(row => {
                const matchesFilter = filter === '' || row.of.toString().includes(filter);
                const matchesRefFilter = refFilter === '' || row.ref.includes(refFilter);
                setClickedOf(null);
                if (showReservable && showReserved) {
                    return matchesFilter && matchesRefFilter && (!row.dejaReserve || Number(row.of_reservation) !== 0);
                } else if (showReservable) {
                    return matchesFilter && matchesRefFilter && !row.dejaReserve && Number(row.of_reservation) === 0;
                } else if (showReserved) {
                    return matchesFilter && matchesRefFilter && Number(row.of_reservation) !== 0;
                }

                return matchesFilter && matchesRefFilter;
            })
        );
    }, [filter, refFilter, showReservable, showReserved, data]);

    const countReservable = filteredData.filter(row => !row.dejaReserve && Number(row.of_reservation) === 0).length;
    const countReserve = filteredData.filter(row => Number(row.of_reservation) !== 0).length;

    const handleRowClick = (event, rowData) => {
        if (rowData.of === clickedOf) {
            setClickedOf(null);
            onRowClick(null);
        } else {
            onRowClick(rowData);
            setClickedOf(rowData.of);
        }
    };

    return (
        <Card>
            <Box display="flex" justifyContent="center" mb={2} mt={2}>
                <TextField
                    label="Ref"
                    variant="outlined"
                    value={refFilter}
                    size='small'
                    onChange={(e) => setRefFilter(e.target.value)}
                />
                <TextField
                    label="N° OF"
                    variant="outlined"
                    value={filter}
                    size='small'
                    onChange={(e) => setFilter(e.target.value)}
                    style={{ marginLeft: 10 }}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={showReservable}
                            onChange={(e) => setShowReservable(e.target.checked)}
                            name="showReservable"
                            color="primary"
                            style={{ marginLeft: 10 }}
                        />
                    }
                    label="Réservable"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={showReserved}
                            onChange={(e) => setShowReserved(e.target.checked)}
                            name="showReserved"
                            color="primary"
                            style={{ marginLeft: 10 }}
                        />
                    }
                    label="Réservé"
                />
            </Box>

            <MaterialTable
                columns={[
                    { title: 'OF', field: 'of', width: 'auto', type: 'numeric' },
                    { title: 'Métal', field: 'metal', width: 'auto' },
                    { title: 'Ref', field: 'ref', width: 'auto', type: 'numeric', align: 'center' },
                    {
                        title: 'Designation',
                        field: 'designation',
                        width: 'auto',
                        render: rowData => rowData.designation.length > 15
                            ? `${rowData.designation.substring(0, 15)}...`
                            : rowData.designation
                    },
                    { title: 'Statut', field: 'statut', width: 'auto' },
                    {
                        title: 'Client', field: 'client', width: 'auto',
                        render: rowData => rowData.client.length > 20
                            ? `${rowData.client.substring(0, 20)}...`
                            : rowData.client
                    },
                    {
                        title: 'Doigt', field: 'dim', width: 'auto', type: 'numeric', align: 'center',
                        render: rowData => rowData.dim === '0'
                            ? ``
                            : rowData.dim
                    },
                ]}
                data={filteredData}
                onRowClick={handleRowClick}
                options={{
                    maxBodyHeight: 450,
                    minBodyHeight: 450,
                    pageSize: 12,
                    overflowY: 'hidden',
                    pageSizeOptions: [12, 15, 30],
                    search: false,
                    padding: 'dense',
                    rowStyle: rowData => ({
                        backgroundColor: rowData.of === clickedOf && Number(rowData.of_reservation) !== 0 ? 'rgba(41, 237, 81, 1)' :
                            rowData.of === clickedOf ? 'rgba(53, 154, 255,1)' :
                                Number(rowData.of_reservation) !== 0 ? 'rgba(41, 237, 81, 0.5)' :
                                    !rowData.dejaReserve ? 'rgba(155, 205, 255, 1)' : 'inherit'
                    })
                }}
                title={""}
                components={{
                    Toolbar: props => (
                        <Toolbar>
                            <div style={{ flex: 1, textAlign: 'center' }}>
                                <Typography variant="h6">Produits en Commande</Typography>
                                <Box display="flex" justifyContent="center" mb={2}>
                                    {countReservable !== 0 && <Typography variant="body2" style={{ backgroundColor: 'rgba(155, 205, 255, 1)', padding: '2px 8px', borderRadius: '4px', marginRight: '10px' }}>
                                        Réservable(s): {countReservable}
                                    </Typography>}
                                    {countReserve !== 0 && <Typography variant="body2" style={{ backgroundColor: 'rgba(41, 237, 81, 0.5)', padding: '2px 8px', borderRadius: '4px', marginRight: '10px' }}>
                                        Réservé(s): {countReserve}
                                    </Typography>}
                                </Box>
                            </div>
                        </Toolbar>
                    ),
                }}
                localization={localization}
            />
        </Card>
    );
};

export default TableProduit;
