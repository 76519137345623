import React, { useState, useEffect } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { euroFormat } from '../../helpers/functions';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';


const ComparaisonCaMois = ({ data }) => {
    const [chartData, setChartData] = useState({ labels: [], datasets: [] });
    const [chartType, setChartType] = useState('bar');

    useEffect(() => {
        if (data && data.current && data.previous) {
            const moisEnFrancais = [
                "Janvier", "Février", "Mars", "Avril", "Mai", "Juin",
                "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"
            ];

            const uniqueMonths = Array.from(new Set(data.current.map(item => item.mois)));
            const updatedChartData = {
                labels: uniqueMonths.map(month => moisEnFrancais[parseInt(month) - 1]),
                datasets: [
                    {
                        label: "CA",
                        backgroundColor: "rgba(75,192,192,0.4)",
                        borderColor: "rgba(75,192,192,1)",
                        hoverBackgroundColor: "rgba(75,192,192,0.6)",
                        hoverBorderColor: "rgba(75,192,192,1)",
                        data: uniqueMonths.map(month => {
                            const totalCurrentCA = data.current.find(item => item.mois === month);
                            return totalCurrentCA ? parseFloat(totalCurrentCA.totalCA) : 0;
                        }),
                        stack: 'Stack 0',
                    },
                    {
                        label: "CA (Année précedente)",
                        backgroundColor: "rgba(255,99,132,0.4)",
                        borderColor: "rgba(255,99,132,1)",
                        hoverBackgroundColor: "rgba(255,99,132,1)",
                        hoverBorderColor: "rgba(255,99,132,1)",
                        data: uniqueMonths.map(month => {
                            const totalPreviousCA = data.previous.find(item => item.mois === month);
                            return totalPreviousCA ? parseFloat(totalPreviousCA.totalCA) : 0;
                        }),
                        stack: 'Stack 1',
                    },
                ]
            };

            setChartData(updatedChartData);
        }
    }, [data]);

    const handleChangeChartType = (type) => {
        setChartType(type);
    };

    const options = {
        scales: {
            xAxes: [{
                scaleLabel: {
                    display: true,
                    labelString: "Mois"
                },
                stacked: true,
            }],
            yAxes: [{
                scaleLabel: {
                    display: true,
                    labelString: "CA HT"
                },
                ticks: {
                    beginAtZero: true,
                },
                stacked: true,
            }]
        },
        title: {
            display: true,
            text: "Comparaison CA HT par Mois"
        },
        tooltips: {
            callbacks: {
                label: function (tooltipItem, data) {
                    let label = data.datasets[tooltipItem.datasetIndex].label || '';
                    if (label) {
                        label += ': ';
                    }
                    label += euroFormat(Math.round(tooltipItem.yLabel * 100) / 100);
                    return label;
                },
                // afterBody: function (tooltipItem, data) {
                //     const total = data.datasets[0].data[tooltipItem[0].index] + data.datasets[1].data[tooltipItem[0].index];
                //     return 'Total: ' + euroFormat(total);
                // }
            }
        },
        elements: {
            line: {
                fill: false,

            },
            point: {
                radius: 0 // Masquer les points sur la ligne
            }
        }
    };

    return (
        <div style={{ textAlign: 'center' }}>
            <ButtonGroup variant="outlined" aria-label="Basic button group">
                <Button onClick={() => handleChangeChartType('bar')}>Barres</Button>
                <Button onClick={() => handleChangeChartType('line')}>Lignes</Button>
            </ButtonGroup>
            {chartType === 'bar' ? (
                <Bar data={chartData} options={options} height={80} />
            ) : (
                <Line data={chartData} options={options} height={80} />
            )}
        </div>
    );
};

export default ComparaisonCaMois;
