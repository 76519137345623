import Axios from 'axios';
import React, { Component } from 'react';
import { imagesRepo } from '../config';
import imageCompression from 'browser-image-compression';
import { roundPrecision2 } from '../helpers/functions';
import { TextField } from '@material-ui/core';

class ImageLoader extends Component {
    state = {
        files: [],
    };

    options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1000,
        useWebWorker: true,
    };

    resizeImage = async (imageFile) => {
        try {
            return await imageCompression(imageFile, this.options);
        } catch (error) {
            console.error("Erreur de compression de l'image :", error);
            return null;
        }
    }

    handleImageUpload = async (event) => {
        if (!event.target.files) return;

        const imageFiles = Array.from(event.target.files);
        const resizedImages = await Promise.all(
            imageFiles.map(async (img) => {
                const resized = await this.resizeImage(img);
                if (resized) {
                    return {
                        initialSize: img.size,
                        image: resized,
                    };
                }
                return null;
            })
        );

        this.setState((prevState) => ({
            files: [...prevState.files, ...resizedImages.filter(Boolean)],
        }));
    }

    removeFile = (file) => {
        this.setState((prevState) => ({
            files: prevState.files.filter((img) => img.image.name !== file.image.name),
        }));
    }

    rename = (oldName, newName) => {
        this.setState((prevState) => ({
            files: prevState.files.map((img) => {
                if (img.image.name === oldName) {
                    img.image = new File([img.image], newName, { type: img.image.type });
                }
                return img;
            }),
        }));
    }

    onFileUpload = async () => {
        const results = await Promise.allSettled(
            this.state.files.map(async (f) => {
                const formData = new FormData();
                formData.append("image", f.image, f.image.name);

                try {
                    const response = await Axios.post(`${imagesRepo}upload.php`, formData);
                    console.log(`Upload réussi pour l'image: ${f.image.name}`, response);
                    this.removeFile(f);
                    return { success: true, imageName: f.image.name };
                } catch (error) {
                    console.error(`Erreur lors de l'upload de l'image: ${f.image.name}`, error);
                    return { success: false, imageName: f.image.name, error };
                }
            })
        );

        // Affichage des résultats après toutes les requêtes
        results.forEach(result => {
            if (result.status === "fulfilled" && result.value.success) {
                console.log(`Upload réussi: ${result.value.imageName}`);
            } else {
                console.warn(`Upload échoué pour: ${result.value.imageName}`, result.value.error);
            }
        });
    };


    renderImage = (file) => {
        return (
            <div className="col-auto" key={file.image.name}>
                <div>
                    <div className='d-flex justify-content-center'>
                        <img style={{ maxWidth: 300, maxHeight: 300, minWidth: 300, minHeight: 300 }} src={URL.createObjectURL(file.image)} alt='' />
                    </div>
                    <h3 className='text-center'> {file.image.name}</h3>
                    <TextField
                        inputProps={{ style: { textAlign: 'center' } }}
                        fullWidth
                        variant='outlined'
                        autoFocus
                        defaultValue={file.image.name}
                        onBlur={(e) => this.rename(file.image.name, e.target.value)}
                    />
                    <p className='text-center'> Taille Initiale: {roundPrecision2(file.initialSize / 1024 / 1024)} Mo</p>
                    <p className='text-center'> Taille Compressée: {roundPrecision2(file.image.size / 1024 / 1024)} Mo</p>
                    <p className='text-center'> Gain Compression: {roundPrecision2((1 - file.image.size / file.initialSize) * 100)} %</p>
                    <div className='d-flex justify-content-center'>
                        <button onClick={() => this.removeFile(file)} className="btn btn-link btn-sm">Supprimer</button>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <div>
                <h3>
                    {this.state.files.length !== 0 ? `Uploader ${this.state.files.length} Image(s)` : 'Uploader une Image'}
                </h3>
                <div className='form-group'>
                    <input
                        style={{ display: 'none' }}
                        ref={(fileInput) => (this.fileInput = fileInput)}
                        type="file"
                        multiple
                        accept="image/*"
                        onChange={this.handleImageUpload}
                    />
                    <button onClick={() => this.fileInput.click()} style={{ color: '#007bff' }} className='btn btn-primary btn-lg'>
                        Importer Image(s)
                    </button>

                    {this.state.files.length !== 0 && (
                        <button onClick={this.onFileUpload} style={{ color: '#007bff' }} className='btn btn-primary btn-lg'>
                            Uploader!
                        </button>
                    )}
                </div>

                {this.state.files.length !== 0 && (
                    <div className="row">
                        {this.state.files.map((file) => this.renderImage(file))}
                    </div>
                )}
            </div>
        );
    }
}

export default ImageLoader;
