import React from 'react';
import { Doughnut } from 'react-chartjs-2';
const DoughnutChartApp = ({ label = 'Joaillier', temps = 6, onSelect = function () { } }) => {
    const data = {
        labels: [
            'Heures effectuées',
            'Heures non renseignées',
        ],
        datasets: [{
            data: [temps, 7 - temps],
            backgroundColor: [
                '#36A2EB',
                '#FF6384',
            ],
            hoverBackgroundColor: [
                '#2c90d3',
                '#e85874',
            ],
            text: 'Boris'
        }]
    };

    return (

        <div onClick={onSelect} className="row justify-content-md-center">

            <div className="col-md-auto">
                <Doughnut
                    options={{
                        responsive: true,
                        maintainAspectRatio: true,
                        legend: false,

                    }}
                    data={data}
                    height={60}
                />
            </div>
            <div className="col col-lg-1">
                {label}
            </div>

        </div>


    );
}
export default DoughnutChartApp;