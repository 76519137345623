import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getStade, getMetaux, getTypeProduit, getAnalyseMetal, getTypeCommande } from '../../actions';
import FilterSelect from '../../components/filterSelect';
import TreeClient from '../../components/treeClient';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { calculateFriday } from '../../helpers/functions';
import Axios from 'axios';
import { meekroDB } from '../../config';

const FiltrePlanning = ({
    getStade, getMetaux, getTypeProduit, getTypeCommande,
    listeStade, listeTypeProduit, listeMetaux, listeTypeCommande, setListeOfs, print,
}) => {

    const [state, setState] = useState({
        stade: '',
        metal: '',
        type: '',
        client: '',
        tournee: null,
        fin: calculateFriday(),
        devis: null,
        imperatif: null,
        typecommande: null,
    });
    const dataTypeCommande = [{ id: 2, description: 'TOUT' }, { id: 1, description: 'TOURNEE' }, { id: 0, description: 'NORMAL' }];
    const dataImperatif = [{ id: 2, description: 'TOUT' }, { id: 1, description: 'IMPERATIF' }, { id: 0, description: 'NORMAL' }];
    useEffect(() => {
        getStade();
        getMetaux();
        getTypeProduit();
        getTypeCommande();
    }, [getStade, getMetaux, getTypeProduit, getTypeCommande]);


    const getFilter = () => {
        const data = {
            stade: state.stade,
            metal: state.metal,
            type: state.type,
            client: state.client,
            tournee: state.tournee ? state.tournee.id : 2,
            fin: state.fin,
            imperatif: state.imperatif ? state.imperatif.id : 2,
            type_commande: state.typecommande ? state.typecommande : 0,
        };
        return data;
    };

    const handleChange = (data, labelState) => {
        const label = labelState.toLowerCase();
        setState({ ...state, [label]: data });
    };
    const onChange = (data, labelState) => {
        const label = labelState.toLowerCase();
        setState({ ...state, [label]: data });
    };

    const handleChangeClient = (data) => {
        setState({ ...state, client: data });
    };
    const afficher = () => {
        Axios.post(`${meekroDB}/of/planning.php`, getFilter())
            .then(response => {
                const ofs = {
                    dateF: state.fin,
                    dataOfs: response.data,
                }
                setListeOfs(ofs);
            })
            .catch(error => {
                console.log(error);
            });
    }
    return (
        <div className='row'>
            <div className="col-auto" style={{ minWidth: 275, maxWidth: 275 }}>
                <TreeClient handleChange={handleChangeClient} />
            </div>

            <div className="col-auto">
                <div className='form-group' style={{ width: "165px" }}>
                    <TextField type='date' value={state.fin} onChange={e => e.target.value && handleChange(e.target.value, 'fin')} label='Fin' variant="outlined" margin="dense" />
                    <Autocomplete
                        options={dataTypeCommande}
                        getOptionLabel={(option) => option.description}
                        onChange={(e, values) => setState({ ...state, tournee: values })}
                        autoHighlight
                        autoComplete
                        clearOnEscape
                        style={{ width: "165px" }}
                        renderInput={(params) => <TextField  {...params} label="Type Commande" variant="outlined" margin="dense" size='small' />}
                    />
                    <Autocomplete
                        options={dataImperatif}
                        getOptionLabel={(option) => option.description}
                        onChange={(e, values) => setState({ ...state, imperatif: values })}
                        autoHighlight
                        autoComplete
                        clearOnEscape
                        style={{ width: "165px" }}
                        renderInput={(params) => <TextField  {...params} label="Impératif" variant="outlined" margin="dense" size='small' />}
                    />
                </div>
            </div>
            <div className="col-sm" style={{ padding: '0 2px' }}>
                <FilterSelect label='Type Commande' labelId='id' labelDescription="label" onChange={(e) => { onChange(e, 'typeCommande') }} options={listeTypeCommande} />
            </div>
            <div className="col-sm" >
                <FilterSelect label='Stade' labelId='id_stade' onChange={onChange} options={listeStade} />
            </div>
            <div className="col-sm" >
                <FilterSelect label='Metal' labelId='id_type' onChange={onChange} options={listeMetaux} />
            </div>
            <div className="col-sm" >
                <FilterSelect label='Type' labelId='id_typ_prod' onChange={onChange} options={listeTypeProduit} />
            </div>
            <div className="col-auto" >
                <button style={{ minHeight: 200 }} className='btn btn-primary btn-lg' onClick={afficher}>Afficher</button>
            </div>
            <div className="col-auto" >
                <button style={{ minHeight: 200 }} className='btn btn-primary btn-lg' onClick={print}>Imprimer</button>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        listeStade: state.generalData.listeStade,
        listeTypeProduit: state.generalData.listeTypeProduit,
        listeMetaux: state.generalData.listeMetaux,
        listeTypeCommande: state.generalData.typeCommande,
        analyseMetal: state.analyse.analyseMetal,
        listeOfs: state.ofs.listeOf,
    };
};

const mapDispatchToProps = {
    getStade,
    getMetaux,
    getTypeProduit,
    getAnalyseMetal,
    getTypeCommande,
};

export default connect(mapStateToProps, mapDispatchToProps)(FiltrePlanning);
