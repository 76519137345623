import React from 'react';
import { Card, CardContent, Avatar, Typography, Button, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { apiATC, imagesRepo } from '../../../config';
import Axios from 'axios';
import { getAtelier, getListeJoaillier } from '../../../actions';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles({
    card: {
        width: 200,
        transition: 'background-color 0.3s',
        '&:hover': {
            backgroundColor: '#e3f2fd', // Bleu clair pour le survol
        },
    },
    selectedCard: {
        backgroundColor: '#56baed', // Bleu clair pour la sélection
    },
    redText: {
        color: 'red',
    },
    clickableText: {
        color: '#1e88e5', // Bleu foncé pour le texte cliquable
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline', // Souligner le texte au survol
        },
    },
    tooltip: {
        fontSize: '0.8em', // Increase font size
    },
});

const CardOuvrier = ({ joaillier, boiteOr, ofs, isSelected }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const fairePesee = (e) => {
        e.stopPropagation();
        try {
            Axios.post(`${apiATC}/atelier/ouvrier.php?action=pesee&id_ouvrier=${joaillier.id}`)
                .then(response => {
                    dispatch(getAtelier());
                    dispatch(getListeJoaillier());
                })
                .catch(error => {
                    console.log(error);
                });
        } catch (error) {
            console.error("Une erreur s'est produite lors de la mise à jour des listes :", error);
        }
    }
const showPesee =()=>{
    return ofs.listeOf.length !== 0 || (boiteOr.solde === 0 && ofs.solde===0)
}
    return (
        <>
            <Card className={`${classes.card} ${isSelected ? classes.selectedCard : ''}`}>
                <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: 0 }}>
                    <Avatar src={`${imagesRepo}/avatar/${joaillier.avatar}`} alt={joaillier.label} style={{ width: 100, height: 100 }} />
                    <Typography variant="h6" gutterBottom>
                        {joaillier.label}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        <span className={classes.redText}>{ofs.listeOf.length}</span> Ofs en Cours
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        <span>Boîte Or</span>: {boiteOr.solde} Gr
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Ofs : {ofs.solde} Gr
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Solde : {(boiteOr.solde + ofs.solde).toFixed(2)} Gr
                    </Typography>
                    <Tooltip classes={{ tooltip: classes.tooltip }} title={ofs.listeOf.length !== 0 ? "Pesée impossible car Of(s) en cours ou déja effectuée" : ""}>
                        <span>
                            <Button size='small' variant="contained" color="primary" onClick={fairePesee} disabled={showPesee()}>
                                Faire Pesée
                            </Button>
                        </span>
                    </Tooltip>
                </CardContent>
            </Card>
        </>
    );
}

export default CardOuvrier;
