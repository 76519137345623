import {
    SET_AUTHENTIFICATION, INCREMENT_ACTIONS_COUNT,
    ADD_RESSOURCE, GET_COURS, GET_LISTE_PRODUIT,
    GET_LISTE_CLIENT, GET_TYPE_TRANSPORT,
    GET_LISTE_METAUX, GET_LISTE_STADE, ADD_OF,
    UPDATE_OF, DELETE_OF, DELETE_OFS, SET_PRODUIT,
    GET_OFS, GET_OF_FROM_ID, ADD_OF_TO_FACT, DELETE_OF_TO_FACT,
    GET_LISTE_TYPE_PRODUIT, GET_LISTE_STATUT, GET_OF_EN_COURS,
    GET_OF_LIVRE, GET_OF_LIVRE_EXTENSIA, GET_LISTE_JOAILLIER, GET_LISTE_ATELIER,
    GET_LISTE_SERTISSEUR,
    GET_LISTE_OF_SERTI,
    GET_LISTE_FOURNISSEUR,
    GET_LISTE_PIERRE,
    GET_LISTE_CONFIE_PIERRE,
    GET_LISTE_BOITE_OR,
    GET_LISTE_TYPE_PIERRE,
    GET_LISTE_FORME_PIERRE,
    GET_LISTE_ETAT_PIERRE,
    GET_STOCK_PRODUIT,
    GET_TYPE_TARIF,
    SET_OF_TO_EDIT,
    GET_TYPE_COM,
    GET_LISTE_COULEUR_PIERRE,
    GET_LISTE_QUALITE_PIERRE,
    GET_LISTE_AVOIR,
    GET_LISTE_IMAGE,
    GET_LISTE_RETOUR_PIERRE,
    GET_TYPE_TVA,
    GET_CA_GROUPED_BY_MONTH,
    GET_ANALYSE_CA,
    GET_ANALYSE_METAL,
    GET_ANALYSE_FOURNISSEUR,
    GET_TYPE_COMMANDE,
    GET_USER,
    GET_ANALYSE_TRANCHE,
    GET_ANALYSE_CA_TARIF,
    SET_SEARCH,
    GET_COMPARAION_CA_GROUPED_BY_MONTH,
    GET_COMPARAION_CA_GROUPED_BY_YEAR,
    GET_ATELIER,
} from './action-types';
import axios from 'axios'
import { apiATC, meekroDB } from '../config'


export function setAuthentification(isLoggedIn) {
    return {
        type: SET_AUTHENTIFICATION,
        payload: isLoggedIn
    };
}
export function setSearch(search) {
    return {
        type: SET_SEARCH,
        payload: search
    };
}
export function setLogin(user, password) {
    return function (dispatch) {
        const data = {
            user: user.toLowerCase(),
            password: password.toLowerCase()
        }
        return axios.post(`${apiATC}/user/login.php?action=login`, data)
            .then(response => {
                if (response.data.length === 0) {
                    dispatch({ type: GET_USER, payload: -1 });
                    return false;
                }
                else if (response.data[0].role !== 1) {
                    window.location.href = "https://www.atcjoaillerie.fr/";
                }
                else {
                    //console.log(response.data[0].id_user);
                    if (response.data[0].id_user !== 0) {
                        const data = {
                            id_user: response.data[0].id_user,
                        }
                        axios.post(`${apiATC}/user/login.php?action=log`, data)
                            .catch(error => {
                                console.log(error);
                            });
                    }
                    localStorage.setItem('user', JSON.stringify(response.data))
                    dispatch({ type: GET_USER, payload: response.data });
                    return true;
                }

            })
            .catch(error => {
                console.log(error);
            });
    };
}
export function setOfToEdit(of) {
    return {
        type: SET_OF_TO_EDIT,
        payload: of
    };
}
export function addOf(of) {
    return {
        type: ADD_OF,
        payload: of
    };
}
export function addOfToFact(of) {
    return {
        type: ADD_OF_TO_FACT,
        payload: of
    };
}
export function deleteOfToFact(of) {
    return {
        type: DELETE_OF_TO_FACT,
        payload: of
    };
}
export function updateOf(of) {
    return {
        type: UPDATE_OF,
        payload: of
    };
}

export function deleteOf(of) {
    return {
        type: DELETE_OF,
        payload: of
    };
}
export function deleteOfs(of) {
    return {
        type: DELETE_OFS,
    };
}
export function setProduit(cv, centre) {

    return function (dispatch) {
        axios.get(`${apiATC}/produit/produit.php?action=detail&cv=${cv}&idp=${centre}`)
            .then(response => {
                dispatch({ type: SET_PRODUIT, payload: response.data });
            })
            .catch(error => {
                console.log(error);
            });
    };
}
export function incrementActionCount() {
    return {
        type: INCREMENT_ACTIONS_COUNT,
    };
}

export function addRessource() {
    return {
        type: ADD_RESSOURCE,
    };
}
export const getCours = () => async dispatch => {
    try {
        return axios.get(`${meekroDB}/general.php?table=cours&orderBy=description`)
            .then(response => {
                return dispatch({ type: GET_COURS, payload: response.data });
            })
            .catch(error => {
                console.log(error);
            });
    }
    catch (error) {
        console.log(error);
    }
}
// export function getCours() {
//     return function (dispatch) {
//         axios.get(`${meekroDB}/general.php?table=cours&orderBy=description`)
//             .then(response => {
//                 dispatch({ type: GET_COURS, payload: response.data });
//             })
//             .catch(error => {
//                 console.log(error);
//             });
//     };
// }
export function getAtelier() {
    return function (dispatch) {
        axios.get(`${apiATC}/atelier/ouvrier.php?action=liste_joaillier`)
            .then(response => {
                dispatch({ type: GET_ATELIER, payload: response.data });
            })
            .catch(error => {
                console.log(error);
            });
    };
}
export function getTypeCom() {
    return function (dispatch) {
        axios.get(`${meekroDB}/general.php?table=type_comission`)
            .then(response => {
                dispatch({ type: GET_TYPE_COM, payload: response.data });
            })
            .catch(error => {
                console.log(error);
            });
    };
}
export function getTypeProduit() {
    return function (dispatch) {
        axios.get(`${apiATC}/general.php?table=type_produit&orderBy=description`)
            .then(response => {
                dispatch({ type: GET_LISTE_TYPE_PRODUIT, payload: response.data });
            })
            .catch(error => {
                console.log(error);
            });
    };
}
export function getTypeTarif() {
    return function (dispatch) {
        axios.get(`${apiATC}/general.php?table=tarif_produit&orderBy=description`)
            .then(response => {
                dispatch({ type: GET_TYPE_TARIF, payload: response.data });
            })
            .catch(error => {
                console.log(error);
            });
    };
}
export function getStatut() {
    return function (dispatch) {
        axios.get(`${meekroDB}/general.php?table=t_statuts&orderBy=OF_interne`)
            .then(response => {
                dispatch({ type: GET_LISTE_STATUT, payload: response.data });
            })
            .catch(error => {
                console.log(error);
            });
    };
}
export function getTypeTransport() {
    return function (dispatch) {
        axios.get(`${meekroDB}/general.php?table=type_transport&orderBy=description`)
            .then(response => {
                dispatch({ type: GET_TYPE_TRANSPORT, payload: response.data });
            })
            .catch(error => {
                console.log(error);
            });
    };
}
export function getMetaux() {
    return function (dispatch) {
        axios.get(`${meekroDB}/general.php?table=type_metal&orderBy=description`)
            .then(response => {
                dispatch({ type: GET_LISTE_METAUX, payload: response.data });
            })
            .catch(error => {
                console.log(error);
            });
    };
}
export function getStade() {
    return function (dispatch) {
        axios.get(`${meekroDB}/general.php?table=stade&orderBy=description`)
            .then(response => {
                dispatch({ type: GET_LISTE_STADE, payload: response.data });
            })
            .catch(error => {
                console.log(error);
            });
    };
}

export function getListeJoaillier() {
    return function (dispatch) {
        axios.get(`${apiATC}/general.php?table=joaillier&orderBy=label`)
            .then(response => {
                dispatch({ type: GET_LISTE_JOAILLIER, payload: response.data });
            })
            .catch(error => {
                console.log(error);
            });
    };
}
export function getListeTypePierre() {
    return function (dispatch) {
        axios.get(`${meekroDB}/general.php?table=type_pier&orderBy=description`)
            .then(response => {
                dispatch({ type: GET_LISTE_TYPE_PIERRE, payload: response.data });
            })
            .catch(error => {
                console.log(error);
            });
    };
}
export function getListeFormePierre() {
    return function (dispatch) {
        axios.get(`${meekroDB}/general.php?table=forme_pier&orderBy=description`)
            .then(response => {
                dispatch({ type: GET_LISTE_FORME_PIERRE, payload: response.data });
            })
            .catch(error => {
                console.log(error);
            });
    };
}
export function getListeCouleurPierre() {
    return function (dispatch) {
        axios.get(`${meekroDB}/general.php?table=couleur&orderBy=description`)
            .then(response => {
                dispatch({ type: GET_LISTE_COULEUR_PIERRE, payload: response.data });
            })
            .catch(error => {
                console.log(error);
            });
    };
}
export function getListeQualitePierre() {
    return function (dispatch) {
        axios.get(`${meekroDB}/general.php?table=qualite&orderBy=description`)
            .then(response => {
                dispatch({ type: GET_LISTE_QUALITE_PIERRE, payload: response.data });
            })
            .catch(error => {
                console.log(error);
            });
    };
}
export function getListeEtatPierre() {
    return function (dispatch) {
        axios.get(`${meekroDB}/general.php?table=pierres_recues_etat&orderBy=description`)
            .then(response => {
                dispatch({ type: GET_LISTE_ETAT_PIERRE, payload: response.data });
            })
            .catch(error => {
                console.log(error);
            });
    };
}
export function getListeBoiteOr() {
    return function (dispatch) {
        // Retourner la promesse renvoyée par Axios
        return axios.get(`${meekroDB}/atelier/boite_or.php`)
            .then(response => {
                dispatch({ type: GET_LISTE_BOITE_OR, payload: response.data });
            })
            .catch(error => {
                console.log(error);
            });
    };
}

export function getListeSertisseur() {
    return function (dispatch) {
        axios.get(`${apiATC}/general.php?table=sertisseur&orderBy=label`)
            .then(response => {
                dispatch({ type: GET_LISTE_SERTISSEUR, payload: response.data });
            })
            .catch(error => {
                console.log(error);
            });
    };
}
export function getListeFournisseur() {
    return function (dispatch) {
        axios.get(`${meekroDB}/general.php?table=fournisseur&orderBy=raison_soc`)
            .then(response => {
                dispatch({ type: GET_LISTE_FOURNISSEUR, payload: response.data });
            })
            .catch(error => {
                console.log(error);
            });
    };
}

export const getListePierre = () => async dispatch => {
    try {
        return axios.get(`${apiATC}/pierre/pierre.php?action=liste`)
            .then(response => {
                return dispatch({ type: GET_LISTE_PIERRE, payload: response.data });
            })
            .catch(error => {
                console.log(error);
            });
    }
    catch (error) {
        console.log(error);
    }
}

export function getListeRetourPierre() {
    return function (dispatch) {
        axios.get(`${meekroDB}/general.php?table=pierre_retour`)
            .then(response => {
                dispatch({ type: GET_LISTE_RETOUR_PIERRE, payload: response.data });
            })
            .catch(error => {
                console.log(error);
            });
    };
}
export function getListeTva() {
    return function (dispatch) {
        axios.get(`${meekroDB}/general.php?table=tva`)
            .then(response => {
                dispatch({ type: GET_TYPE_TVA, payload: response.data });
            })
            .catch(error => {
                console.log(error);
            });
    };
}
export function getTypeCommande() {
    return function (dispatch) {
        axios.get(`${meekroDB}/general.php?table=type_commande`)
            .then(response => {
                dispatch({ type: GET_TYPE_COMMANDE, payload: response.data });
            })
            .catch(error => {
                console.log(error);
            });
    };
}

export function getListeConfiePierre() {
    return function (dispatch) {
        axios.get(`${meekroDB}/pierre/confie.php`)
            .then(response => {
                dispatch({ type: GET_LISTE_CONFIE_PIERRE, payload: response.data });
            })
            .catch(error => {
                console.log(error);
            });
    };
}
export function getListeAtelier(of = '') {
    return function (dispatch) {
        return axios.get(`${apiATC}/of/atelier.php?of=${of}`)
            .then(response => {
                dispatch({ type: GET_LISTE_ATELIER, payload: response.data });
            })
            .catch(error => {
                console.log(error);
            });
    };

}
export function getListeOfSerti(of = '') {
    return function (dispatch) {
        return axios.post(`${meekroDB}/atelier/serti.php?of=${of}`)
            .then(response => {
                dispatch({ type: GET_LISTE_OF_SERTI, payload: response.data });
            })
            .catch(error => {
                console.log(error);
            });
    };

}
export function getListeProduit() {
    return function (dispatch) {
        axios.get(`${apiATC}/produit/produit.php?action=liste`)
            .then(response => {
                dispatch({ type: GET_LISTE_PRODUIT, payload: response.data });
            })
            .catch(error => {
                console.log(error);
            });
    };
}
export function getStockProduit() {
    return function (dispatch) {
        axios.post(`${apiATC}/produit/stock.php?action=liste`)
            .then(response => {
                dispatch({ type: GET_STOCK_PRODUIT, payload: response.data });
            })
            .catch(error => {
                console.log(error);
            });
    };
}
export function getListeImage() {
    return function (dispatch) {
        axios.get(`${meekroDB}/images/liste.php`)
            .then(response => {
                dispatch({ type: GET_LISTE_IMAGE, payload: response.data });
            })
            .catch(error => {
                console.log(error);
            });
    };
}
export function getListeAvoir() {
    return function (dispatch) {
        axios.get(`${meekroDB}/avoir/avoir.php`)
            .then(response => {
                dispatch({ type: GET_LISTE_AVOIR, payload: response.data });
            })
            .catch(error => {
                console.log(error);
            });
    };
}

export function getListeOf(of = '') {
    return function (dispatch) {
        return axios.get(`${meekroDB}/of/of.php?id=${of}`)
            .then(response => {
                dispatch({ type: GET_OFS, payload: response.data });
            })
            .catch(error => {
                console.log(error);
            });
    };

}
export function getOfFromId(id) {
    return function (dispatch) {
        return axios.post(`${apiATC}/of/trace_of.php?of=${id}`)
            .then(response => {
                //console.log(response.data);
                dispatch({ type: GET_OF_FROM_ID, payload: response.data });
            })
            .catch(error => {
                console.log(error);
            });
    };

}

export function getListeClient() {
    return function (dispatch) {
        axios.get(`${meekroDB}/client/client.php`)
            .then(response => {
                dispatch({ type: GET_LISTE_CLIENT, payload: response.data });
            })
            .catch(error => {
                console.log(error);
            });
    };
}
export const getOfEnCours = (filtre = []) => async (dispatch) => {
    try {
        return axios.put(`${meekroDB}/of/analyse_of.php`, filtre)
            .then(response => {
                return dispatch({ type: GET_OF_EN_COURS, payload: response.data });
            })
            .catch(error => {
                console.log(error);
            });
    }
    catch (error) {
        console.log(error);
    }


}
export const getOfLivre = (filtre = []) => async dispatch => {
    try {
        return axios.post(`${meekroDB}/of/analyse_of.php`, filtre)
            .then(response => {
                return dispatch({ type: GET_OF_LIVRE, payload: response.data });
            })
            .catch(error => {
                console.log(error);
            });
    }
    catch (error) {
        console.log(error);
    }
}
export const getOfLivreExtensia = (filtre = []) => async dispatch => {
    try {
        return axios.post(`${meekroDB}/of/analyse_extensia.php`, filtre)
            .then(response => {
                return dispatch({ type: GET_OF_LIVRE_EXTENSIA, payload: response.data });
            })
            .catch(error => {
                console.log(error);
            });
    }
    catch (error) {
        console.log(error);
    }
}

export const getCaGroupedByMonth = (filtre = []) => async (dispatch) => {
    try {
        return axios.put(`${meekroDB}/analyse/analyse_ca.php?action=ca_par_mois`, filtre)
            .then(response => {
                return dispatch({ type: GET_CA_GROUPED_BY_MONTH, payload: response.data });
            })
            .catch(error => {
                console.log(error);
            });
    }
    catch (error) {
        console.log(error);
    }
}
export const getComparaisonCaMois = (filtre = []) => async (dispatch) => {
    try {
        return axios.put(`${meekroDB}/analyse/analyse_ca.php?action=ca_par_mois_comparaison`, filtre)
            .then(response => {
                return dispatch({ type: GET_COMPARAION_CA_GROUPED_BY_MONTH, payload: response.data });
            })
            .catch(error => {
                console.log(error);
            });
    }
    catch (error) {
        console.log(error);
    }
}
export const getComparaisonCaAn = (filtre = []) => async (dispatch) => {
    try {
        return axios.put(`${meekroDB}/analyse/analyse_ca.php?action=ca_par_annee`, filtre)
            .then(response => {
                return dispatch({ type: GET_COMPARAION_CA_GROUPED_BY_YEAR, payload: response.data });
            })
            .catch(error => {
                console.log(error);
            });
    }
    catch (error) {
        console.log(error);
    }
}
export const getAnalyseCa = (filtre = []) => async (dispatch) => {
    try {
        return axios.put(`${meekroDB}/analyse/analyse_ca.php?action=top_vente`, filtre)
            .then(response => {
                return dispatch({ type: GET_ANALYSE_CA, payload: response.data });
            })
            .catch(error => {
                console.log(error);
            });
    }
    catch (error) {
        console.log(error);
    }
}
export const getAnalyseCaParTranche = (filtre = []) => async (dispatch) => {
    try {
        return axios.post(`${meekroDB}/analyse/analyse_ca.php?action=analyse_ca_tranche`, filtre)
            .then(response => {
                return dispatch({ type: GET_ANALYSE_TRANCHE, payload: response.data });
            })
            .catch(error => {
                console.log(error);
            });
    }
    catch (error) {
        console.log(error);
    }
}
export const getAnalyseCaParTarif = (filtre = []) => async (dispatch) => {
    try {
        return axios.post(`${meekroDB}/analyse/analyse_ca.php?action=analyse_type_tarif`, filtre)
            .then(response => {
                return dispatch({ type: GET_ANALYSE_CA_TARIF, payload: response.data });
            })
            .catch(error => {
                console.log(error);
            });
    }
    catch (error) {
        console.log(error);
    }
}

export const getAnalyseMetal = (filtre = []) => async (dispatch) => {
    try {
        return axios.post(`${meekroDB}/analyse/analyse_ca.php?action=analyse_metal`, filtre)
            .then(response => {
                return dispatch({ type: GET_ANALYSE_METAL, payload: response.data });
            })
            .catch(error => {
                console.log(error);
            });
    }
    catch (error) {
        console.log(error);
    }
}
export const getAnalyseFournisseur = (filtre = []) => async (dispatch) => {
    try {
        return axios.post(`${meekroDB}/analyse/analyse_fournisseur.php`, filtre)
            .then(response => {
                return dispatch({ type: GET_ANALYSE_FOURNISSEUR, payload: response.data });
            })
            .catch(error => {
                console.log(error);
            });
    }
    catch (error) {
        console.log(error);
    }
}
export function modifTarifProd(id) {
    return function (dispatch) {
        axios.get(`${meekroDB}/produit/modif_tarif_produit.php?centres=${id}`)
            .then(response => {
                dispatch({ type: '' });
            })
            .catch(error => {
                console.log(error);
            });
    };
}