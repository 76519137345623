import React, { useState, useEffect, useRef } from 'react';
import Button from '@material-ui/core/Button';
import MaterialTable from 'material-table';
import { localization } from '../../../config';
import { modifierJoaillier } from '../action/AtelierAction';
import JoaillierRetourForm from '../form/JoaillierRetourForm';

const RetourJoaillier = () => {
    const [data, setData] = useState([]);
    const [formValues, setFormValues] = useState(null);
    const dataRef = useRef(data);

    useEffect(() => {
        dataRef.current = data;
    }, [data]);

    const handleAjouter = (formData) => {
        const { of, poids, poidsR, temps } = formData;
        const newData = {
            id_ligne: of.id_ligne,
            joaillier: of.label,
            of: of.n_sachet,
            poids,
            poidsR,
            temps,
        };
        modifierJoaillier(newData)
            .then(() => {
                const existingIndex = data.findIndex(item => item.id_ligne === of.id_ligne);
                if (existingIndex !== -1) {
                    // Si le "N° OF" existe déjà, mettez à jour les données
                    setData(prevData => {
                        const updatedData = [...prevData];
                        updatedData[existingIndex] = newData;
                        return updatedData;
                    });
                } else {
                    // Sinon, ajoutez une nouvelle entrée
                    setData([newData, ...data]);
                }
            })

    };
    const handleSupprimer = async (rowData) => {
        const newData = {
            id_ligne: rowData.id_ligne,
            joaillier: rowData.joaillier,
            of: rowData.of,
            poids: rowData.poids,
            poidsR: 0,
            temps: 0,
            rendu: 0,
            date_retour: '0000-00-00',
        };
        modifierJoaillier(newData)
            .then(async () => {
                const index = data.indexOf(rowData);
                const newData = [...data];
                newData.splice(index, 1);
                setData(newData);
                setFormValues();
            })

    };

    const handleRowClick = (event, rowData) => {
        const populateData = {
            of: rowData.of,
            id_ligne: rowData.id_ligne,
            poids: rowData.poids,
            poidsR: rowData.poidsR,
            temps: rowData.temps,
            joaillier: rowData.joaillier,
        }
        setFormValues(populateData);

    };



    return (
        <div>
            <div className="row" style={{ display: 'flex', justifyContent: 'center' }}>
                <div className="col-md-4">
                    <JoaillierRetourForm onSubmitForm={handleAjouter} dataForm={formValues} />
                </div>
                <div className="col-md-7">
                    <MaterialTable
                        title=""
                        options={{
                            search: false,
                        }}
                        columns={[
                            { title: 'Joaillier', field: 'joaillier', align: 'center' },
                            { title: 'OF', field: 'of' },
                            { title: 'Poids D', field: 'poids' },
                            { title: 'Poids R', field: 'poidsR' },
                            { title: 'Temps', field: 'temps' },
                            {
                                title: '',
                                field: 'action',
                                render: rowData => (
                                    <Button
                                        variant="contained"
                                        size='small'
                                        color="secondary"
                                        onClick={(e) => { e.stopPropagation(); handleSupprimer(rowData) }}
                                    >
                                        Supprimer
                                    </Button>
                                ),
                                align: 'center'
                            }
                        ]}
                        components={{
                            Toolbar: props => (
                                <h1 style={{ textAlign: 'center' }}>Retour Joaillier</h1>
                            ),
                        }}
                        data={data}
                        localization={localization}
                        onRowClick={handleRowClick}
                    />

                </div>
            </div>
        </div>
    );
};



export default RetourJoaillier;
