import React, { useState } from 'react';
import TreeClient from '../../components/treeClient';
import { TextField } from '@material-ui/core';
import Axios from 'axios';
import { meekroDB } from '../../config';

const FiltreBesoin = ({ setListeBesoin }) => {

    const today = new Date();
    const deuxSemainesPlusTard = new Date(today.getTime() + (2 * 7 * 24 * 60 * 60 * 1000));
    function formatDate(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    const [state, setState] = useState({
        debut: formatDate(today),
        fin: formatDate(deuxSemainesPlusTard),
        client: '',
    });
    const getFilter = () => {
        const data = {
            client: state.client,
            debut: state.debut,
            fin: state.fin,
        };
        return data;
    };

    const handleChange = (data, labelState) => {
        const label = labelState.toLowerCase();
        data && setState({ ...state, [label]: data });
    };


    const handleChangeClient = (data) => {
        setState({ ...state, client: data });
    };
    const afficher = () => {
        Axios.post(`${meekroDB}/of/besoin.php`, getFilter())
            .then(response => {
                setListeBesoin(response.data);
            })
            .catch(error => {
                console.log(error);
            });
    }
    return (
        <div className='row justify-content-center'>
            < div className="col-auto" style={{ minWidth: 275, maxWidth: 275 }
            }>
                <TreeClient handleChange={handleChangeClient} />
            </div >

            <div className="col-auto">
                <div className="mb-2">
                    <TextField
                        type='date'
                        value={state.debut}
                        onChange={e => handleChange(e.target.value, 'debut')}
                        label='Début'
                        variant="outlined"
                        margin="dense"
                    />
                </div>
                <div>
                    <TextField
                        type='date'
                        value={state.fin}
                        onChange={e => handleChange(e.target.value, 'fin')}
                        label='Fin'
                        variant="outlined"
                        margin="dense"
                    />
                </div>
            </div>
            <div className="col-auto" >
                <button style={{ minHeight: 200 }} className='btn btn-primary btn-lg' onClick={afficher}>Afficher</button>
            </div>
        </div >

    );
};

export default (FiltreBesoin);
