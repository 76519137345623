import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Formik, useFormik } from 'formik'; // 1. Importation de Form
import * as Yup from 'yup';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import { getListeJoaillier } from '../../../actions';

const validationSchema = Yup.object().shape({
    joaillier: Yup.object().required('Joaillier obligatoire.'),
    poidsD: Yup.number(),
    poidsR: Yup.number(),
}).test('poidsD-poidsR', 'Au moins un des poids doit être non nul et non égal à "".', function (item) {
    return (item.poidsD !== undefined && item.poidsD !== '') || (item.poidsR !== undefined && item.poidsR !== '');
});



const BoiteOrForm = ({ listeJoaillier, getListeJoaillier, onSubmitForm }) => {
    const joaillierRef = useRef(null);
    const poidsDRef = useRef(null);
    const poidsRRef = useRef(null);

    const formik = useFormik({
        initialValues: {
            joaillier: null,
            poidsD: '',
            poidsR: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            onSubmitForm(values);
            resetForm();
            setFieldValue('joaillier', values.joaillier);
            poidsDRef.current.focus();
        },
    });

    useEffect(() => {
        getListeJoaillier();
    }, [getListeJoaillier]);

    const { values, errors, touched, handleChange, handleSubmit, handleBlur, setFieldValue } = formik;

    useEffect(() => {
        if (values.joaillier && poidsDRef.current) {
            poidsDRef.current.focus();
        }
    }, [values.joaillier]);

    const handleKeyPress = async (event, nextRef) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            event.persist();
            await formik.validateField(event.target.name);
            const error = formik.errors[event.target.name];
            formik.setFieldTouched(event.target.name)
            console.log(error);
            if (!error) {
                nextRef.current.focus();
            }
        }
    };
    return (
        <Formik initialValues={values} onSubmit={handleSubmit} >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Autocomplete
                        options={listeJoaillier.filter(r => r.actif === 1)}
                        getOptionLabel={(option) => option.label}
                        value={values.joaillier}
                        onChange={(event, newValue) => setFieldValue('joaillier', newValue)}
                        autoHighlight
                        autoComplete
                        fullWidth
                        clearOnEscape
                        renderInput={(params) => <TextField {...params} label="Joaillier" variant="outlined" fullWidth
                            inputRef={joaillierRef}
                            autoFocus
                            error={errors.joaillier && touched.joaillier}
                            helperText={(errors.joaillier && touched.joaillier) ? errors.joaillier : null} />}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Poids Donné"
                        variant="outlined"
                        value={values.poidsD}
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="poidsD"
                        error={errors.poidsD && touched.poidsD}
                        helperText={(errors.poidsD && touched.poidsD) ? errors.poidsD : null}
                        inputRef={poidsDRef}
                        onKeyPress={(e) => handleKeyPress(e, poidsRRef)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Poids Rendu"
                        variant="outlined"
                        value={values.poidsR}
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="poidsR"
                        error={errors.poidsR && touched.poidsR}
                        helperText={(errors.poidsR && touched.poidsR) ? errors.poidsR : null}
                        inputRef={poidsRRef}
                        onKeyPress={async (e) => {
                            if (e.key === 'Enter') {
                                const errors = await formik.validateForm();
                                formik.setFieldTouched('poidsR')
                                console.log(errors);
                                if (!errors.poids) {
                                    formik.handleSubmit();
                                    poidsDRef.current.focus();
                                }
                            }
                        }}
                    />
                </Grid>

                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button variant="contained" color="primary" type="submit" onClick={handleSubmit}>Ajouter</Button>
                </Grid>
            </Grid>
        </Formik>

    );
};

const mapStateToProps = (state) => {
    return {
        listeJoaillier: state.generalData.listeJoaillier,
    };
};

const mapDispatchToProps = {
    getListeJoaillier,

};

export default connect(mapStateToProps, mapDispatchToProps)(BoiteOrForm);
