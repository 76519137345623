import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createMuiTheme, Dialog, DialogActions, DialogContent, DialogTitle, MuiThemeProvider, Button } from '@material-ui/core'
import MaterialTable, { MTableToolbar } from 'material-table';
import { localization } from '../../config';
import ClientForm from './ClientForm';

class Client extends Component {

    state = {
        editer: false,
    }
    add = () => {
        this.setState({ editer: true })
    }

    theme = createMuiTheme({
        palette: {
            primary: {
                main: '#4caf50',
            },
            secondary: {
                main: '#1363b9',
            },
        },

    });
    render() {
        return (
            <div>
                <MuiThemeProvider theme={this.theme}>
                    <MaterialTable
                        style={{ fontSize: 12 }}

                        columns={[
                            { title: 'N° Facture', field: 'id_fact', align: 'left', type: 'numeric' },
                            { title: 'Client', field: 'Nom_agence', align: 'left', defaultSort: null },
                            { title: 'Date Fact', field: 'date_fact', align: 'left', defaultSort: null },
                            { title: 'Total HT', field: 'totalHt', align: 'left', type: 'numeric' },
                            { title: 'TVA', field: 'tva', align: 'left', type: 'numeric' },

                        ]}
                        options={{
                            showTextRowsSelected: false,
                            pageSize: 10,
                            selection: true,

                            actionsColumnIndex: -1,
                            pageSizeOptions: [10, 20, 30, 100],

                        }}
                        onOrderChange={this.handleOnOrderChange}
                        actions={[
                            {
                                icon: 'add',
                                tooltip: 'Ajouter',
                                onClick: () => this.add(),
                                position: 'toolbar'

                            }
                        ]}
                        onSelectionChange={() => {
                            //this.calculateTotalSelected()
                        }}
                        //data={this.state.dataClone}
                        title="Liste Clients "

                        //onRowClick={this.handleRowClick}
                        components={{
                            Toolbar: props => (
                                <div>
                                    <MTableToolbar {...props} />
                                </div>
                            ),
                        }}
                        localization={localization}
                    />
                </MuiThemeProvider>
                <Dialog
                    open={this.state.editer}
                    onClose={() => this.setState({ editer: false })}
                    fullWidth={true}
                    maxWidth={'md'}
                    style={{ textAlign: "center" }}
                >
                    <DialogTitle id="form-dialog-title">Modification Pierre</DialogTitle>
                    <DialogContent >
                        <div className='d-flex justify-content-center'>
                            <ClientForm />
                        </div>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={() => this.setState({ editer: false })} color="primary">
                            Fermer
                            </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {

    }
}
const mapDispatchToProps = {
}
export default connect(mapStateToProps, mapDispatchToProps)(Client)