import React, { useEffect, useState } from 'react'
import {
    getListeProduit, getTypeProduit,
} from '../../actions'
import { connect } from 'react-redux'
import { Autocomplete } from '@material-ui/lab';
import { Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import MaterialTable from 'material-table';
import { apiATC, imagesRepo, localization } from '../../config';
import { List } from 'immutable';
import Axios from 'axios';
import Image from 'react-bootstrap/Image'

const CentreVide = ({ getListeProduit, listeRef, listeTypeProd, getTypeProduit }) => {
    useEffect(() => getListeProduit(), [getListeProduit]);
    useEffect(() => getTypeProduit(), [getTypeProduit]);

    const [id, setId] = useState('')
    const [ref, setRef] = useState('')
    const [type, setType] = useState(null)
    const [errorRef, setErrorRef] = useState('')

    const [facon, setFacon] = useState('')
    const [fsf, setFsf] = useState('')
    const [poids, setPoids] = useState('')
    const [serti, setSerti] = useState('')
    const [prenomLigne, setPrenomLigne] = useState('')
    const [okToAdd, setOkToAdd] = useState(true)
    const [mettreSurWordpress, setMettreSurWordpress] = useState(false);

    const onChangeRef = (ref) => {
        const exist = Object.values(listeRef).find(r => r.ref === ref)
        if (exist === undefined) {
            setErrorRef('')
            setOkToAdd(true)
        }
        else {
            setErrorRef('Réf déja Existante')
            setOkToAdd(false)
        }
        setRef(ref)

    }
    const returnList = () => {
        const data = List(listeRef)
        return data.toJS()
    }
    const handleClick = (event, rowData) => {
        event.preventDefault()
        onChangeRef(rowData.ref)
        setId(rowData.id_cv)
        setRef(rowData.ref)
        setFacon(rowData.facon)
        setFsf(rowData.facon_fonte)
        setPoids(rowData.poids_metal)
        setSerti(rowData.serti_min)
        setPrenomLigne(rowData.prenom_ligne)
        setMettreSurWordpress(rowData.wordpress === 1)

        const typeSelected = Object.values(listeTypeProd).find(r => r.id_typ_prod === rowData.type_prod)
        setType(typeSelected)

    }
    const handleSave = () => {
        const data = {
            table: 'centre_vide',
            primaryKey: 'id_cv',
            id,
            facon: facon,
            facon_fonte: fsf,
            poids_metal: poids,
            serti_min: serti,
            type_prod: type.id_typ_prod,
            prenom_ligne: prenomLigne,
            wordpress: mettreSurWordpress ? 1 : 0,

        }

        Axios.post(`${apiATC}/general.php?action=modifier`, data)
            .then((response) => {
                getListeProduit()
            })
            .catch(error => {
                console.log(error);
            });
    }
    const handleCreate = () => {
        const data = {
            table: 'centre_vide',
            ref,
            facon: facon,
            facon_fonte: fsf,
            poids_metal: poids,
            type_prod: type.id_typ_prod,
            serti_min: serti,
            prenom_ligne: prenomLigne,
            wordpress: mettreSurWordpress ? 1 : 0,
        }
        Axios.post(`${apiATC}/general.php?action=ajouter`, data)
            .then((response) => {
                getListeProduit()
            })
            .catch(error => {
                console.log(error);
            });
    }
    return (
        <div className="container-fluid" >
            <div className="row">
                <div className="col-sm">
                    <MaterialTable

                        style={{ fontSize: 12, zIndex: 0, padding: '0 !important' }}
                        columns={[
                            { align: 'center', title: 'Image', field: 'image', render: rowData => <Image key={rowData.ref} src={`${imagesRepo}thumbs.php?src=${rowData.image}&size=100`} style={{ width: 100, height: 100 }} rounded /> },
                            { title: 'Type', field: 'typeProd' },
                            { title: 'Ref', field: 'ref' },
                            { title: 'Facon', field: 'facon' },
                            { title: 'FSF', field: 'facon_fonte' },
                            { title: 'Poids', field: 'poids_metal' },
                            { title: 'Serti', field: 'serti_min' },
                            { title: 'Prenom', field: 'prenom_ligne' },
                        ]}

                        title={<div style={{ width: 170, fontSize: 16, display: "flex" }}>Centre Vide</div>}
                        data={returnList()}
                        actions={[
                            {
                                tooltip: 'Rafraichir',
                                icon: 'refresh',
                                onClick: () => getListeProduit(),
                                isFreeAction: true,
                            }
                        ]}
                        options={{
                            rowStyle: rowData => ({
                                backgroundColor: (id === rowData.id_cv) ? '#AED1FF' : ''
                            })
                        }}

                        onRowClick={handleClick}


                        localization={localization}
                    />
                </div>
                <div className="col-sm">
                    <form >
                        <div className="form-group">
                            <TextField label="Réf" variant="outlined"
                                value={ref}
                                onChange={e => onChangeRef(e.target.value)}
                            />
                            <p>
                                {errorRef}
                            </p>
                        </div>
                        <div className="form-group">
                            <TextField label="Facon" variant="outlined"
                                value={facon}
                                onChange={e => setFacon(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <TextField label="Facon sur Fonte" variant="outlined"
                                value={fsf}
                                onChange={e => setFsf(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <TextField label="Poids" variant="outlined"
                                value={poids}
                                onChange={e => setPoids(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <TextField label="Serti" variant="outlined"
                                value={serti}
                                onChange={e => setSerti(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <TextField label="Prénom Ligne" variant="outlined"
                                value={prenomLigne}
                                onChange={e => setPrenomLigne(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <Autocomplete
                                value={type}
                                options={listeTypeProd}
                                getOptionLabel={(option) => option.description}
                                onChange={(e, values) => setType(values)}

                                getOptionSelected={(option) => option.id_typ_prod === type.id_typ_prod}
                                autoHighlight
                                autoComplete
                                clearOnEscape
                                style={{ width: 222 }}
                                renderInput={(params) => <TextField autoFocus  {...params} label="Type" variant="outlined" />}
                            />
                        </div>
                        <div className="form-group">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={mettreSurWordpress}
                                        onChange={e => setMettreSurWordpress(e.target.checked)}
                                        color="primary"
                                    />
                                }
                                label="Afficher sur Wordpress"
                            />
                        </div>

                        <div className="form-group">
                            <button type="button" className="btn btn-link btn-sm" onClick={handleCreate} disabled={!okToAdd}>Ajouter</button>
                            <button type="button" className="btn btn-link btn-sm" onClick={handleSave} disabled={okToAdd}>Enregistrer</button>
                        </div>

                    </form>
                </div>
            </div>

        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        listeRef: state.produit.listeReference,
        listeTypeProd: state.generalData.listeTypeProduit,
    }
}
const mapDispatchToProps = {
    getListeProduit,
    getTypeProduit,

}
export default connect(mapStateToProps, mapDispatchToProps)(CentreVide)