import { GET_LISTE_PRODUIT, GET_STOCK_PRODUIT, SET_PRODUIT } from '../actions/action-types';

const initialState = {
    listeProduit: [],
    listeReference: [],
    selectedProduct: [],
    stockProduit: [],

}


export default function ProduitReducer(state = initialState, action) {
    switch (action.type) {
        case GET_LISTE_PRODUIT:
            return {
                ...state,
                listeProduit: action.payload.produit,
                listeReference: action.payload.ref
            }
        case SET_PRODUIT:
            return {
                ...state,
                selectedProduct: [action.payload]
            }
        case GET_STOCK_PRODUIT:
            return {
                ...state,
                stockProduit: [action.payload]
            }

        default:
            return state;

    }

}
