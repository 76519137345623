import React, { Component } from 'react'
import { getListeJoaillier } from '../../actions'
import { connect } from 'react-redux'
import DoughnutChartApp from '../../components/DoughnutChart'
import { TextField } from '@material-ui/core'
import { getTodaySqlFormat, roundPrecision2 } from '../../helpers/functions'
import Axios from 'axios'
import { localization, meekroDB } from '../../config'
import MaterialTable from 'material-table'

class AnalyseJoaillier extends Component {
    state = {
        date: getTodaySqlFormat(),
        data: [
            { joaillier: 'Joaillier', temps: 0 }
        ],
        detail: [],

    }
    componentDidMount() {
        this.props.getListeJoaillier()
    }
    changeDate = e => {
        const date = e.target.value
        Axios.get(`${meekroDB}/atelier/atelier.php?date=${date}`)
            .then(response => {
                this.setState({ date, data: response.data })
            })
            .catch(error => {
                console.log(error);
            });
    }
    selectJoaillier = joaillier => {
        Axios.put(`${meekroDB}/atelier/atelier.php?date=${this.state.date}&joaillier=${joaillier.id}`)
            .then(response => {
                this.setState({ detail: response.data })
            })
            .catch(error => {
                console.log(error);
            });
    }
    render() {
        const cards = Object.values(this.state.data)
            .map((joaillier, i) => <DoughnutChartApp onSelect={() => this.selectJoaillier(joaillier)} key={i} label={joaillier.label} temps={roundPrecision2(joaillier.tempsTotal / 60)} />)

        return (
            <div>
                <div className="form-group">
                    <TextField
                        variant="outlined"
                        value={this.state.date}
                        id="date"
                        label="Livraison"
                        type="date"
                        onChange={this.changeDate}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </div>
                <div className="row">
                    <div className="col-sm">
                        {cards}

                    </div>
                    <div className="col-sm">
                        <div className='form-group'>
                            <MaterialTable
                                style={{ fontSize: 12, zIndex: 0 }}
                                columns={[
                                    { title: 'OF', field: 'n_sachet', align: 'left', type: 'numeric' },
                                    { title: 'Joaillier', field: 'label' },
                                    { title: 'Poids', field: 'poids_d' },
                                    { title: 'Poids Rendu', field: 'poids_r' },
                                    { title: 'Temps', field: 'temps' },
                                    { title: 'Temps T', field: 'plus_mo' },
                                    { title: 'Ref', field: 'ref' },
                                    { title: 'Desc', field: 'refDesc' },
                                ]}

                                options={{
                                    actionsColumnIndex: -1,
                                    // rowStyle: rowData => ({
                                    //     backgroundColor: (this.selectedEnCours === rowData.num_sachet) ? '#AED1FF' : ''
                                    // })
                                }}
                                // actions={[

                                //     rowData => ({
                                //         icon: CheckIcon,
                                //         tooltip: 'Retour',
                                //         onClick: (e, rowData) => retourOf(rowData)
                                //     }),
                                //     rowData => ({
                                //         icon: EditIcon,
                                //         tooltip: 'Modifier',
                                //         onClick: (e, rowData) => editOf(rowData)
                                //     })
                                // ]}
                                data={this.state.detail}
                                title="Joaillier"

                                // components={{
                                //     Toolbar: props => (
                                //         <div className='row'>

                                //             <div className="col-sm">
                                //                 <button onClick={() => getListeAtelier()} className="btn btn-link">Rafraichir</button>
                                //             </div>

                                //             <div className="col-8">
                                //                 <MTableToolbar {...props} />
                                //             </div>

                                //         </div>
                                //     ),
                                // }}

                                //onRowClick={this.onRowClick}
                                localization={localization}
                            />
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        listeJoaillier: state.generalData.listeJoaillier,
    }
}
const mapDispatchToProps = {
    getListeJoaillier
}
export default connect(mapStateToProps, mapDispatchToProps)(AnalyseJoaillier)