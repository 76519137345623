import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getListeProduit } from '../../actions';
import { TextField, List, ListItem, Box, Typography } from '@material-ui/core';
import { imagesRepo } from '../../config';

const ListeProduits = ({ filtre, onFiltreChange, onProduitsSelect, selectedProduits, setSelectedProduits }) => {
    const dispatch = useDispatch();
    const listeRef = useSelector(state => state.produit.listeProduit);

    useEffect(() => {
        if (filtre.length >= 2) {
            dispatch(getListeProduit());
        }
    }, [dispatch, filtre]);

    const handleFiltreChange = (reference) => {
        onFiltreChange(reference);
    };

    const handleProduitClick = (item) => {
        const index = selectedProduits.indexOf(item);
        let newSelectedProduits;

        if (index === -1) {
            newSelectedProduits = [...selectedProduits, item];
        } else {
            newSelectedProduits = [...selectedProduits];
            newSelectedProduits.splice(index, 1);
        }

        setSelectedProduits(newSelectedProduits);
        onProduitsSelect(newSelectedProduits);
    };

    return (
        <Box display="flex" flexDirection="column" alignItems="center">
            <TextField
                label="Rechercher des produits..."
                variant="outlined"
                value={filtre}
                onChange={e => handleFiltreChange(e.target.value)}
                fullWidth
                style={{ marginBottom: '10px' }} // Add margin at the bottom of the TextField
            />
            <List style={{ width: '100%' }}>
                {listeRef
                    .filter(item => item.ref.includes(filtre) && filtre.length >= 2)
                    .map(item => (
                        <ListItem
                            key={item.id_centre}
                            button
                            selected={selectedProduits.includes(item)}
                            onClick={() => handleProduitClick(item)}
                            style={{
                                backgroundColor: selectedProduits.includes(item) ? '#4285f4' : 'transparent',
                                color: selectedProduits.includes(item) ? '#ffffff' : 'inherit',
                                padding: '0px', // Remove padding
                                margin: '0px', // Remove margin
                            }}
                        >
                            <img
                                alt={item.ref + " - " + item.description}
                                src={item.img_centre !== "no_pic.jpg" ? imagesRepo + item.img_centre : imagesRepo + item.image}
                                style={{ height: '100px', marginTop: '10px' }}
                            />
                            <Typography variant="body1" >
                                {item.ref + " - " + item.description}
                            </Typography>
                        </ListItem>
                    ))}
            </List>
        </Box>
    );
};

export default ListeProduits;
