import React, { Component } from 'react'
import { getListeOf, getListeConfiePierre } from '../../actions'
import { connect } from 'react-redux'
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { getTodaySqlFormat } from '../../helpers/functions'
import Axios from 'axios'
import { localization, meekroDB } from '../../config'
import { getOfs } from '../../selectors/ofsSelector'
import MaterialTable from 'material-table'
import { List } from 'immutable'
import DeleteIcon from '@material-ui/icons/Delete';


class ReceptionPierre extends Component {
    state = {
        of: null,
        confie: null,
        poids: '',
        pierresAlimentees: null,

    }
    componentDidMount() {
        this.props.getListeOf()
        this.props.getListeConfiePierre()
    }
    handleSave = () => {

        const data = {
            table: 'op_stock',
            date_op: getTodaySqlFormat(),
            poids_sortant: this.state.poids,
            of: this.state.of.num_sachet,
            id_stock: this.state.confie.id_conf,
        }
        Axios.post(`${meekroDB}/pierre/pierre_conso_remove.php`, data)
            .then((response) => {
                Axios.post(`${meekroDB}/general.php`, data)
                    .then((response) => {
                        this.selectOf(this.state.of)
                        this.resetForm()
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(error => {
                console.log(error);
            });



    }
    resetForm = () => {
        this.setState({
            confie: null,
            poids: '',
        })
    }
    returnList = () => {
        const data = List(this.state.pierresAlimentees)
        return data.toJS()
    }
    selectOf = (values) => {
        const of = values
        if (!of) this.setState({ of: null, pierresAlimentees: null })
        else {
            Axios.get(`${meekroDB}/pierre/alimentee.php?of=${of.num_sachet}`)
                .then((response) => {
                    // console.log(response.data);
                    this.setState({ of, pierresAlimentees: response.data })
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }
    deletePierreAlimentee = (data) => {
        if (window.confirm('Voulez vous supprimer ?')) {
            const datat = {
                table: 'op_stock',
                primaryKey: 'id_op',
                id: data.id_op,
            }
            //console.log(datat);
            Axios.post(`${meekroDB}/general_remove.php`, datat)
                .then((response) => {
                    this.selectOf(this.state.of)
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }
    render() {
        return (
            <div className="container-fluid" >
                <div className="row">
                    <div className="col-sm" >
                        <MaterialTable

                            style={{ fontSize: 12, zIndex: 0 }}
                            columns={[
                                { title: 'Id', field: 'id_conf' },
                                { title: 'N° Conf', field: 'no_confie' },
                                { title: 'Dim1', field: 'dim1' },
                                { title: 'Dim2', field: 'dim2' },
                                { title: 'Recu', field: 'poids' },
                                { title: 'Conso', field: 'totconso' },
                                { title: 'Euro', field: 'euro' },
                                { title: 'Dollar', field: 'dollar' },
                            ]}
                            options={{
                                actionsColumnIndex: -1,
                                // rowStyle: rowData => ({
                                //     backgroundColor: (this.selectedEnCours === rowData.num_sachet) ? '#AED1FF' : ''
                                // })
                            }}
                            actions={[
                                rowData => ({
                                    icon: DeleteIcon,
                                    tooltip: 'Supprimer',
                                    onClick: (e, rowData) => this.deletePierreAlimentee(rowData)
                                })
                            ]}
                            title={<div style={{ width: 170, fontSize: 16, display: "flex" }}>Pierres Alimentées</div>}
                            data={this.returnList()}
                            // onRowClick={this.handleClick}


                            localization={localization}
                        />
                    </div>
                    <div className="col-sm">
                        <form >
                            <div className="form-group">
                                <Autocomplete
                                    value={this.state.of}
                                    options={this.props.listeOfs}
                                    getOptionLabel={(option) => option.num_sachet}
                                    onChange={(e, values) => this.selectOf(values)}

                                    //getOptionSelected={(option) => option.num_sachet === this.state.ofAutoComplete.num_sachet}
                                    autoHighlight
                                    autoComplete
                                    clearOnEscape
                                    style={{ width: 222 }}
                                    renderInput={(params) => <TextField autoFocus onKeyPress={this.preventEnter} {...params} label="of" variant="outlined" />}
                                />
                            </div>
                            <div className="form-group">
                                <Autocomplete
                                    value={this.state.confie}
                                    options={this.props.listeConfie}
                                    getOptionLabel={(option) => option.id_conf}
                                    onChange={(event, values) => this.setState({ confie: values })}
                                    // getOptionSelected={(option) => option.id_confie === this.state.confie.id_confie}
                                    autoHighlight
                                    autoComplete
                                    clearOnEscape
                                    style={{ width: 222 }}

                                    renderInput={(params) => <TextField onKeyPress={this.prenventEnter} {...params} required label="Id Confié" variant="outlined" />}
                                />
                            </div>
                            <div className="form-group">
                                <TextField label="Poids" variant="outlined"
                                    value={this.state.poids}
                                    onChange={(e) => this.setState({ poids: e.target.value })}
                                />
                            </div>
                            <div className="form-group">
                                <button type="button" className="btn btn-link btn-sm" onClick={this.handleSave}>Enregistrer</button>
                            </div>

                        </form>
                    </div>
                </div>
            </div>







        )
    }
}
const mapStateToProps = (state) => {
    return {
        listeOfs: getOfs(state),
        listeConfie: state.pierre.listeConfiePierre,
    }
}
const mapDispatchToProps = {
    getListeOf,
    getListeConfiePierre,
}
export default connect(mapStateToProps, mapDispatchToProps)(ReceptionPierre)