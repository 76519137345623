import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

export default function (Component) {
    class RequireAuthentication extends React.Component {

        componentDidMount() {
            if (!this.props.isLoggedIn) {
                this.props.history.push("/login");
            }
        }
        // static getDerivedStateFromProps(nextProps) {

        //     if (!nextProps.isLoggedIn) {
        //         nextProps.history.push("/");
        //     }
        //     return { isLoggedIn: nextProps.isLoggedIn };
        // }


        render() {
            return this.props.isLoggedIn && <Component {...this.props} />
        }
    }

    const mapStateToProps = state => ({
        isLoggedIn: state.authentification.isLoggedIn
    });


    return withRouter(connect(mapStateToProps)(RequireAuthentication));
}