import React, { useEffect } from 'react';
import { TextField } from '@material-ui/core';
import { connect } from 'react-redux';
import { Autocomplete } from '@material-ui/lab';
import TreeClient from '../../components/treeClient';
import { getListeTva } from '../../actions'

const FiltreCompta = ({ debut, fin, tva, onChange, handleChangeClient, listeTva, getListeTva }) => {
    useEffect(() => {
        getListeTva();
    }, [getListeTva]);

    return (
        <div className='row align-items-start justify-content-center'>
            <div className="col-md-auto">
                <TreeClient handleChange={handleChangeClient} />
            </div>
            <div className='col-md-auto'>
                <div style={{ marginBottom: '10px' }}>
                    <TextField fullWidth type='date' value={debut} onChange={e => onChange(e.target.value, 'debut')} label='Début' variant="outlined" margin="dense" />
                </div>
                <div style={{ marginBottom: '10px' }}>
                    <TextField fullWidth type='date' value={fin} onChange={e => onChange(e.target.value, 'fin')} label='Fin' variant="outlined" margin="dense" />
                </div>
                <div>
                    <Autocomplete
                        value={tva}
                        options={listeTva}
                        getOptionLabel={(option) => option.description}
                        onChange={(event, values) => onChange(values, 'tva')}
                        autoHighlight
                        autoComplete
                        clearOnEscape
                        renderInput={(params) => <TextField  {...params} label="TVA" variant="outlined" margin="dense" />}
                    />
                </div>
            </div>

        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        listeTva: state.generalData.listeTva,
    };
};

const mapDispatchToProps = {
    getListeTva
}

export default connect(mapStateToProps, mapDispatchToProps)(FiltreCompta);
