import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import EnvoiSerti from './envoi/EnvoiSerti';
import RetourSertisseur from './retour/RetourSertisseur';

const MouvementSertisseur = () => {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', height: '100vh', padding: '10px' }}>
            <div style={{ marginRight: '10px', paddingRight: '10px', width: '50%' }}>
                <Card>
                    <CardContent>
                        <EnvoiSerti />
                    </CardContent>
                </Card>
            </div>
            <div style={{ marginLeft: '10px', paddingLeft: '10px', width: '50%' }}>
                <Card>
                    <CardContent>
                        <RetourSertisseur />
                    </CardContent>
                </Card>
            </div>
        </div>
    );
};

export default MouvementSertisseur;
